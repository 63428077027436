import { Dispatch, Action } from 'redux';
export type Theme = 'lightTheme' | 'darkTheme';

export interface ColorState {
    [key: string]: string;
}

export interface appState {
    themeChoice: 'LIGHT';
    theme:'lightTheme';
    lightTheme: ColorState;
    darkTheme: ColorState;
  
}
export const SWITCH_THEME = 'SWITCH_THEME';
export const RESET_COLORS = "RESET_COLORS";
export const SET_THEME_COLOR = 'SET_THEME_COLOR';


interface SwitchThemeAction extends Action<typeof SWITCH_THEME> { payload: { theme: string; } }
interface ResetColors extends Action<typeof RESET_COLORS> { payload: {theme: string;} }
interface SetThemeColorAction extends Action<typeof SET_THEME_COLOR> { payload: {theme: keyof ColorState; color: any;  property: Theme } };

export type ThemeActionTypes = SwitchThemeAction  | ResetColors| SetThemeColorAction 

const themeswitch = (theme: string): SwitchThemeAction => ({ type: SWITCH_THEME, payload: { theme } });
const ResetColors = (theme: string): ResetColors => ({ type: RESET_COLORS, payload: {theme} });
const ThemeColor = (color: any, theme: keyof ColorState, property: Theme): 
SetThemeColorAction => ({ type: SET_THEME_COLOR, payload: { color, theme, property } });
export const ThemeActions = {
    switchTheme: (theme: string) => (dispatch: Dispatch<any>) => { dispatch(themeswitch(theme)); },
    ResetColors: (theme: string) => (dispatch: Dispatch<any>) => { dispatch(ResetColors(theme)); },
    SetThemeColor: (color: any, theme: keyof ColorState, property: Theme) => (dispatch: Dispatch<any>) => (dispatch(ThemeColor(color, theme, property))),

};