import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { IconButton, Box, Divider, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material'
import ListItem from '@mui/material/ListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { version } from '../../../version'
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { TranslationType } from "../../../types/translationTypes";
import { connect } from "react-redux";
import { CustomTheme } from '../../../theme/index';
import ThemeSwitch from '../../switches/theme-switch';
import { ThemeActions } from '../../../redux/actions/themeActions'
import { getDictionary, getField } from '../../../utils/translation/translation';
import { Dispatch } from 'redux';
import useStyles from './styles';
import TranslateIcon from '@mui/icons-material/Translate';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { ReactComponent as Dashboard } from '../../../assets/images/menuIcons/dashboard.svg'
import { ReactComponent as EmployeeMeal } from '../../../assets/images/menuIcons/employeeMeal.svg'
import { ReactComponent as IncomingRequest } from '../../../assets/images/menuIcons/incomingRequest.svg'
import { ReactComponent as InternalRequest } from '../../../assets/images/menuIcons/internalRequest.svg'
import { ReactComponent as MyTask } from '../../../assets/images/menuIcons/myTask.svg'
import { ReactComponent as Outgoing } from '../../../assets/images/menuIcons/outgoing.svg'
import { ReactComponent as ProductsLookup } from '../../../assets/images/menuIcons/productsLookup.svg'
import { ReactComponent as Purchase } from '../../../assets/images/menuIcons/purchase.svg'
import { ReactComponent as PurchaseOrder } from '../../../assets/images/menuIcons/purchaseOrder.svg'
import { ReactComponent as Purchasing } from '../../../assets/images/menuIcons/purchasing.svg'
import { ReactComponent as Quotation } from '../../../assets/images/menuIcons/quotation.svg'
import { ReactComponent as Receiving } from '../../../assets/images/menuIcons/receiving.svg'
import { ReactComponent as Sales } from '../../../assets/images/menuIcons/sales.svg'
import { ReactComponent as StockTake } from '../../../assets/images/menuIcons/stockTake.svg'
import { ReactComponent as StockVariance } from '../../../assets/images/menuIcons/stockVariance.svg'
import { ReactComponent as Supplier } from '../../../assets/images/menuIcons/supplier.svg'
import { ReactComponent as TaskStatus } from '../../../assets/images/menuIcons/taskStatus.svg'
import { ReactComponent as Transfer } from '../../../assets/images/menuIcons/transfer.svg'
import { ReactComponent as Wastage } from '../../../assets/images/menuIcons/wastage.svg'

interface SideBarItem {
    id: string;
    title: string;
    type: "link" | "dropDown";
    path?: string;
    icon: any;
    sub?: SubSideBarItem[];
}

interface SubSideBarItem {
    id: string;
    title: string;
    path: string;
    icon: any
}
function MobileMenu({ state, toggleDrawer, translation, language, switchTheme, themeChoice, menuColor, setSelectedTheme, Theme, lightTheme, darkTheme }: {
    state: any, toggleDrawer: any, translation: TranslationType[];
    language: string; switchTheme: (theme: string) => void; themeChoice: string; menuColor: any, setSelectedTheme: any, Theme: any, lightTheme: any, darkTheme: any
}) {
    const Dictionary = getDictionary(translation, "menu", language);
    const classes = useStyles();
    const navigate = useNavigate();
    const theme: CustomTheme = useTheme();
    const [drop, setDrop] = useState<string[]>([]);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState<{ [key: string]: boolean }>({});
    const toggleSubmenu = (submenuId: string) => {
        if (drop.includes(submenuId)) {
            setDrop((prevDrop) =>
                prevDrop.filter((id) => id !== submenuId)
            );
        } else {
            setDrop((prevDrop) => [...prevDrop, submenuId]);
        }

        setIsSubmenuOpen(prevState => ({
            ...prevState,
            [submenuId]: !prevState[submenuId],
        }));
    };
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [chooseLangModalState, setChooseLangModalState] = useState(false)
    const closeChooseLanguage = () => {
        setChooseLangModalState(false)
    }

    const chooseLang = () => {
        setChooseLangModalState(true)
    }
    const navigations: SideBarItem[] = [
        { id: 'Dashboard', title: 'Dashboard', type: "link", path: "/dashboard", icon: <Dashboard /> },
        { id: 'my-tasks', title: getField(Dictionary, "my-tasks", language), type: "link", path: "/mytasks", icon: <MyTask /> },
        { id: 'tasks-status', title: getField(Dictionary, "tasks-status", language), type: "link", path: "/tasks/status", icon: <TaskStatus /> },
        { id: 'product-lookup', title: getField(Dictionary, "product-lookup", language), type: "link", path: "/scan/lookup", icon: <ProductsLookup /> },
        { id: 'internal-request', title: getField(Dictionary, "internal-request", language), type: "link", path: "/requests/main", icon: <InternalRequest /> },
        { id: 'wastage', title: getField(Dictionary, "wastage", language), type: "link", path: "/wastage/preview", icon: <Wastage /> },
        { id: 'employees-meals', title: getField(Dictionary, "employees-meals", language), type: "link", path: "/employee/preview", icon: <EmployeeMeal /> },
        {
            id: 'internal-request-dropdown', title: getField(Dictionary, "internal-request", language), type: "dropDown", icon: <InternalRequest />,
            sub: [
                { id: 'incoming', title: getField(Dictionary, "incoming", language), path: "/incoming", icon: <IncomingRequest /> },
                { id: 'outgoing', title: getField(Dictionary, "outgoing", language), path: "/outgoing", icon: <Outgoing /> },
                { id: 'receiving', title: getField(Dictionary, "receiving", language), path: "/dashboard", icon: <Receiving /> },
                { id: 'transfer', title: getField(Dictionary, "transfer", language), path: "/transfer/transfer-preview", icon: <Transfer /> },
            ],
        },
        {
            id: 'ipurchasing-dropdown', title: getField(Dictionary, "purchasing", language), type: "dropDown", icon: <Purchasing />,
            sub: [
                { id: 'po', title: getField(Dictionary, "po", language), path: "/purchase/main", icon: <PurchaseOrder /> },
                { id: 'received', title: getField(Dictionary, "received", language), path: "/purchase/recieving", icon: <Purchase /> },
                { id: 'purchased', title: "Purchase", path: "/new-purchase/lookup", icon: <Purchasing /> },
            ],
        },
        { id: 'stock-take', title: getField(Dictionary, "stock-take", language), type: "link", path: "/stock/preview", icon: <StockTake /> },
        { id: 'stock-take', title: "Stock Variance", type: "link", path: "/stock/variance", icon: <StockVariance /> },
        { id: 'production', title: getField(Dictionary, "production", language), type: "link", path: "/production/main", icon: <Quotation /> },
        { id: 'purchased', title: getField(Dictionary, "purchased", language), type: "link", path: "/purchase/purchasing-tabs", icon: <Purchase /> },
        { id: 'supplier', title: getField(Dictionary, "supplier", language), type: "link", path: '/supplier/all-supplier', icon: <Supplier /> },
        { id: 'Sales', title: "Sales", type: "link", path: '/sales/main', icon: <Sales /> },
        { id: 'theme', title: "Design Setup", type: "link", path: '/design/theme', icon: <AutoFixHighIcon /> }
    ];

    return (
        <div>

            <React.Fragment >
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={state.left}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                    PaperProps={{ sx: { height: '100vh', backgroundColor: menuColor, display: 'flex', flexDirection: 'column', } }}
                >
                    <Box sx={{ height: '100%' }} role="presentation" color='primary.main' onKeyDown={toggleDrawer('left', false)}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "right", top: 0, zIndex: 1 }}>
                            <IconButton onClick={toggleDrawer("left", false)} > <CloseIcon sx={{ color: theme.palette.primary.main, fontSize: "35px" }} /> </IconButton>
                        </div>
                        <List >
                            {navigations.map((navItem) => (
                                <ListItem disablePadding >
                                    {navItem.type === "link" ? (
                                        <>
                                            <ListItemButton onClick={() => {
                                                navItem.path && navigate(navItem.path);
                                                toggleDrawer("left", false)();
                                            }} sx={{ textAlign: 'left' }}>
                                                <ListItemText primary={<div className={classes.menuBold} style={{ display: "flex", alignItems: "center", gap: "5px" }}><IconButton sx={{ fill: Theme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor }}> {navItem.icon}</IconButton>{navItem.title}</div>} />
                                            </ListItemButton>
                                        </>
                                    ) : (
                                        <div>
                                            <ListItemButton onClick={() => { toggleSubmenu(navItem.id); }}>
                                                <ListItemText primary={<div className={classes.menuBold} style={{ display: "flex", alignItems: "center", gap: "10px" }}><IconButton sx={{ fill: Theme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor }}> {navItem.icon}</IconButton>{navItem.title}{isSubmenuOpen[navItem.id] ? <ArrowDropUpIcon sx={{ fontSize: "30px" }} /> : <ArrowDropDownIcon sx={{ fontSize: "30px" }} />}</div>} />
                                            </ListItemButton>
                                            <ul className={classes.noBullet}>
                                                {navItem.sub?.map((subItem) => (
                                                    <li key={subItem.id}>
                                                        <div className={`${classes.submenu} ${drop.includes(navItem.id) ? classes.submenuVisible : ''}`}>
                                                            <ListItemButton onClick={() => { navigate(subItem.path); toggleDrawer("left", false)(); }} sx={{ textAlign: 'left' }}>
                                                                <ListItemText primary={<div className={classes.menuBold} style={{ display: "flex", alignItems: "center", gap: "10px" }}><IconButton sx={{ fill: Theme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor }}>{subItem.icon}</IconButton>{subItem.title}</div>} />
                                                            </ListItemButton>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </ListItem>
                            ))}
                            <ListItemButton onClick={() => chooseLang()} >
                                <ListItemText primary={<div className={classes.menuBold} style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'left' }}>
                                    {getField(Dictionary, "choose-language", language)} <TranslateIcon /></div>} />
                            </ListItemButton>
                            <ThemeSwitch switchTheme={switchTheme} theme={themeChoice} textColor={""} text='Change Theme' setSelectedTheme={setSelectedTheme} Theme={Theme} />
                            <li><b style={{ display: 'flex', justifyContent: 'center', userSelect: 'none', color: "#0F2B5D" }}>Version: {version}</b></li>
                        </List>

                    </Box>
                </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
}


const mapStateToProps = (state: any) => ({
    translation: state.translation.translation,
    language: state.translation.language,
    themeChoice: state.themeSwitch.themeChoice,
    menuColor: state.themeSwitch.menuColor,
    lightTheme: state.themeSwitch.lightTheme,
    darkTheme: state.themeSwitch.darkTheme,
    Theme: state.themeSwitch.theme
});
const mapDispatchToProps = (dispatch: Dispatch<any>) => { return { switchTheme: (theme: string) => dispatch(ThemeActions.switchTheme(theme)), } }
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);