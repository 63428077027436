import { Dispatch, Action } from 'redux';

export const INCREMENT_COUNTER = 'INCREMENT_COUNTER'
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER'
export const ADJUST_QUANTITY = 'ADJUST_QUANTITY';
export const INCREMENT_UNPACKED = 'INCREMENT_UNPACKED'
export const DECREMENT_UNPACKED = 'DECREMENT_UNPACKED'
export const ADJUST_UNPACKED_QTY = 'ADJUST_UNPACKED_QTY';
export const CHANGE_REASON = 'CHANGE_REASON';
export const REMOVE_FROM_DETAILS = 'REMOVE_FROM_DETAILS'
export const REMOVE_BRAND_FROM_DETAILS = 'REMOVE_BRAND_FROM_DETAILS'

interface IncrementUnpackedAction extends Action<typeof INCREMENT_UNPACKED> {
    payload: { productId: string; }
}

interface DecrementUnpackedAction extends Action<typeof DECREMENT_UNPACKED> {
    payload: { productId: string; }
}
interface AdjustUnpackedQtyAction extends Action<typeof ADJUST_UNPACKED_QTY> {
    payload: { productId: string; newQuantity: number; };
}

interface IncrementCounterAction extends Action<typeof INCREMENT_COUNTER> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
    }
}

interface DecrementCounterAction extends Action<typeof DECREMENT_COUNTER> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
    }
}

interface AdjustQuantityAction extends Action<typeof ADJUST_QUANTITY> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
        newQuantity: number;
    };
}

interface ChangeReasonAction extends Action<typeof CHANGE_REASON> {
    payload: {
        productId: string;
        newReason: string;
    };
}

interface RemoveFromDetailsAction extends Action<typeof REMOVE_FROM_DETAILS> {
    payload: {
        itemID: string;
    }
}

interface RemoveBrandFromDetailsAction extends Action<typeof REMOVE_BRAND_FROM_DETAILS> {
    payload: {
        brandID: string;
    }
}

export type LookupActionTypes = | IncrementCounterAction | DecrementCounterAction
    | AdjustQuantityAction | ChangeReasonAction
    | RemoveFromDetailsAction | RemoveBrandFromDetailsAction | IncrementUnpackedAction | DecrementUnpackedAction | AdjustUnpackedQtyAction;

const incrementCounterQty = (productId: string, brandIndex: number | null, expieryIndex: number): IncrementCounterAction => ({ type: INCREMENT_COUNTER, payload: { productId, brandIndex, expieryIndex } });

const decrementCounterQty = (productId: string, brandIndex: number | null, expieryIndex: number): DecrementCounterAction => ({ type: DECREMENT_COUNTER, payload: { productId, brandIndex, expieryIndex } });

const adjustCounterQty = (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number): AdjustQuantityAction => ({ type: ADJUST_QUANTITY, payload: { productId, brandIndex, expieryIndex, newQuantity } });

const incrementUnpackedQty = (productId: string,): IncrementUnpackedAction => ({ type: INCREMENT_UNPACKED, payload: { productId, } });

const decrementUnpackedQty = (productId: string,): DecrementUnpackedAction => ({ type: DECREMENT_UNPACKED, payload: { productId, } });

const adjustUnpackedQty = (productId: string, newQuantity: number): AdjustUnpackedQtyAction => ({ type: ADJUST_UNPACKED_QTY, payload: { productId, newQuantity } });

const changeWasteReason = (productId: string, newReason: string): ChangeReasonAction => ({ type: CHANGE_REASON, payload: { productId, newReason } });

const removeFromDet = (itemID: string): RemoveFromDetailsAction => ({ type: REMOVE_FROM_DETAILS, payload: { itemID } });

const removeBrandFromDet = (brandID: string): RemoveBrandFromDetailsAction => ({ type: REMOVE_BRAND_FROM_DETAILS, payload: { brandID } });

export const LookupActions = {
    incrementCounter: (productId: string, brandIndex: number | null, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementCounterQty(productId, brandIndex, expieryIndex)); },
    decrementCounter: (productId: string, brandIndex: number | null, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementCounterQty(productId, brandIndex, expieryIndex)); },
    adjustQuantity: (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustCounterQty(productId, brandIndex, expieryIndex, newQuantity)); },
    incrementUnpacked: (productId: string,) => (dispatch: Dispatch<any>) => { dispatch(incrementUnpackedQty(productId,)); },
    decrementUnpacked: (productId: string,) => (dispatch: Dispatch<any>) => { dispatch(decrementUnpackedQty(productId,)); },
    adjustUnpacked: (productId: string, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustUnpackedQty(productId, newQuantity)); },
    changeReason: (productId: string, comment: string) => (dispatch: Dispatch<any>) => { dispatch(changeWasteReason(productId, comment)); },
    removeFromDetails: (itemID: string) => (dispatch: Dispatch<any>) => { dispatch(removeFromDet(itemID)); },
    removeBrandFromDetails: (brandID: string) => (dispatch: Dispatch<any>) => { dispatch(removeBrandFromDet(brandID)); },
};