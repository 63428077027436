import React, { lazy, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import { styled, Modal } from "@mui/material";
import { Error } from "../components/error/error";
import { CustomTheme } from '../theme/index';
import { OutgoingRequest } from "../redux/actions/outgoingRequestActions";
import { IncomingRequestsTypes, IncomingRequest, IncomingMultiBrandRequest, MultiBrandRequest, SingleProduct } from "../redux/actions/incomingRequestAction"
import { ReceivingProductTypes } from "../redux/actions/receivedPurchaseOrderActions";
// import { getDictionary, getField } from '../utils/translation/translation';
import { connect } from "react-redux";
import { IncomingRequeststActions } from '../redux/actions/incomingRequestAction'
import { Dispatch } from 'redux';
import ItemsNotAvailable from '../components/Modals/items-not-available/items-not-available'
import AlertModal from '../components/Modals/alert-modal/alertModal';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../components/menu-drawer/desktop/sidebar';
import HeaderSection from '../components/header/desktop/headerSection';
import ThemeSwitch from '../components/switches/theme-switch';
import { ThemeActions, Theme, ColorState } from '../redux/actions/themeActions';
import { MenuDrawerActions } from '../redux/actions/menuDrawerActions';

const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const drawerWidth = 240;
//website pages
//theme setup 
const DesignSetUp = lazy(() => import('../pages/design-setup/design-setup'));
//wastage
const WastageMain = lazy(() => import('../pages/wastage/wastage-main/wastage-main'));
const WastagePreview = lazy(() => import('../pages/wastage/wastage-preview/wastage-preview'));
const WastageFinishedProductsDesktop = lazy(() => import('../pages/wastage/wastage-finished-products/wastage-finished-products-desktop/wastage-finished-products-desktop'));
const WastageRawMaterialsDesktop = lazy(() => import('../pages/wastage/wastage-raw-materials/wastage-stock-items-desktop/wastage-stock-items-desktop'));
//tasks
const Tasks = lazy(() => import('../pages/tasks/task-main/tasks-main'));
const NewTask = lazy(() => import('../pages/tasks/new-task/new-task'));
const TransferTasks = lazy(() => import('../pages/tasks/transfer-task/transfer-tasks'));
const TaskStatus = lazy(() => import('../pages/tasks/tasks-status/task-status'));
const TasksList = lazy(() => import('../pages/tasks/tasks-list/task-lists'));
//employee
const EmployeeMain = lazy(() => import('../pages/employee/employee-main/employee-main'));
const EmployeeRawmaterialsDesktop = lazy(() => import('../pages/employee/employee-raw-materials/employee-stock-items-desktop/employee-stock-items-desktop'));
const EmployeeFinishedProductsDesktop = lazy(() => import('../pages/employee/employee-finished-products/employee-finished-products-desktop/employee-finished-products-desktop'));
const EmployeePreview = lazy(() => import('../pages/employee/employee-preview/employee-preview'));
//Orders
const OutgoingOrders = lazy(() => import('../pages/requests/outgoing-orders/outgoing-orders'));
const OutgoingRequestDetails = lazy(() => import('../pages/requests/outgoing-request-details/outgoing-request-details'));
const IncomingOrders = lazy(() => import('../pages/requests/incoming-orders/incoming-orders'));
const IncomingRequestDetails = lazy(() => import('../pages/requests/incoming-request-details/incoming-request-details'));
const InternalRequestLookupDesktop = lazy(() => import('../pages/order/internal-request-lookup/internal-request-desktop'))
const InternalRequest = lazy(() => import('../pages/order/internal-request-main/internal-request-main'));
//scan 
const ScanLookUp = lazy(() => import('../pages/scan/products/scan-lookup'));
const ProductLookupWithExpiry = lazy(() => import('../pages/scan/product-with-expiry/product-with-expiry'));
const ProductWithoutExpiry = lazy(() => import('../pages/scan/product-without-expiry/lookup-without-expiry'));
const ProductBrands = lazy(() => import('../pages/scan/product-brands/multi-brand-lookup'));
//stock
const WarehouseProgress = lazy(() => import('../pages/stock/stock-take-warehouses/warehouse-progress'));
const StockTakeMain = lazy(() => import('../pages/stock/stock-take-main/stock-take-main'));
const StockTakeLookup = lazy(() => import('../pages/stock/stock-take-lookup/stock-take-lookup'));
const StockTakePreviewMain = lazy(() => import('../pages/stock/stock-take-preview/stock-take-preview-main'));
const StockTakeVariance = lazy(() => import('../pages/stock/stock-take-variance/stock-take-variance'));
//supplier
const AllSupplier = lazy(() => import('../pages/supplier/all-supplier/all-supplier'));
const NewSupplier = lazy(() => import('../pages/supplier/new-supplier/new-supplier'));
const SupplierDetails = lazy(() => import('../pages/supplier/supplier-details/supplier-details'));
const AllPayment = lazy(() => import('../pages/supplier/all-payment/all-payment'));
const SupplierPayment = lazy(() => import('../pages/supplier/new-payment/new-payment'))
const StatementOfAccount = lazy(() => import('../pages/supplier/statement-of-account/statement-account'))
//Transfer
const NewTransfer = lazy(() => import('../pages/transfer/transfer-main/transfer-main'))
const TransferPreview = lazy(() => import('../pages/transfer/transfer-preview/transfer-preview'))
const TransferLookupProductsDesktop = lazy(() => import('../pages/transfer/transfer-lookup/transfer-lookup-desktop'))
//Purchase
const PurchaseOrderMain = lazy(() => import('../pages/purchase/new-po/new-po-main/new-po-main'))
const PurchaseOrderLookupDesktop = lazy(() => import('../pages/purchase/new-po/new-po-lookup/new-po-lookup-desktop'))
const PurchaseMain = lazy(() => import('../pages/purchase/purchase/purchase-main/purchase-main'))
const PurchaseOrderSent = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-received'))
const PurchaseTabs = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-order-main'))
const ReceivingProducts = lazy(() => import('../pages/purchase/purchase/po-receiving/po-receiving-product'))
const PoReceivedDetails = lazy(() => import('../pages/purchase/purchase/po-receiving/po-received-details/product-received-details'))
const PurchaseVerification = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-verification'))
//direct purchase 
const NewPurchaseMain = lazy(() => import('../pages/purchase/new-purchase/new-purchase-main/new-purchase-main'))
const NewPurchaseDesktop = lazy(() => import('../pages/purchase/new-purchase/new-purchase-lookup/new-purchase-desktop'))
const NewPurchaseDetails = lazy(() => import('../pages/purchase/new-purchase/new-purchase-details/new-purchase-details'))
const ScanInvoiceMain = lazy(() => import('../pages/purchase/new-purchase/scan-upload-invoice/scan-document-main'))
//production
const ProductionMain = lazy(() => import('../pages/production/production-items/production/production-main'))
const NewProductionMain = lazy(() => import('../pages/production/production-main/new-production-main'))
const ProductionLookupDesktop = lazy(() => import('../pages/production/production-items/production-lookup/production-lookup-desktop'))
const CustomProductionLookup = lazy(() => import('../pages/production/custom-production/custom-production-lookup/custom-production-lookup'))
const CustomProductionLookupDesktop = lazy(() => import('../pages/production/custom-production/custom-production-lookup/custom-production-lookup-desktop'))
const CustomProductionSummary = lazy(() => import('../pages/production/custom-production/custom-production-summary/custom-production-summary'))
//sales 
const SalesMain = lazy(() => import('../pages/sales/sales-main/sales-main'))
const QuotationMain = lazy(() => import('../pages/sales/quotation/quotation-main/quotation-main'))

interface DefaultLayoutProps {
    headerHidden: boolean;
    lightTheme: any, darkTheme: any, Theme: any,
    currentItem: OutgoingRequest;
    currentIncomingItem: (IncomingRequest | IncomingMultiBrandRequest);
    currentIncomingIt: ReceivingProductTypes;
    SetSendOrder: (productId: string,) => void;
    setRequestStatus: (productId: string, status: string) => void;
    incrementOurQty: (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => void;
    decrementOurQty: (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => void;
    adjustOurQty: (productId: string, multiId: string, newQuantity: number, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => void;
    incrementIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => void;
    decrementIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => void;
    adjustIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, newQuantity: number, itemType: 'box' | 'can') => void;
    SetThemeColor: any,
    ResetColors?: any
    MenuOpen: any, MenuClose: any, open: any

}

// Define Props Interfaces
interface LayoutProps {
    children: React.ReactNode;
}

interface MainProps {
    open: boolean;
    children: React.ReactNode;
}

const Layout = styled("div")<LayoutProps>(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
}));


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,

    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


function DesktopLayout({ open, MenuOpen, MenuClose, ResetColors, Theme, darkTheme, lightTheme, SetThemeColor, headerHidden, currentItem, currentIncomingItem, currentIncomingIt, SetSendOrder, setRequestStatus, incrementIncomingMultiQty, decrementIncomingMultiQty, adjustIncomingMultiQty, incrementOurQty, decrementOurQty, adjustOurQty, }: DefaultLayoutProps) {

    const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<MainProps>(
        ({ theme, open, }) => ({
            flexGrow: 1,
            padding: theme.spacing(3),
            paddingTop: "80px",
            backgroundColor: location.pathname === '/dashboard' ? theme.palette.background.default : Theme === 'lightTheme' ? lightTheme.backgroundColor : darkTheme.backgroundColor,
            color: Theme === 'lightTheme' ? lightTheme.mainTextColor : darkTheme.mainTextColor,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${drawerWidth}px`,
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            ...(open && {
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,

            }),
        })
    );
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenuDrawer = () => { setIsMenuOpen(!isMenuOpen); };
    const closeMenu = () => { setIsMenuOpen(false) };
    const [filteredItems, setFilteredItems] = useState([]);
    const [isDuplicatedModalOpen, setDuplicatedModalOpen] = useState(false);
    const [AlertModalOpen, setAlertModalOpen] = useState(false);
    const handleOpenAlertModal = () => { setAlertModalOpen(true); };
    const handleCloseAlertModal = () => { setAlertModalOpen(false); };
    const handleCloseDuplicatedModal = () => { setDuplicatedModalOpen(false); };
    const sumOfBoxes = (boxCount: number, boxSize: any, canWeight: any): number => { const sumOfBoxesKg = (boxCount * boxSize * canWeight) / 1000; return sumOfBoxesKg; };
    const sumOfCans = (canCount: number, canWeight: any): number => { const sumOfCansKg = (canCount * canWeight) / 1000; return sumOfCansKg; };
    const totalKgs = (boxCount: number, boxSize: any, canWeight: any, canCount: number,): number => { const weightInKilograms = sumOfBoxes(boxCount, boxSize, canWeight) + sumOfCans(canCount, canWeight); return Number(weightInKilograms.toFixed(2)); };
    const theme: CustomTheme = useTheme();
    // const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const handleDrawerOpen = () => {
        MenuOpen()
    };

    const handleDrawerClose = () => {
        MenuClose();
    };
    const handleNavigate = (path: any) => {
        MenuClose()
        navigate(path);
    };
    const filterDuplicatedItems = (items: any) => {
        return items.filter((item: any) => {
            const qtyCondition = item.ourQtyOH < item.qtyRequested;
            if ('brands' in item) {
                let totalQtyReqSumInKgs = 0;
                item.brands.forEach((brand: any) => {
                    const brandWeightInKgs = totalKgs(brand.qty[1].boxCount, brand.boxSize, brand.canWeight, brand.qty[1].cansCount);
                    totalQtyReqSumInKgs += brandWeightInKgs;
                });
                totalQtyReqSumInKgs = Number(totalQtyReqSumInKgs.toFixed(2));
                let totalOurQtyOhSumInKgs = 0;
                item.brands.forEach((brand: any) => {
                    const brandWeightInKgs = totalKgs(brand.qty[3].boxCount, brand.boxSize, brand.canWeight, brand.qty[3].cansCount);
                    totalOurQtyOhSumInKgs += brandWeightInKgs;
                });
                totalOurQtyOhSumInKgs = Number(totalOurQtyOhSumInKgs.toFixed(2));
                let unpackedOurOnHand = 0; let unpackedRequested = 0; let sumOurQtyOn = 0; let sumQtyReq = 0;
                if ('baseUnit' in item) {
                    const UnpackedArray = item.baseUnit
                    unpackedOurOnHand = UnpackedArray[3].count;
                    unpackedRequested = UnpackedArray[1].count;
                    if (UnpackedArray[3].weightType === 'Gr') { unpackedOurOnHand = unpackedOurOnHand / 1000 } else { unpackedOurOnHand = unpackedOurOnHand }
                    if (UnpackedArray[1].weightType === 'Gr') { unpackedRequested = unpackedRequested / 1000 } else { unpackedRequested = unpackedRequested }
                }
                if ('brands' in item) { sumQtyReq += totalQtyReqSumInKgs + unpackedRequested; sumOurQtyOn += totalOurQtyOhSumInKgs + unpackedOurOnHand; }
                const totalQtyCondition = ((sumOurQtyOn < sumQtyReq) && (item.status !== 'Rejected'));

                return qtyCondition && totalQtyCondition;
            }
            const statusCondition = item.status !== 'Rejected'
            return qtyCondition && statusCondition;
        });

    };

    const handleFinalizeApproval = () => {
        const hasPendingRequests = currentIncomingItem.products.some((req: any) => req.status !== 'Approved' && req.status !== 'Rejected');
        const productsArray: SingleProduct[] = currentIncomingItem.products || [];
        const multiBrandArray: MultiBrandRequest[] = (currentIncomingItem as IncomingMultiBrandRequest).multiBrand || [];
        const allItems: (SingleProduct | MultiBrandRequest)[] = [...productsArray, ...multiBrandArray];
        const duplicatedFilteredItems = filterDuplicatedItems(allItems);
        if (duplicatedFilteredItems.length > 0) {
            setFilteredItems(duplicatedFilteredItems);
            setDuplicatedModalOpen(true);
        } else if (hasPendingRequests) {
            setAlertModalOpen(true);
        } else {
            const approvedCount = currentIncomingItem.products.filter((req: any) => req.status === 'Approved').length;
            const rejectedCount = currentIncomingItem.products.filter((req: any) => req.status === 'Rejected').length;
            if (approvedCount > rejectedCount) {
                setRequestStatus(currentIncomingItem.id, 'Approved');
                SetSendOrder(currentIncomingItem.id); navigate('/incoming');
            } else {
                setRequestStatus(currentIncomingItem.id, 'Rejected'); navigate('/incoming');
            }
        }
    };
    const [selectedTheme, setSelectedTheme] = useState('lightTheme');
    const headerStyle = {
        backgroundColor: location.pathname === '/dashboard' ? theme.palette.background.default : selectedTheme === "lightTheme" ? lightTheme.headerColor : darkTheme.headerColor,
        color: location.pathname === '/sales/quotation-main' || location.pathname === '/sales/main' || location.pathname === '/production/newMain' || location.pathname === '/purchase/purchasing-verification' || location.pathname === '/stock/main' ||
            location.pathname === '/wastage/main' || location.pathname === '/purchase/main' || location.pathname === '/new-purchase/main' ||
            location.pathname === '/purchase/purchasing-main' || location.pathname === '/employee/main' ? theme.palette.primary.main : location.pathname === '/requests/main'
                || location.pathname === '/transfer/transfer-main' ? theme.palette.primary.main : theme.palette.primary.main,
        boxShadow: 'none !important', height: '80px', zIndex: '10'
    };

    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundColor : darkTheme.backgroundColor);
    const [selectedHeaderColor, setSelectedHeaderColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.headerColor : darkTheme.headerColor);
    const [selectedMenuColor, setSelectedMenuColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.menuColor : darkTheme.menuColor);
    const [selectedMainTextColor, setSelectedMainTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.mainTextColor : darkTheme.mainTextColor);
    const [selectedMainColor, setSelectedMainColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.mainColor : darkTheme.mainColor);
    const [selectedMenuTextColor, setSelectedMenuTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.menuTextColor : darkTheme.menuTextColor);
    const [selectedSectionsBackColor, setSelectedSectionsBackColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundSectionsColor : darkTheme.backgroundSectionsColor);
    const [selectedSectionsBackTextColor, setSelectedSectionsBackTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundSectionsTextColor : darkTheme.backgroundSectionsTextColor);
    const [selectedMenuIconColor, setSelectedMenuIconColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor);
    const [selectedCardsBckgroundColor, setSelectedCardsBckgroundColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.cardsBackgroundColor : darkTheme.cardsBackgroundColor);
    const [selectedCardsTextColor, setSelectedCardsTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.cardsTextColor : darkTheme.cardsTextColor);
    const [selectedCardsIconsColor, setSelectedCardsIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.cardsIconsColor : darkTheme.cardsIconsColor);
    const [selectedBackgroundIconsColor, setSelectedBackgroundIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor);
    const [selectedHeaderIconsColor, setSelectedHeaderIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor);
    const [selectedSectionsIconsColor, setSelectedSectionsIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor);
    const [selectedOddRowColor, setSelectedOddRowColor,] = useState(selectedTheme === 'lightTheme' ? lightTheme.tableOddBackgroundColor : darkTheme.tableOddBackgroundColor);
    const [selectedEvenRowColor, setSelectedEvenRowColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.tableEvenBackgroundColor : darkTheme.tableEvenBackgroundColor);
    const [selectedTextTableColor, setSelectedTextTableColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.tableTextColor : darkTheme.tableTextColor);

    const [selectedFieldColor, setSelectedFieldColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.fieldColor : darkTheme.fieldColor);
    const [selectedTextFieldColor, setSelectedTextFieldColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.fieldTextColor : darkTheme.fieldTextColor);
    useEffect(() => {
        if (selectedTheme === 'lightTheme') {
            setSelectedBackgroundColor(lightTheme.backgroundColor)
            setSelectedHeaderColor(lightTheme.headerColor)
            setSelectedMainColor(lightTheme.mainColor)
            setSelectedMainTextColor(lightTheme.mainTextColor)
            setSelectedMenuColor(lightTheme.menuColor)
            setSelectedMenuTextColor(lightTheme.menuTextColor)
            setSelectedSectionsBackColor(lightTheme.backgroundSectionsColor)
            setSelectedSectionsBackTextColor(lightTheme.backgroundSectionsTextColor)
            setSelectedMenuIconColor(lightTheme.menuIconColor)
            setSelectedCardsBckgroundColor(lightTheme.cardsBackgroundColor)
            setSelectedCardsTextColor(lightTheme.cardsTextColor)
            setSelectedCardsIconsColor(lightTheme.cardsIconsColor)
            setSelectedBackgroundIconsColor(lightTheme.backgroundIconColor)
            setSelectedHeaderIconsColor(lightTheme.headerIconsColor)
            setSelectedSectionsIconsColor(lightTheme.sectionsIconsColor)
            setSelectedOddRowColor(lightTheme.tableOddBackgroundColor)
            setSelectedEvenRowColor(lightTheme.tableEvenBackgroundColor)
            setSelectedTextTableColor(lightTheme.tableTextColor)
            setSelectedFieldColor(lightTheme.fieldColor)
            setSelectedTextFieldColor(lightTheme.fieldTextColor)
        } else {
            setSelectedBackgroundColor(darkTheme.backgroundColor)
            setSelectedHeaderColor(darkTheme.headerColor)
            setSelectedMainColor(darkTheme.mainColor)
            setSelectedMainTextColor(darkTheme.mainTextColor)
            setSelectedMenuColor(darkTheme.menuColor)
            setSelectedMenuTextColor(darkTheme.menuTextColor)
            setSelectedSectionsBackColor(darkTheme.backgroundSectionsColor)
            setSelectedSectionsBackTextColor(darkTheme.backgroundSectionsTextColor)
            setSelectedMenuIconColor(darkTheme.menuIconColor)
            setSelectedCardsBckgroundColor(darkTheme.cardsBackgroundColor)
            setSelectedCardsTextColor(darkTheme.cardsTextColor)
            setSelectedCardsIconsColor(darkTheme.cardsIconsColor)
            setSelectedBackgroundIconsColor(darkTheme.backgroundIconColor)
            setSelectedHeaderIconsColor(darkTheme.headerIconsColor)
            setSelectedSectionsIconsColor(darkTheme.sectionsIconsColor)
            setSelectedOddRowColor(darkTheme.tableOddBackgroundColor)
            setSelectedEvenRowColor(darkTheme.tableEvenBackgroundColor)
            setSelectedTextTableColor(darkTheme.tableTextColor)
            setSelectedFieldColor(darkTheme.fieldColor)
            setSelectedTextFieldColor(darkTheme.fieldTextColor)
        }
    }, [selectedTheme])

    const handleSave = () => {
        SetThemeColor({
            backgroundColor: selectedBackgroundColor,
            mainColor: selectedMainColor,
            menuColor: selectedMenuColor,
            headerColor: selectedHeaderColor,
            mainTextColor: selectedMainTextColor,
            menuTextColor: selectedMenuTextColor,
            backgroundSectionsColor: selectedSectionsBackColor,
            backgroundSectionsTextColor: selectedSectionsBackTextColor,
            menuIconColor: selectedMenuIconColor,
            cardsBackgroundColor: selectedCardsBckgroundColor,
            cardsTextColor: selectedCardsTextColor,
            cardsIconsColor: selectedCardsIconsColor,
            headerIconsColor: selectedHeaderIconsColor,
            sectionsIconsColor: selectedSectionsIconsColor,
            backgroundIconColor: selectedBackgroundIconsColor,
            tableOddBackgroundColor: selectedOddRowColor,
            tableEvenBackgroundColor: selectedEvenRowColor,
            tableTextColor: selectedTextTableColor,
            fieldColor: selectedFieldColor,
            fieldTextColor: selectedTextFieldColor
        }, selectedTheme, "#f4f5f7")


    };


    return (
        <>
            <Layout>

                <AppBar position="fixed" open={open} sx={headerStyle} >
                    <Toolbar>
                        <HeaderSection darkTheme={darkTheme} Theme={selectedTheme} handleSave={handleSave} open={open} handleFinalizeApproval={handleFinalizeApproval} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} mainColor={lightTheme.mainColor} lightTheme={lightTheme} />
                    </Toolbar>
                </AppBar>
                <Sidebar setSelectedTheme={setSelectedTheme} />
                <Main open={open}>
                    <Routes>
                        <Route path="design/theme" element={<DesignSetUp
                            selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} ResetColors={ResetColors}
                            desktopView={true} lightTheme={lightTheme} darkTheme={darkTheme}
                            selectedBackgroundColor={selectedBackgroundColor} setSelectedBackgroundColor={setSelectedBackgroundColor}
                            selectedHeaderColor={selectedHeaderColor} setSelectedHeaderColor={setSelectedHeaderColor}
                            selectedMenuColor={selectedMenuColor} setSelectedMenuColor={setSelectedMenuColor}
                            selectedMenuTextColor={selectedMenuTextColor} setSelectedMenuTextColor={setSelectedMenuTextColor}
                            selectedMainTextColor={selectedMainTextColor} setSelectedMainTextColor={setSelectedMainTextColor}
                            handleSave={handleSave}
                            selectedMainColor={selectedMainColor} setSelectedMainColor={setSelectedMainColor}
                            selectedSectionsBackColor={selectedSectionsBackColor} setSelectedSectionsBackColor={setSelectedSectionsBackColor}
                            selectedSectionsBackTextColor={selectedSectionsBackTextColor} setSelectedSectionsBackTextColor={setSelectedSectionsBackTextColor}
                            selectedMenuIconColor={selectedMenuIconColor} setSelectedMenuIconColor={setSelectedMenuIconColor}
                            selectedCardsBckgroundColor={selectedCardsBckgroundColor} setSelectedCardsBckgroundColor={setSelectedCardsBckgroundColor}
                            setSelectedCardsTextColor={setSelectedCardsTextColor} selectedCardsTextColor={selectedCardsTextColor}
                            setSelectedCardsIconsColor={setSelectedCardsIconsColor} selectedCardsIconsColor={selectedCardsIconsColor}
                            selectedSectionsIconsColor={selectedSectionsIconsColor} setSelectedSectionsIconsColor={setSelectedSectionsIconsColor}
                            selectedHeaderIconsColor={selectedHeaderIconsColor} setSelectedHeaderIconsColor={setSelectedHeaderIconsColor}
                            selectedBackgroundIconsColor={selectedBackgroundIconsColor} setSelectedBackgroundIconsColor={setSelectedBackgroundIconsColor}
                            selectedOddRowColor={selectedOddRowColor} setSelectedOddRowColor={setSelectedOddRowColor}
                            selectedEvenRowColor={selectedEvenRowColor} setSelectedEvenRowColor={setSelectedEvenRowColor}
                            selectedTextTableColor={selectedTextTableColor} setSelectedTextTableColor={setSelectedTextTableColor}
                            selectedTextFieldColor={selectedTextFieldColor} setSelectedTextFieldColor={setSelectedTextFieldColor}
                            selectedFieldColor={selectedFieldColor} setSelectedFieldColor={setSelectedFieldColor}
                        />} />
                        <Route path="dashboard" element={<Dashboard desktopView={true} />} />
                        <Route path="mytasks" element={<Tasks desktopView={true} />} />
                        <Route path="new-task" element={<NewTask desktopView={true} />} />
                        <Route path="transfer-task" element={<TransferTasks desktopView={true} />} />
                        <Route path="tasks/status" element={<TaskStatus desktopView={true} />} />
                        <Route path="tasks/tasks-list" element={<TasksList desktopView={true} />} > </Route>
                        <Route path="wastage/main" element={<WastageMain desktopView={true} />} />
                        <Route path="wastage/finished-products" element={<WastageFinishedProductsDesktop />} />
                        <Route path="wastage/stock-items/details" element={<WastageRawMaterialsDesktop />} />
                        <Route path="wastage/finished-products/details" element={<WastageFinishedProductsDesktop />} />
                        <Route path="wastage/stock-items" element={<WastageRawMaterialsDesktop />} />
                        <Route path="wastage/preview" element={<WastagePreview desktopView={true} />} />
                        <Route path="employee/main" element={<EmployeeMain desktopView={true} />} />
                        <Route path="employee/finished-products" element={<EmployeeFinishedProductsDesktop />} />
                        <Route path="employee/stock-items" element={<EmployeeRawmaterialsDesktop />} />
                        <Route path="employee/preview" element={<EmployeePreview desktopView={true} />} />
                        <Route path="employee/stock-items/details" element={<EmployeeRawmaterialsDesktop />} />
                        <Route path="employee/finished-products/details" element={<EmployeeFinishedProductsDesktop />} />
                        <Route path="outgoing" element={<OutgoingOrders desktopView={true} />} />
                        {/* {!currentItem ? <Route path="/" element={<OutgoingOrders />} /> : <Route path="/requests/:id" element={<OutgoingRequestDetails />} />} */}
                        <Route path="incoming" element={<IncomingOrders desktopView={true} />} />
                        {!currentIncomingItem ? <Route path="/" element={<IncomingOrders />} /> : <Route path="/requests/:id" element={<IncomingRequestDetails desktopView={true} />} />}
                        <Route path="order/order-lookup" element={<InternalRequestLookupDesktop />} > </Route>
                        <Route path="order/order-details" element={<InternalRequestLookupDesktop />} > </Route>
                        <Route path="requests/main" element={<InternalRequest desktopView={true} />} > </Route>
                        <Route path="scan/lookup" element={<ScanLookUp />} > </Route>
                        <Route path="scan/lookup-without-expiry" element={<ProductWithoutExpiry />} > </Route>
                        <Route path="scan/lookup-with-expiry" element={<ProductLookupWithExpiry />} > </Route>
                        <Route path="scan/product-brand" element={<ProductBrands />} > </Route>
                        <Route path="stock/warehouse-progress" element={<WarehouseProgress />} > </Route>
                        <Route path="supplier/all-supplier" element={<AllSupplier />} > </Route>
                        <Route path="/supplier/supplier-details" element={<SupplierDetails />}></Route>
                        <Route path="/supplier/new-supplier" element={<NewSupplier />}></Route>
                        <Route path='supplier/payment' element={<SupplierPayment />}>  </Route>
                        <Route path="supplier/all-payment" element={<AllPayment />}> </Route>
                        <Route path='supplier/statement' element={<StatementOfAccount />}></Route>
                        <Route path='/transfer/transfer-main' element={<NewTransfer desktopView={true} />}></Route>
                        <Route path='/transfer/transfer-preview' element={<TransferPreview desktopView={true} />}></Route>
                        <Route path='/transfer/lookup-product' element={<TransferLookupProductsDesktop />}></Route>
                        <Route path='/transfer/transfer-details' element={<TransferLookupProductsDesktop />}> </Route>
                        <Route path='/purchase/main' element={<PurchaseOrderMain desktopView={true} />}> </Route>
                        <Route path='/purchase/lookup' element={<PurchaseOrderLookupDesktop />}> </Route>
                        <Route path='/purchase/order-details' element={<PurchaseOrderLookupDesktop />}></Route>
                        <Route path='/purchase/purchasing-main' element={<PurchaseMain desktopView={true} />}></Route>
                        <Route path='/purchase/purchasing-order' element={<PurchaseOrderSent />}></Route>
                        <Route path='/purchase/purchasing-tabs' element={<PurchaseTabs />}></Route>
                        <Route path='/purchase/purchasing-verification' element={<PurchaseVerification desktopView={true} />}></Route>
                        <Route path='/purchase/recieving' element={<ReceivingProducts desktopView={true} />}></Route>
                        {!currentIncomingIt ? <Route path="/" element={<ReceivingProducts />} /> : <Route path="/received/:id" element={<PoReceivedDetails />} />}
                        <Route path='/new-purchase/main' element={<NewPurchaseMain desktopView={true} />}></Route>
                        <Route path='/new-purchase/lookup' element={<NewPurchaseDesktop />}></Route>
                        <Route path='/new-purchase/details' element={<NewPurchaseDetails />}></Route>
                        <Route path='/new-purchase/scan-invoice-main' element={<ScanInvoiceMain desktopView={true} />}></Route>
                        <Route path='/stock/main' element={<StockTakeMain desktopView={true} />}></Route>
                        <Route path='/stock/lookup' element={<StockTakeLookup desktopView={true} />}></Route>
                        <Route path='/stock/preview' element={<StockTakePreviewMain desktopView={true} />}></Route>
                        <Route path='/stock/variance' element={<StockTakeVariance desktopView={true} />}></Route>
                        <Route path='/production/main' element={<ProductionMain desktopView={true} />}></Route>
                        <Route path='/production/newMain' element={<NewProductionMain desktopView={true} />}></Route>
                        <Route path='/production/lookup' element={<ProductionLookupDesktop />}></Route>
                        <Route path='/production/summary' element={<ProductionLookupDesktop />}></Route>
                        {/* <Route path='/production/custom-lookup' element={<CustomProductionLookup desktopView={true} />}></Route> */}
                        <Route path='/production/custom-lookup' element={<CustomProductionLookupDesktop />}></Route>
                        <Route path='/production/custom/summary' element={<CustomProductionSummary />}></Route>
                        <Route path='/sales/main' element={<SalesMain desktopView={true} />}></Route>
                        <Route path='/sales/quotation-main' element={<QuotationMain desktopView={true} />}></Route>
                        <Route path="/*" element={<Error />} />
                    </Routes>
                </Main>
            </Layout>

            <Modal open={isDuplicatedModalOpen} onClose={handleCloseDuplicatedModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ItemsNotAvailable filteredItems={filteredItems} id={currentIncomingItem.id} closeModal={handleCloseDuplicatedModal}
                    incrementRequestQty={incrementOurQty} decrementRequestQty={decrementOurQty} adjustRequestQty={adjustOurQty}
                    incrementMultiRequest={incrementIncomingMultiQty} decrementMultiRequest={decrementIncomingMultiQty} adjustMultiRequest={adjustIncomingMultiQty} />
            </Modal>

            <Modal open={AlertModalOpen} onClose={handleCloseAlertModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AlertModal alert='Please approve or reject the remaining items before finalizing.' closeModal={handleCloseAlertModal} />
            </Modal>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    currentItem: state.outgoing.currentItem,
    currentIncomingItem: state.incomingRequest.currentIncomingItem,
    currentIncomingIt: state.receivedProducts.currentIncomingItem,
    lightTheme: state.themeSwitch.lightTheme,
    darkTheme: state.themeSwitch.darkTheme,
    Theme: state.themeSwitch.theme,
    open: state.menuDrawer.open,

});
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        SetSendOrder: (productId: string,) => dispatch(IncomingRequeststActions.SetSendOrder(productId)),
        setRequestStatus: (productId: string, status: string) => dispatch(IncomingRequeststActions.setRequestStatus(productId, status)),
        //Single Products
        incrementOurQty: (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => dispatch(IncomingRequeststActions.incrementOurQty(productId, multiId, qtyType)),
        decrementOurQty: (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => dispatch(IncomingRequeststActions.decrementOurQty(productId, multiId, qtyType)),
        adjustOurQty: (productId: string, multiId: string, newQuantity: number, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => dispatch(IncomingRequeststActions.adjustOurQty(productId, multiId, newQuantity, qtyType)),
        //Box & Cans
        incrementIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => dispatch(IncomingRequeststActions.incrementIncomingMultiQty(productId, multiId, brandId, qtyId, itemType)),
        decrementIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => dispatch(IncomingRequeststActions.decrementIncomingMultiQty(productId, multiId, brandId, qtyId, itemType)),
        adjustIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, newQuantity: number, itemType: 'box' | 'can') => dispatch(IncomingRequeststActions.adjustIncomingMultiQty(productId, multiId, brandId, qtyId, newQuantity, itemType)),
        SetThemeColor: (color: string, theme: keyof ColorState, property: Theme) => dispatch(ThemeActions.SetThemeColor(color, theme, property)),
        ResetColors: (theme: string) => dispatch(ThemeActions.ResetColors(theme)),
        MenuOpen: () => dispatch(MenuDrawerActions.MenuOpen()),
        MenuClose: () => dispatch(MenuDrawerActions.MenuClose()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopLayout);