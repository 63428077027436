import { Typography, useTheme } from '@mui/material';
import { ReactComponent as FoodIcon } from '../../../assets/images/food-popup.svg'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDictionary, getField } from '../../../utils/translation/translation';
import { TranslationType } from '../../../types/translationTypes';
import { connect } from 'react-redux';
import useStyles from './styles';
import { CustomTheme } from '../../../theme/index';
function WastageSave({ translation, language, }: { translation: TranslationType[], language: string, }) {
    const Dictionary = getDictionary(translation, "wastage-details", language);
    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    return (
        <div className={classes.HorizCentering}>
            <div className={classes.wastageModalContainer}>
                <div className={classes.wastageModalTitle}>
                    <Typography variant="h3" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold' }}>{getField(Dictionary, "wastage", language)} </Typography>
                </div>
                <div className={classes.wastageModalBody}>
                    <FoodIcon />
                    <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold', marginTop: '10px' }}>
                        13 {getField(Dictionary, "success-waste", language)}
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold', marginTop: '50px' }}>For:</Typography>
                    <div className={classes.wastageModalBranch}>
                        <LocationOnIcon sx={{ color: theme.palette.error.main, fontSize: '40px' }} />
                        <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center" }}>Branch1, warehouse 2</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, });
export default connect(mapStateToProps, null)(WastageSave);
