import {
    INCREMENT_BOX_ORDER_QUANTITY, DECREMENT_BOX_ORDER_QUANTITY, ADJUST_BOX_ORDER_QUANTITY,
    INCREMENT_CAN_ORDER_QUANTITY, DECREMENT_CAN_ORDER_QUANTITY, ADJUST_CAN_ORDER_QUANTITY,
    INCREMENT_ORDER_BASE_UNIT_QUANTITY, DECREMENT_ORDER_BASE_UNIT_QUANTITY, UPDATE_ORDER_BASE_UNIT_QUANTITY, CHANGE_ORDER_WEIGHT_TYPE,
    ProductActionTypes, REMOVE_ORDER_FROM_PURCHASE, REMOVE_BRAND_FROM_ORDER, ADD_ORDER_TO_DETAILS,
    UPDATE_UPRICE_ON_HAND, UPDATE_UPRICE_REQ, SET_BRAND_DISCOUNT_AED, SET_BRAND_DISCOUNT_PERCENTAGE,
    SET_TOTAL_QTY_ON_HAND_PRICE, SET_TOTAL_QTY_REQUESTED_PRICE, SET_TOTAL_PRICE,
    SET_DISCOUNT_AED, SET_DISCOUNT_PERCENTAGE, SET_FINAL_PRICE, UPDATE_BASE_UNIT_PRICE, SET_TOTAL_BASE_UNIT_PRICE, TOGGLE_BRAND_FAVORITE,
    SET_ACTIVE_CARD, ADD_REMARK_TO_PURCHASE
} from "../actions/newPurchaseActions";
import { RootState } from "../actions/newPurchaseActions";
import { NewPurchaseType } from "../../types";
import { NewPurchaseData } from "../../pages/purchase/new-purchase/new-purchase-data";

const INITIAL_STATE: RootState = {
    PurchasedProducts: NewPurchaseData as NewPurchaseType[],
    newPODetails: [] as NewPurchaseType[],
    cansPerBox: 24,
};

const incrementBoxCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.boxCount === 'number'
                                    ) {
                                        return {
                                            ...quantity,
                                            boxCount: quantity.boxCount + 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    })
}

const decrementBoxCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.boxCount === 'number' &&
                                        quantity.boxCount > 0
                                    ) {
                                        return {
                                            ...quantity,
                                            boxCount: quantity.boxCount - 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const incrementCanCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.cansCount === 'number'
                                    ) {
                                        return {
                                            ...quantity,
                                            cansCount: quantity.cansCount + 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    })
}

const decrementCanCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.cansCount === 'number' &&
                                        quantity.cansCount > 0
                                    ) {
                                        return {
                                            ...quantity,
                                            cansCount: quantity.cansCount - 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const incrementBaseUnitInput = (products: NewPurchaseType[], productId: string, baseUnitIndex: number,) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('quantity' in product) {
                product.quantity = product.quantity?.map((bUnit, i) => {
                    if (i === baseUnitIndex) {
                        let count = bUnit.count;
                        count += 1;
                        return { ...bUnit, count };
                    }
                    return { ...bUnit };
                });
            }
        }
        return product;
    })
}

const decrementBaseUnitInput = (products: NewPurchaseType[], productId: string, baseUnitIndex: number,) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('quantity' in product) {
                product.quantity = product.quantity?.map((bUnit, buIndex) => {
                    if (buIndex === baseUnitIndex && typeof bUnit.count === 'number' && bUnit.count > 0) {
                        return {
                            ...bUnit,
                            count: bUnit.count - 1,
                        };
                    }
                    return { ...bUnit };
                });
            }
        }
        return product;
    });
};

const updateBoxQuantity = (
    products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number,
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((qty, eIndex) => {
                                    if (eIndex === qtyIndex && typeof qty.boxCount === 'number') {
                                        return {
                                            ...qty,
                                            boxCount: newQuantity,
                                        };
                                    }
                                    return qty;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const updateCanQuantity = (
    products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number,
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (eIndex === qtyIndex && typeof quantity.cansCount === 'number') {
                                        return {
                                            ...quantity,
                                            cansCount: newQuantity,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const setBaseUnitToZero = (details: NewPurchaseType[], productId: string,) => {
    const bUToZero = details.find((item) => item.id === productId);
    if (bUToZero) {
        if ('quantity' in bUToZero) { bUToZero.quantity.forEach((qty) => { qty.count = 0; }); }
    }
}
const setBoxAndCansUnitToZero = (details: NewPurchaseType[], productId: string,) => {
    const boxAndCansToRemove = details.find((item) => item.id === productId);
    if (boxAndCansToRemove) {
        if ('productBrands' in boxAndCansToRemove) {
            boxAndCansToRemove.productBrands.forEach((brand) => {
                brand.quantity.forEach((qty) => {
                    qty.boxCount = 0;
                    qty.cansCount = 0;
                });
            });
        }
    }
}

const updateDetailsWithProduct = (details: NewPurchaseType[], updatedProducts: NewPurchaseType[], productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};


const NewPurchaseReducer = (state = INITIAL_STATE, action: ProductActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    let bUToZero;
    let boxAndCansToRemove;
    switch (action.type) {

        case INCREMENT_BOX_ORDER_QUANTITY:
            updatedProducts = incrementBoxCounter(state.PurchasedProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case DECREMENT_BOX_ORDER_QUANTITY:
            updatedProducts = decrementBoxCounter(state.PurchasedProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };
        case ADJUST_BOX_ORDER_QUANTITY:
            updatedProducts = updateBoxQuantity(state.PurchasedProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex, action.payload.newQuantity,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case INCREMENT_CAN_ORDER_QUANTITY:
            updatedProducts = incrementCanCounter(state.PurchasedProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case DECREMENT_CAN_ORDER_QUANTITY:
            updatedProducts = decrementCanCounter(state.PurchasedProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case ADJUST_CAN_ORDER_QUANTITY:
            updatedProducts = updateCanQuantity(state.PurchasedProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex, action.payload.newQuantity,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case INCREMENT_ORDER_BASE_UNIT_QUANTITY:
            updatedProducts = incrementBaseUnitInput(state.PurchasedProducts, action.payload.productId, action.payload.baseUnitIndex,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case DECREMENT_ORDER_BASE_UNIT_QUANTITY:
            updatedProducts = decrementBaseUnitInput(state.PurchasedProducts, action.payload.productId, action.payload.baseUnitIndex,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case UPDATE_ORDER_BASE_UNIT_QUANTITY:
            updatedProducts = state.PurchasedProducts.map((product) => {
                if ('quantity' in product) {
                    product.quantity = product.quantity.map((bUnit) => {
                        if (bUnit.id === action.payload.baseUnitId) {
                            return { ...bUnit, count: action.payload.newCount };
                        }
                        return { ...bUnit };
                    });
                }
                return { ...product };
            });
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };


        case ADD_ORDER_TO_DETAILS:

            updatedDetails = updateDetailsWithProduct(state.newPODetails, state.PurchasedProducts, action.payload.productId);
            return {
                ...state,
                newPODetails: updatedDetails,
            };

        case REMOVE_BRAND_FROM_ORDER:
            updatedDetails = state.newPODetails.map((item) => {
                if ('productBrands' in item) {
                    const brandsToRemove = item.productBrands.filter((brand) => brand.id === action.payload.brandID);
                    brandsToRemove.forEach((brand) => {
                        brand.favorite = false;
                        brand.quantity.forEach((qty) => {
                            qty.boxCount = 0;
                            qty.cansCount = 0;

                        });
                    });
                }
                return item;
            });
            return {
                ...state,
                newPODetails: updatedDetails,
            };

        case REMOVE_ORDER_FROM_PURCHASE:
            updatedDetails = state.newPODetails.filter((item) => item.id !== action.payload.productId);
            const itemToRemove = state.newPODetails.find((item) => item.id === action.payload.productId);
            if (itemToRemove) {
                if ('quantity' in itemToRemove) {
                    itemToRemove.quantity.forEach((q) => {
                        q.count = 0;
                    });
                }
            }
            return {
                ...state,
                newPODetails: updatedDetails,
            };

        case UPDATE_UPRICE_ON_HAND:
            const { productIndex, newValue } = action.payload;
            let convertUpriceProducts = state.PurchasedProducts.map((product, index) => {
                if (index === productIndex) {
                    let updatedProductBrands = product.productBrands.map((brand) => {
                        return {
                            ...brand,
                            upriceonhand: parseFloat(newValue),
                            upricereq: parseFloat(newValue) / state.cansPerBox
                        };
                    });

                    return {
                        ...product,
                        productBrands: updatedProductBrands
                    };
                }
                return product;
            });
            console.log(`After update:`, convertUpriceProducts);
            return {
                ...state,
                PurchasedProducts: convertUpriceProducts,
                newPODetails: convertUpriceProducts
            };
        case UPDATE_UPRICE_REQ:
            const { productReqIndex, newReqValue } = action.payload;
            let convertUpriceReqProducts = state.PurchasedProducts.map((product, index) => {
                if (index === productReqIndex) {
                    let updatedProductBrands = product.productBrands.map((brand) => {
                        return {
                            ...brand,
                            upricereq: parseFloat(newReqValue),
                            upriceonhand: parseFloat(newReqValue) * state.cansPerBox
                        };
                    });

                    return {
                        ...product,
                        productBrands: updatedProductBrands
                    };
                }

                return product;
            });

            return {
                ...state,
                PurchasedProducts: convertUpriceReqProducts,
                newPODetails: convertUpriceReqProducts
            };
        case UPDATE_BASE_UNIT_PRICE:
            const { productInd, newUnitPrice } = action.payload;


            const updatedbBaseUnitPrice = state.PurchasedProducts.map((product, index) => {
                if (index === productInd) {
                    return {
                        ...product,
                        upriceonhand: newUnitPrice
                    };
                }
                return product;
            });

            return {
                ...state,
                PurchasedProducts: updatedbBaseUnitPrice,
                newPODetails: updatedbBaseUnitPrice
            };

        case SET_TOTAL_QTY_ON_HAND_PRICE: return { ...state, totalQtyOnHandPrice: action.payload, };
        case SET_TOTAL_QTY_REQUESTED_PRICE: return { ...state, totalQtyRequestedPrice: action.payload, };
        case SET_TOTAL_PRICE: return { ...state, totalPrice: action.payload, };
        case SET_TOTAL_BASE_UNIT_PRICE: return { ...state, totalQtyOnHandPrice: action.payload, };
        case SET_DISCOUNT_AED:
            const { discountAEDproductId, discountAED } = action.payload;
            let DiscountAED = state.PurchasedProducts.map((product, index) => {
                if (index === discountAEDproductId) {
                    return {
                        ...product,
                        discountAED: parseFloat(discountAED),
                        discountPercentage: (((parseFloat(discountAED)) / product.totalPrice) * 100).toFixed(2)

                    };
                }
                return product;
            });
            console.log(`discountAED:`, discountAED);
            return {
                ...state,
                PurchasedProducts: DiscountAED,
                newPODetails: DiscountAED
            };
        case SET_DISCOUNT_PERCENTAGE:
            const { discountAPercproductId, discountPerc } = action.payload;
            let DiscountPercentage = state.PurchasedProducts.map((product, index) => {
                if (index === discountAPercproductId) {
                    return {
                        ...product,
                        discountPercentage: parseFloat(discountPerc),
                        discountAED: ((parseFloat(discountPerc) / 100) * product.totalPrice).toFixed(2)

                    };
                }
                return product;
            });
            console.log(`After update:`, DiscountPercentage);
            return {
                ...state,
                PurchasedProducts: DiscountPercentage,
                newPODetails: DiscountPercentage
            }
        case SET_FINAL_PRICE: return { ...state, finalPrice: action.payload, };
        case SET_BRAND_DISCOUNT_AED:
            const { discountAEDbrandId, bdiscountAED } = action.payload;
            let updatedProductsAED = state.PurchasedProducts.map((product, index) => {
                if (index === discountAEDbrandId) {
                    let updatedBrandsAED = product.productBrands.map((brand, brandIndex) => {
                        // if (brandIndex === discountAEDbrandId) {
                        return {
                            ...brand,
                            discountAED: parseFloat(bdiscountAED),
                            discountPercentage: (((parseFloat(bdiscountAED)) / brand.totalPrice) * 100).toFixed(2)
                        };
                        // }
                        // return brand;
                    });

                    return {
                        ...product,
                        productBrands: updatedBrandsAED
                    };
                }

                return product;
            });

            console.log(`discountAED:`, bdiscountAED);

            return {
                ...state,
                PurchasedProducts: updatedProductsAED,
                newPODetails: updatedProductsAED
            };
        case SET_BRAND_DISCOUNT_PERCENTAGE:
            const { discountPercbrandId, bdiscountPerc } = action.payload;

            let updatedBrandPercen = state.PurchasedProducts.map((product, index) => {
                if (index === discountPercbrandId) {
                    let updatedBrands = product.productBrands.map((brand) => {
                        return {
                            ...brand,
                            discountPercentage: parseFloat(bdiscountPerc),
                            discountAED: ((parseFloat(bdiscountPerc) / 100) * brand.totalPrice).toFixed(2)
                        };
                    });

                    return {
                        ...product,
                        productBrands: updatedBrands
                    };
                }

                return product;
            });

            console.log(`After update:`, updatedBrandPercen);

            return {
                ...state,
                PurchasedProducts: updatedBrandPercen,
                newPODetails: updatedBrandPercen
            };

        case CHANGE_ORDER_WEIGHT_TYPE:
            updatedProducts = state.PurchasedProducts.map((product) => {
                if ('quantity' in product) {
                    product.quantity = product.quantity.map((qty) => {
                        if (qty.id === action.payload.baseUnitId) {
                            const newCount =
                                action.payload.weightType === 'Gr'
                                    ? qty.count * 1000
                                    : qty.count / 1000;
                            return { ...qty, weightType: action.payload.weightType, count: newCount };
                        }
                        return { ...qty };
                    });
                }
                return { ...product };
            });
            updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                PurchasedProducts: updatedProducts,
                newPODetails: updatedDetails,
            };
        case TOGGLE_BRAND_FAVORITE:
            const updatedPurchasedProducts = state.PurchasedProducts.map(product => {
                const updatedBrands = product.productBrands.map(brand => {
                    if (brand.id === action.payload.brandId) {
                        return { ...brand, favorite: true };
                    } else {
                        return { ...brand, favorite: false };
                    }
                });

                let brandWithUpdatedFavorite = false;
                updatedBrands.forEach(brand => {
                    if (brand.favorite) {
                        brandWithUpdatedFavorite = true;
                    }
                });

                if (!brandWithUpdatedFavorite && updatedBrands.length > 0) {
                    updatedBrands[0].favorite = true;
                }

                return { ...product, productBrands: updatedBrands };
            });

            return {
                ...state,
                PurchasedProducts: updatedPurchasedProducts,
                activeCardId: action.payload.brandId
            };
        case SET_ACTIVE_CARD:
            return {
                ...state,
                activeCardId: action.payload.brandId,
            };

        case ADD_REMARK_TO_PURCHASE:
            updatedProducts = state.PurchasedProducts.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        remarks: action.payload.remarks,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                newPODetails: updatedDetails,
                PurchasedProducts: updatedProducts,
            };
        default:
            return state;
    }
};
export default NewPurchaseReducer;
