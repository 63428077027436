import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';


    const viewTaskStyle = makeStyles((theme: CustomTheme) =>
    createStyles({

        viewTaskImage: {
            display: 'flex',
            justifyContent: 'center',
        },
        cardHeader: {
            display: 'flex',
            alignItems: 'center',
        },
        viewTaskCardBodyLeft: {
            fontSize: '10px',
            lineHeight: '4.7px',
        },


    })

    );
    export default viewTaskStyle;