
import { useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Grid, Avatar, Typography, Box, Badge, Modal, useTheme, Toolbar } from '@mui/material'
import CircularProgressFixed from '../../components/statistics/circular-progress'
//ICONS
import { Dispatch } from 'redux';
import ViewTask from '../tasks/view-task-modal/view-tasks-modal';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReactComponent as WarningIcon } from '../../assets/images/warning-icon.svg'
import { ReactComponent as WastageIcon } from '../../assets/images/wastage-icon.svg'
import { ReactComponent as EmployeeIcon } from '../../assets/images/employee-lookup.svg'
import { ReactComponent as IncomingIcon } from '../../assets/images/incoming-icon.svg'
import { ReactComponent as OutgoingIcon } from '../../assets/images/outgoing-icon.svg'
import { ReactComponent as ReceivingIcon } from '../../assets/images/receiving-icon.svg'
import { ReactComponent as TransferIcon } from '../../assets/images/transfer-icon.svg'
import { ReactComponent as PoIcon } from '../../assets/images/Po-icon.svg'
import { ReactComponent as ReceivedIcon } from '../../assets/images/received-icon.svg'
import { ReactComponent as PurchaseIcon } from '../../assets/images/purchase-icon.svg'
import { ReactComponent as StockTakeIcon } from '../../assets/images/stock-take-icon.svg'
import { ReactComponent as SalesIcon } from '../../assets/images/sales-icon.svg'
import { ReactComponent as ProductionIcon } from '../../assets/images/production-icon.svg'
//TRANSLATION
import { getDictionary, getField } from '../../utils/translation/translation';
import { TranslationType } from "../../types/translationTypes";
import { connect } from "react-redux";
//DESIGN
import "../../components/calendar/calendar.css";
import useStyles from './styles';
import ThemeSwitch from '../../components/switches/theme-switch';
import { CustomTheme } from '../../theme/index';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../../components/menu-drawer/desktop/sidebar';
import MobileMenu from '../../components/menu-drawer/mobile/MobileMenu';
import { MenuDrawerActions } from '../../redux/actions/menuDrawerActions';

const drawerWidth = 240;
interface MainProps {
    open: boolean;
    children: React.ReactNode;
}


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

type Anchor = 'bottom' | 'left';

function Dashboard({ MenuOpen, MenuClose, Theme, open, lightTheme, darkTheme, translation, language, mobileView, desktopView, DashHeaderTextColor }: { Theme: any, darkTheme: any, lightTheme: any, translation: TranslationType[], language: string, mobileView?: boolean, desktopView?: boolean, DashHeaderTextColor: any, MenuOpen?: any, MenuClose?: any, open?: any }) {
    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
        open?: boolean;
    }>(({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        color: Theme === "lightTheme" ? lightTheme.mainTextColor : darkTheme.mainTextColor,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }));
    const MobileMain = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<MainProps>(
        ({ theme, open, }) => ({
            flexGrow: 1,
            color: Theme === "lightTheme" ? lightTheme.mainTextColor : darkTheme.mainTextColor,
            padding: theme.spacing(2),
            paddingTop: "34px",
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            ...(open && {
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),

            }),
        })
    );
    // const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
       MenuOpen()
    };

    const handleDrawerClose = () => {
        MenuClose()
    };
    const Dictionary = getDictionary(translation, "menu", language);

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClose = () => {
        handleCloseDialog();
    };
    const [selectedTheme, setSelectedTheme] = useState('lightTheme');
    const navigate = useNavigate();

    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open} sx={{ boxShadow: 'none !important', paddingTop: '10px', paddingBottom: '45px', borderRadius: '0px 0px 26px 26px', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.DashHeaderTextColor : darkTheme.DashHeaderTextColor }}>
                <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid container display='flex' justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={mobileView ? (toggleDrawer("left", true)) : (open ? handleDrawerClose : handleDrawerOpen)}
                                edge="start"
                                sx={{ mr: 2, }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton size="large" edge="start" color="inherit"><NotificationsOutlinedIcon /></IconButton>
                        </Grid>
                        <Grid item display='flex' alignItems='center' gap='10px'>
                            <Typography component="div" sx={{ flexGrow: 1, textTransform: 'none', fontSize: '20px', fontWeight: '400' }}>User Name</Typography>
                            <IconButton edge="end" ><Avatar sx={{ width: 50, height: 50, }}>EKS</Avatar></IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Grid container paddingLeft='24px' paddingRight='24px' alignItems='center' justifyContent='center'>
                    <Grid container item xs={12} sm={8} md={8} lg={9} gap='30px' alignItems="center" paddingLeft='30px' textAlign='left'>
                        <Grid item justifyContent="center">
                            <Typography variant="h6" sx={{}}>{getField(Dictionary, "tasks-done", language)}</Typography>
                            <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: "normal" }}>Tuesday, 09 May</Typography>
                        </Grid>
                        <Grid item><CircularProgressFixed /></Grid>
                    </Grid>
                    <Grid container item xs={12} sm={4} md={4} lg={3} alignItems='center' textAlign='center' paddingTop='10px'>
                        <Grid item xs={6} sm={7} md={6} lg={6} >
                            <Button className={classes.smallbutton} sx={{ backgroundColor: 'third.main' }} onClick={() => { navigate('/tasks/tasks-list') }}>{getField(Dictionary, "view-tasks", language)}</Button>
                        </Grid>
                        <Grid item xs={6} sm={5} md={6} lg={6} >
                            <Button className={classes.smallbutton} sx={{ backgroundColor: 'third.main' }} onClick={() => { navigate('/new-task') }}>{getField(Dictionary, "add-task", language)}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" sx={{ position: 'absolute', top: '90%' }}>
                    <Button className={` ${classes.tasksleftbutton}`} onClick={handleOpenDialog} sx={{ backgroundColor: theme.palette.third.main, '&:hover': { backgroudColor: `${theme.palette.primary.main} !important`, } }}>
                        <WarningIcon /> <Typography variant="h6" sx={{ color: theme.palette.third.contrastText, fontWeight: "bold" }}>2 {getField(Dictionary, "tasks-left", language)}</Typography>
                    </Button>
                    <Modal open={openDialog} onClose={handleCloseDialog}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ backgroundColor: 'white', padding: '20px', outline: 'none', borderRadius: "40px" }}>
                            <ViewTask />
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <u onClick={handleClose} style={{ cursor: 'pointer' }} >Close</u>
                            </div>
                        </div>
                    </Modal>
                </Grid>
            </AppBar>

            {mobileView ? (<><MobileMenu state={state} toggleDrawer={toggleDrawer} setSelectedTheme={setSelectedTheme} /></>) : (<><Sidebar   setSelectedTheme={setSelectedTheme} /></>)}
            {mobileView ? (<>  <MobileMain open={open}>
                <Grid container sx={{ display: "flex", flexDirection: mobileView ? "column" : '', justifyContent: mobileView ? "center" : 'space-between', alignItems: desktopView ? "center" : '', marginTop: mobileView ? '250px' : '150px', paddingLeft: '24px', paddingRight: '24px' }}>
                    <Grid item xs={12} md={3.5} sx={{ borderRadius: '20px', display: 'flex', flexDirection: 'column', justifyContent: "center" }} className={classes.widebox}>
                        <div style={{ display: "flex", alignItems: "center", }}>
                            <LocationOnIcon sx={{ color: theme.palette.warning.main }} />
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Branch 1 Location</Typography>
                        </div>
                        <Typography variant="subtitle2" sx={{ fontWeight: "normal", paddingLeft: "5px", whiteSpace: 'nowrap' }}>Branch 1, branch, address, street, etc.</Typography>
                    </Grid>
                    <Grid container item xs={12} md={8} gap='5px' alignItems='center'>
                        <Grid item sx={{ color: 'text.primary', fontWeight: "bold", marginTop: mobileView ? '20px' : "", whiteSpace: 'nowrap' }}>{getField(Dictionary, "today-store", language)}</Grid>
                        <Grid item xs={12} md={10} style={{ display: 'flex' }}>
                            <Box sx={{ backgroundColor: "success.main", width: "50%", borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px', display: 'flex', flexDirection: 'column', justifyContent: "center", }} className={classes.widebox}>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>300</Typography>
                                <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>{getField(Dictionary, "item-received", language)}</Typography>
                            </Box>
                            <Box sx={{ backgroundColor: "attention.main", width: "40%", display: 'flex', flexDirection: 'column', justifyContent: "center" }} className={classes.widebox}>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>700</Typography>
                                <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>{getField(Dictionary, "item-sent", language)}</Typography>
                            </Box>
                            <Box sx={{ width: "10%", borderBottomRightRadius: '20px', borderTopRightRadius: '20px', display: 'flex', justifyContent: "center", alignItems: 'center' }} className={classes.widebox}><IconButton><KeyboardArrowRightIcon sx={{ color: 'icons.main' }} /></IconButton></Box>
                        </Grid></Grid>
                    <Grid container item gap={mobileView ? '20px' : ''} justifyContent={mobileView ? 'center' : 'space-around'} alignItems='center' marginTop={desktopView ? '5%' : '0px'}>
                        <Grid item style={{ marginTop: mobileView ? "40px" : '' }} display='flex' gap={mobileView ? '50px' : '70px'} >
                            <Grid item>
                                <Badge badgeContent={3} color="warning">
                                    <Button sx={{ backgroundColor: "card.main", }} onClick={() => { navigate('/wastage/preview') }} className={classes.menuButton}>
                                        <WastageIcon className={classes.menuIcons} />{getField(Dictionary, "wastage", language)}
                                    </Button></Badge></Grid>
                            <Grid item>   <Badge badgeContent={2} color="warning">
                                <Button sx={{ backgroundColor: "card.main", }} onClick={() => { navigate('/employee/preview') }} className={classes.menuButton}>
                                    <EmployeeIcon className={classes.menuIcons} />{getField(Dictionary, "employees", language)}
                                </Button></Badge></Grid>
                        </Grid>

                        <Grid item md={4} sx={{ border: '2px solid', borderColor: "box", borderRadius: "20px", padding: '10px 20px 20px 20px', marginTop: "20px", display: "flex", flexDirection: "column", gap: "15px", }}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", paddingLeft: '10px', }}>{getField(Dictionary, "internal-request", language)}</Typography>
                            <div style={{ display: "flex", flexDirection: "column", gap: '30px' }} >
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : 'center', gap: mobileView ? '50px' : '20px' }}>
                                    <Badge badgeContent={3} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                            <IncomingIcon className={classes.menuIcons} />{getField(Dictionary, "incoming", language)}
                                        </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                            <OutgoingIcon className={classes.menuIcons} />{getField(Dictionary, "outgoing", language)}
                                        </Button></Badge>
                                </div>
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : 'center', gap: mobileView ? '50px' : '20px' }}>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                        <ReceivingIcon className={classes.menuIcons} />{getField(Dictionary, "receiving", language)}
                                    </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main" }} className={classes.menuButton} onClick={() => { navigate('/transfer/transfer-preview') }}>
                                            <TransferIcon className={classes.menuIcons} />{getField(Dictionary, "transfer", language)}
                                        </Button></Badge>
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={4} flexDirection='column'>
                            <Grid item sx={{ border: '1px solid', borderColor: "box", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "20px", marginTop: "20px", padding: '10px 20px 20px 20px' }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", paddingLeft: '10px', }}>{getField(Dictionary, "purchasing", language)}</Typography>
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : '', gap: mobileView ? '25px' : '15px' }}>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/purchase/purchasing-main') }}>
                                        <PoIcon className={classes.menuIcons} />{getField(Dictionary, "po", language)}
                                    </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/purchase/recieving') }}>
                                            <ReceivedIcon className={classes.menuIcons} />Receiving
                                        </Button></Badge>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/new-purchase/lookup') }}>
                                        <PurchaseIcon className={classes.menuIcons} />Purchase </Button> </Badge>
                                </div>
                            </Grid>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: '10px', marginTop: "20px", paddingBottom: '20px' }}>

                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : '', gap: mobileView ? '25px' : '' }}>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/stock/preview') }}>
                                        <StockTakeIcon className={classes.menuIcons} />{getField(Dictionary, "stock-take", language)}
                                    </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                            <SalesIcon className={classes.menuIcons} />{getField(Dictionary, "outgoing", language)}
                                        </Button></Badge>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                        <ProductionIcon className={classes.menuIcons} />{getField(Dictionary, "receiving", language)}
                                    </Button></Badge>
                                </div>
                            </Box>
                        </Grid>
                    </Grid >
                </Grid >
            </MobileMain> </>) : (<>  <Main open={open}>
                <Grid container sx={{ display: "flex", flexDirection: mobileView ? "column" : '', justifyContent: mobileView ? "center" : 'space-between', alignItems: desktopView ? "center" : '', marginTop: mobileView ? '250px' : '120px', paddingLeft: '24px', paddingRight: '24px' }}>
                    <Grid item xs={12} md={3.5} sx={{ borderRadius: '20px', display: 'flex', flexDirection: 'column', justifyContent: "center" }} className={classes.widebox}>
                        <div style={{ display: "flex", alignItems: "center", }}>
                            <LocationOnIcon sx={{ color: theme.palette.warning.main }} />
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Branch 1 Location</Typography>
                        </div>
                        <Typography variant="subtitle2" sx={{ fontWeight: "normal", paddingLeft: "5px", whiteSpace: 'nowrap' }}>Branch 1, branch, address, street, etc.</Typography>
                    </Grid>
                    <Grid container item xs={12} md={8} gap='5px' alignItems='center'>
                        <Grid item sx={{ fontWeight: "bold", marginTop: mobileView ? '20px' : "", whiteSpace: 'nowrap' }}>{getField(Dictionary, "today-store", language)}</Grid>
                        <Grid item xs={12} md={10} style={{ display: 'flex' }}>
                            <Box sx={{ backgroundColor: "success.main", width: "50%", borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px', display: 'flex', flexDirection: 'column', justifyContent: "center", }} className={classes.widebox}>
                                <Typography variant="h4" sx={{ color: 'white', fontWeight: "bold" }}>300</Typography>
                                <Typography sx={{ color: 'white', fontWeight: "bold", fontSize: "10px" }}>{getField(Dictionary, "item-received", language)}</Typography>
                            </Box>
                            <Box sx={{ backgroundColor: "attention.main", width: "40%", display: 'flex', flexDirection: 'column', justifyContent: "center" }} className={classes.widebox}>
                                <Typography variant="h4" sx={{ color: 'white', fontWeight: "bold" }}>700</Typography>
                                <Typography sx={{ color: 'white', fontWeight: "bold", fontSize: "10px" }}>{getField(Dictionary, "item-sent", language)}</Typography>
                            </Box>
                            <Box sx={{ width: "10%", borderBottomRightRadius: '20px', borderTopRightRadius: '20px', display: 'flex', justifyContent: "center", alignItems: 'center' }} className={classes.widebox}><IconButton><KeyboardArrowRightIcon sx={{ color: 'icons.main' }} /></IconButton></Box>
                        </Grid></Grid>
                    <Grid container item gap={mobileView ? '20px' : ''} justifyContent={mobileView ? 'center' : 'space-around'} alignItems='center' marginTop={desktopView ? '5%' : '0px'}>
                        <Grid item style={{ marginTop: mobileView ? "40px" : '' }} display='flex' gap={mobileView ? '50px' : '70px'} >
                            <Grid item>
                                <Badge badgeContent={3} color="warning">
                                    <Button sx={{ backgroundColor: "card.main", }} onClick={() => { navigate('/wastage/preview') }} className={classes.menuButton}>
                                        <WastageIcon className={classes.menuIcons} />{getField(Dictionary, "wastage", language)}
                                    </Button></Badge></Grid>
                            <Grid item>   <Badge badgeContent={2} color="warning">
                                <Button sx={{ backgroundColor: "card.main", }} onClick={() => { navigate('/employee/preview') }} className={classes.menuButton}>
                                    <EmployeeIcon className={classes.menuIcons} />{getField(Dictionary, "employees", language)}
                                </Button></Badge></Grid>
                        </Grid>
                        <Grid item md={4} sx={{ border: '2px solid', borderColor: "box", borderRadius: "20px", padding: '10px 20px 20px 20px', display: "flex", flexDirection: "column", gap: "15px", }}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", paddingLeft: '10px', }}>{getField(Dictionary, "internal-request", language)}</Typography>
                            <div style={{ display: "flex", flexDirection: "column", gap: '30px' }} >
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : 'center', gap: mobileView ? '50px' : '20px' }}>
                                    <Badge badgeContent={3} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                            <IncomingIcon className={classes.menuIcons} />{getField(Dictionary, "incoming", language)}
                                        </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                            <OutgoingIcon className={classes.menuIcons} />{getField(Dictionary, "outgoing", language)}
                                        </Button></Badge>
                                </div>
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : 'center', gap: mobileView ? '50px' : '20px' }}>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                        <ReceivingIcon className={classes.menuIcons} />{getField(Dictionary, "receiving", language)}
                                    </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/transfer/transfer-preview') }}>
                                            <TransferIcon className={classes.menuIcons} />{getField(Dictionary, "transfer", language)}
                                        </Button></Badge>
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={4} flexDirection='column'>
                            <Grid item sx={{ border: '1px solid', borderColor: "box", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "20px", marginTop: "20px", padding: '10px 20px 20px 20px' }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", paddingLeft: '10px', }}>{getField(Dictionary, "purchasing", language)}</Typography>
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : '', gap: mobileView ? '25px' : '15px' }}>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/purchase/purchasing-main') }}>
                                        <PoIcon className={classes.menuIcons} />{getField(Dictionary, "po", language)}
                                    </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/purchase/recieving') }}>
                                            <ReceivedIcon className={classes.menuIcons} />Receiving
                                        </Button></Badge>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/new-purchase/lookup') }}>
                                        <PurchaseIcon className={classes.menuIcons} />Purchase </Button> </Badge>
                                </div>
                            </Grid>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: '10px', marginTop: "20px", paddingBottom: '20px' }}>
                                <div style={{ display: 'flex', justifyContent: desktopView ? 'space-around' : '', gap: mobileView ? '25px' : '' }}>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton} onClick={() => { navigate('/stock/preview') }}>
                                        <StockTakeIcon className={classes.menuIcons} />{getField(Dictionary, "stock-take", language)}
                                    </Button></Badge>
                                    <Badge badgeContent={2} color="warning">
                                        <Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                            <SalesIcon className={classes.menuIcons} />{getField(Dictionary, "outgoing", language)}
                                        </Button></Badge>
                                    <Badge badgeContent={3} color="warning"><Button sx={{ backgroundColor: "card.main", }} className={classes.menuButton}>
                                        <ProductionIcon className={classes.menuIcons} />{getField(Dictionary, "receiving", language)}
                                    </Button></Badge>
                                </div>
                            </Box>
                        </Grid>
                    </Grid >
                </Grid >
            </Main> </>)}

        </Box>
    );
}
const mapStateToProps = (state: any) => ({
    translation: state.translation.translation,
    language: state.translation.language,
    DashHeaderTextColor: state.themeSwitch.DashHeaderTextColor,
    lightTheme: state.themeSwitch.lightTheme,
    darkTheme: state.themeSwitch.darkTheme,
    Theme: state.themeSwitch.theme,
    open : state.menuDrawer.open , 

});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        MenuOpen: () => dispatch(MenuDrawerActions.MenuOpen()),
        MenuClose: () => dispatch(MenuDrawerActions.MenuClose()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
