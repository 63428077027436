import { RootState , StockTakeActions} from "../actions/stockTakeLookupActions";
import {
    INCREMENT_ORDER_QUANTITY, DECREMENT_ORDER_QUANTITY, ADJUST_ORDER_QUANTITY, INCREMENT_EXPIRY_QUANTITY,
    DECREMENT_EXPIRY_QUANTITY, ADJUST_EXPIRY_QUANTITY, INCREMENT_BRAND_EXPIRY_QUANTITY, DECREMENT_BRAND_EXPIRY_QUANTITY, ADJUST_BRAND_EXPIRY_QUANTITY,
    INCREMENT_UNPACKED_QTY, DECREMENT_UNPACKED_QTY, ADJUST_UNPACKED_QTY
} from "../actions/stockTakeLookupActions";
import { prodArr } from "../../types";
import { StockTakeData } from "../../pages/stock/stock-take-lookup/stock-take-lookup-data";
const INITIAL_STATE: RootState = {
    StockProducts: StockTakeData as prodArr,


};

const updatePurchasedProductQty = (
    products: prodArr,
    productId: string,
    brandId: string | null,
    index: number
): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('prodbrands' in product) {
                product.prodbrands.forEach((brand) => {
                    if (brand.id === brandId) {
                        if (brand.qty && brand.qty.length > index && index >= 0) {
                            brand.qty[index].count += 1;
                        }

                    }
                });
            } else {
                product.quantity += 1;
            }
        }
        return product;
    });
};

const decrementPurchasedProductQty = (
    products: prodArr,
    productId: string,
    brandId: string | null,
    index: number
): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('prodbrands' in product) {
                product.prodbrands.forEach((brand) => {
                    if (brand.id === brandId) {
                        if (brand.qty && brand.qty.length > index && index >= 0) {
                            if (brand.qty[index].count > 0) {
                                brand.qty[index].count -= 1;
                            }
                        }
                    }
                });
            } else {
                if (product.quantity > 0) {
                    product.quantity -= 1;
                }
            }
        }
        return product;
    });
};
const updateProductDetailsQty = (
    products: prodArr,
    productId: string,
    newQuantity: number,
    brandId: string | null,
    index: number
): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('prodbrands' in product) {
                product.prodbrands.forEach((brand) => {
                    if (brand.id === brandId) {
                        if (brand.qty && brand.qty.length > index && index >= 0) {
                            brand.qty[index].count = newQuantity;

                        }
                    }
                });
            } else {
                product.quantity = newQuantity;

            }
        }
        return product;
    });

};



const incrementExpQuantity = (
    products: prodArr,
    productId: string,
    expiryIndex: number,
    brandId: string,
): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'expiry' in product) {
            product.expiry.forEach((expiry, index) => {
                if (index === expiryIndex) {
                    expiry.count += 1;
                }
            });
        }
        return product;
    });
};

const incrementBrandExpQuantity = (
    products: prodArr,
    productId: string,
    expiryIndex: number,
    brandId: string,
): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'prodbrands' in product) {
            product.prodbrands.forEach((brand) => {
                if (brand.id === brandId && 'expiry' in brand) {
                    brand.expiry.forEach((expiry, index) => {
                        if (index === expiryIndex) {
                            expiry.count += 1;
                        }
                    });
                }
            });
        }
        return product;
    });
};
const decrementBrandExpQuantity = (
    products: prodArr,
    productId: string,
    expiryIndex: number,
    brandId: string,
): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'prodbrands' in product) {
            product.prodbrands.forEach((brand) => {
                if (brand.id === brandId && 'expiry' in brand) {
                    brand.expiry.forEach((expiry, index) => {
                        if (index === expiryIndex) {
                            expiry.count -= 1;
                        }
                    });
                }
            });
        }
        return product;
    });
};
const updateBrandExpQuantity = (
    products: prodArr,
    productId: string,
    expiryIndex: number,
    brandId: string,
    newQty: number,
): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'prodbrands' in product) {
            product.prodbrands.forEach((brand) => {
                if (brand.id === brandId && 'expiry' in brand) {
                    brand.expiry.forEach((expiry, index) => {
                        if (index === expiryIndex) {
                            expiry.count = newQty;
                        }
                    });
                }
            });
        }
        return product;
    });
};

const decrementExpiryQty = (
    products: prodArr,
    productId: string,
    expiryIndex: number,
): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry.forEach((expiry, index) => {
                    if (index === expiryIndex) {
                        if (expiry.count > 0) {
                            expiry.count -= 1;
                        }
                    }
                });
            } console.log(product)
        }
        return product;
    });
};

const updateProductExpQty = (
    products: prodArr,
    prodId: string,
    newQty: number,
    expiryIndex: number,
): prodArr => {
    return products.map((product) => {
        if (product.id === prodId) {
            if ('expiry' in product) {
                return {
                    ...product,
                    expiry: product.expiry.map((expiry, index) => {
                        if (index === expiryIndex) {
                            return {
                                ...expiry,
                                count: newQty,
                            };
                        }
                        return expiry;
                    }),
                };
            }
        }
        return product;
    });
};

const updatePurchasedUnpackedProductQty = (
    products: prodArr,
    productId: string,
    brandId: string | null,
    index: number
): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            product.Unpacked += 1

        }
        return product;
    });
};

const decrementPurchasedUnpackedProductQty = (
    products: prodArr,
    productId: string,
    brandId: string | null,
    index: number,

): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if (product.Unpacked > 0) {
                product.Unpacked -= 1
            }
        }
        return product;
    });
};
const adjustUnpackedQty = (
    products: prodArr,
    productId: string,
    newQuantity: number
): prodArr => {
    return products.map((product) => {
        if (product.id === productId) {
            product.Unpacked = newQuantity;
        }
        return product;
    });
};
const StockTakeReducer = (state = INITIAL_STATE, action: any) => {

    let updatedProducts;
    switch (action.type) {
        case INCREMENT_ORDER_QUANTITY:
            updatedProducts = updatePurchasedProductQty(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case DECREMENT_ORDER_QUANTITY:
            updatedProducts = decrementPurchasedProductQty(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.brandId, action.payload.index
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case ADJUST_ORDER_QUANTITY:
            const { productId, newQuantity, brandId, index } = action.payload;
            updatedProducts = updateProductDetailsQty(
                state.StockProducts || [],
                productId,
                newQuantity,
                brandId,
                index
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case INCREMENT_EXPIRY_QUANTITY:
            updatedProducts = incrementExpQuantity(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.expiryIndex,
                action.payload.brandId,
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };

        case DECREMENT_EXPIRY_QUANTITY:
            updatedProducts = decrementExpiryQty(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.expiryIndex,
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case ADJUST_EXPIRY_QUANTITY:
            const { prodId, newQty, expiryIndex } = action.payload;
            updatedProducts = updateProductExpQty(
                state.StockProducts || [],
                prodId,
                newQty,
                expiryIndex,
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case INCREMENT_BRAND_EXPIRY_QUANTITY:
            updatedProducts = incrementBrandExpQuantity(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.expiryIndex,
                action.payload.brandId,
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case DECREMENT_BRAND_EXPIRY_QUANTITY:
            updatedProducts = decrementBrandExpQuantity(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.expiryIndex,
                action.payload.brandId,
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case ADJUST_BRAND_EXPIRY_QUANTITY:
            updatedProducts = updateBrandExpQuantity(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.expiryIndex,
                action.payload.brandId,
                action.payload.newQty
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case INCREMENT_UNPACKED_QTY:
            updatedProducts = updatePurchasedUnpackedProductQty(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case DECREMENT_UNPACKED_QTY:
            updatedProducts = decrementPurchasedUnpackedProductQty(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };
        case ADJUST_UNPACKED_QTY:
            updatedProducts = adjustUnpackedQty(
                state.StockProducts || [],
                action.payload.productId,
                action.payload.newQuantity
            );
            return {
                ...state,
                StockProducts: updatedProducts,
            };

        default:
            return state;
    }
};
export default StockTakeReducer;