import { EmployeeSiActionTypes, REMOVE_FROM_EMPLOYEE_LOOKUP_DETAILS, REMOVE_EMPLOYEE_BRAND_FROM_DETAILS, INCREMENT_EMPLOYEE_LOOKUP, DECREMENT_EMPLOYEE_LOOKUP, ADJUST_EMPLOYEE_LOOKUP_QUANTITY, EDIT_EMPLOYEE_SI_COMMENT, INCREMENT_EMPLOYEE_UNPACKED, DECREMENT_EMPLOYEE_UNPACKED, ADJUST_EMPLOYEE_UNPACKED_QTY } from '../actions/employeeStockItemsActions'
import { prodArr, AppState } from '../actions/employeeStockItemsActions';
import EmployeeProductsData from '../../pages/employee/employee-raw-materials/employee-multibrand-data';


const INITIAL_STATE: AppState = {
    employeeSi: EmployeeProductsData as prodArr,
    empDetails: [] as prodArr,
}

const updateProductLookup = (products: prodArr, productId: string, brandIndex: number | null, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === expiryIndex &&
                                        typeof expiry.count === 'number'
                                    ) {
                                        return {
                                            ...expiry,
                                            count: expiry.count + 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        let count = expiry.count;
                        count += 1;
                        return { ...expiry, count };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    })
}

const decrementProductLookup = (products: prodArr, productId: string, brandIndex: number | null, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === expiryIndex &&
                                        typeof expiry.count === 'number' &&
                                        expiry.count > 0
                                    ) {
                                        return {
                                            ...expiry,
                                            count: expiry.count - 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, eIndex) => {
                    if (eIndex === expiryIndex && typeof expiry.count === 'number' && expiry.count > 0) {
                        return {
                            ...expiry,
                            count: expiry.count - 1,
                        };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const incrementUnpacked = (products: prodArr, productId: string): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'Unpacked' in product) {
            return {
                ...product,
                Unpacked: product.Unpacked + 1,
            };
        }
        return product;
    });
};

const decrementUnpacked = (products: prodArr, productId: string): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'Unpacked' in product && product.Unpacked > 0) {
            return {
                ...product,
                Unpacked: product.Unpacked - 1,
            };
        }
        return product;
    });
};

const adjustUnpackedQty = (products: prodArr, productId: string, newQuantity: number): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'Unpacked' in product) {
            return {
                ...product,
                Unpacked: newQuantity >= 0 ? newQuantity : 0,
            };
        }
        return product;
    });
};


const updateDetailsWithProduct = (details: any, updatedProducts: prodArr, productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateQuantityAndDetails = (
    products: prodArr, productId: string,
    brandIndex: number | null, expiryIndex: number,
    newQuantity: number, details: any[],
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (eIndex === expiryIndex && typeof expiry.count === 'number') {
                                        return {
                                            ...expiry,
                                            count: newQuantity,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};


// const setUnpackedToZero = (details: prodArr, productId: string) => {
//     return details.map((item) => {
//         if (item.id === productId && 'Unpacked' in item) {
//             return {
//                 ...item,
//                 Unpacked: 0,
//             };
//         }
//         return item;
//     });
// };


const setExpCountsToZero = (details: prodArr, productId: string,) => {
    const ExpCountsToRemove = details.find((item) => item.id === productId);
    if (ExpCountsToRemove) {
        if ('brands' in ExpCountsToRemove) {
            ExpCountsToRemove.brands.forEach((brand) => {
                brand.expiry.forEach((exp) => { exp.count = 0; });
            });
        }
    }
}

const employeeStockItemsReducer = (state = INITIAL_STATE, action: EmployeeSiActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    let expCountToRemove;
    switch (action.type) {

        case INCREMENT_EMPLOYEE_LOOKUP:
            updatedProducts = updateProductLookup(state.employeeSi, action.payload.productId, action.payload.brandIndex, action.payload.expieryIndex);
            updatedDetails = updateDetailsWithProduct(state.empDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails
            };

        case DECREMENT_EMPLOYEE_LOOKUP:
            updatedProducts = decrementProductLookup(state.employeeSi, action.payload.productId, action.payload.brandIndex, action.payload.expieryIndex)
            updatedDetails = updateDetailsWithProduct(state.empDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails
            };

        case REMOVE_FROM_EMPLOYEE_LOOKUP_DETAILS:
            updatedDetails = state.empDetails.filter((item) => item.id !== action.payload.itemID);
            const itemToRemove = state.empDetails.find((item) => item.id === action.payload.itemID);
            if (itemToRemove) {
                if ('brands' in itemToRemove) {
                    itemToRemove.brands.forEach((brand) => {
                        brand.expiry.forEach((expiry) => {
                            expiry.count = 0;
                        });
                    });
                } else if ('expiry' in itemToRemove) {
                    itemToRemove.expiry.forEach((expiry) => {
                        expiry.count = 0;
                    });
                }
            }
            return {
                ...state,
                empDetails: updatedDetails,
            };

        case REMOVE_EMPLOYEE_BRAND_FROM_DETAILS:
            updatedDetails = state.empDetails.map((item) => {
                if ('brands' in item) {
                    const brandsToRemove = item.brands.filter((brand) => brand.id === action.payload.brandID);
                    brandsToRemove.forEach((brand) => {
                        brand.expiry.forEach((expiry) => {
                            expiry.count = 0;
                        });
                    });
                }
                return item;
            });
            return {
                ...state,
                empDetails: updatedDetails,
            };

        case ADJUST_EMPLOYEE_LOOKUP_QUANTITY:
            const { productId, brandIndex, expieryIndex, newQuantity } = action.payload;
            updatedProducts = updateQuantityAndDetails(state.employeeSi, productId, brandIndex, expieryIndex, newQuantity, state.empDetails);
            updatedDetails = updateDetailsWithProduct(state.empDetails, updatedProducts, productId);
            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails,
            };

        case EDIT_EMPLOYEE_SI_COMMENT:
            updatedProducts = state.employeeSi.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        comment: action.payload.comment,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(
                state.empDetails,
                updatedProducts,
                action.payload.productId
            );

            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails,
            };

        case INCREMENT_EMPLOYEE_UNPACKED:
            updatedProducts = incrementUnpacked(state.employeeSi, action.payload.productId);
            updatedDetails = updateDetailsWithProduct(state.empDetails, updatedProducts, action.payload.productId);
            expCountToRemove = setExpCountsToZero(state.empDetails, action.payload.productId)
            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails
            };

        case DECREMENT_EMPLOYEE_UNPACKED:
            updatedProducts = decrementUnpacked(state.employeeSi, action.payload.productId);
            updatedDetails = updateDetailsWithProduct(state.empDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails
            };

        case ADJUST_EMPLOYEE_UNPACKED_QTY:
            updatedProducts = adjustUnpackedQty(state.employeeSi, action.payload.productId, action.payload.newQuantity);
            updatedDetails = updateDetailsWithProduct(state.empDetails, updatedProducts, action.payload.productId);
            expCountToRemove = setExpCountsToZero(state.empDetails, action.payload.productId)
            return {
                ...state,
                employeeSi: updatedProducts,
                empDetails: updatedDetails
            };

        default:
            return state;
    }
};
export default employeeStockItemsReducer;