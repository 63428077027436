import { combineReducers } from "redux";
import translation from "./Translation.reducer";
import lookupReducer from "./lookupReducer"
import wastageFinishedProductsReducer from './wastageFinishedProductsReducer'
import employeeFinishedProductsReducer from './employeeFinishedProductsReducer'
import employeeStockItemsReducer from "./employeeStockItemsReducer";
import OutgoingRequestReducer from "./outgoingRequestReducers";
import purchaseOrderReducer from "./purchaseOrderReducer";
import inReqRed from "./internalRequestReducer";
import transferProductsReducer from './transferReducer'
import newPurchaseReducer from "./newPurchaseReducers";
import IncomingRequestReducer from './incomingRequestReducers'
import RecievedProductsReducer from "./receivedPurchaseOrderReducers";
import PurchaseOrderBrandReducer from "./purchaseOrderBrandsReducer";
import StockTakeReducer from "./stockTakeLookupReducers";
import ProductionReducer from "./productionReducer";
import themeReducer from "./themeReducer";
import menuDrawerReducer from "./menuDrawerReducer";
const reducers: any = combineReducers({
	translation,
	lookup: lookupReducer,
	wastFP: wastageFinishedProductsReducer,
	employeeFinishedProducts: employeeFinishedProductsReducer,
	employeeLookup: employeeStockItemsReducer,
	outgoing: OutgoingRequestReducer,
	incomingRequest: IncomingRequestReducer,
	internalrequest: inReqRed,
	transfers: transferProductsReducer,
	receivedProducts: RecievedProductsReducer,
	purchasedOrder: purchaseOrderReducer,
	newPurchase: newPurchaseReducer,
	purchOrderBrand: PurchaseOrderBrandReducer,
	themeSwitch: themeReducer,
	stockTake : StockTakeReducer,
	production : ProductionReducer ,
	menuDrawer : menuDrawerReducer
});

export default reducers;