import { useState, useEffect } from 'react';
import { Grid, useTheme, Button, Card, IconButton } from '@mui/material';
//Icons
import { ReactComponent as CloseIt } from '../../../assets/images/cancel.svg'
import { ReactComponent as EditQty } from '../../../assets/images/editQty.svg'
import { ReactComponent as NewRequest } from '../../../assets/images/Group 9626.svg'
import { ReactComponent as NotAvailable } from '../../../assets/images/Group 9613.svg'
import CloseIcon from '@mui/icons-material/Close';
//Redux
import { getDictionary, getField } from '../../../utils/translation/translation';
import { connect } from "react-redux";
import { IncomingRequeststActions } from '../../../redux/actions/incomingRequestAction'
import { Dispatch } from 'redux';
//Types
import { TranslationType } from "../../../types/translationTypes";
import { MultiBrandRequest, SingleProduct } from "../../../redux/actions/incomingRequestAction"
//Design
import useStyles from './styles';
import { CustomTheme } from "../../../theme";
//Custom Components
import EditQtyModal from '../edit-qty-modal/edit-qty-modal'

interface ItemsNotAvailableProps {
    translation: TranslationType[]; language: string; desktopView?: boolean; mobileView?: boolean; id: string; closeModal: any;
    filteredItems: any; setAllStatuses: (productId: string, status: string) => void; SetSendOrder: (productId: string,) => void;
    setStatus: (productId: string, multiId: string, status: string) => void; setMultiStatus: (productId: string, multiId: string, status: string) => void;
    incrementMultiRequest?: any; decrementMultiRequest?: any; adjustMultiRequest?: any;
    incrementIncomingBaseUnitQty?: any; decrementIncomingBaseUnitQty?: any; adjustIncomingBaseUnitQty?: any; convertIncomingBUWeight?: any;
    incrementRequestQty?: any;
    decrementRequestQty?: any;
    adjustRequestQty?: any;
}

const ItemsNotAvailable: React.FC<ItemsNotAvailableProps> = ({ translation, language, desktopView, mobileView, id, closeModal, filteredItems, setStatus, setMultiStatus, SetSendOrder, setAllStatuses,
    incrementMultiRequest, decrementMultiRequest, adjustMultiRequest, incrementIncomingBaseUnitQty, decrementIncomingBaseUnitQty, adjustIncomingBaseUnitQty,
    incrementRequestQty, decrementRequestQty, adjustRequestQty, convertIncomingBUWeight,
}) => {
    const Dictionary = getDictionary(translation, "request-details", language);
    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    const [openEditModals, setOpenEditModals] = useState<{ [key: string]: boolean }>({});
    const OpenEditModal = (id: string) => { const updatedOpenModals = { ...openEditModals, [id]: true }; setOpenEditModals(updatedOpenModals); };
    const CloseEditModal = (id: string) => { setOpenEditModals({ ...openEditModals, [id]: false, }); };
    const sumOfBoxes = (boxCount: number, boxSize: any, canWeight: any): number => { const sumOfBoxesKg = (boxCount * boxSize * canWeight) / 1000; return sumOfBoxesKg; };
    const sumOfCans = (canCount: number, canWeight: any): number => { const sumOfCansKg = (canCount * canWeight) / 1000; return sumOfCansKg; };
    const totalKgs = (boxCount: number, boxSize: any, canWeight: any, canCount: number,): number => { const weightInKilograms = sumOfBoxes(boxCount, boxSize, canWeight) + sumOfCans(canCount, canWeight); return Number(weightInKilograms.toFixed(2)); };
    function declinedAllItem(id: string, filteredItems: any) { filteredItems.map((multiReq: MultiBrandRequest) => { setMultiStatus(id, multiReq.id, 'Rejected') }); filteredItems.map((singleReq: SingleProduct) => { setStatus(id, singleReq.id, 'Rejected') }) }

    return (
        <div className={classes.MultiBrandModal} style={{ display: 'flex', flexDirection: 'column', }}>
            <Grid md={12} display='flex' justifyContent='center' sx={{ position: 'sticky', top: 0, zIndex: 1, background: theme.palette.third.main, }}>
                <IconButton sx={{ position: 'absolute', right: '0', transform: 'translate(-10px, 5px)', }} className={classes.closeButton} onClick={closeModal}><CloseIcon sx={{ color: theme.palette.text.primary }} /></IconButton>
                <Grid item display='flex' flexDirection='column' alignItems='center'> <NotAvailable />  <b>Some items are not available</b></Grid>

            </Grid>
            <div className={classes.HorizCentering}>
                <Grid container className={classes.PendingModal} flexDirection='column' gap='5px'>

                    <Grid flexDirection='column' marginBottom='10px'>
                        {filteredItems.map((reqDet: SingleProduct | MultiBrandRequest, j: number) => {
                            let totalQtyReqSumInKgs = 0;
                            if ('brands' in reqDet) {
                                (reqDet as MultiBrandRequest).brands.forEach((brand) => {
                                    const brandWeightInKgs = totalKgs(brand.qty[1].boxCount, brand.boxSize, brand.canWeight, brand.qty[1].cansCount,);
                                    totalQtyReqSumInKgs += brandWeightInKgs;
                                });
                            }
                            totalQtyReqSumInKgs = Number(totalQtyReqSumInKgs.toFixed(2));
                            let totalQtyOurOhSumInKgs = 0;
                            if ('brands' in reqDet) {
                                (reqDet as MultiBrandRequest).brands.forEach((brand) => {
                                    const brandWeightInKgs = totalKgs(brand.qty[3].boxCount, brand.boxSize, brand.canWeight, brand.qty[3].cansCount,);
                                    totalQtyOurOhSumInKgs += brandWeightInKgs;
                                });
                            }
                            totalQtyOurOhSumInKgs = Number(totalQtyOurOhSumInKgs.toFixed(2));
                            let unpackedOurOnHand = 0; let unpackedRequested = 0; let sumOurQtyOn = 0; let sumQtyReq = 0;
                            if ('baseUnit' in reqDet) {
                                const UnpackedArray = (reqDet as MultiBrandRequest).baseUnit
                                unpackedOurOnHand = UnpackedArray[3].count;
                                unpackedRequested = UnpackedArray[1].count;
                                if (UnpackedArray[3].weightType === 'Gr') { unpackedOurOnHand = unpackedOurOnHand / 1000 } else { unpackedOurOnHand = unpackedOurOnHand }
                                if (UnpackedArray[1].weightType === 'Gr') { unpackedRequested = unpackedRequested / 1000 } else { unpackedRequested = unpackedRequested }
                            }
                            if ('brands' in reqDet) { sumQtyReq += totalQtyReqSumInKgs + unpackedRequested; sumOurQtyOn += totalQtyOurOhSumInKgs + unpackedOurOnHand; }
                            const totalReqQty = sumQtyReq.toLocaleString();
                            const totalOurOhQty = sumOurQtyOn.toLocaleString();

                            function declinedItem(id: string, reqDet: SingleProduct | MultiBrandRequest) {
                                if ('brands' in reqDet) { setMultiStatus(id, reqDet.id, 'Rejected'); } else { setStatus(id, reqDet.id, 'Rejected'); }
                            }

                            return (
                                <Card key={reqDet.id} className={classes.DetailsCard} data-id={reqDet.id} sx={{ backgroundColor: 'card.main', color: theme.palette.text.third, userSelect: 'none', padding: '5px', marginTop: '5px' }}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item display='flex' gap='5px' alignItems='center'>
                                            <div className={classes.Name}>{reqDet.name}</div> <i style={{ fontSize: '11px' }}>(Kg)</i>
                                        </Grid>
                                        <Grid item display='flex' gap='5px' alignItems='center'>
                                            <i style={{ fontSize: '11px' }}>Our Qtoh</i>
                                            <div className={classes.qtyNumber}>{'brands' in reqDet ? totalOurOhQty : reqDet.ourQtyOH}</div>
                                        </Grid>
                                        <Grid item display='flex' gap='5px' alignItems='center'>
                                            <i style={{ fontSize: '11px' }}>Qty Req</i>
                                            <div className={classes.qtyNumber}>{'brands' in reqDet ? totalReqQty : reqDet.qtyRequested}</div>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item onClick={() => declinedItem(id, reqDet)}>
                                            <div className={classes.iconBtn}><CloseIt /></div>
                                            <div className={classes.btnTitle}>Reject it</div>
                                        </Grid>
                                        {('brands' in reqDet ? (sumOurQtyOn > 0) : (reqDet.ourQtyOH > 0)) && (
                                            <Grid item >
                                                <div className={classes.iconBtn}><EditQty onClick={() => OpenEditModal(reqDet.id)} /></div>
                                                <div className={classes.btnTitle}>Edit Qty</div>
                                            </Grid>)}
                                        <Grid item>
                                            <div className={classes.iconBtn}><NewRequest /></div>
                                            <div className={classes.btnTitle}>Send PO</div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            );
                        })}
                    </Grid>
                </Grid>
            </div>
            <Grid className={classes.RejectProductsButton} >
                <Button className={classes.bigBtn2} variant="contained" onClick={() => { declinedAllItem(id, filteredItems); closeModal() }}>Reject Products from request</Button>
            </Grid>
            {filteredItems.map((reqDet: SingleProduct | MultiBrandRequest, j: number) => {
                const isEditModalOpen = openEditModals[reqDet.id];
                return (
                    <EditQtyModal openModal={isEditModalOpen} closeModal={() => CloseEditModal(reqDet.id)} reqDet={reqDet} index={j} key={reqDet.id} id={id}
                        incrementMultiRequest={incrementMultiRequest} decrementMultiRequest={decrementMultiRequest} adjustMultiRequest={adjustMultiRequest}

                        incrementIncomingBaseUnitQty={incrementIncomingBaseUnitQty} decrementIncomingBaseUnitQty={decrementIncomingBaseUnitQty} adjustIncomingBaseUnitQty={adjustIncomingBaseUnitQty}
                        incrementRequestQty={incrementRequestQty} decrementRequestQty={decrementRequestQty} adjustRequestQty={adjustRequestQty} convertIncomingBUWeight={convertIncomingBUWeight}
                    />
                )
            })}
        </div>
    );
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, currentIncomingItem: state.incomingRequest.currentIncomingItem });
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setStatus: (productId: string, multiId: string, status: string) => dispatch(IncomingRequeststActions.setStatus(productId, multiId, status)),
        setMultiStatus: (productId: string, multiId: string, status: string) => dispatch(IncomingRequeststActions.setMultiStatus(productId, multiId, status)),
        setAllStatuses: (productId: string, status: string) => dispatch(IncomingRequeststActions.setAllStatuses(productId, status)),
        SetSendOrder: (productId: string,) => dispatch(IncomingRequeststActions.SetSendOrder(productId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemsNotAvailable);