export const INCREMENT_REQUEST = 'INCREMENT_COUNTER';
export const DECREMENT_REQUEST = 'DECREMENT_COUNTER';
export const ADJUST_REQUEST_QUANTITY = 'ADJUST_QUANTITY';
export const LOAD_CURRENT_ITEM = 'LOAD_CURRENT_ITEM';

export interface OutgoingRequest {
    id: number;
    user: string;
    date: string;
    from: string;
    to: string;
    status: string;
    orderDate: string;
    acceptanceDate: string;
    deliveryDate: string;
    products: {
        id: string;
        name: string;
        requested: number;
        approved: number;
        oh: number;
        date: {
            date: string;
            count: number
        }[];
    }[]
}

export const incrementRequest = (requestId: number, productIndex: number, dateIndex: number) => {
    return {
        type: INCREMENT_REQUEST,
        payload: { requestId, productIndex, dateIndex },
    };
};

export const decrementRequest = (requestId: number, productIndex: number, dateIndex: number) => {
    return {
        type: DECREMENT_REQUEST,
        payload: { requestId, productIndex, dateIndex },
    };
};

export const loadCurrentItem = (item: any) => {
    return {
        type: LOAD_CURRENT_ITEM,
        payload: { item },
    }
}

// export const adjustQuantity = (requestId: string, productIndex: number, dateIndex: number, newQuantity: number) => {
//     return {
//         type: ADJUST_QUANTITY,
//         payload: { requestId, productIndex, dateIndex, newQuantity },
//     };
// };