import React, { lazy, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { styled } from "@mui/material";
// import { Theme } from "@mui/material/styles"; 
import Header from "../components/header/mobile/header";
import { Error } from "../components/error/error";
import { OutgoingRequest } from "../redux/actions/outgoingRequestActions";
import { IncomingRequest, IncomingMultiBrandRequest } from "../redux/actions/incomingRequestAction"
import { ReceivingProductTypes } from "../redux/actions/receivedPurchaseOrderActions";
import Sidebar from '../components/menu-drawer/desktop/sidebar';
import MobileMenu from '../components/menu-drawer/mobile/MobileMenu';
import { ThemeActions, Theme, ColorState } from '../redux/actions/themeActions';


//website pages
//theme setup 
const DesignSetUp = lazy(() => import('../pages/design-setup/design-setup'));
//wastage
const WastageMain = lazy(() => import('../pages/wastage/wastage-main/wastage-main'));
const WastageFinishedProducts = lazy(() => import('../pages/wastage/wastage-finished-products/wastage-finished-products'));
const WastageDetails = lazy(() => import('../pages/wastage/wastage-details/wastage-details'));
const WastageFPDetails = lazy(() => import('../pages/wastage/wastage-details/wastage-finished-products-details'));
const WastagePreview = lazy(() => import('../pages/wastage/wastage-preview/wastage-preview'));
const WastageRawMaterials = lazy(() => import('../pages/wastage/wastage-raw-materials/wastage-stock-items'));
const WastageDesktop = lazy(() => import('../pages/wastage/wastage-finished-products/wastage-finished-products-desktop/wastage-finished-products-desktop'));
//tasks
const Tasks = lazy(() => import('../pages/tasks/task-main/tasks-main'));
const NewTask = lazy(() => import('../pages/tasks/new-task/new-task'));
const TransferTasks = lazy(() => import('../pages/tasks/transfer-task/transfer-tasks'));
const TaskStatus = lazy(() => import('../pages/tasks/tasks-status/task-status'));
const TasksList = lazy(() => import('../pages/tasks/tasks-list/task-lists'));
//employee
const EmployeeMain = lazy(() => import('../pages/employee/employee-main/employee-main'));
const EmployeeLookUpRawMaterials = lazy(() => import('../pages/employee/employee-raw-materials/employee-stock-items'));
const EmployeeLookUpFinishedProducts = lazy(() => import('../pages/employee/employee-finished-products/employee-finished-products'));
const EmployeeDetails = lazy(() => import('../pages/employee/employee-details/employee-details'));
const EmployeeFPDetails = lazy(() => import('../pages/employee/employee-details/employee-FP-details'));
const EmployeePreview = lazy(() => import('../pages/employee/employee-preview/employee-preview'));
//Orders
const OutgoingOrders = lazy(() => import('../pages/requests/outgoing-orders/outgoing-orders'));
const OutgoingRequestDetails = lazy(() => import('../pages/requests/outgoing-request-details/outgoing-request-details'));
const IncomingOrders = lazy(() => import('../pages/requests/incoming-orders/incoming-orders'));
const IncomingRequestDetails = lazy(() => import('../pages/requests/incoming-request-details/incoming-request-details'));
const InternalRequestLookup = lazy(() => import('../pages/order/internal-request-lookup/internal-request-lookup'));
const InternalRequest = lazy(() => import('../pages/order/internal-request-main/internal-request-main'));
const InternalRequestDetails = lazy(() => import('../pages/order/internal-request-details/internal-request-details'));
//scan 
const ScanLookUp = lazy(() => import('../pages/scan/products/scan-lookup'));
const ProductLookupWithExpiry = lazy(() => import('../pages/scan/product-with-expiry/product-with-expiry'));
const ProductWithoutExpiry = lazy(() => import('../pages/scan/product-without-expiry/lookup-without-expiry'));
const ProductBrands = lazy(() => import('../pages/scan/product-brands/multi-brand-lookup'));
//stock
const WarehouseProgress = lazy(() => import('../pages/stock/stock-take-warehouses/warehouse-progress'));
const StockTakeMain = lazy(() => import('../pages/stock/stock-take-main/stock-take-main'));
const StockTakeLookup = lazy(() => import('../pages/stock/stock-take-lookup/stock-take-lookup'));
const StockTakePreviewMain = lazy(() => import('../pages/stock/stock-take-preview/stock-take-preview-main'));
const StockTakeVariance = lazy(() => import('../pages/stock/stock-take-variance/stock-take-variance'));
//supplier
const AllSupplier = lazy(() => import('../pages/supplier/all-supplier/all-supplier'));
const NewSupplier = lazy(() => import('../pages/supplier/new-supplier/new-supplier'));
const SupplierDetails = lazy(() => import('../pages/supplier/supplier-details/supplier-details'));
const AllPayment = lazy(() => import('../pages/supplier/all-payment/all-payment'));
const SupplierPayment = lazy(() => import('../pages/supplier/new-payment/new-payment'))
const StatementOfAccount = lazy(() => import('../pages/supplier/statement-of-account/statement-account'))
//Transfer
const NewTransfer = lazy(() => import('../pages/transfer/transfer-main/transfer-main'))
const TransferPreview = lazy(() => import('../pages/transfer/transfer-preview/transfer-preview'))
const TransferLookupProducts = lazy(() => import('../pages/transfer/transfer-lookup/transfer-lookup'))
const TransferDetails = lazy(() => import('../pages/transfer/transfer-details/transfer-details'))
//Purchase Order
const PurchaseOrderMain = lazy(() => import('../pages/purchase/new-po/new-po-main/new-po-main'))
const PurchaseOrderLookup = lazy(() => import('../pages/purchase/new-po/new-po-lookup/new-po-lookup'))
const PurchaseMain = lazy(() => import('../pages/purchase/purchase/purchase-main/purchase-main'))
const PurchaseOrderSent = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-received'))
const PurchaseTabs = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-order-main'))
const PurchaseOrderApproval = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-credit-note'))
const NewPurchaseOrderDetails = lazy(() => import('../pages/purchase/new-po/new-po-details/new-po-details'))
const ReceivingProducts = lazy(() => import('../pages/purchase/purchase/po-receiving/po-receiving-product'))
const PoReceivedDetails = lazy(() => import('../pages/purchase/purchase/po-receiving/po-received-details/product-received-details'))
const PurchaseVerification = lazy(() => import('../pages/purchase/new-po/purchase-order/purchase-verification'))
//direct purchase 
const NewPurchaseMain = lazy(() => import('../pages/purchase/new-purchase/new-purchase-main/new-purchase-main'))
const NewPurchaseLookup = lazy(() => import('../pages/purchase/new-purchase/new-purchase-lookup/new-purchase-lookup'))
const NewPurchaseDetails = lazy(() => import('../pages/purchase/new-purchase/new-purchase-details/new-purchase-details'))
const ScanInvoiceMain = lazy(() => import('../pages/purchase/new-purchase/scan-upload-invoice/scan-document-main'))
//production
const ProductionMain = lazy(() => import('../pages/production/production-items/production/production-main'))
const NewProductionMain = lazy(() => import('../pages/production/production-main/new-production-main'))
const ProductionLookup = lazy(() => import('../pages/production/production-items/production-lookup/production-lookup'))
const ProductionSummary = lazy(() => import('../pages/production/production-items/production-summary/production-summary'))
const CustomProductionLookup = lazy(() => import('../pages/production/custom-production/custom-production-lookup/custom-production-lookup'))
const CustomProductionSummary = lazy(() => import('../pages/production/custom-production/custom-production-summary/custom-production-summary'))
//sales 
const SalesMain = lazy(() => import('../pages/sales/sales-main/sales-main'))
const QuotationMain = lazy(() => import('../pages/sales/quotation/quotation-main/quotation-main'))
interface DefaultLayoutProps {
    headerHidden: boolean;
    currentItem: OutgoingRequest;
    currentIncomingItem: (IncomingRequest | IncomingMultiBrandRequest);
    currentIncomingIt: ReceivingProductTypes;
    ResetColors: any,
    SetThemeColor: any,
    lightTheme: any,
    darkTheme: any,
    Theme: any 
}

// Define Props Interfaces
interface LayoutProps {
    children: React.ReactNode;
}

interface MainProps {
    open: boolean;
    children: React.ReactNode;
}

// Styled Components
const Layout = styled("div")<LayoutProps>(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
}));


type Anchor = 'bottom';

// DefaultLayout Component
function DefaultLayout({Theme, ResetColors, darkTheme, lightTheme, SetThemeColor, headerHidden, currentItem, currentIncomingItem, currentIncomingIt }: DefaultLayoutProps) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };
    const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<MainProps>(
        ({ theme, open, }) => ({
            flexGrow: 1,
            padding: theme.spacing(2),
            paddingTop: "64px",
            backgroundColor:  Theme === 'lightTheme' ? lightTheme.backgroundColor : darkTheme.backgroundColor,
            color: Theme === 'lightTheme' ? lightTheme.mainTextColor : darkTheme.mainTextColor,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            ...(open && {
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),

            }),
        })
    );
    const [selectedTheme, setSelectedTheme] = useState('lightTheme');
    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundColor : darkTheme.backgroundColor);
    const [selectedHeaderColor, setSelectedHeaderColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.headerColor : darkTheme.headerColor);
    const [selectedMenuColor, setSelectedMenuColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.menuColor : darkTheme.menuColor);
    const [selectedMainTextColor, setSelectedMainTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.mainTextColor : darkTheme.mainTextColor);
    const [selectedMainColor, setSelectedMainColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.mainColor : darkTheme.mainColor);
    const [selectedMenuTextColor, setSelectedMenuTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.menuTextColor : darkTheme.menuTextColor);
    const [selectedMenuIconColor, setSelectedMenuIconColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor);
    const [selectedSectionsBackColor, setSelectedSectionsBackColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundSectionsColor : darkTheme.backgroundSectionsColor);
    const [selectedSectionsBackTextColor, setSelectedSectionsBackTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundSectionsTextColor : darkTheme.backgroundSectionsTextColor);
    const [selectedCardsBckgroundColor, setSelectedCardsBckgroundColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.cardsBackgroundColor : darkTheme.cardsBackgroundColor);
    const [selectedCardsTextColor, setSelectedCardsTextColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.cardsTextColor : darkTheme.cardsTextColor);
    const [selectedCardsIconsColor, setSelectedCardsIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.cardsIconsColor : darkTheme.cardsIconsColor);
    const [selectedBackgroundIconsColor, setSelectedBackgroundIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor);
    const [selectedHeaderIconsColor, setSelectedHeaderIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor);
    const [selectedSectionsIconsColor, setSelectedSectionsIconsColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor);
    const [selectedOddRowColor, setSelectedOddRowColor,] = useState(selectedTheme === 'lightTheme' ? lightTheme.tableOddBackgroundColor : darkTheme.tableOddBackgroundColor);
    const [selectedEvenRowColor, setSelectedEvenRowColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.tableEvenBackgroundColor : darkTheme.tableEvenBackgroundColor);
    const [selectedTextTableColor, setSelectedTextTableColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.tableTextColor : darkTheme.tableTextColor);
    const [selectedFieldColor, setSelectedFieldColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.fieldColor : darkTheme.fieldColor);
    const [selectedTextFieldColor, setSelectedTextFieldColor] = useState(selectedTheme === 'lightTheme' ? lightTheme.fieldTextColor : darkTheme.fieldTextColor);
    useEffect(() => {
        if (selectedTheme === 'lightTheme') {
            setSelectedBackgroundColor(lightTheme.backgroundColor)
            setSelectedHeaderColor(lightTheme.headerColor)
            setSelectedMainColor(lightTheme.mainColor)
            setSelectedMainTextColor(lightTheme.mainTextColor)
            setSelectedMenuColor(lightTheme.menuColor)
            setSelectedMenuTextColor(lightTheme.menuTextColor)
            setSelectedSectionsBackColor(lightTheme.backgroundSectionsColor)
            setSelectedSectionsBackTextColor(lightTheme.backgroundSectionsTextColor)
            setSelectedMenuIconColor(lightTheme.menuIconColor)
            setSelectedCardsBckgroundColor(lightTheme.cardsBackgroundColor)
            setSelectedCardsTextColor(lightTheme.cardsTextColor)
            setSelectedCardsIconsColor(lightTheme.cardsIconsColor)
            setSelectedBackgroundIconsColor(lightTheme.backgroundIconColor)
            setSelectedHeaderIconsColor(lightTheme.headerIconsColor)
            setSelectedSectionsIconsColor(lightTheme.sectionsIconsColor)
            setSelectedOddRowColor(lightTheme.tableOddBackgroundColor)
            setSelectedEvenRowColor(lightTheme.tableEvenBackgroundColor)
            setSelectedTextTableColor(lightTheme.tableTextColor)
            setSelectedFieldColor(lightTheme.fieldColor)
            setSelectedTextFieldColor(lightTheme.fieldTextColor)
        } else {
            setSelectedBackgroundColor(darkTheme.backgroundColor)
            setSelectedHeaderColor(darkTheme.headerColor)
            setSelectedMainColor(darkTheme.mainColor)
            setSelectedMainTextColor(darkTheme.mainTextColor)
            setSelectedMenuColor(darkTheme.menuColor)
            setSelectedMenuTextColor(darkTheme.menuTextColor)
            setSelectedSectionsBackColor(darkTheme.backgroundSectionsColor)
            setSelectedSectionsBackTextColor(darkTheme.backgroundSectionsTextColor)
            setSelectedMenuIconColor(darkTheme.menuIconColor)
            setSelectedCardsBckgroundColor(darkTheme.cardsBackgroundColor)
            setSelectedCardsTextColor(darkTheme.cardsTextColor)
            setSelectedCardsIconsColor(darkTheme.cardsIconsColor)
            setSelectedBackgroundIconsColor(darkTheme.backgroundIconColor)
            setSelectedHeaderIconsColor(darkTheme.headerIconsColor)
            setSelectedSectionsIconsColor(darkTheme.sectionsIconsColor)
            setSelectedOddRowColor(darkTheme.tableOddBackgroundColor)
            setSelectedEvenRowColor(darkTheme.tableEvenBackgroundColor)
            setSelectedTextTableColor(darkTheme.tableTextColor)
            setSelectedFieldColor(darkTheme.fieldColor)
            setSelectedTextFieldColor(darkTheme.fieldTextColor)

        }
    }, [selectedTheme])

    const handleSave = () => {
        SetThemeColor({
            backgroundColor: selectedBackgroundColor,
            mainColor: selectedMainColor,
            menuColor: selectedMenuColor,
            headerColor: selectedHeaderColor,
            mainTextColor: selectedMainTextColor,
            menuTextColor: selectedMenuTextColor,
            menuIconColor: selectedMenuIconColor,
            backgroundSectionsColor: selectedSectionsBackColor,
            backgroundSectionsTextColor: selectedSectionsBackTextColor,
            cardsBackgroundColor: selectedCardsBckgroundColor,
            cardsTextColor: selectedCardsTextColor,
            cardsIconsColor: selectedCardsIconsColor,
            headerIconsColor: selectedHeaderIconsColor,
            sectionsIconsColor: selectedSectionsIconsColor,
            backgroundIconColor: selectedBackgroundIconsColor,
            tableOddBackgroundColor: selectedOddRowColor,
            tableEvenBackgroundColor: selectedEvenRowColor,
            tableTextColor: selectedTextTableColor,
            fieldColor: selectedFieldColor,
            fieldTextColor: selectedTextFieldColor
        }, selectedTheme, "#ffffff")


    };
    return (
        <>
            <Layout>
                <Header toggleDrawer={toggleDrawer} />
                <MobileMenu state={state} toggleDrawer={toggleDrawer} setSelectedTheme={setSelectedTheme} />
                <Main open={false}>
                    <Routes>
                        <Route path="design/theme" element={<DesignSetUp mobileView={true}
                            selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme}
                            lightTheme={lightTheme} darkTheme={darkTheme} ResetColors={ResetColors}
                            selectedBackgroundColor={selectedBackgroundColor} setSelectedBackgroundColor={setSelectedBackgroundColor}
                            selectedHeaderColor={selectedHeaderColor} setSelectedHeaderColor={setSelectedHeaderColor}
                            selectedMenuColor={selectedMenuColor} setSelectedMenuColor={setSelectedMenuColor}
                            selectedMenuTextColor={selectedMenuTextColor} setSelectedMenuTextColor={setSelectedMenuTextColor}
                            selectedMainTextColor={selectedMainTextColor} setSelectedMainTextColor={setSelectedMainTextColor}
                            handleSave={handleSave}
                            selectedSectionsBackColor={selectedSectionsBackColor} setSelectedSectionsBackColor={setSelectedSectionsBackColor}
                            selectedSectionsBackTextColor={selectedSectionsBackTextColor} setSelectedSectionsBackTextColor={setSelectedSectionsBackTextColor}
                            selectedMenuIconColor={selectedMenuIconColor} setSelectedMenuIconColor={setSelectedMenuIconColor}
                            selectedMainColor={selectedMainColor} setSelectedMainColor={setSelectedMainColor}
                            selectedCardsBckgroundColor={selectedCardsBckgroundColor} setSelectedCardsBckgroundColor={setSelectedCardsBckgroundColor}
                            setSelectedCardsTextColor={setSelectedCardsTextColor} selectedCardsTextColor={selectedCardsTextColor}
                            setSelectedCardsIconsColor={setSelectedCardsIconsColor} selectedCardsIconsColor={selectedCardsIconsColor}
                            selectedSectionsIconsColor={selectedSectionsIconsColor} setSelectedSectionsIconsColor={setSelectedSectionsIconsColor}
                            selectedHeaderIconsColor={selectedHeaderIconsColor} setSelectedHeaderIconsColor={setSelectedHeaderIconsColor}
                            selectedBackgroundIconsColor={selectedBackgroundIconsColor} setSelectedBackgroundIconsColor={setSelectedBackgroundIconsColor}
                            selectedOddRowColor={selectedOddRowColor} setSelectedOddRowColor={setSelectedOddRowColor}
                            selectedEvenRowColor={selectedEvenRowColor} setSelectedEvenRowColor={setSelectedEvenRowColor}
                            selectedTextTableColor={selectedTextTableColor} setSelectedTextTableColor={setSelectedTextTableColor}
                            selectedTextFieldColor={selectedTextFieldColor} setSelectedTextFieldColor={setSelectedTextFieldColor}
                            selectedFieldColor={selectedFieldColor} setSelectedFieldColor={setSelectedFieldColor} />}
                        />
                        <Route path="mytasks" element={<Tasks mobileView={true} />} />
                        <Route path="new-task" element={<NewTask mobileView={true} />} />
                        <Route path="transfer-task" element={<TransferTasks mobileView={true} />} />
                        <Route path="tasks/status" element={<TaskStatus mobileView={true} />} />
                        <Route path="tasks/tasks-list" element={<TasksList mobileView={true} />} > </Route>
                        <Route path="wastage/main" element={<WastageMain mobileView={true} />} />
                        <Route path="wastage/finished-products" element={<WastageFinishedProducts mobileView={true} bottomSpace='150px' />} />
                        <Route path="wastage/stock-items" element={<WastageRawMaterials mobileView={true} bottomSpace='150px' />} />
                        <Route path="wastage/stock-items/details" element={<WastageDetails mobileView={true} />} />
                        <Route path="wastage/finished-products/details" element={<WastageFPDetails mobileView={true} />} />
                        <Route path="wastage/preview" element={<WastagePreview mobileView={true} />} />
                        <Route path="employee/main" element={<EmployeeMain mobileView={true} />} />
                        <Route path="employee/finished-products" element={<EmployeeLookUpFinishedProducts mobileView={true} bottomSpace='150px' />} />
                        <Route path="employee/stock-items" element={<EmployeeLookUpRawMaterials mobileView={true} bottomSpace='150px' />} />
                        <Route path="employee/preview" element={<EmployeePreview mobileView={true} />} />
                        <Route path="employee/stock-items/details" element={<EmployeeDetails mobileView={true} />} />
                        <Route path="employee/finished-products/details" element={<EmployeeFPDetails mobileView={true} />} />
                        <Route path="outgoing" element={<OutgoingOrders mobileView={true} />} />
                        {/* {!currentItem ? <Route path="/" element={<OutgoingOrders />} /> : <Route path="/requests/:id" element={<OutgoingRequestDetails />} />} */}
                        <Route path="incoming" element={<IncomingOrders mobileView={true} />} />
                        {!currentIncomingItem ? <Route path="/" element={<IncomingOrders />} /> : <Route path="/requests/:id" element={<IncomingRequestDetails mobileView={true} />} />}
                        <Route path="order/order-lookup" element={<InternalRequestLookup mobileView={true} bottomSpace='150px' />} > </Route>
                        <Route path="order/order-details" element={<InternalRequestDetails mobileView={true} />} > </Route>
                        <Route path="requests/main" element={<InternalRequest mobileView={true} />} > </Route>
                        <Route path="scan/lookup" element={<ScanLookUp />} > </Route>
                        <Route path="scan/lookup-without-expiry" element={<ProductWithoutExpiry />} > </Route>
                        <Route path="scan/lookup-with-expiry" element={<ProductLookupWithExpiry />} > </Route>
                        <Route path="scan/product-brand" element={<ProductBrands />} > </Route>
                        <Route path="stock/warehouse-progress" element={<WarehouseProgress />} > </Route>
                        <Route path="supplier/all-supplier" element={<AllSupplier />} > </Route>
                        <Route path="/supplier/supplier-details" element={<SupplierDetails />}></Route>
                        <Route path="/supplier/new-supplier" element={<NewSupplier />}></Route>
                        <Route path='supplier/payment' element={<SupplierPayment />}>  </Route>
                        <Route path="supplier/all-payment" element={<AllPayment />}> </Route>
                        <Route path='supplier/statement' element={<StatementOfAccount />}></Route>
                        <Route path='/transfer/transfer-main' element={<NewTransfer mobileView={true} />}></Route>
                        <Route path='/transfer/transfer-preview' element={<TransferPreview mobileView={true} />}></Route>
                        <Route path='/transfer/lookup-product' element={<TransferLookupProducts mobileView={true} bottomSpace='150px' />}></Route>
                        <Route path='/transfer/transfer-details' element={<TransferDetails />}> </Route>
                        <Route path='/purchase/main' element={<PurchaseOrderMain mobileView={true} />}> </Route>
                        <Route path='/purchase/lookup' element={<PurchaseOrderLookup mobileView={true} />}> </Route>
                        <Route path='/purchase/purchasing-main' element={<PurchaseMain mobileView={true} />}></Route>
                        <Route path='/purchase/purchasing-order' element={<PurchaseOrderSent />}></Route>
                        <Route path='/purchase/purchasing-verification' element={<PurchaseVerification mobileView={true} />}></Route>
                        <Route path='/purchase/purchasing-tabs' element={<PurchaseTabs />}></Route>
                        <Route path='/purchase/order-details' element={<NewPurchaseOrderDetails mobileView={true} />}></Route>
                        <Route path='/purchase/recieving' element={<ReceivingProducts mobileView={true} />}></Route>
                        {!currentIncomingIt ? <Route path="/" element={<ReceivingProducts />} /> : <Route path="/received/:id" element={<PoReceivedDetails />} />}
                        <Route path='/new-purchase/main' element={<NewPurchaseMain mobileView={true} />}></Route>
                        <Route path='/new-purchase/lookup' element={<NewPurchaseLookup mobileView={true} />}></Route>
                        <Route path='/new-purchase/details' element={<NewPurchaseDetails mobileView={true} />}></Route>
                        <Route path='/new-purchase/scan-invoice-main' element={<ScanInvoiceMain mobileView={true} />}></Route>
                        <Route path='/stock/main' element={<StockTakeMain mobileView={true} />}></Route>
                        <Route path='/stock/lookup' element={<StockTakeLookup mobileView={true} />}></Route>
                        <Route path='/stock/preview' element={<StockTakePreviewMain mobileView={true} />}></Route>
                        <Route path='/stock/variance' element={<StockTakeVariance mobileView={true} />}></Route>
                        <Route path='/production/main' element={<ProductionMain mobileView={true} />}></Route>
                        <Route path='/production/newMain' element={<NewProductionMain mobileView={true} />}></Route>
                        <Route path='/production/lookup' element={<ProductionLookup mobileView={true} />}></Route>
                        <Route path='/production/summary' element={<ProductionSummary mobileView={true} />}></Route>
                        <Route path='/production/custom-lookup' element={<CustomProductionLookup mobileView={true} />}></Route>
                        <Route path='/production/custom/summary' element={<CustomProductionSummary mobileView={true} />}></Route>
                        <Route path='/sales/main' element={<SalesMain mobileView={true} />}></Route>
                        <Route path='/sales/quotation-main' element={<QuotationMain mobileView={true} />}></Route>
                        <Route path="/*" element={<Error />} />
                    </Routes>
                </Main>
            </Layout>
        </>
    );
}
const mapStateToProps = (state: any) => ({
    currentItem: state.outgoing.currentItem,
    currentIncomingItem: state.incomingRequest.currentIncomingItem,
    currentIncomingIt: state.receivedProducts.currentIncomingItem,
    lightTheme: state.themeSwitch.lightTheme,
    darkTheme: state.themeSwitch.darkTheme,
    Theme: state.themeSwitch.theme,
});
const mapDispatchToProps = (dispatch: any) => ({
    ResetColors: (theme: string) => dispatch(ThemeActions.ResetColors(theme)),
    SetThemeColor: (color: string, theme: keyof ColorState, property: Theme) => dispatch(ThemeActions.SetThemeColor(color, theme, property)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);