import {
    Theme, appState, SWITCH_THEME, ThemeActionTypes, SET_THEME_COLOR, RESET_COLORS
} from '../actions/themeActions'

const INITIAL_STATE: appState = {

    themeChoice: 'LIGHT',
    theme: 'lightTheme',
    lightTheme: {
        mainColor: '#7648A3',
        mainTextColor: "#050505",

        backgroundColor: '#f4f5f7',
        backgroundIconColor: "#000000",

        headerColor: '#f4f5f7',
        headerTextColor: '#7648A3',
        headerIconsColor: "#000000",

        menuColor: "#ffffff",
        menuTextColor: "#0F2B5D",
        menuIconColor: "#0F2B5D",

        buttonColor: '#ffffff',
        buttonTextColor: '#ffffff',
        borderButtonColor: '#7648A3',

        backgroundSectionsColor: '#ffffff',
        backgroundSectionsTextColor: "#00000",
        sectionsIconsColor: "#000000",

        cardsBackgroundColor: '#F6F8FB',
        cardsTextColor: "#000000",
        cardsIconsColor: "#000000",

        tableOddBackgroundColor:'#e5dee7',
        tableEvenBackgroundColor:'#f4f5f7',
        tableTextColor:"#000000",

        fieldColor:"#ffffff",
        fieldTextColor:"#000000"
    },
    darkTheme: {
        mainColor: '#A9A9A9',
        mainTextColor: "#050505",

        backgroundColor: '#212121',
        backgroundIconColor: "#ffffff",

        headerColor: '#363636',
        headerTextColor: '#fff',
        headerIconsColor: "#ffffff",

        menuColor: '#36454F',
        menuTextColor: "#ffffff",
        menuIconColor: "#ffffff",

        buttonColor: '#444',
        buttonTextColor: '#fff',
        borderButtonColor: '#7648A3',

        backgroundSectionsColor: '#363636',
        backgroundSectionsTextColor: "#00000",
        sectionsIconsColor: "ffffff",

        cardsBackgroundColor: '#3d3d3d',
        cardsTextColor: "#ffffff",
        cardsIconsColor: "#ffffff",

        tableOddBackgroundColor:'#e5dee7',
        tableEvenBackgroundColor: '#171c24',
        tableTextColor:"#ffffff",

        fieldColor:	"#2e2e2e",
        fieldTextColor:"#ffffff",

    }
}


const themeReducer = (state: appState = INITIAL_STATE, action: ThemeActionTypes) => {

    switch (action.type) {
        case SWITCH_THEME:
            return {
                ...state,
                themeChoice: action.payload.theme,
            };

        case SET_THEME_COLOR:
            const { theme, color } = action.payload;
            let copy: any = {};
            if (theme === "lightTheme") {
                copy = { ...state?.lightTheme }
            }
            else {
                copy = { ...state?.darkTheme }
            }
            for (let property in color) {
                copy[property] = color[property];
            }
            console.log({
                ...state,
                [theme]: { ...copy }
            })
            return {
                ...state,
                [theme]: { ...copy }
                , theme
            };


        case RESET_COLORS:
            const { theme: selectedTheme } = action.payload;
            const initialColors = selectedTheme === 'lightTheme' ? INITIAL_STATE.lightTheme : INITIAL_STATE.darkTheme;
            return {
                ...state,
                [selectedTheme]: { ...initialColors },
            };

        default:
            return state;
    }
};
export default themeReducer;