import { ReceivingProductTypes, ActionTypes } from '../actions/receivedPurchaseOrderActions'
import { PoReceivedData } from '../../pages/purchase/purchase/po-receiving/received-product-data';
import {
    LOAD_CURRENT_ITEM, INCREMENT_MULTI_INCOMING_REQUEST, DECREMENT_MULTI_INCOMING_REQUEST, ADJUST_MULTI_INCOMING_REQUEST

} from '../actions/receivedPurchaseOrderActions';

const INITIAL_STATE = {
    PoReceived: PoReceivedData as ReceivingProductTypes[],
    currentIncomingItem: {} as ReceivingProductTypes,
}

const incrementCounterHelper = (
    item: ReceivingProductTypes,
    multiId: string,
    dateId: number | null,

) => {
    if ('products' in item) {
        return {
            ...item,
            products: item.products.map((multi) => {
                if ('id' in multi && multi.id === multiId) {
                    return {
                        ...multi,
                        date: multi.date.map((date, i) => {
                            if ('date' in date && i === dateId && 'count' in date) {

                                return {
                                    ...date,
                                    count: date.count + 1,

                                };

                            } 
                            return date;
                        }),
                    };
                }
                return multi;
            }),
        };
    } 
    return item;
};

const incrementMultiCounter = (products: ReceivingProductTypes[], productId: string, multiId: string, dateId: number | null,) => {
    return products.map((product) => {
        if ('id' in product && product.id === productId) {
            return incrementCounterHelper(product, multiId, dateId,);
        }
        return product;
    });
};

const incrementMultiItemCounter = (product: ReceivingProductTypes, productId: string, multiId: string, dateId: number | null,) => {
    if ('id' in product && product.id === productId) {
        return incrementCounterHelper(product, multiId, dateId,);
    }
    return product;
};

const decrementCounterHelper = (
    item: ReceivingProductTypes,
    multiId: string,
    dateId: number | null,

) => {
    if ('products' in item) {
        return {
            ...item,
            products: item.products.map((multi) => {
                if ('id' in multi && multi.id === multiId) {
                    return {
                        ...multi,
                        date: multi.date.map((date, i) => {
                            if ('date' in date && i === dateId && 'count' in date) {

                                return {
                                    ...date,
                                    count: date.count - 1,

                                };

                            } 
                            return date;
                        }),
                    };
                }
                return multi;
            }),
        };
    } 
    return item;
};
const adjustCounterHelper = (item: ReceivingProductTypes, multiId: string, dateId: number | null, newQuantity: number) => {
    if ('products' in item) {
        const updatedItem = {
            ...item,
            products: item.products.map((multi) => {
                if ('id' in multi && multi.id === multiId) {
                    const updatedMulti = {
                        ...multi,
                        date: multi.date.map((date, i) => {
                            if ('date' in date && i === dateId && 'count' in date) {
                                return {
                                    ...date,
                                    count: newQuantity,
                                };
                            }
                            return date;
                        }),
                    };
                   
                    return updatedMulti;
                }
                return multi;
            }),
        };
       
        return updatedItem;
    }
    
    return item;
};


const decrementMultiCounter = (products: ReceivingProductTypes[], productId: string, multiId: string, dateId: number | null,) => {
    return products.map((product) => {
        if ('id' in product && product.id === productId) {
            return decrementCounterHelper(product, multiId, dateId,);
        }
        return product;
    });
};

const decrementMultiItemCounter = (product: ReceivingProductTypes, productId: string, multiId: string, dateId: number | null,) => {
    if ('id' in product && product.id === productId) {
        return decrementCounterHelper(product, multiId, dateId,);
    }
    return product;
};
const adjustMultiCounter = (products: ReceivingProductTypes[], productId: string, multiId: string, dateId: number | null, newCount: number,) => {
    return products.map((product) => {
        if ('id' in product && product.id === productId) {
            return adjustCounterHelper(product, multiId, dateId, newCount,);
        }
        return product;
    });
};

const adjustMultiItemCounter = (product: ReceivingProductTypes, productId: string, multiId: string, dateId: number | null, newCount: number,) => {
    if ('id' in product && product.id === productId) {
        return adjustCounterHelper(product, multiId, dateId, newCount,);
    }
    return product;
};



const RecievedProductsReducer = (state = INITIAL_STATE, action: ActionTypes) => {
    let updatedProducts;
    let updatedItem;

    switch (action.type) {
        case INCREMENT_MULTI_INCOMING_REQUEST:
            updatedProducts = incrementMultiCounter(state.PoReceived, action.payload.productId, action.payload.multiId, action.payload.dateId,);
            updatedItem = incrementMultiItemCounter(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.dateId,);
            return {
                ...state,
                PoReceived: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case DECREMENT_MULTI_INCOMING_REQUEST:
            updatedProducts = decrementMultiCounter(state.PoReceived, action.payload.productId, action.payload.multiId, action.payload.dateId,);
            updatedItem = decrementMultiItemCounter(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.dateId,);
            return {
                ...state,
                PoReceived: updatedProducts,
                currentIncomingItem: updatedItem,
            };
        case ADJUST_MULTI_INCOMING_REQUEST:
            updatedProducts = adjustMultiCounter(state.PoReceived, action.payload.productId, action.payload.multiId, action.payload.dateId, action.payload.newQuantity,);
            updatedItem = adjustMultiItemCounter(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.dateId, action.payload.newQuantity,);
            return {
                ...state,
                PoReceived: updatedProducts,
                currentIncomingItem: updatedItem,
            };
        case LOAD_CURRENT_ITEM:
            return {
                ...state,
                currentIncomingItem: action.payload.item
            };

        default:
            return state;
    }
};

export default RecievedProductsReducer;