import humus from '../../../../assets/images/humus.jpg'


export interface ProductionProduct {
    id: string;
    image: string;
    type: string;
    name: string;
    lastdate: string;
    quantity: string;
    qtyOnhand: string;
    qtyKg: string;
    reason: string;
    expiryDate: string;
    ingredient: {
        id: string;
        name: string;
        weight: string;
        specification: string,
    }[];

}
export type productionArr = (ProductionProduct)[]

const ProductionLookupData: productionArr = [
    {
        id: '1',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fatteh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "3",
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [

            { id: "b", name: "Garlic", weight: "500", specification: "Auto Produce" }
        ]

    },
    {
        id: '2',
        image: "",
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "25",
        reason: "Request",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "c", name: "Chickpeas", weight: "5", specification: "Boiled Chickpeas" },
            { id: "c", name: "Aubergine", weight: "50", specification: "" },
            { id: "c55", name: "Garlic", weight: "33", specification: "" },
            { id: "46c", name: "Salt", weight: "190", specification: "" },
        ]


    },
    {
        id: '5',
        image: "",
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "5",
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "d", name: "Chickpeas", weight: "300", specification: "" },
            { id: "c5", name: "Garlic", weight: "33", specification: "Auto Produce" },
            { id: "4c", name: "Salt", weight: "190", specification: "" },
        ]


    },
    {
        id: '3',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "e", name: "Tehine", weight: "180", specification: "" },
            { id: "e", name: "Chickpeas", weight: "180", specification: "" },

        ]

    },
    {
        id: '4',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fatteh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "5",
        reason: "Request",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "f", name: "Chickpeas", weight: "20", specification: "Boiled Chickpeas" }
        ]

    },

    {
        id: '6',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '6',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Qty End",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "k", name: "Aubergine", weight: "20", specification: "" }
        ]

    },
    {
        id: '8',
        image: "",
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "5",
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [

            { id: "l", name: "salt", weight: "470", specification: "" },
            { id: "cf5", name: "Garlic", weight: "33", specification: "" },
        ]


    },
    {
        id: '7',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fatteh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "5",
        reason: "Request",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "r", name: "Chickpeas", weight: "70", specification: "" },
            { id: "r", name: "peper", weight: "70", specification: "" },
        ]

    },

    {
        id: '9',
        image: '',
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Qty End",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "m", name: "Chickpeas", weight: "90", specification: "" },
            { id: "e", name: "Tehine", weight: "180", specification: "" },

        ]

    },
]



export default ProductionLookupData;