import { Grid, useTheme, Button, IconButton, TextField, Modal, Divider } from '@mui/material';
//Redux
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getDictionary, getField } from '../../../utils/translation/translation';
import { connect } from "react-redux";
import { Dispatch } from 'redux';
//Types
import { TranslationType } from "../../../types/translationTypes";
//Design
import useStyles from './styles';
import { CustomTheme } from "../../../theme";

interface EditQtyModalProps {
    translation: TranslationType[]; language: string; desktopView?: boolean; mobileView?: boolean; closeModal: any; openModal: any, key: any; id: any; reqDet: any; index: any;
    incrementMultiRequest?: any; decrementMultiRequest?: any; adjustMultiRequest?: any;

    incrementIncomingBaseUnitQty?: any; decrementIncomingBaseUnitQty?: any; adjustIncomingBaseUnitQty?: any; convertIncomingBUWeight?: any;
    incrementRequestQty?: any;
    decrementRequestQty?: any;
    adjustRequestQty?: any;
}
const EditQtyModal: React.FC<EditQtyModalProps> = ({ translation, language, desktopView, mobileView, closeModal, openModal, key, id, reqDet, index,
    incrementMultiRequest, decrementMultiRequest, adjustMultiRequest, incrementIncomingBaseUnitQty, decrementIncomingBaseUnitQty, adjustIncomingBaseUnitQty,
    incrementRequestQty, decrementRequestQty, adjustRequestQty, convertIncomingBUWeight,
}) => {
    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    const SingleProdInputChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => {
        let inputValue = event.target.value.trim();
        if (inputValue.startsWith('0') && inputValue.length > 1) { inputValue = inputValue.slice(1); }
        const newCount = parseFloat(inputValue) || 0; adjustRequestQty(id, productId, newCount, qtyType);
    };
    const MultiInputChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
        let inputValue = event.target.value.trim();
        if (inputValue.startsWith('0') && inputValue.length > 1) { inputValue = inputValue.slice(1); }
        const newCount = parseFloat(inputValue) || 0; adjustMultiRequest(id, productId, brandId, qtyId, newCount, itemType);
    };
    return (
        <Modal open={openModal} onClose={closeModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container width='300px' gap='20px' sx={{ backgroundColor: theme.palette.third.main, borderRadius: '20px' }} padding='20px' >
                <Grid container item justifyContent='space-between'>
                    <Grid item display='flex' flexDirection='column'>
                        <b style={{ fontSize: "22px", }}>{reqDet.name}</b>
                        <b style={{ fontSize: "12px", }}>Our Quantity On Hand</b>
                    </Grid>
                    <IconButton onClick={closeModal} sx={{ transform: 'translate(5px, -5px)', width: mobileView ? '30px' : '40px', height: mobileView ? '30px' : '40px' }} className={classes.closeButton} ><CloseIcon sx={{ color: theme.palette.text.primary }} /> </IconButton>
                </Grid>
                {'brands' in reqDet ? (
                    <>
                        <Grid container flexDirection='column' gap="10px">
                            <b><u>Unpacked</u></b>
                            <Grid item className={classes.CounterContainer}  >
                                <div className={classes.brandCounterButton}>
                                    <RemoveIcon onClick={() => decrementIncomingBaseUnitQty(id, index, 3)} className={classes.brandCounter} />
                                </div>
                                <div className={classes.QuantityWrapper}>
                                    <input type="number" className={classes.BrandQuantityInput}
                                        value={reqDet.baseUnit[3].count.toString()}
                                        placeholder={reqDet.baseUnit[3].count?.toString()}
                                        inputMode={/iPhone|iPad|iPod/.test(navigator.userAgent) ? 'decimal' : 'decimal'}
                                        //  onChange={(e) => BaseUnitInputChange(e, pIndex, 2)}
                                        onClick={(event) => event.stopPropagation()} />
                                    <b style={{ color: theme.palette.text.color, fontSize: "12px", }}>Kilograms</b>
                                </div>
                                <div className={classes.brandCounterButton}>
                                    <AddIcon onClick={() => incrementIncomingBaseUnitQty(id, index, 3)} className={classes.brandCounter} />
                                </div>
                            </Grid>
                            <Divider />
                        </Grid>
                        {reqDet.brands?.map((brand: any, bI: number) => (
                            <Grid container flexDirection='column' gap="10px">
                                <b><u>{brand.name}</u></b>
                                <Grid item display='flex' alignItems='center' gap='15px'  >
                                    <Grid item className={classes.CounterContainer} >
                                        <div className={classes.brandCounterButton}><RemoveIcon onClick={() => decrementMultiRequest(id, reqDet.id, brand.id, brand.qty[3].id, 'box')} className={classes.brandCounter} />  </div>
                                        <div className={classes.QuantityWrapper}>
                                            <input type="number" className={classes.BrandQuantityInput}
                                                value={brand.qty[3].boxCount.toString()}
                                                inputMode={/iPhone|iPad|iPod/.test(navigator.userAgent) ? 'decimal' : 'decimal'}
                                                onChange={(e) => MultiInputChange(e, reqDet.id, brand.id, brand.qty[3].id, 'box')}
                                            />
                                            <b style={{ color: theme.palette.success.main, fontSize: "12px", }}>Box</b>
                                        </div>
                                        <div className={classes.brandCounterButton}> <AddIcon onClick={() => incrementMultiRequest(id, reqDet.id, brand.id, brand.qty[3].id, 'box')} className={classes.brandCounter} /></div>
                                    </Grid>
                                    <Grid item className={classes.CounterContainer} >
                                        <div className={classes.brandCounterButton}><RemoveIcon onClick={() => decrementMultiRequest(id, reqDet.id, brand.id, brand.qty[3].id, 'can')} className={classes.brandCounter} />  </div>
                                        <div className={classes.QuantityWrapper}>
                                            <input type="number" className={classes.BrandQuantityInput}
                                                value={brand.qty[3].cansCount.toString()}
                                                inputMode={/iPhone|iPad|iPod/.test(navigator.userAgent) ? 'decimal' : 'decimal'}
                                                onChange={(e) => MultiInputChange(e, reqDet.id, brand.id, brand.qty[3].id, 'box')}
                                            />
                                            <b style={{ color: theme.palette.success.main, fontSize: "12px", }}>Can</b>
                                        </div>
                                        <div className={classes.brandCounterButton} ><AddIcon onClick={() => incrementMultiRequest(id, reqDet.id, brand.id, brand.qty[3].id, 'can')} className={classes.brandCounter} />  </div>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>
                        ))}

                    </>
                ) : (<Grid container item className={classes.CounterContainer}>
                    <div className={classes.counterButton}><RemoveIcon onClick={() => decrementRequestQty(id, reqDet.id, 'ourQtyOH')} className={classes.counter} /></div>
                    <div className={classes.QuantityWrapper}>
                        <input type="number" className={classes.QuantityInput}
                            disabled={'brands' in reqDet}
                            value={reqDet.ourQtyOH}
                            inputMode={/iPhone|iPad|iPod/.test(navigator.userAgent) ? 'decimal' : 'decimal'}
                            onChange={(e) => SingleProdInputChange(e, reqDet.id, 'ourQtyOH')}
                            onClick={(event) => event.stopPropagation()} />
                    </div>
                    <div className={classes.counterButton}><AddIcon onClick={() => incrementRequestQty(id, reqDet.id, 'ourQtyOH')} className={classes.counter} /></div>
                </Grid>)}
            </Grid>
        </Modal>
    );
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, });
const mapDispatchToProps = (dispatch: Dispatch<any>) => { return {} }
export default connect(mapStateToProps, mapDispatchToProps)(EditQtyModal);