import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({

ModalStyle:{backgroundColor: theme.palette.third.main, padding: '20px', outline: 'none', borderRadius: "40px"},

InsideModalButtons:{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "100px"},

ModalButton:{padding: "7px 30px", textTransform: "none", marginBottom: "15px", fontWeight: "bold", borderRadius: "15px"},

smallbutton: {   backgroundColor: '#fff !important', width: '103px !important', height: '33px !important', fontSize: '16px !important',  '&:hover': { backgroundColor: '#fdefff !important', color: "#000 !important" }},

moreIcon: {  marginLeft: '80px',  color: 'white', },

tasksleftbutton: {
  display: 'flex !important', gap: '10px !important', justifyContent: 'center !important', alignItems: 'center !important', backgroundColor: '#fff !important', borderRadius: "20px !important",transform:'translate(48%,-20px)',
  border: "2px solid red !important", paddingTop: "5px !important", paddingBottom: "5px !important", paddingRight: '15px !important', paddingLeft: '15px !important', '&:hover': { backgroundColor: '#ececec !important' },
},
textarea: {
   padding: theme.spacing(1),  border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: theme.shape.borderRadius,
  fontSize: '16px',  lineHeight: '1.5',  resize: 'vertical', '&:focus': {  outline: 'none',  borderColor: theme.palette.primary.main, },
},

  })

);

export default useStyles;