import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({


bigBtn:{height:'45px',fontSize:'18px !important',fontWeight:'normal !important',borderRadius:'13px !important',},

PendingModal:{ backgroundColor: theme.palette.third.main, padding: '20px', outline: 'none', borderRadius: "40px", overflowY: 'auto',display: 'flex', alignItems: 'center', justifyContent: 'center'},

HorizCentering:{display: 'flex', alignItems: 'center', justifyContent: 'center'},

MultiBrandModal:{ backgroundColor: theme.palette.third.main, padding: '10px', outline: 'none', borderRadius: "40px",overflowY:'auto'},

 })
);

export default useStyles;