import * as React from 'react';
import { IconButton, Box, useTheme, Divider, ListItemButton, ListItemIcon, ListItemText, List, Typography } from '@mui/material'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import "../../../components/calendar/calendar.css";
import { useNavigate } from 'react-router-dom';
import { getDictionary, getField } from '../../../utils/translation/translation';
import { TranslationType } from "../../../types/translationTypes";
import { connect } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import ChooseLanguage from '../../choose-language/ChooseLanguage';
import useStyles from './styles';
import TranslateIcon from '@mui/icons-material/Translate';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { version } from '../../../version'
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { CustomTheme } from '../../../theme/index';
import ThemeSwitch from '../../switches/theme-switch';
import { ThemeActions } from '../../../redux/actions/themeActions'
import { Dispatch } from 'redux';
import ListItem from '@mui/material/ListItem';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { ReactComponent as Dashboard } from '../../../assets/images/menuIcons/dashboard.svg'
import { ReactComponent as EmployeeMeal } from '../../../assets/images/menuIcons/employeeMeal.svg'
import { ReactComponent as IncomingRequest } from '../../../assets/images/menuIcons/incomingRequest.svg'
import { ReactComponent as InternalRequest } from '../../../assets/images/menuIcons/internalRequest.svg'
import { ReactComponent as MyTask } from '../../../assets/images/menuIcons/myTask.svg'
import { ReactComponent as Outgoing } from '../../../assets/images/menuIcons/outgoing.svg'
import { ReactComponent as ProductsLookup } from '../../../assets/images/menuIcons/productsLookup.svg'
import { ReactComponent as Purchase } from '../../../assets/images/menuIcons/purchase.svg'
import { ReactComponent as PurchaseOrder } from '../../../assets/images/menuIcons/purchaseOrder.svg'
import { ReactComponent as Purchasing } from '../../../assets/images/menuIcons/purchasing.svg'
import { ReactComponent as Quotation } from '../../../assets/images/menuIcons/quotation.svg'
import { ReactComponent as Receiving } from '../../../assets/images/menuIcons/receiving.svg'
import { ReactComponent as Sales } from '../../../assets/images/menuIcons/sales.svg'
import { ReactComponent as StockTake } from '../../../assets/images/menuIcons/stockTake.svg'
import { ReactComponent as StockVariance } from '../../../assets/images/menuIcons/stockVariance.svg'
import { ReactComponent as Supplier } from '../../../assets/images/menuIcons/supplier.svg'
import { ReactComponent as TaskStatus } from '../../../assets/images/menuIcons/taskStatus.svg'
import { ReactComponent as Transfer } from '../../../assets/images/menuIcons/transfer.svg'
import { ReactComponent as Wastage } from '../../../assets/images/menuIcons/wastage.svg'
import DesignSetUp from '../../../pages/design-setup/design-setup';
import { MenuDrawerActions } from '../../../redux/actions/menuDrawerActions';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const drawerWidth = 220;
interface SideBarItem {
	id: string;
	title: string;
	type: "link" | "dropDown";
	path?: string;
	icon: any;
	sub?: SubSideBarItem[];
}

interface SubSideBarItem {
	id: string;
	title: string;
	path: string;
	icon: any;
}

interface SideBarProps {
	translation: TranslationType[];
	language: string;
	 open?: any, switchTheme: (theme: string) => void; themeChoice: string; menuColor: any, menuTextColor: any,
	lightTheme: any, darkTheme: any, Theme: any, setSelectedTheme?: any, MenuOpen?:any, MenuClose?:any
}

const SideBar = ({ MenuOpen, MenuClose, lightTheme, darkTheme, Theme, setSelectedTheme, translation, language, open, switchTheme, themeChoice, menuColor, menuTextColor }: SideBarProps) => {
	const [drop, setDrop] = useState<string[]>([]);
	const Dictionary = getDictionary(translation, "menu", language);
	const [state, setState] = useState({ top: false, left: false, bottom: false, right: false, });
	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) { return; }
				setState({ ...state, [anchor]: open });
			};


	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [chooseLangModalState, setChooseLangModalState] = useState(false)
	const closeChooseLanguage = () => {
		setChooseLangModalState(false)
	}

	const chooseLang = () => {
		setChooseLangModalState(true)
	}
	const classes = useStyles();
	const theme: CustomTheme = useTheme();
	const location = useLocation();
	const [isSubmenuOpen, setIsSubmenuOpen] = useState<{ [key: string]: boolean }>({});
	const toggleSubmenu = (submenuId: string) => {
		if (drop.includes(submenuId)) {
			setDrop((prevDrop) =>
				prevDrop.filter((id) => id !== submenuId)
			);
		} else {
			setDrop((prevDrop) => [...prevDrop, submenuId]);
		}

		setIsSubmenuOpen(prevState => ({
			...prevState,
			[submenuId]: !prevState[submenuId],
		}));
	};

	const navigations: SideBarItem[] = [
		{ id: 'Dashboard', title: 'Dashboard', type: "link", path: "/dashboard", icon: <Dashboard /> },
		{ id: 'my-tasks', title: getField(Dictionary, "my-tasks", language), type: "link", path: "/mytasks", icon: <MyTask /> },
		{ id: 'tasks-status', title: getField(Dictionary, "tasks-status", language), type: "link", path: "/tasks/status", icon: <TaskStatus /> },
		{ id: 'product-lookup', title: getField(Dictionary, "product-lookup", language), type: "link", path: "/scan/lookup", icon: <ProductsLookup /> },
		{ id: 'internal-request', title: getField(Dictionary, "internal-request", language), type: "link", path: "/requests/main", icon: <InternalRequest /> },
		{ id: 'wastage', title: getField(Dictionary, "wastage", language), type: "link", path: "/wastage/preview", icon: <Wastage /> },
		{ id: 'employees-meals', title: getField(Dictionary, "employees-meals", language), type: "link", path: "/employee/preview", icon: <EmployeeMeal /> },
		{
			id: 'internal-request-dropdown', title: getField(Dictionary, "internal-request", language), type: "dropDown", icon: <InternalRequest />,
			sub: [
				{ id: 'incoming', title: getField(Dictionary, "incoming", language), path: "/incoming", icon: <IncomingRequest /> },
				{ id: 'outgoing', title: getField(Dictionary, "outgoing", language), path: "/outgoing", icon: <Outgoing /> },
				{ id: 'receiving', title: getField(Dictionary, "receiving", language), path: "/dashboard", icon: <Receiving /> },
				{ id: 'transfer', title: getField(Dictionary, "transfer", language), path: "/transfer/transfer-preview", icon: <Transfer /> },
			],
		},
		{
			id: 'ipurchasing-dropdown', title: getField(Dictionary, "purchasing", language), type: "dropDown", icon: <Purchasing />,
			sub: [
				{ id: 'po', title: getField(Dictionary, "po", language), path: "/purchase/main", icon: <PurchaseOrder /> },
				{ id: 'received', title: getField(Dictionary, "received", language), path: "/purchase/recieving", icon: <Purchase /> },
				{ id: 'purchased', title: "Purchase", path: "/new-purchase/lookup", icon: <Purchasing /> },
			],
		},
		{ id: 'stock-take', title: getField(Dictionary, "stock-take", language), type: "link", path: "/stock/preview", icon: <StockTake /> },
		{ id: 'stock-take', title: "Stock Variance", type: "link", path: "/stock/variance", icon: <StockVariance /> },
		{ id: 'production', title: getField(Dictionary, "production", language), type: "link", path: "/production/main", icon: <Quotation /> },
		{ id: 'purchased', title: getField(Dictionary, "purchased", language), type: "link", path: "/purchase/purchasing-tabs", icon: <Purchase /> },
		{ id: 'supplier', title: getField(Dictionary, "supplier", language), type: "link", path: '/supplier/all-supplier', icon: <Supplier /> },
		{ id: 'Sales', title: "Sales", type: "link", path: '/sales/main', icon: <Sales /> },
		{ id: 'theme', title: "Design Setup", type: "link", path: '/design/theme', icon: <AutoFixHighIcon /> }
	];
	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),

		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}));
	const [activeListItem, setActiveListItem] = useState(null);
	// React.useEffect(() => {

	// 	return () => {
	// 		handleDrawerClose();
	// 	};
	// }, [handleDrawerClose]);
	return (
		<>
			<Drawer
				sx={{
					backgroundColor: location.pathname === '/dashboard' ? theme.palette.background.default : Theme === 'LightTheme' ? lightTheme.backgroundColor : Theme === 'darkTheme' ? darkTheme.backgroundColor : lightTheme.backgroundColor,
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
				PaperProps={{ sx: { marginLeft: "10px", color: Theme === 'LightTheme' ? lightTheme.menuTextColor : Theme === 'darkTheme' ? darkTheme.menuTextColor : lightTheme.menuTextColor, top: "20px", bottom: "20px", borderRadius: "25px", height: '95vh', backgroundColor: Theme === 'lightTheme' ? lightTheme.menuColor : darkTheme.menuColor, display: 'flex', flexDirection: 'column', alignItems: 'center' } }}
			>
				<DrawerHeader>
					<Typography color={"primary"} fontWeight={"bold"}> Stock Managment App </Typography>
				</DrawerHeader>
				<List >
					{navigations.map((navItem) => (
						<ListItem disablePadding >
							{navItem.type === "link" ? (
								<>
									<ListItemButton onClick={() => {
										// setActiveListItem(navItem.title);
										MenuClose();
										// open=false ;
										navItem.path && navigate(navItem.path);

									}} sx={{ textAlign: 'left', backgroundColor: activeListItem === navItem.title ? 'violet' : 'inherit' }}>
										<ListItemText primary={<div className={classes.menuBold} style={{ display: "flex", alignItems: "center", lineHeight: "0.5" }}><IconButton sx={{ fill: Theme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor }}> {navItem.icon}</IconButton>{navItem.title}</div>} />
									</ListItemButton>
								</>
							) : (
								<div>
									<ListItemButton onClick={() => { toggleSubmenu(navItem.id); }}>
										<ListItemText primary={<div className={classes.menuBold} style={{ display: "flex", alignItems: "center" }}><IconButton sx={{ fill: Theme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor }}> {navItem.icon}</IconButton>{navItem.title}{isSubmenuOpen[navItem.id] ? <ArrowDropUpIcon sx={{ fontSize: "30px" }} /> : <ArrowDropDownIcon sx={{ fontSize: "30px" }} />}</div>} />
									</ListItemButton>
									<ul className={classes.noBullet}>
										{navItem.sub?.map((subItem) => (
											<li key={subItem.id}>
												<div className={`${classes.submenu} ${drop.includes(navItem.id) ? classes.submenuVisible : ''}`}>
													<ListItemButton onClick={() => { navigate(subItem.path); MenuClose(); }} sx={{ textAlign: 'left' }}>
														<ListItemText primary={<div className={classes.menuBold} style={{ display: "flex", alignItems: "center", gap: "10px" }}><IconButton sx={{ fill: Theme === 'lightTheme' ? lightTheme.menuIconColor : darkTheme.menuIconColor }}>{subItem.icon}</IconButton>{subItem.title}</div>} />
													</ListItemButton>
												</div>
											</li>
										))}
									</ul>
								</div>
							)}
						</ListItem>
					))}
					<ListItemButton onClick={() => chooseLang()} >
						<ListItemText primary={<div className={classes.menuBold} style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'left' }}>
							{getField(Dictionary, "choose-language", language)} <TranslateIcon /></div>} />
					</ListItemButton>
					<ThemeSwitch setSelectedTheme={setSelectedTheme} switchTheme={switchTheme} theme={themeChoice} textColor={""} text='Change Theme' Theme={Theme} />

					<li><b style={{ display: 'flex', justifyContent: 'center', userSelect: 'none', color: "#0F2B5D" }}>Version: {version}</b></li>
				</List>
			</Drawer >
			<ChooseLanguage isOpen={chooseLangModalState} onClose={closeChooseLanguage} dictionary={Dictionary} getField={getField} loading={isSubmitting} />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	translation: state.translation.translation, language: state.translation.language,
	themeChoice: state.themeSwitch.themeChoice,
	menuColor: state.themeSwitch.menuColor, menuTextColor: state.themeSwitch.menuTextColor,
	lightTheme: state.themeSwitch.lightTheme,
	darkTheme: state.themeSwitch.darkTheme,
	Theme: state.themeSwitch.theme,
	open: state.menuDrawer.open,
});
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		switchTheme: (theme: string) => dispatch(ThemeActions.switchTheme(theme)),
		MenuOpen: () => dispatch(MenuDrawerActions.MenuOpen()),
		MenuClose: () => dispatch(MenuDrawerActions.MenuClose()),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
