import { OutgoingRequest } from '../actions/outgoingRequestActions'
import { RequestData } from '../../pages/requests/request-data';
import { INCREMENT_REQUEST, DECREMENT_REQUEST, ADJUST_REQUEST_QUANTITY, LOAD_CURRENT_ITEM } from '../actions/outgoingRequestActions';

const INITIAL_STATE = {
    requests: RequestData as OutgoingRequest[],

}

const incrementRequestProducts = (requests: OutgoingRequest[], requestId: number, productIndex: number, dateIndex: number) => {
    return requests.map((request) => {
        if (request.id === requestId) {
            return {
                ...request,
                products: request.products.map((product, pIndex) => {
                    if (pIndex === productIndex) {
                        return {
                            ...product,
                            date: product.date.map((dt, dIndex) => {
                                if (dIndex === dateIndex) {
                                    return {
                                        ...dt,
                                        count: dt.count + 1,
                                    };
                                }
                                console.log('inc count', dt.count)
                                return dt;
                            }),
                        };
                    }
                    return product;
                }),
            };
        }
        return request;
    });
}

const decrementRequestProducts = (requests: OutgoingRequest[], requestId: number, productIndex: number, dateIndex: number) => {
    return requests.map((request) => {
        if (request.id === requestId) {
            return {
                ...request,
                products: request.products.map((product, pIndex) => {
                    if (pIndex === productIndex) {
                        return {
                            ...product,
                            date: product.date.map((dt, dIndex) => {
                                if (
                                    dIndex === dateIndex &&
                                    dt.count > 0
                                ) {
                                    return {
                                        ...dt,
                                        count: dt.count - 1,

                                    };
                                }
                                console.log('dec count', dt.count)
                                return dt;

                            }),
                        };
                    }
                    return product;
                }),
            };
        }
        return request;
    });
};

const OutgoingRequestReducer = (state = INITIAL_STATE, action: any) => {
    let updatedRequests;

    switch (action.type) {
        case INCREMENT_REQUEST:
            if (
                action.payload &&
                action.payload.requestId !== undefined &&
                action.payload.productIndex !== undefined &&
                action.payload.dateIndex !== undefined
            ) {
                updatedRequests = incrementRequestProducts(
                    state.requests,
                    action.payload.requestId,
                    action.payload.productIndex,
                    action.payload.dateIndex
                );

                return {
                    ...state,
                    requests: updatedRequests,
                };
            }
            return state;

        case DECREMENT_REQUEST:
            if (
                action.payload &&
                action.payload.requestId !== undefined &&
                action.payload.productIndex !== undefined &&
                action.payload.dateIndex !== undefined
            ) {
                updatedRequests = decrementRequestProducts(
                    state.requests,
                    action.payload.requestId,
                    action.payload.productIndex,
                    action.payload.dateIndex
                );

                return {
                    ...state,
                    requests: updatedRequests,
                };
            }
            return state;

        case LOAD_CURRENT_ITEM:
            return {
                ...state,
                currentItem: action.payload.item
            };

        default:
            return state;
    }
};

export default OutgoingRequestReducer;