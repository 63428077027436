import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({


// bigBtn:{height:'45px',fontSize:'18px !important',fontWeight:'normal !important',borderRadius:'13px !important',},

// PendingModal:{ backgroundColor: theme.palette.third.main, padding: '20px', outline: 'none', borderRadius: "40px", overflowY: 'auto',display: 'flex', alignItems: 'center', justifyContent: 'center'},

// HorizCentering:{display: 'flex', alignItems: 'center', justifyContent: 'center'},

// MultiBrandModal:{ backgroundColor: theme.palette.third.main, padding: '10px', outline: 'none', borderRadius: "40px",overflowY:'auto'},

CounterContainer: {display: 'flex',alignItems: 'center',justifyContent:'center', gap: '2px', position: 'relative',},

QuantityWrapper: {display: 'flex',flexDirection: 'column',alignItems: 'center',transform: 'translate(0px, 0px)',},

counterButton: {display: 'flex',justifyContent: 'center',alignItems: 'center',},

brandCounterButton: {display: 'flex',justifyContent: 'center',alignItems: 'center',transform: 'translate(0px, -9px)',},


QuantityInput: {color:theme.palette.text.primary,width: '100px',height: '45px', border: '2px solid',borderColor: theme.palette.lightGrey, borderRadius: '7px',display: 'flex',fontWeight: 'bold',fontSize: '30px',textAlign: 'center',transition: 'color 0.1s ease',backgroundColor: theme.palette.third.main,'&:hover': {color: theme.palette.primary.main,},},

BrandQuantityInput: {color:theme.palette.text.primary,width: '70px',height: '35px', border: '2px solid',borderColor: theme.palette.lightGrey, borderRadius: '7px',display: 'flex',fontWeight: 'bold',fontSize: '25px',textAlign: 'center',transition: 'color 0.1s ease',backgroundColor: theme.palette.third.main,'&:hover': {color: theme.palette.primary.main,},},

kgTitle: { color: theme.palette.lightGrey, fontSize: '9px',whiteSpace:'nowrap', '&:hover': { color: theme.palette.primary.main, }, },

closeButton:{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12),0px 0px 0px 1px rgba(0, 0, 0, 0.05)'},

counter: {fontSize: '17px !important', width: '35px !important', height: '35px !important',
border: '2px solid', borderColor:theme.palette.lightGrey, borderRadius: '30px', cursor: 'pointer', backgroundColor: theme.palette.third.main,
transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease',
'&:hover': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main, color: theme.palette.white }},

brandCounter: { width: '25px !important', height: '25px !important',
border: '2px solid', borderColor:theme.palette.lightGrey, borderRadius: '30px', cursor: 'pointer', backgroundColor: theme.palette.third.main,
transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease',
'&:hover': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main, color: theme.palette.white }},


 })
);

export default useStyles;