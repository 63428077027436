import { Dispatch, Action } from 'redux';

export const INCREMENT_MULTI_INCOMING_REQUEST = 'INCREMENT_INCOMING_BOX_REQUEST';
export const DECREMENT_MULTI_INCOMING_REQUEST = 'DECREMENT_INCOMING_BOX_REQUEST';
export const ADJUST_MULTI_INCOMING_REQUEST = 'ADJUST_INCOMING_BOX_REQUEST';
export const INCREMENT_INCOMING_BASE_UNIT = 'INCREMENT_INCOMING_BASE_UNIT';
export const DECREMENT_INCOMING_BASE_UNIT = 'DECREMENT_INCOMING_BASE_UNIT';
export const ADJUST_INCOMING_BASE_UNIT = 'ADJUST_INCOMING_BASE_UNIT';
export const LOAD_CURRENT_INCOMING_ITEM = 'LOAD_CURRENT_ITEM';
export const CHANGE_INCOMING_WEIGHT_TYPE = 'CHANGE_INCOMING_WEIGHT_TYPE';
export const INCREMENT_OUR_QTY = 'INCREMENT_OUR_QTY';
export const DECREMENT_OUR_QTY = 'DECREMENT_OUR_QTY';
export const ADJUST_OUR_QTY = 'ADJUST_OUR_QTY';
export const SET_REQUEST = 'SET_REQUEST';
export const SET_MULTIBRAND_REQUEST = 'SET_MULTIBRAND_REQUEST';
export const SET_ALL_STATUSES = 'SET_ALL_STATUSES';
export const SET_ALL_MULTIBRAND_STATUSES = 'SET_ALL_MULTIBRAND_STATUSES';
export const SET_SEND_ORDER = 'SET_SEND_ORDER';
export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';

export interface SingleProduct { id: string; name: string; date: string; status: string; qtyRequested: number; qtyOH: number; ourQtyOH: number; qtyApproved: number; }

export interface MultiBrandRequest {
    id: string; name: string; qtyRequested: number; qtyOH: number; ourQtyOH: number; status: string;
    baseUnit: { id: string; qtyType: string; count: number; weightType: string; date?: string; }[];
    brands: {
        id: string; name: string; boxSize: number, canWeight: number, avBoxes: number, avCans: number, expiryDate: string;
        qty: { qtyType: string; boxCount: number; cansCount: number; date?: string; id: string }[];
    }[]
}

export interface IncomingRequest {
    id: string;
    user: string;
    date: string;
    from: string;
    to: string;
    status: string;
    sendOrder: boolean;
    orderDate: string;
    acceptanceDate: string;
    deliveryDate: string;
    products: SingleProduct[];
}

export interface IncomingMultiBrandRequest {
    id: string;
    user: string;
    date: string;
    from: string;
    to: string;
    status: string;
    sendOrder: boolean;
    orderDate: string;
    acceptanceDate: string;
    deliveryDate: string;
    products: SingleProduct[];
    multiBrand: MultiBrandRequest[]
}

export type IncomingRequestsTypes = (IncomingRequest | IncomingMultiBrandRequest)[]

export interface AppState { incomingReq: IncomingRequestsTypes, currentIncomingItem: IncomingRequest | IncomingMultiBrandRequest }

interface IncrementMultiIncomingAction extends Action<typeof INCREMENT_MULTI_INCOMING_REQUEST> {
    payload: { productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can' }
}

interface DecrementMultiIncomingAction extends Action<typeof DECREMENT_MULTI_INCOMING_REQUEST> {
    payload: { productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can' }
}

interface AdjustMultiIncomingAction extends Action<typeof ADJUST_MULTI_INCOMING_REQUEST> {
    payload: { productId: string; multiId: string, brandId: string | null, qtyId: string | null, newQuantity: number; itemType: 'box' | 'can' }
}

interface IncrementIncomingBaseUnitAction extends Action<typeof INCREMENT_INCOMING_BASE_UNIT> {
    payload: { productId: string; productIndex: number, baseUnitIndex: number; }
}

interface DecrementIncomingBaseUnitAction extends Action<typeof DECREMENT_INCOMING_BASE_UNIT> {
    payload: { productId: string; productIndex: number, baseUnitIndex: number; }
}

interface AdjustIncomingBaseUnitAction extends Action<typeof ADJUST_INCOMING_BASE_UNIT> {
    payload: { productId: string; productIndex: number, baseUnitIndex: number; newCount: number; }
}

interface ChangeIncomingWeightTypeAction extends Action<typeof CHANGE_INCOMING_WEIGHT_TYPE> {
    payload: { productId: string; productIndex: number, baseUnitIndex: number; newQuantity: number; weightType: string };
}

interface LoadIncomingCurrentItemAction extends Action<typeof LOAD_CURRENT_INCOMING_ITEM> {
    payload: { item: any };
}

interface SetRequestAction extends Action<typeof SET_REQUEST> {
    payload: { productId: string; multiId: string, status: string, }
}

interface SetMultiBrandRequestAction extends Action<typeof SET_MULTIBRAND_REQUEST> {
    payload: { productId: string; multiId: string, status: string, }
}

interface setSendOrderAction extends Action<typeof SET_SEND_ORDER> {
    payload: { productId: string; }
}

interface allProductsToRejectedAction extends Action<typeof SET_ALL_STATUSES> {
    payload: { productId: string; status: string; }
}

interface allMultiBrandToRejectedAction extends Action<typeof SET_ALL_MULTIBRAND_STATUSES> {
    payload: { productId: string; status: string; }
}

interface SetRequestStatusAction extends Action<typeof SET_REQUEST_STATUS> {
    payload: { productId: string; status: string, }
}

interface IncrementOurQtyAction extends Action<typeof INCREMENT_OUR_QTY> {
    payload: { productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH' }
}

interface DecrementOurQtyAction extends Action<typeof DECREMENT_OUR_QTY> {
    payload: { productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH' }
}

interface AdjustOurQtyAction extends Action<typeof ADJUST_OUR_QTY> {
    payload: { productId: string; multiId: string, newQuantity: number; qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH' }
}


export type IncomingRequeststActionTypes = IncrementMultiIncomingAction | DecrementMultiIncomingAction | AdjustMultiIncomingAction
    | IncrementIncomingBaseUnitAction | DecrementIncomingBaseUnitAction | AdjustIncomingBaseUnitAction | ChangeIncomingWeightTypeAction
    | LoadIncomingCurrentItemAction | SetRequestAction | SetMultiBrandRequestAction
    | setSendOrderAction | allProductsToRejectedAction | allMultiBrandToRejectedAction | SetRequestStatusAction |
    IncrementOurQtyAction | DecrementOurQtyAction | AdjustOurQtyAction;

const incrementMulti = (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can'): IncrementMultiIncomingAction => ({ type: INCREMENT_MULTI_INCOMING_REQUEST, payload: { productId, multiId, brandId, qtyId, itemType } });

const decrementMulti = (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can'): DecrementMultiIncomingAction => ({ type: DECREMENT_MULTI_INCOMING_REQUEST, payload: { productId, multiId, brandId, qtyId, itemType } });

const adjustMulti = (productId: string, multiId: string, brandId: string | null, qtyId: string | null, newQuantity: number, itemType: 'box' | 'can'): AdjustMultiIncomingAction => ({ type: ADJUST_MULTI_INCOMING_REQUEST, payload: { productId, multiId, brandId, qtyId, newQuantity, itemType } });

const incrementBU = (productId: string, productIndex: number, baseUnitIndex: number): IncrementIncomingBaseUnitAction => ({ type: INCREMENT_INCOMING_BASE_UNIT, payload: { productId, productIndex, baseUnitIndex, } });

const decrementBU = (productId: string, productIndex: number, baseUnitIndex: number): DecrementIncomingBaseUnitAction => ({ type: DECREMENT_INCOMING_BASE_UNIT, payload: { productId, productIndex, baseUnitIndex, } });

const adjustBU = (productId: string, productIndex: number, baseUnitIndex: number, newCount: number): AdjustIncomingBaseUnitAction => ({ type: ADJUST_INCOMING_BASE_UNIT, payload: { productId, productIndex, baseUnitIndex, newCount } });

const changeBUWeightType = (productId: string, productIndex: number, baseUnitIndex: number, newQuantity: number, weightType: string): ChangeIncomingWeightTypeAction => ({ type: CHANGE_INCOMING_WEIGHT_TYPE, payload: { productId, productIndex, baseUnitIndex, newQuantity, weightType }, });

const LoadCurrentItem = (item: any,): LoadIncomingCurrentItemAction => ({ type: LOAD_CURRENT_INCOMING_ITEM, payload: { item }, });

const setStat = (productId: string, multiId: string, status: string,): SetRequestAction => ({ type: SET_REQUEST, payload: { productId, multiId, status } });

const setMultiStat = (productId: string, multiId: string, status: string,): SetMultiBrandRequestAction => ({ type: SET_MULTIBRAND_REQUEST, payload: { productId, multiId, status } });

const sendOrder = (productId: string,): setSendOrderAction => ({ type: SET_SEND_ORDER, payload: { productId, } });

const setAll = (productId: string, status: string): allProductsToRejectedAction => ({ type: SET_ALL_STATUSES, payload: { productId, status } });

const setAllMultiBrand = (productId: string, status: string): allMultiBrandToRejectedAction => ({ type: SET_ALL_MULTIBRAND_STATUSES, payload: { productId, status } });

const setRequestStat = (productId: string, status: string): SetRequestStatusAction => ({ type: SET_REQUEST_STATUS, payload: { productId, status } });

const incrementOurQ = (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH'): IncrementOurQtyAction => ({ type: INCREMENT_OUR_QTY, payload: { productId, multiId, qtyType } });

const decrementOurQ = (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH'): DecrementOurQtyAction => ({ type: DECREMENT_OUR_QTY, payload: { productId, multiId, qtyType } });

const adjustOurQ = (productId: string, multiId: string, newQuantity: number, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH'): AdjustOurQtyAction => ({ type: ADJUST_OUR_QTY, payload: { productId, multiId, newQuantity, qtyType } });

export const IncomingRequeststActions = {
    incrementIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => (dispatch: Dispatch<any>) => { dispatch(incrementMulti(productId, multiId, brandId, qtyId, itemType)); },
    decrementIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => (dispatch: Dispatch<any>) => { dispatch(decrementMulti(productId, multiId, brandId, qtyId, itemType)); },
    adjustIncomingMultiQty: (productId: string, multiId: string, brandId: string | null, qtyId: string | null, newQuantity: number, itemType: 'box' | 'can') => (dispatch: Dispatch<any>) => { dispatch(adjustMulti(productId, multiId, brandId, qtyId, newQuantity, itemType)); },

    incrementIncomingBaseUnitQty: (productId: string, productIndex: number, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBU(productId, productIndex, baseUnitIndex,)); },
    decrementIncomingBaseUnitQty: (productId: string, productIndex: number, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBU(productId, productIndex, baseUnitIndex,)); },
    adjustIncomingBaseUnitQty: (productId: string, productIndex: number, baseUnitIndex: number, newCount: number) => (dispatch: Dispatch<any>) => { dispatch(adjustBU(productId, productIndex, baseUnitIndex, newCount)); },
    convertIncomingBUWeight: (productId: string, productIndex: number, baseUnitIndex: number, newQuantity: number, weightType: string) => (dispatch: Dispatch<any>) => { dispatch(changeBUWeightType(productId, productIndex, baseUnitIndex, newQuantity, weightType)); },

    incrementOurQty: (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => (dispatch: Dispatch<any>) => { dispatch(incrementOurQ(productId, multiId, qtyType)); },
    decrementOurQty: (productId: string, multiId: string, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH') => (dispatch: Dispatch<any>) => { dispatch(decrementOurQ(productId, multiId, qtyType)); },
    adjustOurQty: (productId: string, multiId: string, newQuantity: number, qtyType: 'ourQtyOH' | 'qtyApproved' | 'qtyRequested' | 'qtyOH',) => (dispatch: Dispatch<any>) => { dispatch(adjustOurQ(productId, multiId, newQuantity, qtyType)); },

    setStatus: (productId: string, multiId: string, status: string,) => (dispatch: Dispatch<any>) => { dispatch(setStat(productId, multiId, status)); },
    setMultiStatus: (productId: string, multiId: string, status: string,) => (dispatch: Dispatch<any>) => { dispatch(setMultiStat(productId, multiId, status)); },

    LoadIncomingCurrentItem: (item: any) => (dispatch: Dispatch<any>) => { dispatch(LoadCurrentItem(item)); },
    SetSendOrder: (productId: string,) => (dispatch: Dispatch<any>) => { dispatch(sendOrder(productId)); },

    setAllStatuses: (productId: string, status: string) => (dispatch: Dispatch<any>) => { dispatch(setAll(productId, status)); },
    setAllMultiBrandStatuses: (productId: string, status: string) => (dispatch: Dispatch<any>) => { dispatch(setAllMultiBrand(productId, status)); },

    setRequestStatus: (productId: string, status: string) => (dispatch: Dispatch<any>) => { dispatch(setRequestStat(productId, status)); },
};