import { WastageActionTypes, REMOVE_FROM_WASTAGE_DETAILS, INCREMENT_WASTAGE, DECREMENT_WASTAGE, CHANGE_WASTAGE_REASON, ADJUST_WASTAGE_QUANTITY } from '../actions/wastageFinishedProductsActions'
import { AppState, Product } from '../actions/wastageFinishedProductsActions';
import WastageFPData from '../../pages/wastage/wastage-finished-products/wastage-fp-data'

const INITIAL_STATE: AppState = {
    wastProds: WastageFPData as Product[],
    wastDetails: [] as Product[],
}

const updateProductLookup = (products: Product[], productId: string, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        let count = expiry.count;
                        count += 1;
                        return { ...expiry, count };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    })
}

const decrementProductLookup = (products: Product[], productId: string, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, eIndex) => {
                    if (eIndex === expiryIndex && typeof expiry.count === 'number' && expiry.count > 0) {
                        return {
                            ...expiry,
                            count: expiry.count - 1,
                        };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const updateDetailsWithProduct = (details: any, updatedProducts: Product[], productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateQuantityAndDetails = (
    products: Product[], productId: string, expiryIndex: number,
    newQuantity: number, details: any[],
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const wastageFinishedProductsReducer = (state = INITIAL_STATE, action: WastageActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    switch (action.type) {

        case INCREMENT_WASTAGE:
            updatedProducts = updateProductLookup(state.wastProds, action.payload.productId, action.payload.expieryIndex)
            updatedDetails = updateDetailsWithProduct(state.wastDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                wastProds: updatedProducts,
                wastDetails: updatedDetails
            };

        case DECREMENT_WASTAGE:
            updatedProducts = decrementProductLookup(state.wastProds, action.payload.productId, action.payload.expieryIndex)

            updatedDetails = updateDetailsWithProduct(
                state.wastDetails,
                updatedProducts,
                action.payload.productId
            );
            return {
                ...state,
                wastProds: updatedProducts,
                wastDetails: updatedDetails
            };

        case CHANGE_WASTAGE_REASON:
            updatedProducts = state.wastProds.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        reason: action.payload.newReason,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(
                state.wastDetails,
                updatedProducts,
                action.payload.productId
            );

            return {
                ...state,
                wastProds: updatedProducts,
                wastDetails: updatedDetails,
            };

        case REMOVE_FROM_WASTAGE_DETAILS:
            updatedDetails = state.wastDetails.filter((item) => item.id !== action.payload.itemID);
            const itemToRemove = state.wastDetails.find((item) => item.id === action.payload.itemID);
            if (itemToRemove) {
                if ('expiry' in itemToRemove) {
                    itemToRemove.expiry.forEach((expiry) => {
                        expiry.count = 0;
                    });
                }
            }
            return {
                ...state,
                wastDetails: updatedDetails,
            };

        case ADJUST_WASTAGE_QUANTITY:
            const { productId, expieryIndex, newQuantity } = action.payload;
            updatedProducts = updateQuantityAndDetails(
                state.wastProds,
                productId,
                expieryIndex,
                newQuantity,
                state.wastDetails
            );
            updatedDetails = updateDetailsWithProduct(
                state.wastDetails,
                updatedProducts,
                productId
            );
            return {
                ...state,
                wastProds: updatedProducts,
                wastDetails: updatedDetails,
            };

        default:
            return state;
    }
};
export default wastageFinishedProductsReducer;