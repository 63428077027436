import { TransferActionTypes, INCREMENT_TRANSFER, DECREMENT_TRANSFER, REMOVE_FROM_TRANSFER_DETAILS, ADJUST_TRANSFER_QUANTITY, } from '../actions/transferActions'
import { AppState, Product } from '../actions/transferActions';
import TransferProductsData from '../../pages/transfer/transfer-lookup/transfer-product-data'

const INITIAL_STATE: AppState = {
    transferProducts: TransferProductsData as Product[],
    transferDetails: [] as Product[],
}

const updateProductLookup = (products: Product[], productId: string, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        let count = expiry.count;
                        count += 1;
                        return { ...expiry, count };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    })
}

const decrementProductLookup = (products: Product[], productId: string, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, eIndex) => {
                    if (eIndex === expiryIndex && typeof expiry.count === 'number' && expiry.count > 0) {
                        return {
                            ...expiry,
                            count: expiry.count - 1,
                        };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const updateDetailsWithProduct = (details: any, updatedProducts: Product[], productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateQuantityAndDetails = (
    products: Product[], productId: string, expiryIndex: number,
    newQuantity: number, details: any[],
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const transferProductsReducer = (state = INITIAL_STATE, action: TransferActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    switch (action.type) {

        case INCREMENT_TRANSFER:
            updatedProducts = updateProductLookup(state.transferProducts, action.payload.productId, action.payload.expieryIndex)
            updatedDetails = updateDetailsWithProduct(state.transferDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                transferProducts: updatedProducts,
                transferDetails: updatedDetails
            };

        case DECREMENT_TRANSFER:
            updatedProducts = decrementProductLookup(state.transferProducts, action.payload.productId, action.payload.expieryIndex)

            updatedDetails = updateDetailsWithProduct(
                state.transferDetails,
                updatedProducts,
                action.payload.productId
            );
            return {
                ...state,
                transferProducts: updatedProducts,
                transferDetails: updatedDetails
            };

        case REMOVE_FROM_TRANSFER_DETAILS:
            updatedDetails = state.transferDetails.filter((item) => item.id !== action.payload.itemID);
            const itemToRemove = state.transferDetails.find((item) => item.id === action.payload.itemID);
            if (itemToRemove) {
                if ('expiry' in itemToRemove) {
                    itemToRemove.expiry.forEach((expiry) => {
                        expiry.count = 0;
                    });
                }
            }
            return {
                ...state,
                transferDetails: updatedDetails,
            };

        case ADJUST_TRANSFER_QUANTITY:
            const { productId, expieryIndex, newQuantity } = action.payload;
            updatedProducts = updateQuantityAndDetails(
                state.transferProducts,
                productId,
                expieryIndex,
                newQuantity,
                state.transferDetails
            );
            updatedDetails = updateDetailsWithProduct(
                state.transferDetails,
                updatedProducts,
                productId
            );
            return {
                ...state,
                transferProducts: updatedProducts,
                transferDetails: updatedDetails,
            };

        default:
            return state;
    }
};
export default transferProductsReducer;