
export interface Product {
    id: string;
        type: string;
        name: string;
        lastdate: string;
        cost: number;

    expiry: {
        date: string;
        count: number;
    }[]
    reason?: string;
    comment?: string;
}

export interface MultiBrandProduct {
    id: string;
        type: string;
        name: string;
        lastdate: string;
    reason?: string;
    comment?: string;
    Unpacked: number;
    brands: {
        id: string;
        name: string;
        description: string;
        cost: number;
        expiry: {
            date: string;
            count: number
        }[];

    }[]
}


type prodArr = (MultiBrandProduct | Product)[]


const WastageProductsData : prodArr = [
    {
        id: '1',
        type: 'Dry Food',
        name: 'Corn',
        lastdate: '09 Sep 2023',
        Unpacked: 0,
        brands: [
            {
                id: '125-ps-corn-1',
                name: 'Plein Soleil',
                description:"500g",
                cost:3,
                expiry: [
                    { date: '18/01/2023',count:0 },
                    { date: '22/08/2023',count:0 }
                ]
            },
            {
                id: '227-awa-corn-2',
                name: 'Al Wadi el AKhdar',
                description:"480g",
                cost:2,
                expiry: [
                    { date: '18/01/2023',count:0 },
                    { date: '22/08/2023',count:0 },
                    { date: '07/11/2023',count:0 },
                    { date: '11/12/2023',count:0 }
                ]
            },
            {
                id: '254-max-corn-4',
                name: 'Maxims',
                description:"700g",
                cost:2.5,
                expiry: [
                    { date: '19/10/2025',count:0 },
                    { date: '19/10/2026',count:0 },
                    { date: '20/02/2030',count:0 },
                ]
            },
        ]
    },

    {
        id: '2',
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        cost:20,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },
    {
        id: '3',
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        cost:25,
        expiry: [
            { date: '23/04/2023',count:0 },
        ],
    },

    {
        id: '4',
        type: 'Mezza Berde',
        name: 'Hommos',
        lastdate: '09 Sep 2023',
        cost:5,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '5',
        type: 'Salad',
        name: 'Salade chevre',
        lastdate: '09 Sep 2023',
        cost:7,
        expiry: [
            { date: '23/04/2023',count:0 },
        ],
    },

    {
        id: '6',
        type: 'Salad',
        name: 'Salade Quinoa',
        lastdate: '09 Sep 2023',
        cost:50,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '7',
        type: 'Salad',
        name: 'Salade pattes',
        lastdate: '09 Sep 2023',
        cost:3,
        expiry: [
            { date: '23/04/2023',count:0 },
        ],
    },

    {
        id: '8',
        type: 'Mezza Sokhne',
        name: 'Kafta',
        lastdate: '09 Sep 2023',
        cost:27,
        expiry: [
            { date: '23/04/2023',count:0 },
        ],
    },

    {
        id: '9',
        type: 'Sauces',
        name: 'Drob Sauce',
        lastdate: '09 Sep 2023',
        cost:1,
        expiry: [
            { date: '23/04/2023',count:0 },
        ],
    },

    {
        id: '10',
        type: 'Sauces',
        name: 'Thousand Island',
        lastdate: '09 Sep 2023',
        cost:1,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '11',
        type: 'Drinks',
        name: 'Coke',
        lastdate: '09 Sep 2023',
        cost:1,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
            { date: '19/05/2023',count:0 },
            
        ],
    },

    {
        id: '12',
        type: 'Sauces',
        name: 'Honey Mustard',
        lastdate: '09 Sep 2023',
        cost:5,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '13',
        type: 'Dry Food',
        name: 'Product Name',
        lastdate: '09 Sep 2023',
        cost:3,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
            { date: '19/05/2023',count:0 },
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
            { date: '19/05/2023',count:0 },
        ],
    },


    {
        id: '14',
        type: 'Dry Food',
        name: 'Product Name',
        lastdate: '09 Sep 2023',
        cost:2,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '15',
        type: 'Drinks',
        name: 'Beer',
        lastdate: '09 Sep 2023',
        cost:2,
        expiry: [
            { date: '23/04/2023',count:0 },
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '16',
        type: 'Drinks',
        name: 'Wine',
        lastdate: '09 Sep 2023',
        cost:7,
        expiry: [
            { date: '23/04/2023',count:0},
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '17',
        type: 'Drinks',
        name: 'Cocktails',
        lastdate: '09 Sep 2023',
        cost:20,
        expiry: [
            { date: '23/04/2023',count:0},
            { date: '17/07/2023',count:0 },
        ],
    },

    {
        id: '18',
        type: 'Dry Food',
        name: 'Mushrooms',
        lastdate: '09 Sep 2023',
        Unpacked: 0,
        brands: [
            {
                id: '125-ps-mush-1',
                name: 'Plein Soleil',
                description:"500g",
                cost:4,
                expiry: [
                    { date: '18/01/2023',count:0  },
                    { date: '22/08/2023',count:0  }
                ]
            },
            {
                id: '227-awa-mush-2',
                name: 'Al Wadi el AKhdar',
                description:"500g",
                cost:2.5,
                expiry: [
                    { date: '18/01/2023',count:0 },
                    { date: '22/08/2023',count:0},
                    { date: '07/11/2023',count:0},
                    { date: '11/12/2023',count:0}
                ]
            }
        ]
    },

];

export default WastageProductsData;