import React, { useState, useEffect } from "react";
import { IconButton, Card, Grid, } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment, { Moment } from "moment";
//ICONS
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import EditIcon from '@mui/icons-material/Edit';
//DESIGN
import "./calendar.css";
import useStyles from "../../theme/styles";
import calendarStyles from "./style";
import { useTheme } from '@mui/material';
import { CustomTheme } from '../../theme/index';
//CUSTOM COMPONENTS
import Clock from "./clock";
//TYPES
import { TranslationType } from "../../types/translationTypes";
//REDUX
import { getDictionary, getField } from '../../utils/translation/translation';
import { connect } from "react-redux";
interface Props {
  desktopView?: boolean, mobileView?: any,
  initialDate: Moment;
  selectedDate: Moment | null;
  onDateSelect: (date: Moment | null) => void;
  textColor: string;
  textPosition: string;
  rightSideComponent?: any;
  oneComponent?: boolean;
  doubleComponents?: boolean;
  editButton?: boolean;
  horizontalComponent?: boolean;
  calendarRight?: boolean;
  noDateTitle?: boolean;
  timeContainer?: boolean;
  miniCalendar?: boolean;
  onlyCalendar?: boolean;
  FixedDateComponent?: boolean;
  translation: TranslationType[],
  language: string,
  Theme?: any, lightTheme?: any, darkTheme?: any
}

const Calendar: React.FC<Props> = ({ desktopView, mobileView, initialDate, selectedDate, onDateSelect, textColor, textPosition, rightSideComponent, oneComponent, doubleComponents, onlyCalendar, FixedDateComponent, editButton, horizontalComponent, calendarRight, noDateTitle, timeContainer, miniCalendar, translation, language, Theme, lightTheme, darkTheme }) => {
  const [currentDate, setCurrentDate] = useState<Moment>(initialDate);
  const showCalendarForDoubleComponents = doubleComponents && !editButton;
  const [showCalendar, setShowCalendar] = useState(showCalendarForDoubleComponents);
  const Dictionary = getDictionary(translation, "calendar", language);
  const [selectedDatePickerDate, setSelectedDatePickerDate] = useState<Moment | null>(selectedDate);
  const handleDatePickerChange = (newValue: Moment | null): void => { setSelectedDatePickerDate(newValue); if (newValue) { } };
  useEffect(() => { if (!selectedDate) { onDateSelect(moment()); } }, [selectedDate, onDateSelect]);
  const handleDateClick = (): void => { setShowCalendar(!showCalendar); };
  const handleCalendarSelect = (date: Moment): void => { onDateSelect(date); };
  const goToPreviousMonth = (): void => { setCurrentDate((prevDate) => prevDate.clone().subtract(1, "month")); };
  const goToNextMonth = (): void => { setCurrentDate((prevDate) => prevDate.clone().add(1, "month")); };
  function getTextForDate(selectedDate: Moment | null): string {
    if (!selectedDate) return "";
    const today = moment();
    const yesterday = moment().subtract(1, "day");
    const tomorrow = moment().add(1, "day");
    if (selectedDate.isSame(today, "day")) {
      return getField(Dictionary, 'today', language) + ",";
    } else if (selectedDate.isSame(yesterday, "day")) {
      return getField(Dictionary, 'yesterday', language) + ",";
    } else if (selectedDate.isSame(tomorrow, "day")) {
      return getField(Dictionary, 'tomorrow', language) + ",";
    } else {
      return "";
    }
  }
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const toggleDatePickerVisibility = () => { setIsDatePickerVisible(!isDatePickerVisible); };
  const classes = useStyles();
  const calendar = calendarStyles();
  const theme: CustomTheme = useTheme();

  return (
    <>
      {horizontalComponent && (
        <div className={calendar.horizDate} style={{  justifyContent: textPosition, alignItems: 'center' }} >
          <div className={calendar.DayTitle} style={{ justifyContent: textPosition }}> {getTextForDate(selectedDatePickerDate)}</div>
          <div className={calendar.DateContainer} style={{ justifyContent: textPosition }}><CalendarMonthRoundedIcon sx={{ fontSize: '18px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }} />  {selectedDatePickerDate?.format("dddd, DD MMMM")} <IconButton onClick={toggleDatePickerVisibility} color='inherit'><EditIcon style={{ width: '17px', height: '17px' }} /></IconButton> </div>
          {timeContainer && (<div className={calendar.TimeContainer} style={{ justifyContent: textPosition }}><AccessTimeRoundedIcon sx={{ fontSize: '18px' }} /><div style={{ fontSize: '14px' }}><Clock /></div></div>)}
        </div>
      )}
      {oneComponent && (
        <div style={{ display: 'flex', alignItems: 'center', color: textColor, justifyContent: textPosition }}>
          <div className={calendar.dateContainer} style={{ justifyContent: textPosition }} >
            {!miniCalendar && (<div className={calendar.DayTitle} style={{ justifyContent: textPosition }}> {getTextForDate(selectedDate)}</div>)}
            {miniCalendar && (<div className={calendar.DayTitle} style={{ justifyContent: textPosition }}> {getTextForDate(selectedDatePickerDate)}</div>)}
            {!miniCalendar && (<div className={calendar.DateContainer} style={{ justifyContent: textPosition }}><CalendarMonthRoundedIcon sx={{ fontSize: '18px',  }} /> {selectedDate?.format("dddd, DD MMMM")}
              <IconButton onClick={handleDateClick} color='inherit'><EditIcon style={{ width: '17px', height: '17px' }} /></IconButton>
            </div>)}
            {miniCalendar && (<div className={calendar.DateContainer} style={{ justifyContent: textPosition }}><CalendarMonthRoundedIcon sx={{ fontSize: '18px', }} />{selectedDatePickerDate?.format("dddd, DD MMMM")}</div>)}
            {timeContainer && (<div className={calendar.TimeContainer} style={{ justifyContent: textPosition }}><AccessTimeRoundedIcon sx={{ fontSize: '18px' }} /><div style={{ fontSize: '14px' }}><Clock /></div></div>)}
          </div>
          {miniCalendar && (<IconButton onClick={toggleDatePickerVisibility} color='inherit'><EditIcon style={{ width: '17px', height: '17px' }} /></IconButton>)}
        </div>
      )}
      {doubleComponents && (
        <Grid container alignItems='center' gap='30px' justifyContent='center' >
          {!onlyCalendar && (<Grid item className={calendar.dateContainer} style={{ color: textColor, justifyContent: textPosition }} >
            <div className={calendar.DayTitle} style={{ justifyContent: textPosition }}> {getTextForDate(selectedDate)}</div>
            <div className={calendar.DateContainer} style={{ justifyContent: textPosition }}><CalendarMonthRoundedIcon sx={{ fontSize: '18px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }} /> {selectedDate?.format("dddd, DD MMMM")} {editButton && (<IconButton onClick={handleDateClick} color='inherit'><EditIcon style={{ width: '17px', height: '17px' }} /></IconButton>)} </div>
            {timeContainer && (<div className={calendar.TimeContainer} style={{ justifyContent: textPosition }}><AccessTimeRoundedIcon sx={{ fontSize: '18px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }} /><div style={{ fontSize: '14px' }}><Clock /></div></div>)}
          </Grid>)}
          <Grid item>
            {rightSideComponent}
          </Grid>
        </Grid>
      )}
      {FixedDateComponent && (
        <>
          <Grid item className={calendar.dateContainer} style={{ color: textColor, justifyContent: textPosition ,}} >
            <div className={calendar.DayTitle} style={{ justifyContent: textPosition }}> {getTextForDate(selectedDate)}</div>
            <div className={calendar.DateContainer} style={{ justifyContent: textPosition }}><CalendarMonthRoundedIcon sx={{ fontSize: '18px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }} /> {selectedDate?.format("dddd, DD MMMM")} {editButton && (<IconButton onClick={handleDateClick} color='inherit'><EditIcon style={{ width: '17px', height: '17px' }} /></IconButton>)} </div>
            {timeContainer && (<div className={calendar.TimeContainer} style={{ justifyContent: textPosition }}><AccessTimeRoundedIcon sx={{ fontSize: '18px' }} /><div style={{ fontSize: '14px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }}><Clock /></div></div>)}
          </Grid>
          <Grid item>
            {rightSideComponent}
          </Grid>
        </>
      )}
      {noDateTitle && (
        <div className={calendar.dateContainer} style={{ color: textColor, justifyContent: textPosition ,}} >
          <div className={calendar.DateContainer} style={{ justifyContent: textPosition, fontSize: '12px', fontWeight: '600' }}><CalendarMonthRoundedIcon sx={{ fontSize: '18px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }} />{getField(Dictionary, 'date', language)} {selectedDate?.format("dddd, DD MMMM")} </div>
          {timeContainer && (<div className={calendar.TimeContainer} style={{ justifyContent: textPosition, fontSize: '12px', fontWeight: '600' }}><AccessTimeRoundedIcon sx={{ fontSize: '18px', color: desktopView ? Theme === "lightTheme" ? lightTheme.sectionsIconsColor : darkTheme.sectionsIconsColor : mobileView ? Theme === "lightTheme" ? lightTheme.backgroundIconColor : darkTheme.backgroundIconColor : lightTheme.backgroundIconColor, }} />{getField(Dictionary, 'time', language)}<div style={{ fontSize: '14px' }}><Clock /></div></div>)}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {showCalendar && (
          <Card className={calendar.calendarCard} style={{ borderRadius: '20px' , backgroundColor:theme.palette.third.main}} >
            <div className={calendar.calendarHeader}>
              <IconButton onClick={goToPreviousMonth} color='primary' className={classes.dateswitch}> <ArrowBackIosRoundedIcon /> </IconButton>
              <b > {currentDate.format("MMMM")}, {currentDate.year()} </b>
              <IconButton onClick={goToNextMonth} color='primary' className={classes.dateswitch}> <ArrowForwardIosRoundedIcon /> </IconButton>
            </div>
            <div className={calendar.calendarBody}>
              <div className={calendar.days}>
                {Array.from({ length: 42 }, (_, i) => i).map((i) => {
                  const day = moment(currentDate)
                    .startOf("month")
                    .startOf("week")
                    .add(i, "days");
                  const isCurrentMonth = day.month() === currentDate.month();
                  const isSelected = selectedDate && day.isSame(selectedDate, "day");
                  const isToday = day.isSame(moment(), "day");
                  if (isCurrentMonth) {
                    return (
                      <div key={i} className={`${calendar.day}${isSelected ? calendar.selected : ""} ${isToday ? "current-day" : ""}`} onClick={() => handleCalendarSelect(day)} >
                        <div className={`${calendar.weekday} ${isSelected ? calendar.selectedWeekday : ""}`}>{day.format("ddd")}</div>
                        <div className={`${isSelected ? calendar.selectedDay : ""}`}>{day.format("D")}</div>
                      </div>
                    );
                  } else {
                  }
                })}
              </div>
            </div>
          </Card>
        )}
      </div>
      {isDatePickerVisible && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDatePicker value={selectedDatePickerDate || null} onAccept={handleDatePickerChange} onClose={toggleDatePickerVisibility} sx={{ transform: 'translate(-250px,200px)', width: '250px', height: '330px', borderRadius: '20px', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12),0px 0px 0px 1px rgba(0, 0, 0, 0.05)', zIndex: '12 !important' }} />
        </LocalizationProvider>)}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  translation: state.translation.translation,
  language: state.translation.language,
  lightTheme: state.themeSwitch.lightTheme,
  darkTheme: state.themeSwitch.darkTheme,
  Theme: state.themeSwitch.theme
});
export default connect(mapStateToProps, null)(Calendar);