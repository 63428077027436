import { Dispatch, Action } from 'redux';


export interface appState {
    open: false

}

export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const SET_OPEN = "SET_OPEN";
interface OpenMenuActions extends Action<typeof OPEN_SIDEBAR> { payload: {} }
interface CloseMenuActions extends Action<typeof CLOSE_SIDEBAR> { payload: {} }
interface setOpen extends Action<typeof SET_OPEN> { payload: {} }

export type MenuDrawerActionTypes = OpenMenuActions | CloseMenuActions | setOpen

const menuOpen = (): OpenMenuActions => ({ type: OPEN_SIDEBAR, payload: {} });
const menuClose = (): CloseMenuActions => ({ type: CLOSE_SIDEBAR, payload: {} });
const setOpen = (): setOpen => ({ type: SET_OPEN, payload: {} });
export const MenuDrawerActions = {
    MenuOpen: () => (dispatch: Dispatch<any>) => { dispatch(menuOpen()); },
    MenuClose: () => (dispatch: Dispatch<any>) => { dispatch(menuClose()); },
    setOpen: () => (dispatch: Dispatch<any>) => { dispatch(setOpen()); },

};