import { createTheme, responsiveFontSizes, Theme } from '@mui/material';
//  import { sx } from '@mui/system';

import { THEMES } from './constants';
import { lightShadows, darkShadows } from './shadows';
import { ThemeActions } from '../redux/actions/themeActions';
import { connect } from "react-redux";
import { Dispatch } from 'redux';
interface ComponentStyleOverrides {
  [key: string]: any;
}

export interface ThemePalette {

  white: string;
  lightwhite: { contrastText: string; main: string; }
  black: string;
  darkGrey: string;
  lightGrey: string;
  disabled: string;
  overlay: string;
  boxShadow: string;
  box: string;
  orange: string;
  secondaryTitle: string;
  svg: string;
  lightBlueGray: string,
  lightViolet: string,
  divider: string;
  selected: {
    chip: string;
  };
  orderStatus: {
    isPaid: string;
    notPaid: string;
  };
  userMenu: {
    icons: string;
  };
  icons: {
    main: string;
    secondary: string;
  };
  pageTitle: string;
  rateStar: string;
  navItems: {
    active: {
      subText: string;
      text: string;
      arrow: string;
    };
    arrow: string;
  };
  menuButton: {
    activeBackgroundColor: string;
    backgroundColor: string;
  };
  titleBox: {
    contrastText: string;
    backgroundColor: string;
  };
  action: {
    active: string;
  };
  background: {
    default: string;
    paper: string;
    hover: string;
    selected: string;
    chipSelected: string;
  };
  error: {
    contrastText: string;
    main: string;
    lighter: string;

  };
  lightError: {
    contrastText: string;
    main: string;
  },
  secondary: {
    contrastText: string;
    main: string;
  };
  mode: 'light' | 'dark';
  primary: {
    contrastText: string;
    main: any;
    second: string;
    lighter: string;
  };
  success: {
    contrastText: string;
    main: string;
    lighter: string;
  };
  text: {
    primary: string;
    secondary: string;
    third: string;
    fourth: string;
    color: string;
  };
  warning: {
    contrastText: string;
    main: string;
  };
  attention: {
    contrastText: string;
    main: string;
  },
  card: {
    contrastText: string;
    main: string;
  };
  unpackedCard: { main: string; };
  container: {
    contrastText: string;
    main: string;
  };
  bg: {
    contrastText: string;
    main: string;
  };
  third: {
    contrastText: string;
    main: string;
  };

  darkRed: {
    contrastText: string;
    main: string;
  };
  violet: string;
  green: string;
  yelloww: string;
  personbleu: { contrastText: string; main: string; },
  productId: { contrastText: string; main: string; },
  lightRed: { contrastText: string; main: string; },
  lightGreen: { contrastText: string; main: string; },
  lightYellow: { contrastText: string; main: string; },
  lightPurpl: { contrastText: string; main: string; },
  BlueGray: {
    contrastText: string;
    main: string;
  };
  progressGreen: { contrastText: string, main: string },
  progressRed: { contrastText: string, main: string },
  progressYellow: { contrastText: string, main: string },
  progressOrange: { contrastText: string, main: string },
  progressMain: { contrastText: string, main: string },
  progressVert: { contrastText: string, main: string }
}

interface BaseOptions {
  direction: 'ltr' | 'rtl';
  components: ComponentStyleOverrides;

  typography: {
    button: {
      fontWeight: number;
    };
    fontFamily: string;
    h1: {
      fontWeight: number;
      fontSize: string;
    };
    h2: {
      fontWeight: number;
      fontSize: string;
    };
    h3: {
      fontWeight: number;
      fontSize: string;
    };
    h4: {
      fontWeight: number;
      fontSize: string;
    };
    h5: {
      fontWeight: number;
      fontSize: string;
    };
    h6: {
      fontWeight: number;
      fontSize: string;
    };
    overline: {
      fontWeight: number;
    };
  };
}

interface ThemesOptions {
  [key: string]: {
    components: ComponentStyleOverrides;
    palette: ThemePalette;
    shadows: Theme['shadows'];
  };
}

const baseOptions: BaseOptions = {
  direction: 'ltr',

  components: {
    MuiOutlinedInput: { styleOverrides: { root: { borderRadius: '10px', } } },
    MuiInputBase: {
      styleOverrides: { root: { height: '40px', }, input: { '&::placeholder': { opacity: 0.86, padding: '0px !important' } } }
    },
    MuiFormHelperText: { styleOverrides: { root: { fontWeight: 'bold' } } },
    MuiAvatar: { styleOverrides: { fallback: { height: '75%', width: '75%', }, }, },
    MuiButton: { styleOverrides: { root: { textTransform: 'none', borderRadius: '15px', }, }, },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: { MozOsxFontSmoothing: 'grayscale', WebkitFontSmoothing: 'antialiased', height: '100%', width: '100%', },
        body: { height: '100%', },
        '#root': { height: '100%', },
        '#nprogress .bar': { zIndex: '2000 !important', },
      },
    },
    MuiCardHeader: { defaultProps: { titleTypographyProps: { variant: 'h6', }, }, },
    MuiLinearProgress: { styleOverrides: { root: { borderRadius: 3, overflow: 'hidden', }, }, },
    MuiListItemIcon: { styleOverrides: { root: { minWidth: 'auto', marginRight: '16px', }, }, },
    MuiPaper: { styleOverrides: { root: { backgroundImage: 'none', }, }, },
    MuiDateCalendar: { styleOverrides: { root: { borderColor: '#2196f3', maxWidth: '280px', margin: '0px' }, }, },

    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          color: '#7648A3',
          padding: '0px 0px 0px 15px',
          h4: { fontSize: '20px', },
          span: { color: '#7648A3', textTransform: 'none' },

        },
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: '#7648A3',
          margin: '5px 0px 0px 0px',
          padding: '0px 0px 0px 15px',
        },
        labelContainer: {
          marginRight: '10px'
        }
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: { color: '#7648A3', fontWeight: 'bold', fontSize: '15px', width: '30px', height: '30px' },
        slideTransition: {
          minHeight: '170px'
        },
      },
    },
    MuiPickersDay: { styleOverrides: { root: { width: '30px', height: '30px' }, }, },
    MuiPickersLayout: { styleOverrides: { root: { minWidth: '280px !important', }, }, },
    MuiDialogActions: { styleOverrides: { root: { transform: 'translate(0px,-110px)' } } }
  },
  typography: {
    button: { fontWeight: 600, },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: { fontWeight: 600, fontSize: '3.5rem', },
    h2: { fontWeight: 600, fontSize: '3rem', },
    h3: { fontWeight: 600, fontSize: '2.25rem', },
    h4: { fontWeight: 600, fontSize: '2rem', },
    h5: { fontWeight: 600, fontSize: '1.5rem', },
    h6: { fontWeight: 600, fontSize: '1.125rem', },

    overline: { fontWeight: 600, },
  }
};



export interface CustomTheme extends Omit<Theme, 'palette'> {
  palette: ThemePalette
}
export function GetThemeOptions(lightTheme: any, property: any, darkTheme: any,) {

  const themesOptions: ThemesOptions = {
    [THEMES.LIGHT]: {
      components: {
        MuiInputBase: { styleOverrides: { input: { '&::placeholder': { opacity: 0.86, color: '#42526e' } } }, },
        MuiFormHelperText: { styleOverrides: { root: { fontWeight: 'bold' } } },
      },
      palette: {
        white: '#fff',
        lightwhite: { contrastText: '#000', main: '#fff' },
        black: '#000',
        darkGrey: '#5B5B5B',
        lightGrey: '#9A9A9A',
        divider: 'rgba(145, 158, 171, 0.24)',
        disabled: '#d6d7d9',
        overlay: 'rgba(0, 0, 0, 0.50)',
        boxShadow: '#6b778c',
        orange: "#E57E25",
        lightBlueGray: "#00000029",
        lightViolet: "#7648A34C",
        box: '#C5C5C5',
        secondaryTitle: 'red',
        svg: '#5b5b5b',
        selected: { chip: '#86469a', },
        orderStatus: { isPaid: '#1FB719', notPaid: '#EB4242', },
        userMenu: { icons: 'white', },
        icons: { main: '#000', secondary: '#fff' },
        pageTitle: '#5b5b5b',
        rateStar: '#97c24c',
        navItems: { active: { subText: "#723087", text: '#ffff', arrow: 'white' }, arrow: 'black' },
        menuButton: { activeBackgroundColor: '#dadada', backgroundColor: '#ffffff', },
        titleBox: { contrastText: '#ffffff', backgroundColor: '#723087', },
        action: { active: '#6b778c', },
        background: { default: '#f4f5f7', paper: '#ffffff', hover: '#e5dee7', selected: '#eee3f6', chipSelected: '#c0a9c6' },
        error: { contrastText: '#ffffff', main: '#BC2323', lighter: '#BC232340' },
        lightError: { contrastText: '#ffffff', main: '#da5454', },
        secondary: { contrastText: '#fff', main: '#5b5b5b', },
        mode: 'light',
        primary: { contrastText: '#ffffff', main: property === "LightTheme" ? lightTheme.mainColor : property === "darkTheme" ? darkTheme.mainColor : lightTheme.mainColor, second: '#553179', lighter: '#7648A340' },
        success: { contrastText: '#ffffff', main: '#63AF58', lighter: '#63AF5842' },
        text: { primary: '#000', secondary: '#fff', third: '#686868', fourth: '#fff', color: '#7648A3' },
        attention: { contrastText: '#ffffff', main: '#E9A51C', },
        warning: { contrastText: '#ffffff', main: '#C82323', },
        card: { contrastText: '#000000', main: '#F6F8FB' },
        unpackedCard: { main: '#f2fcff' },
        container: { contrastText: '#000', main: '#E9E9E9' },
        bg: { contrastText: '#000', main: '#f4f5f7' },
        third: { contrastText: property === "LightTheme" ? lightTheme.fieldTextColor : property === "darkTheme" ? darkTheme.fieldTextColor : lightTheme.fieldTextColor , main: property === "LightTheme" ? lightTheme.fieldColor : property === "darkTheme" ? darkTheme.fieldColor : lightTheme.fieldColor },
        darkRed: { contrastText: '#000000', main: '#C82323' },
        violet: "#7648A3",
        green: "#63AF58",
        yelloww: "#FFC156",
        personbleu: { contrastText: '#000000', main: '#458CD4' },
        productId: { contrastText: "#E68F69", main: "#E68F69" },
        lightRed: { contrastText: "#63AF58", main: "#ffd7d5" },
        lightGreen: { contrastText: "#63AF58", main: "#63AF5842" },
        lightYellow: { contrastText: "#E9A51C", main: "#E9A51C26" },
        lightPurpl: { contrastText: "#000", main: '#CDBEDE' },
        BlueGray: { contrastText: "#ffff", main: "#D8DEE8" },
        progressGreen: { contrastText: "#B7D14C", main: "#B7D14C66" },
        progressRed: { contrastText: "#BC2323", main: "#BC232366" },
        progressYellow: { contrastText: "#DF9758", main: "#DF975866" },
        progressOrange: { contrastText: "#E6D569", main: "#E6D56966" },
        progressMain: { contrastText: "#EBEEF3", main: "#EBEEF3" },
        progressVert: { contrastText: "#63AF58", main: "#63AF58" }
      },
      shadows: lightShadows,
    },
    [THEMES.DARK]: {
      components: {
        MuiTableHead: {
          root: {
            borderRadius: 5,
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            },
          },
        },
      },

      palette: {
        white: '#000',
        lightwhite: { contrastText: '#000', main: '#fff' },
        black: '#fff',
        darkGrey: '#fff',
        lightGrey: '#fff',
        divider: '#fff',
        disabled: '#d6d7d9',
        overlay: 'rgba(0, 0, 0, 0.50)',
        boxShadow: '#6b778c',
        box: '#fff',
        orange: "#E57E25",
        secondaryTitle: 'red',
        lightBlueGray: "#00000029",
        lightViolet: "#7648A34C",
        svg: '#5b5b5b',
        selected: { chip: '#86469a', },
        orderStatus: { isPaid: '#1FB719', notPaid: '#EB4242', },
        userMenu: { icons: 'white', },
        icons: { main: '#fff', secondary: '#000' },
        pageTitle: '#5b5b5b',
        rateStar: '#97c24c',
        navItems: { active: { subText: "#723087", text: '#ffff', arrow: 'white' }, arrow: 'black' },
        menuButton: { activeBackgroundColor: '#dadada', backgroundColor: '#ffffff', },
        titleBox: { contrastText: '#ffffff', backgroundColor: '#688eff', },
        action: { active: '#6b778c', },
        background: { default: '#171c24', paper: '#000', hover: '#e5dee7', selected: '#eee3f6', chipSelected: '#c0a9c6' },
        error: { contrastText: '#ffffff', main: '#f44336', lighter: '#BC232340' },
        lightError: { contrastText: '#ffffff', main: '#da5454', },
        secondary: { contrastText: '#fff', main: '#5b5b5b', },
        mode: 'dark',
        primary: { contrastText: '#ffffff', main: property === "LightTheme" ? lightTheme.mainColor : property === "darkTheme" ? darkTheme.mainColor : darkTheme.mainColor, second: '#553179', lighter: '#7648A340' },
        success: { contrastText: '#ffffff', main: '#4caf50', lighter: '#63AF5842' },
        text: { primary: '#ffffff', secondary: '#919eab', third: '#ffffff', fourth: '#ffffff', color: '#fff' },
        attention: { contrastText: '#ffffff', main: '#ff9800', },
        warning: { contrastText: '#ffffff', main: '#C82323', },
        card: { contrastText: '#ffffff', main: '#3d3d3d' },
        unpackedCard: { main: '#527580' },
        container: { contrastText: '#000', main: '#E9E9E9' },
        bg: { contrastText: '#fff', main: '#688eff' },
        third: { contrastText: '#fff', main: '#363636', },
        darkRed: { contrastText: '#000000', main: '#C82323' },
        violet: "#7648A3",
        green: "#63AF58",
        yelloww: "#FFC156",
        personbleu: { contrastText: '#000000', main: '#458CD4' },
        productId: { contrastText: "#E68F69", main: "#E68F69" },
        lightRed: { contrastText: "#63AF58", main: "#ffd7d5" },
        lightGreen: { contrastText: "#63AF58", main: "#63AF5842" },
        lightYellow: { contrastText: "#E9A51C", main: "#E9A51C26" },
        lightPurpl: { contrastText: "#000", main: '#CDBEDE' },
        BlueGray: { contrastText: "#ffff", main: "#D8DEE8" },
        progressGreen: { contrastText: "#EBEEF3", main: "#EBEEF3" },
        progressRed: { contrastText: "#BC2323", main: "#BC232366" },
        progressYellow: { contrastText: "#DF9758", main: "#DF975866" },
        progressOrange: { contrastText: "#E6D569", main: "#E6D56966" },
        progressMain: { contrastText: "#B7D14C", main: "#B7D14C66" },
        progressVert: { contrastText: "#63AF58", main: "#63AF58" }
      },
      shadows: darkShadows,
    },

  }

  return themesOptions;

}
export const createCustomTheme = (config: {
  lightTheme?: any,
  darkTheme?: any,
  property?: any,
  theme?: string;
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
} = {}): CustomTheme => {
  let allThemesOptions = GetThemeOptions(config.lightTheme, config.property, config.darkTheme)

  let themeOptions = allThemesOptions[config.theme || THEMES.LIGHT || THEMES.DARK];
  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themeOptions[THEMES.LIGHT];
  }
  const themeShadows: CustomTheme['shadows'] = [...themeOptions.shadows];
  let theme: CustomTheme = createTheme({
    ...themeOptions, ...baseOptions,
    direction: config.direction,
    shadows: themeShadows,
    palette: {
      ...themeOptions.palette,

    }
  }) as unknown as CustomTheme;

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme as unknown as Theme) as unknown as CustomTheme;
  }
  return theme;
};






