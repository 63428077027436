import { IconButton, Button, Box, Link, Container, FormControl, TextField, useTheme, Grid } from '@mui/material';
import login from "../../assets/images/login.svg";
import loginDesktop from '../../assets/images/login copy.png'
import "../../index.css";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDictionary, getField } from '../../utils/translation/translation';
import { TranslationType } from "../../types/translationTypes";
import { connect } from "react-redux";
import ChooseLanguage from '../../components/choose-language/ChooseLanguage';
import TranslateIcon from '@mui/icons-material/Translate';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useStyles from './styles';
import { CustomTheme } from '../../theme/index';

const LoginPage = ({ translation, language, mobileView, desktopView }: { translation: TranslationType[], language: string, mobileView?: boolean; desktopView?: boolean; }) => {

    const Dictionary = getDictionary(translation, "login", language);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [chooseLangModalState, setChooseLangModalState] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const closeChooseLanguage = () => { setChooseLangModalState(false) }
    const chooseLang = () => { setChooseLangModalState(true) }
    const handleTogglePassword = () => { setShowPassword((prevShowPassword) => !prevShowPassword); };
    const theme: CustomTheme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        <>
            <Grid container display='flex' justifyContent='center' alignItems='center' style={{ minHeight: desktopView ? '100vh' : '' }}>
                <Grid item sx={{
                    width: mobileView ? '100%' : '400px', backgroundColor: desktopView ? theme.palette.text.fourth : '', borderRadius: '20px', zIndex: '2',
                    paddingTop: desktopView ? '50px' : '', paddingBottom: desktopView ? '50px' : '', boxShadow: desktopView ? '0 3px 3px rgba(0, 0, 0, 0.1)' : '',
                }}>
                    <div className={desktopView ? classes.desktopBackground : classes.background}>
                        <Container style={{ display: 'grid', userSelect: 'none', justifyContent: 'center', gap: '5px' }}>
                            <div className={classes.loginHeader} >
                                {mobileView && (<img src={login} loading="lazy" />)}
                                {desktopView && (<img src={loginDesktop} loading="lazy"  style={{ width: '140px', height: '140px', objectFit: 'cover' }} />)}
                                <div className={classes.loginHeaderTitle} style={{ color: desktopView ? theme.palette.primary.main : theme.palette.text.fourth }}>
                                    {getField(Dictionary, "login", language)}</div>
                            </div>
                            <div className={classes.loginFormContainer} >
                                <FormControl>
                                    <div className={classes.usernameLabel} style={{ color: desktopView ? theme.palette.text.primary : theme.palette.text.fourth }}>{getField(Dictionary, "username", language)}</div>
                                    <Box sx={{ width: 360, maxWidth: '100%', }}>
                                        <TextField fullWidth hiddenLabel variant={desktopView ? 'outlined' : 'filled'} sx={{ padding: "3px", '& .MuiInputBase-root': { borderBottom: 'none', borderRadius: '15px' }, }}
                                            id="filled-hidden-label-normal" placeholder={getField(Dictionary, "enter-your-username-here", language)}
                                            InputProps={{ disableUnderline: true, style: { fontStyle: 'italic', color: theme.palette.lightGrey, backgroundColor: theme.palette.white, }, }} />
                                    </Box>
                                </FormControl>
                                <FormControl sx={{ paddingTop: '10px' }}>
                                    <div className={classes.usernameLabel} style={{ color: desktopView ? theme.palette.text.primary : theme.palette.text.fourth }}>  {getField(Dictionary, "password", language)}</div>
                                    <Box sx={{ width: 360, maxWidth: '100%', }}  >
                                        <TextField fullWidth hiddenLabel id="filled-hidden-label-normal" variant={desktopView ? 'outlined' : 'filled'}
                                            sx={{ padding: "3px", '& .MuiInputBase-root': { borderBottom: 'none', borderRadius: '15px' } }}
                                            placeholder={getField(Dictionary, "enter-your-password-here", language)}
                                            type={showPassword ? 'text' : 'password'} InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (<InputAdornment position="end">   <IconButton onClick={handleTogglePassword} sx={{ color: theme.palette.lightGrey }} edge="end" aria-label="toggle password visibility"  >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton> </InputAdornment>), style: { fontStyle: 'italic', color: theme.palette.lightGrey, backgroundColor: theme.palette.white, }
                                            }} />
                                    </Box>
                                </FormControl>
                                <div className={classes.forgotPassword} style={{ color: desktopView ? theme.palette.text.primary : theme.palette.text.fourth }}>
                                    {getField(Dictionary, "forgot-your-password", language)}?
                                </div>
                            </div>
                        </Container >
                    </div>
                    <div className={classes.groupbuttons}>
                        <Button variant='contained' onClick={() => { navigate('/dashboard') }} className={classes.loginButton} >
                            {getField(Dictionary, "login", language)}</Button>
                        <Button variant="contained" startIcon={<TranslateIcon />} onClick={() => chooseLang()} className={classes.loginButton} >Choose Language</Button>
                    </div>
                    <ChooseLanguage isOpen={chooseLangModalState} onClose={closeChooseLanguage} dictionary={Dictionary} getField={getField} loading={isSubmitting} />
                </Grid>
            </Grid>
            {desktopView && (<div style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)', width: '100%', height: '250px', backgroundColor: theme.palette.primary.main, borderRadius: '50% 50% 0 0', }} ></div>)}
        </>
    );
};
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, });
export default connect(mapStateToProps, null)(LoginPage);