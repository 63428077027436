import { Dispatch, Action } from 'redux';


export const LOAD_CURRENT_ITEM = 'LOAD_CURRENT_ITEM';
export const INCREMENT_MULTI_INCOMING_REQUEST = 'INCREMENT_INCOMING_BOX_REQUEST';
export const DECREMENT_MULTI_INCOMING_REQUEST = 'DECREMENT_INCOMING_BOX_REQUEST';
export const ADJUST_MULTI_INCOMING_REQUEST = 'ADJUST_INCOMING_BOX_REQUEST';
export interface SupplierType {
    name: string;
    img: string;
}

export interface ProductType {
    id: string;
    name: string;
    requested: number;
    received: { id: string, recCount: number }[];
    oh: number;
    img: string;
    date: {
        date: string;
        count: number;
    }[];
}

export interface ReceivingProductTypes {
    id: string;
    user: string;
    date: string;
    from: string;
    to: string;
    status: string;
    orderDate: string;
    orderPlacedDate: string;
    deliveryDate: string;
    supplier: SupplierType[];
    branch: string;
    remarks?: string;
    products: ProductType[];
}


interface LoadCurrentItemAction extends Action<typeof LOAD_CURRENT_ITEM> {
    payload: {
        item: ReceivingProductTypes;
    };
}
interface IncrementMultiIncomingAction extends Action<typeof INCREMENT_MULTI_INCOMING_REQUEST> {
    payload: { productId: string, multiId: string, dateId: number | null, }
}

interface DecrementMultiIncomingAction extends Action<typeof DECREMENT_MULTI_INCOMING_REQUEST> {
    payload: { productId: string, multiId: string, dateId: number | null, }
}

interface AdjustMultiIncomingAction extends Action<typeof ADJUST_MULTI_INCOMING_REQUEST> {
    payload: { productId: string; multiId: string, dateId: number | null, newQuantity: number; }
}



export type ActionTypes =

    | LoadCurrentItemAction | IncrementMultiIncomingAction | DecrementMultiIncomingAction | AdjustMultiIncomingAction




const loadCurrentItem = (item: ReceivingProductTypes): LoadCurrentItemAction => ({ type: LOAD_CURRENT_ITEM, payload: { item }, });
const incrementMulti = (productId: string, multiId: string, dateId: number | null,): IncrementMultiIncomingAction => ({ type: INCREMENT_MULTI_INCOMING_REQUEST, payload: { productId, multiId, dateId, } });

const decrementMulti = (productId: string, multiId: string, dateId: number | null,): DecrementMultiIncomingAction => ({ type: DECREMENT_MULTI_INCOMING_REQUEST, payload: { productId, multiId, dateId, } });

const adjustMulti = (productId: string, multiId: string, dateId: number | null, newQuantity: number,): AdjustMultiIncomingAction => ({ type: ADJUST_MULTI_INCOMING_REQUEST, payload: { productId, multiId, dateId, newQuantity, } });

export const Actions = {

    loadItem: (item: ReceivingProductTypes) => (dispatch: Dispatch<any>) => { dispatch(loadCurrentItem(item)); },
    incrementIncomingMultiQty: (productId: string, multiId: string, dateId: number | null,) => (dispatch: Dispatch<any>) => { dispatch(incrementMulti(productId, multiId, dateId,)); },
    decrementIncomingMultiQty: (productId: string, multiId: string, dateId: number | null,) => (dispatch: Dispatch<any>) => { dispatch(decrementMulti(productId, multiId, dateId,)); },
    adjustIncomingMultiQty: (productId: string, multiId: string, dateId: number | null, newQuantity: number,) => (dispatch: Dispatch<any>) => { dispatch(adjustMulti(productId, multiId, dateId, newQuantity,)); },

}