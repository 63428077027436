import { Dispatch, Action } from 'redux';

export const INCREMENT_EMPLOYEE_LOOKUP = 'INCREMENT_EMPLOYEE_LOOKUP'
export const DECREMENT_EMPLOYEE_LOOKUP = 'DECREMENT_EMPLOYEE_LOOKUP'
export const ADJUST_EMPLOYEE_LOOKUP_QUANTITY = 'ADJUST_EMPLOYEE_LOOKUP_QUANTITY';
export const EDIT_EMPLOYEE_SI_COMMENT = 'EDIT_EMPLOYEE_SI_COMMENT';
export const REMOVE_FROM_EMPLOYEE_LOOKUP_DETAILS = 'REMOVE_FROM_EMPLOYEE_LOOKUP_DETAILS'
export const REMOVE_EMPLOYEE_BRAND_FROM_DETAILS = 'REMOVE_EMPLOYEE_BRAND_FROM_DETAILS'
export const INCREMENT_EMPLOYEE_UNPACKED = 'INCREMENT_UNPACKED'
export const DECREMENT_EMPLOYEE_UNPACKED = 'DECREMENT_UNPACKED'
export const ADJUST_EMPLOYEE_UNPACKED_QTY = 'ADJUST_UNPACKED_QTY';

export interface Product {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    cost: number;
    expiry: { date: string; count: number; }[]
    reason: string;
    comment?: string;
}

export interface MultiBrandProduct {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    reason: string;
    comment?: string;
    Unpacked: number;
    brands: {
        id: string; name: string; description: string; cost: number;
        expiry: { date: string; count: number }[];
    }[]
}

export type prodArr = (MultiBrandProduct | Product)[]

export interface AppState {
    employeeSi: prodArr;
    empDetails: prodArr;
}

interface IncrementEmployeeUnpackedAction extends Action<typeof INCREMENT_EMPLOYEE_UNPACKED> {
    payload: { productId: string; }
}

interface DecrementEmployeeUnpackedAction extends Action<typeof DECREMENT_EMPLOYEE_UNPACKED> {
    payload: { productId: string; }
}
interface AdjustEmployeeUnpackedQtyAction extends Action<typeof ADJUST_EMPLOYEE_UNPACKED_QTY> {
    payload: { productId: string; newQuantity: number; };
}

interface IncrementEmployeeSiAction extends Action<typeof INCREMENT_EMPLOYEE_LOOKUP> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
    }
}

interface DecrementEmployeeSiAction extends Action<typeof DECREMENT_EMPLOYEE_LOOKUP> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
    }
}

interface AdjustEmployeeSiQuantityAction extends Action<typeof ADJUST_EMPLOYEE_LOOKUP_QUANTITY> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
        newQuantity: number;
    };
}

interface RemoveBrandFromEmployeeSiAction extends Action<typeof REMOVE_EMPLOYEE_BRAND_FROM_DETAILS> {
    payload: {
        brandID: string;
    }

}

interface RemoveFromEmployeeSiAction extends Action<typeof REMOVE_FROM_EMPLOYEE_LOOKUP_DETAILS> {
    payload: {
        itemID: string;
    }

}

interface EditEmployeeSiAction extends Action<typeof EDIT_EMPLOYEE_SI_COMMENT> {
    payload: {
        productId: string;
        comment: string;
    };
}

export type EmployeeSiActionTypes = | IncrementEmployeeSiAction | DecrementEmployeeSiAction
    | AdjustEmployeeSiQuantityAction
    | RemoveFromEmployeeSiAction | RemoveBrandFromEmployeeSiAction
    | EditEmployeeSiAction | IncrementEmployeeUnpackedAction | DecrementEmployeeUnpackedAction | AdjustEmployeeUnpackedQtyAction

const incrementEmployeeSiQty = (productId: string, brandIndex: number | null, expieryIndex: number): IncrementEmployeeSiAction => ({ type: INCREMENT_EMPLOYEE_LOOKUP, payload: { productId, brandIndex, expieryIndex } });

const decrementEmployeeSiQty = (productId: string, brandIndex: number | null, expieryIndex: number): DecrementEmployeeSiAction => ({ type: DECREMENT_EMPLOYEE_LOOKUP, payload: { productId, brandIndex, expieryIndex } });

const adjustEmployeeSiQty = (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number): AdjustEmployeeSiQuantityAction => ({ type: ADJUST_EMPLOYEE_LOOKUP_QUANTITY, payload: { productId, brandIndex, expieryIndex, newQuantity } });

const incrementEmpUnpackedQty = (productId: string,): IncrementEmployeeUnpackedAction => ({ type: INCREMENT_EMPLOYEE_UNPACKED, payload: { productId, } });

const decrementEmpUnpackedQty = (productId: string,): DecrementEmployeeUnpackedAction => ({ type: DECREMENT_EMPLOYEE_UNPACKED, payload: { productId, } });

const adjustEmpUnpackedQty = (productId: string, newQuantity: number): AdjustEmployeeUnpackedQtyAction => ({ type: ADJUST_EMPLOYEE_UNPACKED_QTY, payload: { productId, newQuantity } });
const removeFromEmpSiDet = (itemID: string): RemoveFromEmployeeSiAction => ({ type: REMOVE_FROM_EMPLOYEE_LOOKUP_DETAILS, payload: { itemID } });

const removeBrandFromEmpSiDet = (brandID: string): RemoveBrandFromEmployeeSiAction => ({ type: REMOVE_EMPLOYEE_BRAND_FROM_DETAILS, payload: { brandID } });

const editEmpSiComment = (productId: string, comment: string): EditEmployeeSiAction => ({ type: EDIT_EMPLOYEE_SI_COMMENT, payload: { productId, comment } });

export const EmployeeSiActions = {
    incrementEmployeeSi: (productId: string, brandIndex: number | null, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementEmployeeSiQty(productId, brandIndex, expieryIndex)); },
    decrementEmployeeSi: (productId: string, brandIndex: number | null, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementEmployeeSiQty(productId, brandIndex, expieryIndex)); },
    adjustEmployeeSiQuantity: (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustEmployeeSiQty(productId, brandIndex, expieryIndex, newQuantity)); },
    incrementEmpUnpacked: (productId: string,) => (dispatch: Dispatch<any>) => { dispatch(incrementEmpUnpackedQty(productId,)); },
    decrementEmpUnpacked: (productId: string,) => (dispatch: Dispatch<any>) => { dispatch(decrementEmpUnpackedQty(productId,)); },
    adjustEmpUnpacked: (productId: string, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustEmpUnpackedQty(productId, newQuantity)); },
    removeEmployeeSiFromDetails: (itemID: string) => (dispatch: Dispatch<any>) => { dispatch(removeFromEmpSiDet(itemID)); },
    removeEmployeeSiBrandFromDetails: (brandID: string) => (dispatch: Dispatch<any>) => { dispatch(removeBrandFromEmpSiDet(brandID)); },
    editEmployeeSiComment: (productId: string, comment: string) => (dispatch: Dispatch<any>) => { dispatch(editEmpSiComment(productId, comment)); },
};