import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    
HorizCentering:{display: 'flex', alignItems: 'center', justifyContent: 'center'},
    
TransferModalContainer: {display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',},

TransferModalTitle: {marginBottom: '50px',},

TransferModalBody: {display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',width: '302px',},

TransferModalBranch: {display: 'flex',alignItems: 'center',},

  })

);

export default useStyles;