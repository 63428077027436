import { Box, AppBar, Toolbar, Typography, useTheme, Grid, Avatar } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MenuDrawer from '../../menu-drawer/mobile/MobileMenu';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTheme } from '../../../theme/index';
import { getDictionary, getField } from '../../../utils/translation/translation';
import { TranslationType } from "../../../types/translationTypes";
import { connect } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
interface HeaderTitle {
	id: string;
	title: string;
	paths: string[];
	showNotifications?: boolean;
	showPrintAndDownload?: boolean;
	showDownloadAndShare?: boolean;
	showCalendarAndMenu?: boolean;
	showHistoryIcon?: boolean;
}

function Header({ translation, language, currentItem, currentProduct,toggleDrawer,Theme , lightTheme,darkTheme }: {Theme:any, lightTheme:any, darkTheme:any,  translation: TranslationType[], language: string, currentItem: any, currentProduct: any ,toggleDrawer:any}) {
	const Dictionary = getDictionary(translation, "header", language);
	const theme: CustomTheme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const back = () => { navigate(-1) }
	const getTitle = () => {
		const matchingNavigation = navigations.find(nav => nav.paths?.includes(location.pathname));
		if (matchingNavigation) {
			return matchingNavigation.title;
		}
		return 'No Title';
	};

	const navigations: HeaderTitle[] = [
		{ id: 'my-tasks', title: getField(Dictionary, "all-supplier", language), paths: ["/supplier/all-supplier"], showPrintAndDownload: true, },
		{ id: 'my-tasks', title: getField(Dictionary, "supplier-details", language), paths: ["/supplier/supplier-details",], showDownloadAndShare: true, },
		{ id: 'my-tasks', title: "New Supplier", paths: ['/supplier/new-supplier'], showDownloadAndShare: true, },
		{ id: 'my-tasks', title: "Payment", paths: ['/supplier/payment'] },
		{ id: 'my-tasks', title: 'Statement', paths: ['/supplier/statement', '/supplier/all-payment'] },
		{ id: 'my-tasks', title: getField(Dictionary, "tasks", language), paths: ["/tasks/tasks-list", '/tasks/status'], showNotifications: true },
		{ id: 'my-tasks', title: getField(Dictionary, "tasks", language), paths: ['/mytasks'], showCalendarAndMenu: true },
		{ id: 'my-tasks', title: getField(Dictionary, "new-task", language), paths: ["/new-task"], showNotifications: true },
		{ id: 'my-tasks', title: getField(Dictionary, "transfer-tasks", language), paths: ["/transfer-task"], showHistoryIcon: true },
		{ id: 'my-tasks', title: 'Transfer', paths: ['/transfer/transfer-preview'], showPrintAndDownload: true },
		{ id: 'my-tasks', title: 'Transfer', paths: ['/transfer/transfer-main'] },
		{ id: 'my-tasks', title: 'Internal Request', paths: ['/requests/main'] },
		{ id: 'my-tasks', title: "Request#16001", paths: ['/order/order-details'] },
		{ id: 'product-lookup', title: getField(Dictionary, "internal-request", language), paths: ['/order/order-lookup'], showNotifications: true },
		{ id: 'my-tasks', title: getField(Dictionary, "stock", language), paths: ["/scan/lookup-with-expiry", '/scan/lookup', '/scan/product-brand', '/scan/lookup-without-expiry'] },
		{ id: 'tasks-status', title: getField(Dictionary, "stock-take", language), paths: ['/stock/warehouse-progress'], showNotifications: true },
		{ id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ["/wastage/preview"], showPrintAndDownload: true, showNotifications: true },
		{ id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ["/wastage/finished-products", '/wastage/stock-items'], showNotifications: true },
		{ id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ["/wastage/main"] },
		{ id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ['/wastage/finished-products/details', '/wastage/stock-items/details'], showPrintAndDownload: true },
		{ id: 'employee', title: getField(Dictionary, "employee", language), paths: ['/employee/finished-products/details', '/employee/stock-items/details'], showPrintAndDownload: true },
		{ id: 'employees-meals', title: getField(Dictionary, "employee", language), paths: ["/employee/main"] },
		{ id: 'employees-meals', title: getField(Dictionary, "employee", language), paths: ['/employee/finished-products', '/employee/stock-items'], showNotifications: true },
		{ id: 'outgoing', title: getField(Dictionary, "outgoing", language), paths: ["/outgoing"], showNotifications: true },
		{ id: 'incoming', title: 'Incoming'/*getField(Dictionary, "incoming", language)*/, paths: ["/incoming"], showNotifications: true },
		{ id: 'request', title: currentItem ? `${getField(Dictionary, "request", language)} ${currentItem.id}` : '', paths: currentItem ? [`/requests/${currentItem.id}`] : [], showPrintAndDownload: true },
		{ id: 'employee', title: getField(Dictionary, "employee", language), paths: ["/employee/preview"], showPrintAndDownload: true, showNotifications: true },
		{ id: 'transfer', title: getField(Dictionary, "transfer", language), paths: ["/transfer/lookup-product"], showNotifications: true },
		{ id: 'transfer', title: getField(Dictionary, "transfer", language), paths: ["/transfer/transfer-details"], showNotifications: true },
		{ id: 'purchase', title: "New Purchase Order", paths: ["/purchase/main"] },
		{ id: 'purchase', title: "New Purchase Order", paths: ["/purchase/lookup"], showNotifications: true },
		{ id: 'purchase', title: "purchasing", paths: ["/purchase/purchasing-main"] },
		{ id: 'purchase', title: "purchase", paths: ["/purchase/purchasing-tabs"], showNotifications: true },
		{ id: 'purchase', title: "Add New linked product", paths: ["/purchase/linked-product"], showNotifications: true },
		{ id: 'purchase', title: "Price Comparison", paths: ["/purchase/price-comparison"], showNotifications: true },
		{ id: 'purchase', title: "New PO Details", paths: ["/purchase/order-details"], showNotifications: true },
		{ id: 'purchase', title: "Receiving", paths: ["/purchase/recieving"], showNotifications: true },
		{ id: 'purchase', title: currentProduct ? `PO ${currentProduct.id}` : '', paths: currentProduct ? [`/received/${currentProduct.id}`] : [], showPrintAndDownload: true },
		{ id: 'new-purchase', title: "Received Purchase", paths: ["/new-purchase/main"], showNotifications: false },
		{ id: 'new-purchase', title: "New Purchase", paths: ["/new-purchase/lookup"], showNotifications: true },
		{ id: 'new-purchase', title: `${getField(Dictionary, "ref-numb", language)}#12345`, paths: ["/new-purchase/details"], showPrintAndDownload: true, },
		{ id: 'new-purchase', title: "Price Comparison", paths: ["/new-purchase/price-comparison"], showNotifications: true, },
		{ id: 'new-purchase', title: "Scan Document", paths: ["/new-purchase/scan-invoice-main"], },
		{ id: 'stock', title: "Stock Take", paths: ["/stock/main"], showNotifications: false },
		{ id: 'stock', title: "Stock Take", paths: ["/stock/lookup"], showNotifications: true },
		{ id: 'stock', title: "Stock Take", paths: ["/stock/preview"], showNotifications: true },
		{ id: 'stock', title: "Stock Variance", paths: ["/stock/variance"], showNotifications: true },
		{ id: 'purchase', title: "Received Purchase", paths: ["/purchase/purchasing-verification"], showNotifications: true },
		{ id: 'production', title: "Production", paths: ["/production/main"], showNotifications: true },
		{ id: 'production', title: "Production", paths: ["/production/newMain"], showNotifications: true },
		{ id: 'production', title: "Production", paths: ["/production/lookup", "/production/summary"], showNotifications: true },
		{ id: 'production', title: "Production", paths: ["/production/summary"], showNotifications: true },
		{ id: 'production', title: "Custom Production", paths: ["/production/custom-lookup"], showNotifications: true },
		{ id: 'production', title: "Custom Production", paths: ["/production/custom/summary"], showNotifications: true },
		{ id: 'sales', title: "Sales", paths: ["/sales/main"], showNotifications: true },
		{ id: 'sales', title: "Quotation", paths: ["/sales/quotation-main"], showNotifications: true },
		{ id: 'theme', title: "Design Setup", paths: ["/design/theme"], showNotifications: true },
	];
	const headerStyle = {
		backgroundColor: location.pathname === '/wastage/preview' ? theme.palette.card.main : location.pathname === '/sales/quotation-main' || location.pathname === '/sales/main' || location.pathname === '/production/newMain' || location.pathname === '/purchase/purchasing-verification' || location.pathname === '/stock/main' || location.pathname === '/transfer/transfer-main' || location.pathname === '/requests/main' || location.pathname === '/new-purchase/main' || location.pathname === '/purchase/main' || location.pathname === '/purchase/purchasing-main' || location.pathname === '/wastage/main' || location.pathname === '/employee/main' || location.pathname === '/mytasks' ? theme.palette.primary.main :  theme.palette.background.default,
		color: location.pathname === '/sales/quotation-main' || location.pathname === '/sales/main' || location.pathname === '/production/newMain' || location.pathname === '/purchase/purchasing-verification' || location.pathname === '/stock/main' || location.pathname === '/wastage/main' || location.pathname === '/purchase/main' || location.pathname === '/new-purchase/main' || location.pathname === '/purchase/purchasing-main' || location.pathname === '/employee/main' ? theme.palette.primary.contrastText : location.pathname === '/mytasks' || location.pathname === '/requests/main' || location.pathname === '/transfer/transfer-main' ? theme.palette.white : Theme === "lightTheme" ? lightTheme.mainTextColor : darkTheme.mainTextColor,
	};

	function checkNavigationProperty(property: keyof HeaderTitle): boolean {
		const currentPath = location.pathname;
		const matchingNavigation = navigations.find(nav => nav.paths?.includes(currentPath));
		return matchingNavigation ? !!matchingNavigation[property] : false;
	}
	const shouldShowNotifications = checkNavigationProperty('showNotifications');
	const shouldShowPrintAndDownload = checkNavigationProperty('showPrintAndDownload');
	const shouldShowDownloadAndShare = checkNavigationProperty('showDownloadAndShare');
	const shouldShowCalendarAndMenu = checkNavigationProperty('showCalendarAndMenu');
	const shouldHistoryIcon = checkNavigationProperty('showHistoryIcon');

	return (<div>
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="fixed" style={headerStyle} sx={{ boxShadow: 'none !important', height: '64px', zIndex: '5' }}>
				<Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
					{/* <IconButton
						aria-label="open drawer"
						onClick={toggleDrawer("left", true)}
						edge="start"
						sx={{ mr: 2, borderRadius: '9px', padding: "5px" }}
					>
						<MenuIcon />
					</IconButton> */}
					<IconButton size="small" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={back}> <ArrowBackRoundedIcon /> </IconButton>
					<Grid item sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', }} >
						<Typography component="div" sx={{ flexGrow: 1, textTransform: 'none', fontSize: '20px', fontWeight: '500', position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)', whiteSpace: 'nowrap' }} >
							{getTitle()}
						</Typography></Grid>
					{shouldShowNotifications && (
						<IconButton size="small" edge="end" color="inherit"> <NotificationsOutlinedIcon /> </IconButton>)}
					{shouldShowPrintAndDownload && (<>
						<IconButton size="small" edge="end" color="inherit"><PrintOutlinedIcon /></IconButton>
						<IconButton size="small" edge="end" color="inherit"><FileDownloadOutlinedIcon /></IconButton> </>)}
					{shouldHistoryIcon && (<>
						<IconButton size="small" edge="end" color="inherit"> <HistoryIcon /></IconButton> </>)}
					{shouldShowCalendarAndMenu && (<>
						<IconButton size="small" edge="end"><CalendarTodayRoundedIcon sx={{ color: "userMenu.icons" }} /></IconButton>
					 </>)}
					{shouldShowDownloadAndShare && (<>
						<IconButton size="small" edge="end" color="inherit"><ShareIcon /></IconButton>
						<IconButton size="small" edge="end" color="inherit"><FileDownloadOutlinedIcon /></IconButton> </>)}
					{/* <IconButton size="small" edge="end"><Avatar sx={{ width: 31, height: 31, textAlign: 'center', fontSize: '17px', }} onClick={() => navigate('/dashboard')}>EKS</Avatar></IconButton> */}

				</Toolbar>
			</AppBar>
		</Box></div>);
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, 
	language: state.translation.language, currentItem: state.outgoing.currentItem, 
	currentProduct: state.receivedProducts.currentIncomingItem ,
	lightTheme: state.themeSwitch.lightTheme,
	Theme: state.themeSwitch.theme,
    darkTheme: state.themeSwitch.darkTheme});
export default connect(mapStateToProps, null)(Header);