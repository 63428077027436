import antonnyprof from "../../../assets/images/antonnyprof.svg"
import annanas from '../../../assets/images/annanas.svg'
import fish from '../../../assets/images/fish.svg'
import cannedfood from '../../../assets/images/cannedfood.svg'
import tinnedfood from '../../../assets/images/tinnedfood.svg'
import tomato from "../../../assets/images/tomato.svg"




export const NewPurchaseData = [


    {
        id: "A3323",
        refferenceNumber: 7884,
        supplierName: " Lucas Schultz",
        type: 'Poultry & Eggs',
        productname: 'corn',
        price: 300,
        weight: 700,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: tinnedfood,
        itemname: "potato",
        itemimage: tinnedfood,
        itemweight: '30',
        expirydate: 'none',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'a121', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'a2456', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '66-ps-corn-3',
                brandname: 'Plein Soleil',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.10",
                can: "0.1",
                canAvg: "0.20",
                deliveryDuration: "2",
                canWeight: 4,
                cansPerBox:17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '1-ps-corn-3',
                brandname: 'Maxims',
                supplierName: "Antony Antonny",
                gram: "0.50",
                gramAvg: "0.70",
                can: "0.5",
                canAvg: "0.70",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox:17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '126-ps-corn-3',
                brandname: 'Heinz',
                supplierName: "Lucas Lucas",
                gram: "0.20",
                gramAvg: "0.20",
                can: "0.2",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox:17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "B699",
        refferenceNumber: 1234,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'Humus',
        price: 300,
        weight: 600,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b14545', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b12122', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '7-ps-humus-7',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight:4,
                cansPerBox:17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '4-ps-humus-3',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox:17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },



    // {
    //     id: "DD111",
    //     type: 'Poultry & Eggs',
    //     productname: 'Salmon',
    //     refferenceNumber: 9654,
    //     supplierName: " Lucas Schultz",
    //     price: 300,
    //     weight: 700,
    //     unitprice: '5000',
    //     brandname: '',
    //     purchase: '1.57',
    //     availableqty: '60kg',
    //     levelstatus: 'none',
    //     lastdate: '09 Sep 2023',
    //     imageUrl: fish,
    //     itemname: "potato",
    //     itemimage: tomato,
    //     itemweight: '30',
    //     expirydate: 'none',

    //     upriceonhand: 240,
    //     upricereq: 10,
    //     quantity: [
    //         { qtyType: 'Quantity per Box', count: 0 },
    //         { qtyType: 'Quantity cans', count: 0 }
    //     ],

    //     costonhand: 0,
    //     costReq: 0,
    //     discountAED: 0,
    //     discountPercentage: 0,
    //     totalPrice: 0,
    //     finalPrice: 0,
    // },
    // {
    //     id: "K789",
    //     type: 'Fruits & Vegetables',
    //     productname: 'Tomato',
    //     price: 300,
    //     weight: 600,
    //     refferenceNumber: 9654,
    //     supplierName: " Lucas Schultz",
    //     brandname: '',
    //     purchase: '1.57',
    //     availableqty: '60kg',
    //     levelstatus: 'none',
    //     lastdate: '09 Sep 2023',
    //     imageUrl: annanas,
    //     itemname: "Annanas",
    //     itemimage: annanas,
    //     itemweight: '60',
    //     expirydate: '27/11/2023',

    //     upriceonhand: 240,
    //     upricereq: 10,

    //     quantity: [
    //         { qtyType: 'Quantity per Box', count: 0 },
    //         { qtyType: 'Quantity cans', count: 0 }
    //     ],
    //     costonhand: 0,
    //     costReq: 0,
    //     discountAED: 0,
    //     discountPercentage: 0,
    //     totalPrice: 0,
    //     finalPrice: 0,
    // },
    // {
    //     id: "D5558",
    //     type: 'Fruits & Vegetables',
    //     productname: 'Tomato',
    //     price: 300,
    //     weight: 600,
    //     refferenceNumber: 9654,
    //     supplierName: " Lucas Schultz",
    //     brandname: '',
    //     purchase: '1.57',
    //     availableqty: '60kg',
    //     levelstatus: 'none',
    //     lastdate: '09 Sep 2023',
    //     imageUrl: annanas,
    //     itemname: "Annanas",
    //     itemimage: annanas,
    //     itemweight: '60',
    //     expirydate: '27/11/2023',

    //     upriceonhand: 240,
    //     upricereq: 10,

    //     quantity: [
    //         { qtyType: 'Quantity per Box', count: 0 },
    //         { qtyType: 'Quantity cans', count: 0 }
    //     ],
    //     costonhand: 0,
    //     costReq: 0,
    //     discountAED: 0,
    //     discountPercentage: 0,
    //     totalPrice: 0,
    //     finalPrice: 0,
    // },

    // {
    //     id: "C21222",
    //     type: 'Fruits & Vegetables',
    //     productname: 'Tomato',
    //     price: 300,
    //     weight: 600,
    //     refferenceNumber: 9654,
    //     supplierName: " Lucas Schultz",
    //     brandname: '',
    //     purchase: '1.57',
    //     availableqty: '60kg',
    //     levelstatus: 'none',
    //     lastdate: '09 Sep 2023',
    //     imageUrl: annanas,
    //     itemname: "Annanas",
    //     itemimage: annanas,
    //     itemweight: '60',
    //     expirydate: '27/11/2023',

    //     upriceonhand: 240,
    //     upricereq: 10,

    //     quantity: [
    //         { qtyType: 'Quantity per Box', count: 0 },
    //         { qtyType: 'Quantity cans', count: 0 }
    //     ],
    //     costonhand: 0,
    //     costReq: 0,
    //     discountAED: 0,
    //     discountPercentage: 0,
    //     totalPrice: 0,
    //     finalPrice: 0,
    // },

    // {
    //     id: "D87810",
    //     type: 'Fruits & Vegetables',
    //     productname: 'Tomato',
    //     price: 300,
    //     weight: 600,
    //     refferenceNumber: 16354,
    //     supplierName: " Lucas Schultz",
    //     brandname: '',
    //     purchase: '1.57',
    //     availableqty: '60kg',
    //     levelstatus: 'none',
    //     lastdate: '09 Sep 2023',
    //     imageUrl: annanas,
    //     itemname: "Annanas",
    //     itemimage: annanas,
    //     itemweight: '60',
    //     expirydate: '27/11/2023',

    //     upriceonhand: 240,
    //     upricereq: 10,

    //     quantity: [
    //         { qtyType: 'Quantity per Box', count: 0 },
    //         { qtyType: 'Quantity cans', count: 0 }
    //     ],
    //     costonhand: 0,
    //     costReq: 0,
    //     discountAED: 0,
    //     discountPercentage: 0,
    //     totalPrice: 0,
    //     finalPrice: 0,
    // },








]