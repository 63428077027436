import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    
HorizCentering:{display: 'flex', alignItems: 'center', justifyContent: 'center'},
    
wastageModalContainer: {display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',},

wastageModalTitle: {marginBottom: '50px',},

wastageModalBody: {display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',width: '302px',},

wastageModalBranch: {display: 'flex',alignItems: 'center',},

  })

);

export default useStyles;