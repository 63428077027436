import { Dispatch, Action } from 'redux';

export const INCREMENT_TRANSFER = 'INCREMENT_TRANSFER'
export const DECREMENT_TRANSFER = 'DECREMENT_TRANSFER'
export const ADJUST_TRANSFER_QUANTITY = 'ADJUST_TRANSFER_QUANTITY';
export const REMOVE_FROM_TRANSFER_DETAILS = 'REMOVE_FROM_TRANSFER_DETAILS'

export interface Product {
    map(arg0: (item: any) => any): any
    id: string;
    type: string;
    name: string;
    lastdate: string;
    cost: number;
    avQty?: number;
    expiry: { date: string; count: number; }[]
}

export interface MultiBrandProduct {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    Unpacked: number;
    brands: {
        id: string; name: string; description: string; cost: number;
        expiry: { date: string; count: number }[];
    }[]
}

export type prodArr = (MultiBrandProduct | Product)[]

export interface AppState {
    transferProducts: Product[];
    transferDetails: Product[];
}

interface IncrementTransferAction extends Action<typeof INCREMENT_TRANSFER> {
    payload: {
        productId: string;
        expieryIndex: number;
    }
}

interface DecrementTransferAction extends Action<typeof DECREMENT_TRANSFER> {
    payload: {
        productId: string;
        expieryIndex: number;
    }
}

interface AdjustTransferQuantityAction extends Action<typeof ADJUST_TRANSFER_QUANTITY> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
        newQuantity: number;
    };
}

interface RemoveFromTransferDetailsAction extends Action<typeof REMOVE_FROM_TRANSFER_DETAILS> {
    payload: {
        itemID: string;
    }
}

export type TransferActionTypes = | IncrementTransferAction | DecrementTransferAction
    | AdjustTransferQuantityAction | RemoveFromTransferDetailsAction

const incrementTransferQty = (productId: string, expieryIndex: number): IncrementTransferAction => ({ type: INCREMENT_TRANSFER, payload: { productId, expieryIndex } });

const decrementTransferQty = (productId: string, expieryIndex: number): DecrementTransferAction => ({ type: DECREMENT_TRANSFER, payload: { productId, expieryIndex } });

const adjustTransferQty = (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number): AdjustTransferQuantityAction => ({ type: ADJUST_TRANSFER_QUANTITY, payload: { productId, brandIndex, expieryIndex, newQuantity } });

const removeTransferFromDet = (itemID: string): RemoveFromTransferDetailsAction => ({ type: REMOVE_FROM_TRANSFER_DETAILS, payload: { itemID } });

export const TransferActions = {
    incrementTransfer: (productId: string, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementTransferQty(productId, expieryIndex)); },
    decrementTransfer: (productId: string, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementTransferQty(productId, expieryIndex)); },
    adjustTransferQuantity: (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustTransferQty(productId, brandIndex, expieryIndex, newQuantity)); },
    removeFromTransferDetails: (itemID: string) => (dispatch: Dispatch<any>) => { dispatch(removeTransferFromDet(itemID)); },
};