import { Typography, useTheme } from '@mui/material';
import transferred from '../../../assets/images/transferred.svg'
import transfergreen from '../../../assets/images/transfergreen.svg'
import transferapproval from '../../../assets/images/transferapproval.svg'
import { getDictionary, getField } from '../../../utils/translation/translation';
import { TranslationType } from '../../../types/translationTypes';
import { connect } from 'react-redux';
import useStyles from './style';
import { CustomTheme } from '../../../theme/index';
function TransferApproval({ translation, language, }: { translation: TranslationType[], language: string, }) {
    const Dictionary = getDictionary(translation, "transfer-details", language);
    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    return (
        <div className={classes.HorizCentering}>
            <div className={classes.TransferModalContainer}>
                <div className={classes.TransferModalTitle}>
                    <Typography variant="h3" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold' }}>{getField(Dictionary, "transfer", language)} </Typography>
                </div>
                <div className={classes.TransferModalBody}>
                    <img src={transferapproval} />
                    <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold', marginTop: '10px' }}>
                        13 {getField(Dictionary, "send-for-approval", language)}
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold', marginTop: '50px' }}>From:</Typography>
                    <div className={classes.TransferModalBranch}>
                        <img src={transferred} />
                        <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center" }}>Branch1, warehouse 2</Typography>
                    </div>
                    <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center", fontWeight: 'bold', marginTop: '50px' }}>To:</Typography>
                    <div className={classes.TransferModalBranch}>
                        <img src={transfergreen} />
                        <Typography variant="h6" sx={{ color: 'text.main', textAlign: "center" }}>Branch2,  Kitchen</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, });
export default connect(mapStateToProps, null)(TransferApproval);
