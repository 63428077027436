import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../theme/index';
const calendarStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        weekdays: {
            display: 'flex',
            justifyContent: 'space-between',
         
            marginTop: '15px',
            width: 'fit-content',
            overflowX: 'auto',
            overflowY: 'hidden',
            gap: '20px',
            textTransform: 'uppercase',
        },
        weekday: {
            fontSize: '12px',
            textAlign: 'center',
            width: '36px',
            marginTop: '20px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        calendarIconStyle:{width: '12px', height: '12px',},
        datepicker: { '& .MuiInputBase-root': { width: '120px !important',  borderRadius: '10px !important', height: '32px !important',  backgroundColor: theme.palette.third.main,},
  '@media screen and (min-width: 1024px)': { '& .MuiInputBase-root': { width: '160px !important', }, }},
        days: {
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: '8.5px',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch',
            borderBottom: '1.5px solid #E0E0E0',
            paddingBottom: '15px',
            position: 'relative',
            alignItems: 'center',
        },
        month: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            height: '40px',
        },
        months: {
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: '10px',
            display: 'flex',
            overflowX: 'auto',
            borderBottom: '1px solid #E0E0E0',
            paddingBottom: '16px',
            position: 'relative',
        },
        day: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            height: '40px',
            color:  theme.palette.lightGrey,
            '&:hover':{color:theme.palette.primary.main},
        },
        selected: {
            color: theme.palette.primary.main,
            borderBottomColor:  theme.palette.primary.main,
        },
        selectedWeekday: {
            color:  theme.palette.primary.main,
        },
        selectedDay: {
            position: 'relative',
            color:  theme.palette.primary.main,
            textAlign:'center',
            fontWeight:'bold'
        },
        selectedDayBefore: {
            content: '',
            position: 'absolute',
            left: '0',
            bottom: '-17px',
            width: '100%',
            height: '4px',
            backgroundColor: theme.palette.primary.main,
        },
        selectedDayAfter: {
            content: '',
            position: 'absolute',
            bottom: '-17px',
            left: '0',
            width: '100%',
            height: '4px',
            backgroundColor: theme.palette.primary.main,
        },
        calendarBody: {
            overflowX: 'auto',
            whiteSpace: 'nowrap',
        },
        calendarContainer: {
            maxWidth: '350px',
            margin: '0 auto',
        },
        clickedDay: {
            borderBottomColor:  theme.palette.primary.main,
        },
        monthCard: {
            width: '100%',
            height: '15.7vh',
            borderRadius: '25px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            overflow: 'auto',
            marginTop: '20px',
        },
        weekdaysMonth: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            justifyItems: 'center',
            alignItems: 'center',
            color:  theme.palette.darkGrey,
            marginTop: '15px',
        },
        calendarScroll: {
            display: 'flex',
            flexDirection: 'column',
        },
        monthContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: '-10px',
        },
        weekdayMonth: {
            fontSize: '12px',
            textAlign: 'center',
            width: '36px',
            marginTop: '20px',
            textTransform: 'uppercase',
            color: '#a8a4a4',
            fontWeight: 'bold',
        },
        monthBody: {
            display: 'flex',
            marginTop: '-20px',
        },
        daysMonth: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '-10px',
        },
        dayMonth: {
            margin: '9px',
        },
        emptyDayMonth: {
            margin: '8px',
        },
        emptyDayMonthText: {
            color: '#a49f9f',
        },
        employeeMealsDateTitle: {
            fontSize: '25px',
            fontWeight: 'bold',
            color: theme.palette.white,
        },
        employeeMealsDateContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '7px',
            fontSize: '16px',
            color: theme.palette.white,
        },
        dateContainer: {
            display: 'grid',  gap: '0.3rem',  marginBottom: '10px',
        },

        horizDate:{ display: 'flex',  gap: '0.3rem',  marginBottom: '10px',},
        employeeMealsTimeContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
        },
        employeeMealsTime: {
            color: theme.palette.white,
            fontSize: '16px',
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: '7px',
        },
        calendarHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
        },
        calendarCard: {
            width: '330px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            padding: '7px',
            paddingBottom: '20px',
            overflow: 'auto',
            
        },
        iconButtonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },

           
DayTitle: { display: 'flex', fontSize: '25px',    fontWeight: 'bold', },

DateContainer: { display: 'flex', gap: '0.4rem', alignItems: 'center', fontSize: '14px',},

TimeContainer: {  display: 'flex',   alignItems: 'center',  gap: '0.4rem', },










    })
);

export default calendarStyles;