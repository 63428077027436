import { RootState } from "../actions/productionActions";
import { productionArr, IngredientTypes, NewPurchaseType, customproductionArr } from "../../types";
import ProductionLookupData from "../../pages/production/production-items/production-lookup/production-lookup-data";
import CustomProdLookupData from "../../pages/production/custom-production/custom-production-lookup/custom-production-data";
import Ingredients from "../../pages/production/production-items/production-summary/ingredient-product-data";
import {
    INCREMENT_ORDER_QUANTITY, DECREMENT_ORDER_QUANTITY, ADJUST_ORDER_QUANTITY,
    INCREMENT_BATCH_QTY, DECREMENT_BATCH_QTY, ADJUST_BATCH_QTY, SET_CARDS_ID,
    INCREMENT_BOX_ORDER_QUANTITY, DECREMENT_BOX_ORDER_QUANTITY, ADJUST_BOX_ORDER_QUANTITY,
    INCREMENT_CAN_ORDER_QUANTITY, DECREMENT_CAN_ORDER_QUANTITY, ADJUST_CAN_ORDER_QUANTITY,
    INCREMENT_ORDER_BASE_UNIT_QUANTITY, DECREMENT_ORDER_BASE_UNIT_QUANTITY, UPDATE_ORDER_BASE_UNIT_QUANTITY, CHANGE_ORDER_WEIGHT_TYPE,
    ProductActionTypes, REMOVE_ORDER_FROM_PURCHASE, REMOVE_BRAND_FROM_ORDER, ADD_ORDER_TO_DETAILS,
    UPDATE_UPRICE_ON_HAND, UPDATE_UPRICE_REQ, SET_BRAND_DISCOUNT_AED, SET_BRAND_DISCOUNT_PERCENTAGE,
    SET_TOTAL_QTY_ON_HAND_PRICE, SET_TOTAL_QTY_REQUESTED_PRICE, SET_TOTAL_PRICE,
    SET_DISCOUNT_AED, SET_DISCOUNT_PERCENTAGE, SET_FINAL_PRICE, UPDATE_BASE_UNIT_PRICE, SET_TOTAL_BASE_UNIT_PRICE,
    TOGGLE_BRAND_FAVORITE, SET_ACTIVE_CARD, ADD_REMARKS_TO_REQUEST, ADD_INTERNAL_REMARKS_TO_PURCHASE, ADD_PRODUCT_BRAND, UPDATE_PRODUCT_REASON, SET_SELECTED_REASON,
    INCREMENT_CUSTOM_QUANTITY, DECREMENT_CUSTOM__QUANTITY, ADJUST_CUSTOM__QUANTITY, INCREMENT_CUSTOM_BATCH_QTY, DECREMENT_CUSTOM_BATCH_QTY, ADJUST_CUSTOM_BATCH_QTY,
    INCREMENT_INGREDIENT_QUANTITY, DECREMENT_INGREDIENT_QUANTITY, ADJUST_INGREDIENT_QUANTITY
} from "../actions/productionActions"
const INITIAL_STATE: RootState = {
    productionProduct: ProductionLookupData as productionArr,
    customProduct: CustomProdLookupData as customproductionArr,
    selectedCards: [],
    ingredientProducts: Ingredients as NewPurchaseType[],
    newPODetails: [] as NewPurchaseType[],
    cansPerBox: 24,
    activeCardId: null,
    selectedReason: "",

}

const updateProductQty = (
    products: productionArr,
    productId: string,
    brandId: string | null,
    index: number
): productionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            const newQuantityAsNumber = parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10);
            product.quantity = (newQuantityAsNumber + 1).toString();
            const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
            product.qtyOnhand = (quantityNumber).toString();
        }
        return product;
    });
};

const updateCustomProductQty = (
    products: customproductionArr,
    productId: string,
    brandId: string | null,
    index: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            const newQuantityAsNumber = parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10);
            product.quantity = (newQuantityAsNumber + 1).toString();
            const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
            product.qtyOnhand = (quantityNumber).toString();
        }
        return product;
    });
};

const decrementProductQty = (
    products: productionArr,
    productId: string,
    brandId: string | null,
    index: number,
): productionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            const newQuantityAsNumber = (parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10));
            if (newQuantityAsNumber > 0) {
                product.quantity = (newQuantityAsNumber - 1).toString();
                const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
                product.qtyOnhand = (quantityNumber).toString();
            }
        }
        return product;
    });
};
const decrementCustomProductQty = (
    products: customproductionArr,
    productId: string,
    brandId: string | null,
    index: number,
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            const newQuantityAsNumber = (parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10));
            if (newQuantityAsNumber > 0) {
                product.quantity = (newQuantityAsNumber - 1).toString();
                const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
                product.qtyOnhand = (quantityNumber).toString();
            }
        }
        return product;
    });
};

const adjustQty = (
    products: productionArr,
    productId: string,
    newQuantity: number
): productionArr => {
    return products.map((product) => {
        if (product.id === productId) {

            product.quantity = newQuantity.toString();
            const newbatchQuantity = newQuantity / parseInt(product.qtyKg, 10)
            product.qtyOnhand = (newbatchQuantity).toString();
        }
        return product;
    });
};
const adjustCustomQty = (
    products: customproductionArr,
    productId: string,
    newQuantity: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {

            product.quantity = newQuantity.toString();
            const newbatchQuantity = newQuantity / parseInt(product.qtyKg, 10)
            product.qtyOnhand = (newbatchQuantity).toString();
        }
        return product;
    });
};
const updateBatchQty = (
    products: productionArr,
    productId: string,
    index: number
): productionArr => {
    return products.map((product) => {
        if (product.id === productId) {

            const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
            product.qtyOnhand = (quantityNumber + 1).toString();
            const newQuantityAsNumber = parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10);
            product.quantity = (newQuantityAsNumber).toString();

        }
        return product;
    });
};

const updateCustomBatchQty = (
    products: customproductionArr,
    productId: string,
    index: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {

            const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
            product.qtyOnhand = (quantityNumber + 1).toString();
            const newQuantityAsNumber = parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10);
            product.quantity = (newQuantityAsNumber).toString();

        }
        return product;
    });
};


const decrementBatchQty = (
    products: productionArr,
    productId: string,
    index: number,
): productionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
            if (quantityNumber > 0) {
                product.qtyOnhand = (quantityNumber - 1).toString();
                const newQuantityAsNumber = parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10);
                product.quantity = (newQuantityAsNumber).toString();
            }
        }
        return product;
    });
};
const decrementCustomBatchQty = (
    products: customproductionArr,
    productId: string,
    index: number,
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            const quantityNumber = parseInt(product.quantity, 10) / parseInt(product.qtyKg, 10);
            if (quantityNumber > 0) {
                product.qtyOnhand = (quantityNumber - 1).toString();
                const newQuantityAsNumber = parseInt(product.qtyKg, 10) * parseInt(product.qtyOnhand, 10);
                product.quantity = (newQuantityAsNumber).toString();
            }
        }
        return product;
    });
};

const adjustBatchQty = (
    products: productionArr,
    productId: string,
    newQuantity: number
): productionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            product.qtyOnhand = newQuantity.toString();
            const newKgQuantity = newQuantity * parseInt(product.qtyKg, 10)
            product.quantity = (newKgQuantity).toString();
        }
        return product;
    });
};
const adjustCustomBatchQty = (
    products: customproductionArr,
    productId: string,
    newQuantity: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            product.qtyOnhand = newQuantity.toString();
            const newKgQuantity = newQuantity * parseInt(product.qtyKg, 10)
            product.quantity = (newKgQuantity).toString();
        }
        return product;
    });
};

const incrementBoxCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.boxCount === 'number'
                                    ) {
                                        return {
                                            ...quantity,
                                            boxCount: quantity.boxCount + 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    })
}

const decrementBoxCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.boxCount === 'number' &&
                                        quantity.boxCount > 0
                                    ) {
                                        return {
                                            ...quantity,
                                            boxCount: quantity.boxCount - 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const incrementCanCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.cansCount === 'number'
                                    ) {
                                        return {
                                            ...quantity,
                                            cansCount: quantity.cansCount + 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    })
}

const decrementCanCounter = (products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof quantity.cansCount === 'number' &&
                                        quantity.cansCount > 0
                                    ) {
                                        return {
                                            ...quantity,
                                            cansCount: quantity.cansCount - 1,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};
const incrementBaseUnitInput = (products: NewPurchaseType[], productId: string, baseUnitIndex: number,) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('quantity' in product) {
                product.quantity = product.quantity?.map((bUnit, i) => {
                    if (i === baseUnitIndex) {
                        let count = bUnit.count;
                        count += 1;
                        return { ...bUnit, count };
                    }
                    return { ...bUnit };
                });
            }
        }
        return product;
    })
}

const decrementBaseUnitInput = (products: NewPurchaseType[], productId: string, baseUnitIndex: number,) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('quantity' in product) {
                product.quantity = product.quantity?.map((bUnit, buIndex) => {
                    if (buIndex === baseUnitIndex && typeof bUnit.count === 'number' && bUnit.count > 0) {
                        return {
                            ...bUnit,
                            count: bUnit.count - 1,
                        };
                    }
                    return { ...bUnit };
                });
            }
        }
        return product;
    });
};

const updateBoxQuantity = (
    products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number,
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((qty, eIndex) => {
                                    if (eIndex === qtyIndex && typeof qty.boxCount === 'number') {
                                        return {
                                            ...qty,
                                            boxCount: newQuantity,
                                        };
                                    }
                                    return qty;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const updateCanQuantity = (
    products: NewPurchaseType[], productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number,
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('productBrands' in product) {
                return {
                    ...product,
                    productBrands: product.productBrands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'quantity' in brand) {
                            return {
                                ...brand,
                                quantity: brand.quantity.map((quantity, eIndex) => {
                                    if (eIndex === qtyIndex && typeof quantity.cansCount === 'number') {
                                        return {
                                            ...quantity,
                                            cansCount: newQuantity,
                                        };
                                    }
                                    return quantity;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};
const incrementIngredientCounter = (
    products: customproductionArr,
    productId: string,
    brandId: string | null,
    index: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('ingredient' in product) {
                product.ingredient.forEach((brand) => {
                    if (brand.id === brandId) {


                        brand.weight = (parseInt(brand.weight, 10) + 1).toString();

                    }
                });
            }
        }
        return product;
    });
};
const decrementIngredientCounter = (
    products: customproductionArr,
    productId: string,
    brandId: string | null,
    index: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('ingredient' in product) {
                product.ingredient.forEach((brand) => {
                    if (brand.id === brandId) {
                        const currentWeight = parseFloat(brand.weight);
                        if (currentWeight > 0) {
                            brand.weight = (parseInt(brand.weight, 10) - 1).toString();
                        }

                    }
                });
            }
        }
        return product;
    });
};
const adjustIngredientQty = (
    products: customproductionArr,
    productId: string,
    brandId: string | null,
    newQuantity: number,
    index: number
): customproductionArr => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('ingredient' in product) {
                product.ingredient.forEach((brand) => {
                    if (brand.id === brandId) {
                        brand.weight = newQuantity.toString();
                    }
                });
            }
        }
        return product;
    });
};



const setBaseUnitToZero = (details: NewPurchaseType[], productId: string,) => {
    const bUToZero = details.find((item) => item.id === productId);
    if (bUToZero) {
        if ('quantity' in bUToZero) { bUToZero.quantity.forEach((qty) => { qty.count = 0; }); }
    }
}
const setBoxAndCansUnitToZero = (details: NewPurchaseType[], productId: string,) => {
    const boxAndCansToRemove = details.find((item) => item.id === productId);
    if (boxAndCansToRemove) {
        if ('productBrands' in boxAndCansToRemove) {
            boxAndCansToRemove.productBrands.forEach((brand) => {
                brand.quantity.forEach((qty) => {
                    qty.boxCount = 0;
                    qty.cansCount = 0;
                });
            });
        }
    }
}

const updateDetailsWithProduct = (details: NewPurchaseType[], updatedProducts: NewPurchaseType[], productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};
const ProductionReducer = (state = INITIAL_STATE, action: any) => {
    let updatedProducts;
    let updatedIngredients;
    let updatedDetails;
    let bUToZero;
    let boxAndCansToRemove;
    switch (action.type) {
        case INCREMENT_ORDER_QUANTITY:
            updatedProducts = updateProductQty(
                state.productionProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                productionProduct: updatedProducts,
            };
        case DECREMENT_ORDER_QUANTITY:
            updatedProducts = decrementProductQty(
                state.productionProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                productionProduct: updatedProducts,
            };
        case ADJUST_ORDER_QUANTITY:
            updatedProducts = adjustQty(
                state.productionProduct || [],
                action.payload.productId,
                action.payload.newQuantity
            );
            return {
                ...state,
                productionProduct: updatedProducts,
            };
        case INCREMENT_BATCH_QTY:
            updatedProducts = updateBatchQty(
                state.productionProduct || [],
                action.payload.productId,

                action.payload.index
            );
            return {
                ...state,
                productionProduct: updatedProducts,
            };
        case DECREMENT_BATCH_QTY:
            updatedProducts = decrementBatchQty(
                state.productionProduct || [],
                action.payload.productId,

                action.payload.index
            );
            return {
                ...state,
                productionProduct: updatedProducts,
            };
        case ADJUST_BATCH_QTY:
            updatedProducts = adjustBatchQty(
                state.productionProduct || [],
                action.payload.productId,
                action.payload.newQuantity
            );
            return {
                ...state,
                productionProduct: updatedProducts,
            };
        case INCREMENT_CUSTOM_QUANTITY:
            updatedProducts = updateCustomProductQty(
                state.customProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case DECREMENT_CUSTOM__QUANTITY:
            updatedProducts = decrementCustomProductQty(
                state.customProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case ADJUST_CUSTOM__QUANTITY:
            updatedProducts = adjustCustomQty(
                state.customProduct || [],
                action.payload.productId,
                action.payload.newQuantity
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case INCREMENT_CUSTOM_BATCH_QTY:
            updatedProducts = updateCustomBatchQty(
                state.customProduct || [],
                action.payload.productId,

                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case DECREMENT_CUSTOM_BATCH_QTY:
            updatedProducts = decrementCustomBatchQty(
                state.customProduct || [],
                action.payload.productId,
                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case ADJUST_CUSTOM_BATCH_QTY:
            updatedProducts = adjustCustomBatchQty(
                state.customProduct || [],
                action.payload.productId,
                action.payload.newQuantity
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case INCREMENT_INGREDIENT_QUANTITY:
            updatedProducts = incrementIngredientCounter(
                state.customProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case DECREMENT_INGREDIENT_QUANTITY:
            updatedProducts = decrementIngredientCounter(
                state.customProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case ADJUST_INGREDIENT_QUANTITY:
            updatedProducts = adjustIngredientQty(
                state.customProduct || [],
                action.payload.productId,
                action.payload.brandId,
                action.payload.newQuantity,
                action.payload.index
            );
            return {
                ...state,
                customProduct: updatedProducts,
            };
        case SET_CARDS_ID:
            console.log(action.payload.cardIds)
            return {
                ...state,
                selectedCards: action.payload.cardIds
            };
        case INCREMENT_BOX_ORDER_QUANTITY:
            updatedProducts = incrementBoxCounter(state.ingredientProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case DECREMENT_BOX_ORDER_QUANTITY:
            updatedProducts = decrementBoxCounter(state.ingredientProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };
        case ADJUST_BOX_ORDER_QUANTITY:
            updatedProducts = updateBoxQuantity(state.ingredientProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex, action.payload.newQuantity,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case INCREMENT_CAN_ORDER_QUANTITY:
            updatedProducts = incrementCanCounter(state.ingredientProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case DECREMENT_CAN_ORDER_QUANTITY:
            updatedProducts = decrementCanCounter(state.ingredientProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case ADJUST_CAN_ORDER_QUANTITY:
            updatedProducts = updateCanQuantity(state.ingredientProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex, action.payload.newQuantity,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case INCREMENT_ORDER_BASE_UNIT_QUANTITY:
            updatedProducts = incrementBaseUnitInput(state.ingredientProducts, action.payload.productId, action.payload.baseUnitIndex,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case DECREMENT_ORDER_BASE_UNIT_QUANTITY:
            updatedProducts = decrementBaseUnitInput(state.ingredientProducts, action.payload.productId, action.payload.baseUnitIndex,);
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };

        case UPDATE_ORDER_BASE_UNIT_QUANTITY:
            updatedProducts = state.ingredientProducts.map((product) => {
                if ('quantity' in product) {
                    product.quantity = product.quantity.map((bUnit) => {
                        if (bUnit.id === action.payload.baseUnitId) {
                            return { ...bUnit, count: action.payload.newCount };
                        }
                        return { ...bUnit };
                    });
                }
                return { ...product };
            });
            // updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.newPODetails, action.payload.productId)
            return {
                ...state,
                ingredientProducts: updatedProducts,
                // newPODetails: updatedDetails,
            };


        case ADD_ORDER_TO_DETAILS:

            updatedDetails = updateDetailsWithProduct(state.newPODetails, state.ingredientProducts, action.payload.productId);
            return {
                ...state,
                newPODetails: updatedDetails,
            };

        case REMOVE_BRAND_FROM_ORDER:
            updatedDetails = state.newPODetails.map((item) => {
                if ('productBrands' in item) {
                    const brandsToRemove = item.productBrands.filter((brand) => brand.id === action.payload.brandID);
                    brandsToRemove.forEach((brand) => {
                        brand.favorite = false;
                        brand.quantity.forEach((qty) => {
                            qty.boxCount = 0;
                            qty.cansCount = 0;

                        });
                    });
                }
                return item;
            });
            return {
                ...state,
                newPODetails: updatedDetails,
            };

        case REMOVE_ORDER_FROM_PURCHASE:
            updatedDetails = state.newPODetails.filter((item) => item.id !== action.payload.productId);
            const itemToRemove = state.newPODetails.find((item) => item.id === action.payload.productId);
            if (itemToRemove) {
                if ('quantity' in itemToRemove) {
                    itemToRemove.quantity.forEach((q) => {
                        q.count = 0;
                    });
                }
            }
            return {
                ...state,
                newPODetails: updatedDetails,
            };

        case UPDATE_UPRICE_ON_HAND:
            const { productIndex, newValue } = action.payload;
            let convertUpriceProducts = state.ingredientProducts.map((product, index) => {
                if (index === productIndex) {
                    let updatedProductBrands = product.productBrands.map((brand) => {
                        return {
                            ...brand,
                            upriceonhand: parseFloat(newValue),
                            upricereq: parseFloat(newValue) / state.cansPerBox
                        };
                    });

                    return {
                        ...product,
                        productBrands: updatedProductBrands
                    };
                }
                return product;
            });
            console.log(`After update:`, convertUpriceProducts);
            return {
                ...state,
                ingredientProducts: convertUpriceProducts,
                newPODetails: convertUpriceProducts
            };
        case UPDATE_UPRICE_REQ:
            const { productReqIndex, newReqValue } = action.payload;
            let convertUpriceReqProducts = state.ingredientProducts.map((product, index) => {
                if (index === productReqIndex) {
                    let updatedProductBrands = product.productBrands.map((brand) => {
                        return {
                            ...brand,
                            upricereq: parseFloat(newReqValue),
                            upriceonhand: parseFloat(newReqValue) * state.cansPerBox
                        };
                    });

                    return {
                        ...product,
                        productBrands: updatedProductBrands
                    };
                }

                return product;
            });

            return {
                ...state,
                ingredientProducts: convertUpriceReqProducts,
                newPODetails: convertUpriceReqProducts
            };
        case UPDATE_BASE_UNIT_PRICE:
            const { productInd, newUnitPrice } = action.payload;


            const updatedbBaseUnitPrice = state.ingredientProducts.map((product, index) => {
                if (index === productInd) {
                    return {
                        ...product,
                        upriceonhand: newUnitPrice
                    };
                }
                return product;
            });

            return {
                ...state,
                ingredientProducts: updatedbBaseUnitPrice,
                newPODetails: updatedbBaseUnitPrice
            };

        case SET_TOTAL_QTY_ON_HAND_PRICE: return { ...state, totalQtyOnHandPrice: action.payload, };
        case SET_TOTAL_QTY_REQUESTED_PRICE: return { ...state, totalQtyRequestedPrice: action.payload, };
        case SET_TOTAL_PRICE: return { ...state, totalPrice: action.payload, };
        case SET_TOTAL_BASE_UNIT_PRICE: return { ...state, totalQtyOnHandPrice: action.payload, };

        case SET_DISCOUNT_AED:
            const { discountAEDproductId, discountAED } = action.payload;
            let DiscountAED = state.ingredientProducts.map((product, index) => {
                if (index === discountAEDproductId) {
                    return {
                        ...product,
                        discountAED: parseFloat(discountAED),
                        discountPercentage: (((parseFloat(discountAED)) / product.costonhand) * 100).toFixed(2)

                    };
                }
                return product;
            });
            console.log(`discountAED:`, discountAED);
            return {
                ...state,
                ingredientProducts: DiscountAED,
                newPODetails: DiscountAED
            };
        case SET_DISCOUNT_PERCENTAGE:
            const { discountAPercproductId, discountPerc } = action.payload;
            let DiscountPercentage = state.ingredientProducts.map((product, index) => {
                if (index === discountAPercproductId) {
                    return {
                        ...product,
                        discountPercentage: parseFloat(discountPerc),
                        discountAED: ((parseFloat(discountPerc) / 100) * product.costonhand).toFixed(2)

                    };
                }
                return product;
            });
            console.log(`After update:`, DiscountPercentage);
            return {
                ...state,
                ingredientProducts: DiscountPercentage,
                newPODetails: DiscountPercentage
            }
        case SET_FINAL_PRICE: return { ...state, finalPrice: action.payload, };

        case SET_BRAND_DISCOUNT_AED:
            const { discountAEDbrandId, bdiscountAED } = action.payload;
            let updatedProductsAED = state.ingredientProducts.map((product, index) => {
                if (index === discountAEDbrandId) {
                    let updatedBrandsAED = product.productBrands.map((brand, brandIndex) => {
                        // if (brandIndex === discountAEDbrandId) {
                        return {
                            ...brand,
                            discountAED: parseFloat(bdiscountAED),
                            discountPercentage: (((parseFloat(bdiscountAED)) / brand.totalPrice) * 100).toFixed(2)
                        };
                        // }
                        // return brand;
                    });

                    return {
                        ...product,
                        productBrands: updatedBrandsAED
                    };
                }

                return product;
            });

            console.log(`discountAED:`, bdiscountAED);

            return {
                ...state,
                ingredientProducts: updatedProductsAED,
                newPODetails: updatedProductsAED
            };
        case SET_BRAND_DISCOUNT_PERCENTAGE:
            const { discountPercbrandId, bdiscountPerc } = action.payload;

            let updatedBrandPercen = state.ingredientProducts.map((product, index) => {
                if (index === discountPercbrandId) {
                    let updatedBrands = product.productBrands.map((brand) => {
                        return {
                            ...brand,
                            discountPercentage: parseFloat(bdiscountPerc),
                            discountAED: ((parseFloat(bdiscountPerc) / 100) * brand.totalPrice).toFixed(2)
                        };
                    });

                    return {
                        ...product,
                        productBrands: updatedBrands
                    };
                }

                return product;
            });

            console.log(`After update:`, updatedBrandPercen);

            return {
                ...state,
                ingredientProducts: updatedBrandPercen,
                newPODetails: updatedBrandPercen
            };

        case CHANGE_ORDER_WEIGHT_TYPE:
            updatedProducts = state.ingredientProducts.map((product) => {
                if ('quantity' in product) {
                    product.quantity = product.quantity.map((qty) => {
                        if (qty.id === action.payload.baseUnitId) {
                            const newCount =
                                action.payload.weightType === 'Gr'
                                    ? qty.count * 1000
                                    : qty.count / 1000;
                            return { ...qty, weightType: action.payload.weightType, count: newCount };
                        }
                        return { ...qty };
                    });
                }
                return { ...product };
            });
            updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                ingredientProducts: updatedProducts,
                newPODetails: updatedDetails,
            };

        case TOGGLE_BRAND_FAVORITE:
            console.log(action.payload)
            const updatedingredientProducts = state.ingredientProducts.map(product => {
                const updatedBrands = product.productBrands.map(brand => {
                    if (brand.id === action.payload.brandId) {
                        return { ...brand, favorite: true };
                    } else {
                        return { ...brand, favorite: false };
                    }
                });

                let brandWithUpdatedFavorite = false;
                updatedBrands.forEach(brand => {
                    if (brand.favorite) {
                        brandWithUpdatedFavorite = true;
                    }
                });

                if (!brandWithUpdatedFavorite && updatedBrands.length > 0) {
                    updatedBrands[0].favorite = true;
                }

                return { ...product, productBrands: updatedBrands };
            });

            return {
                ...state,
                ingredientProducts: updatedingredientProducts,
                activeCardId: action.payload.brandId

            };
        case SET_ACTIVE_CARD:

            console.log(action.payload.brandId)
            return {
                ...state,
                activeCardId: action.payload.brandId
            };
        case ADD_REMARKS_TO_REQUEST:
            updatedProducts = state.ingredientProducts.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        remarks: action.payload.remarks,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                newPODetails: updatedDetails,
                ingredientProducts: updatedProducts,
            };
        case ADD_INTERNAL_REMARKS_TO_PURCHASE:
            updatedProducts = state.ingredientProducts.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        internalRemark: action.payload.remarks,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(state.newPODetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                newPODetails: updatedDetails,
                ingredientProducts: updatedProducts,
            };
        case ADD_PRODUCT_BRAND:
            updatedProducts = state.ingredientProducts.map(product => {
                if (product.id === action.payload.productId && 'productBrands' in product) {
                    return {
                        ...product,
                        productBrands: [...product.productBrands, action.payload.newBrand],
                    };
                }
                return product;
            });
            return {
                ...state,
                ingredientProducts: updatedProducts,
            };
        case UPDATE_PRODUCT_REASON:
            return {
                ...state,
                productionProduct: state.productionProduct.map((product) =>
                    product.id === action.payload.productId
                        ? { ...product, reason: action.payload.reason }
                        : product
                ),
            };
        case SET_SELECTED_REASON:
            return { ...state, selectedReason: action.payload.reason };
        default:
            return state;
    }
};
export default ProductionReducer;