import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({

smallbutton: { width: '103px !important', height: '33px !important', fontSize: '16px !important', },

tasksleftbutton: {display: 'flex !important', gap: '10px !important', justifyContent: 'center !important', alignItems: 'center !important', borderRadius: "20px !important", border: "2px solid red !important", paddingTop: "5px !important", paddingBottom: "5px !important", paddingRight: '15px !important', paddingLeft: '15px !important', },

widebox: {backgroundColor: theme.palette.card.main,  padding: "7px", paddingRight: "20px", paddingLeft: "20px", height: '50px' , boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12),0px 0px 0px 1px rgba(0, 0, 0, 0.05)'},

menuButton: { width: '80px', height: '90px', borderRadius: "18px !important", display: "flex", flexDirection: "column", boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.4)' },

menuIcons: { width: "50px", height: "50px" },
 
internalRequestPagesButtons: {display: 'flex',justifyContent: 'center',gap: '50px',},

})
);

export default useStyles;