import { NewPurchaseType } from "../../types";
import { Dispatch, Action } from 'redux';

export const INCREMENT_BOX_ORDER_QUANTITY = 'INCREMENT_BOX_ORDER_QUANTITY'
export const DECREMENT_BOX_ORDER_QUANTITY = 'DECREMENT_BOX_ORDER_QUANTITY'
export const ADJUST_BOX_ORDER_QUANTITY = 'ADJUST_BOX_ORDER_QUANTITY'
export const INCREMENT_CAN_ORDER_QUANTITY = 'INCREMENT_CAN_ORDER_QUANTITY'
export const DECREMENT_CAN_ORDER_QUANTITY = 'DECREMENT_CAN_ORDER_QUANTITY'
export const ADJUST_CAN_ORDER_QUANTITY = 'ADJUST_CAN_ORDER_QUANTITY'
export const INCREMENT_ORDER_BASE_UNIT_QUANTITY = 'INCREMENT_ORDER_BASE_UNIT_QUANTITY'
export const DECREMENT_ORDER_BASE_UNIT_QUANTITY = 'DECREMENT_ORDER_BASE_UNIT_QUANTITY'
export const UPDATE_ORDER_BASE_UNIT_QUANTITY = 'UPDATE_ORDER_BASE_UNIT_QUANTITY'
export const REMOVE_ORDER_FROM_PURCHASE = 'REMOVE_ORDER_FROM_PURCHASE'
export const UPDATE_UPRICE_ON_HAND = 'UPDATE_UPRICE_ON_HAND'
export const UPDATE_UPRICE_REQ = 'UPDATE_UPRICE_REQ'
export const SET_TOTAL_QTY_ON_HAND_PRICE = 'SET_TOTAL_QTY_ON_HAND_PRICE'
export const SET_TOTAL_QTY_REQUESTED_PRICE = 'SET_TOTAL_QTY_REQUESTED_PRICE'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const SET_DISCOUNT_AED = 'SET_DISCOUNT_AED'
export const SET_DISCOUNT_PERCENTAGE = 'SET_DISCOUNT_PERCENTAGE';
export const SET_FINAL_PRICE = 'SET_FINAL_PRICE'
export const CHANGE_ORDER_WEIGHT_TYPE = 'CHANGE_ORDER_WEIGHT_TYPE';
export const ADD_ORDER_TO_DETAILS = 'ADD_ORDER_TO_DETAILS';
export const REMOVE_BRAND_FROM_ORDER = 'REMOVE_BRAND_FROM_ORDER'
export const UPDATE_BASE_UNIT_PRICE = 'UPDATE_BASE_UNIT_PRICE';
export const SET_TOTAL_BASE_UNIT_PRICE = 'SET_TOTAL_BASE_UNIT_PRICE';
export const TOGGLE_BRAND_FAVORITE = 'TOGGLE_BRAND_FAVORITE';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD';
export const SET_BRAND_DISCOUNT_AED = 'SET_BRAND_DISCOUNT_AED';
export const SET_BRAND_DISCOUNT_PERCENTAGE = 'SET_BRAND_DISCOUNT_PERCENTAGE';
export const ADD_REMARK_TO_PURCHASE = 'ADD_REMARK_TO_PURCHASE';
export interface RootState { PurchasedProducts: NewPurchaseType[]; newPODetails: NewPurchaseType[]; cansPerBox: number; }

interface IncrementBoxOrderQuantityAction extends Action<typeof INCREMENT_BOX_ORDER_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}
interface DecrementBoxOrderQuantityAction extends Action<typeof DECREMENT_BOX_ORDER_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}
interface IncrementCanOrderQuantityAction extends Action<typeof INCREMENT_CAN_ORDER_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}
interface DecrementCanOrderQuantityAction extends Action<typeof DECREMENT_CAN_ORDER_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}
interface AdjustBoxOrderQuantityAction extends Action<typeof ADJUST_BOX_ORDER_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; newQuantity: number; };
}
interface AdjustCanOrderQuantityAction extends Action<typeof ADJUST_CAN_ORDER_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; newQuantity: number; };
}

interface IncrementOrderBaseUnitQuantityAction extends Action<typeof INCREMENT_ORDER_BASE_UNIT_QUANTITY> {
    payload: { productId: string; baseUnitIndex: number; }
}

interface DecrementOrderBaseUnitQuantityAction extends Action<typeof DECREMENT_ORDER_BASE_UNIT_QUANTITY> {
    payload: { productId: string; baseUnitIndex: number; }
}

interface UpdateOrderBaseUnitQuantityAction extends Action<typeof UPDATE_ORDER_BASE_UNIT_QUANTITY> {
    payload: { productId: string; baseUnitId: string; newCount: number; }
}

interface ChangeOrderWeightTypeAction extends Action<typeof CHANGE_ORDER_WEIGHT_TYPE> {
    payload: { productId: string; baseUnitId: string; weightType: string };
}

interface AddOrderToDetailsAction extends Action<typeof ADD_ORDER_TO_DETAILS> {
    payload: { productId: string };
}

interface RemoveBrandFromOrderAction extends Action<typeof REMOVE_BRAND_FROM_ORDER> {
    payload: { brandID: string; }
}

interface RemoveOrderFromPurchaseAction extends Action<typeof REMOVE_ORDER_FROM_PURCHASE> { payload: { productId: string; } }
interface UpdateUpriceOnHandAction extends Action<typeof UPDATE_UPRICE_ON_HAND> { payload: { productIndex: number; newValue: string; }; }
interface UpdateUpriceReqAction extends Action<typeof UPDATE_UPRICE_REQ> { payload: { productReqIndex: number; newReqValue: string; }; }
interface SetTotalQtyOnHandPriceAction { type: typeof SET_TOTAL_QTY_ON_HAND_PRICE; payload: number | string; }
interface SetTotalQtyRequestedPriceAction { type: typeof SET_TOTAL_QTY_REQUESTED_PRICE; payload: number | string; }
interface SetTotalPriceAction { type: typeof SET_TOTAL_PRICE; payload: number | string; }
interface SetDiscountAEDAction { type: typeof SET_DISCOUNT_AED; payload: { discountAEDproductId: number; discountAED: string; } }
interface SetDiscountPercAction { type: typeof SET_DISCOUNT_PERCENTAGE; payload: { discountAPercproductId: number, discountPerc: string } }
interface SetFinalPriceAction { type: typeof SET_FINAL_PRICE; payload: number | string; }
interface UpdateBaseUnitPrice { type: typeof UPDATE_BASE_UNIT_PRICE; payload: { productInd: number, newUnitPrice: number } }
interface SetTotalBaseUnitPriceAction { type: typeof SET_TOTAL_BASE_UNIT_PRICE; payload: number | string; }
interface toggleBrandFavorite { type: typeof TOGGLE_BRAND_FAVORITE; payload: { brandId: string } }
interface SetActiveCardId { type: typeof SET_ACTIVE_CARD; payload: { brandId: string } }
interface SetBrandDiscountAEDAction { type: typeof SET_BRAND_DISCOUNT_AED; payload: { discountAEDbrandId: number; bdiscountAED: string; } }
interface SetBrandDiscountPercAction { type: typeof SET_BRAND_DISCOUNT_PERCENTAGE; payload: { discountPercbrandId: number, bdiscountPerc: string } }
interface AddRemarksToRequestAction extends Action<typeof ADD_REMARK_TO_PURCHASE> {
    payload: { productId: string; remarks: string; }
}

export type ProductActionTypes = | IncrementBoxOrderQuantityAction | DecrementBoxOrderQuantityAction | AdjustBoxOrderQuantityAction
    | IncrementCanOrderQuantityAction | DecrementCanOrderQuantityAction | AdjustCanOrderQuantityAction |
    IncrementOrderBaseUnitQuantityAction | DecrementOrderBaseUnitQuantityAction | UpdateOrderBaseUnitQuantityAction |
    ChangeOrderWeightTypeAction | AddOrderToDetailsAction | RemoveBrandFromOrderAction |
    RemoveOrderFromPurchaseAction | UpdateUpriceOnHandAction | UpdateUpriceReqAction
    | SetTotalQtyOnHandPriceAction | SetTotalQtyRequestedPriceAction | SetTotalPriceAction
    | SetDiscountAEDAction | SetDiscountPercAction | SetBrandDiscountAEDAction | SetBrandDiscountPercAction
    | SetFinalPriceAction | UpdateBaseUnitPrice | SetTotalBaseUnitPriceAction | toggleBrandFavorite | SetActiveCardId | AddRemarksToRequestAction

const incrementBoxOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): IncrementBoxOrderQuantityAction => ({ type: INCREMENT_BOX_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const decrementBoxOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): DecrementBoxOrderQuantityAction => ({ type: DECREMENT_BOX_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const adjustBoxOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number): AdjustBoxOrderQuantityAction => ({ type: ADJUST_BOX_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex, newQuantity } });

const incrementCanOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): IncrementCanOrderQuantityAction => ({ type: INCREMENT_CAN_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const decrementCanOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): DecrementCanOrderQuantityAction => ({ type: DECREMENT_CAN_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const adjustCanOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number): AdjustCanOrderQuantityAction => ({ type: ADJUST_CAN_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex, newQuantity } });

const incrementBaseUnitOrdQ = (productId: string, baseUnitIndex: number,): IncrementOrderBaseUnitQuantityAction => ({ type: INCREMENT_ORDER_BASE_UNIT_QUANTITY, payload: { productId, baseUnitIndex, } });

const decrementBaseUnitOrdQ = (productId: string, baseUnitIndex: number,): DecrementOrderBaseUnitQuantityAction => ({ type: DECREMENT_ORDER_BASE_UNIT_QUANTITY, payload: { productId, baseUnitIndex, } });

const updatetBaseUnitOrdQ = (productId: string, baseUnitId: string, newCount: number): UpdateOrderBaseUnitQuantityAction => ({ type: UPDATE_ORDER_BASE_UNIT_QUANTITY, payload: { productId, baseUnitId, newCount } });

const changeOrdWeightType = (productId: string, baseUnitId: string, weightType: string): ChangeOrderWeightTypeAction => ({ type: CHANGE_ORDER_WEIGHT_TYPE, payload: { productId, baseUnitId, weightType }, });

const removeBrandFromOrd = (brandID: string): RemoveBrandFromOrderAction => ({ type: REMOVE_BRAND_FROM_ORDER, payload: { brandID } });

const RemoveProd = (productId: string): RemoveOrderFromPurchaseAction => ({ type: REMOVE_ORDER_FROM_PURCHASE, payload: { productId } });
const updateUpriceOnHand = (productIndex: number, newValue: string): UpdateUpriceOnHandAction => ({ type: UPDATE_UPRICE_ON_HAND, payload: { productIndex, newValue }, });
const updateUpriceReq = (productReqIndex: number, newReqValue: string,): UpdateUpriceReqAction => ({ type: UPDATE_UPRICE_REQ, payload: { productReqIndex, newReqValue }, });
const setTotalQtyOnHandPrice = (price: number | string): SetTotalQtyOnHandPriceAction => ({ type: SET_TOTAL_QTY_ON_HAND_PRICE, payload: price, });
const setTotalQtyRequestedPrice = (price: number | string): SetTotalQtyRequestedPriceAction => ({ type: SET_TOTAL_QTY_REQUESTED_PRICE, payload: price, });
const setTotalPrice = (price: number | string): SetTotalPriceAction => ({ type: SET_TOTAL_PRICE, payload: price, });
const setDiscountAED = (discountAEDproductId: number, discountAED: string): SetDiscountAEDAction => ({ type: SET_DISCOUNT_AED, payload: { discountAEDproductId, discountAED } });
const setDiscountPercentage = (discountAPercproductId: number, discountPerc: string): SetDiscountPercAction => ({ type: SET_DISCOUNT_PERCENTAGE, payload: { discountAPercproductId, discountPerc } });
const setFinalPrice = (finalPrice: number | string): SetFinalPriceAction => ({ type: SET_FINAL_PRICE, payload: finalPrice, });
const addOrdToDet = (productId: string): AddOrderToDetailsAction => ({ type: ADD_ORDER_TO_DETAILS, payload: { productId }, });
const updateBaseUnitPrice = (productInd: number, newUnitPrice: number): UpdateBaseUnitPrice => ({ type: UPDATE_BASE_UNIT_PRICE, payload: { productInd, newUnitPrice } })
const SetTotalBaseUnitPriceAction = (price: number | string): SetTotalBaseUnitPriceAction => ({ type: SET_TOTAL_BASE_UNIT_PRICE, payload: price, });
const toggleBrandFavorite = (brandId: string): toggleBrandFavorite => ({ type: TOGGLE_BRAND_FAVORITE, payload: { brandId } });
const SetActiveCardId = (brandId: string): SetActiveCardId => ({ type: SET_ACTIVE_CARD, payload: { brandId } });
const setBrandDiscountAED = (discountAEDbrandId: number, bdiscountAED: string): SetBrandDiscountAEDAction => ({ type: SET_BRAND_DISCOUNT_AED, payload: { discountAEDbrandId, bdiscountAED } });
const setBrandDiscountPercentage = (discountPercbrandId: number, bdiscountPerc: string): SetBrandDiscountPercAction => ({ type: SET_BRAND_DISCOUNT_PERCENTAGE, payload: { discountPercbrandId, bdiscountPerc } });
const addRemarksReq = (productId: string, remarks: string): AddRemarksToRequestAction => ({ type: ADD_REMARK_TO_PURCHASE, payload: { productId, remarks } });
export const NewPurchaseActions = {
    //Box
    incrementBoxOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBoxOrdQ(productId, brandIndex, qtyIndex)); },
    decrementBoxOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBoxOrdQ(productId, brandIndex, qtyIndex)); },
    adjustBoxOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustBoxOrdQ(productId, brandIndex, qtyIndex, newQuantity)); },
    //Can
    incrementCanOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementCanOrdQ(productId, brandIndex, qtyIndex)); },
    decrementCanOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementCanOrdQ(productId, brandIndex, qtyIndex)); },
    adjustCanOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustCanOrdQ(productId, brandIndex, qtyIndex, newQuantity)); },
    //Base Unit
    incrementBaseUnitOrderQty: (productId: string, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBaseUnitOrdQ(productId, baseUnitIndex,)); },
    decrementBaseUnitOrderQty: (productId: string, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBaseUnitOrdQ(productId, baseUnitIndex,)); },
    updateBaseUnitOrderQty: (productId: string, baseUnitId: string, newCount: number) => (dispatch: Dispatch<any>) => { dispatch(updatetBaseUnitOrdQ(productId, baseUnitId, newCount)); },
    convertOrderWeight: (productId: string, baseUnitId: string, weightType: string) => (dispatch: Dispatch<any>) => { dispatch(changeOrdWeightType(productId, baseUnitId, weightType)); },
    //Add Order To details
    addOrderDetails: (productId: string) => (dispatch: Dispatch<any>) => { dispatch(addOrdToDet(productId)); },
    removeBrandOrder: (brandID: string) => (dispatch: Dispatch<any>) => { dispatch(removeBrandFromOrd(brandID)); },
    //Other
    removeProduct: (productId: string) => (dispatch: Dispatch<any>) => { dispatch(RemoveProd(productId)); },
    updateUpriceHand: (productIndex: number, newValue: string) => (dispatch: Dispatch<any>) => { dispatch(updateUpriceOnHand(productIndex, newValue)); },
    updateUpriceReq: (productReqIndex: number, newReqValue: string,) => (dispatch: Dispatch<any>) => { dispatch(updateUpriceReq(productReqIndex, newReqValue)); },
    setTotalQtyOnHandPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalQtyOnHandPrice(price)); },
    setTotalQtyRequestedPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalQtyRequestedPrice(price)); },
    SetTotalBaseUnitPriceAction: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(SetTotalBaseUnitPriceAction(price)); },
    setTotalPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalPrice(price)); },
    setDiscountAED: (discountAEDproductId: number, discountAED: string) => (dispatch: Dispatch<any>) => { dispatch(setDiscountAED(discountAEDproductId, discountAED)); },
    setDiscountPercentage: (discountAPercproductId: number, discountPerc: string) => (dispatch: Dispatch<any>) => { dispatch(setDiscountPercentage(discountAPercproductId, discountPerc)); },
    setFinalPrice: (finalPrice: number | string) => (dispatch: Dispatch<any>) => { dispatch(setFinalPrice(finalPrice)); },
    updateBaseUnitPrice: (productInd: number, newUnitPrice: number) => (dispatch: Dispatch<any>) => { dispatch(updateBaseUnitPrice(productInd, newUnitPrice)); },
    toggleBrandFavorite: (brandId: string) => (dispatch: Dispatch<any>) => { dispatch(toggleBrandFavorite(brandId)); },
    SetActiveCardId: (brandId: string) => (dispatch: Dispatch<any>) => { dispatch(SetActiveCardId(brandId)); },
    setBrandDiscountAED: (discountAEDbrandId: number, bdiscountAED: string) => (dispatch: Dispatch<any>) => { dispatch(setBrandDiscountAED(discountAEDbrandId, bdiscountAED)); },
    setBrandDiscountPercentage: (discountPercbrandId: number, bdiscountPerc: string) => (dispatch: Dispatch<any>) => { dispatch(setBrandDiscountPercentage(discountPercbrandId, bdiscountPerc)); },
    //add remark 
    addRemarkstoRequest: (productId: string, remarks: string) => (dispatch: Dispatch<any>) => { dispatch(addRemarksReq(productId, remarks)); },
};