import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({

langbutton: { fontSize: '16px !important', },

menuBold: {fontWeight: 'bold','&:hover': {opacity: '70%'}, fontSize:"14.5px" , lineHeight:"1"},

listButton:{ textAlign: 'center', fontWeight: 'bold','&:hover': {opacity: '70%'}},

submenu: { maxHeight: 0,overflow: 'hidden',transition: 'max-height 0.5s ease',},

submenuVisible: {maxHeight: 1000,},

noBullet:{listStyleType: 'none',},


submenuOpened:{  maxHeight: '500px',  opacity: '1', transition: 'max-height 0.3s ease, opacity 0.3s ease'},

submenuClosed:{ maxHeight: '0',  opacity: '0', overflow: 'hidden', transition: 'max-height 0.3s ease, opacity 0.3s ease'},


})
);

export default useStyles;