import {
    INCREMENT_OUR_QTY, DECREMENT_OUR_QTY, ADJUST_OUR_QTY,
    INCREMENT_MULTI_INCOMING_REQUEST, DECREMENT_MULTI_INCOMING_REQUEST, ADJUST_MULTI_INCOMING_REQUEST,
    INCREMENT_INCOMING_BASE_UNIT, DECREMENT_INCOMING_BASE_UNIT, ADJUST_INCOMING_BASE_UNIT, CHANGE_INCOMING_WEIGHT_TYPE,
    LOAD_CURRENT_INCOMING_ITEM, SET_REQUEST, SET_MULTIBRAND_REQUEST, SET_ALL_STATUSES, SET_ALL_MULTIBRAND_STATUSES, SET_SEND_ORDER, SET_REQUEST_STATUS,
    IncomingRequeststActionTypes, IncomingRequestsTypes, IncomingRequest, IncomingMultiBrandRequest, AppState, MultiBrandRequest, SingleProduct,
} from '../actions/incomingRequestAction'
import { IncomingRequestData } from '../../pages/requests/incoming-request-data';

const INITIAL_STATE: AppState = {
    incomingReq: IncomingRequestData as IncomingRequestsTypes,
    currentIncomingItem: {} as IncomingRequest | IncomingMultiBrandRequest,
}

const incrementCounterHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
    if ('multiBrand' in item) {
        return {
            ...item,
            multiBrand: item.multiBrand.map((multi) => {
                if ('id' in multi && multi.id === multiId && 'brands' in multi) {
                    return {
                        ...multi,
                        brands: multi.brands.map((brand) => {
                            if ('id' in brand && brand.id === brandId && 'qty' in brand) {
                                return {
                                    ...brand,
                                    qty: brand.qty.map((q) => {
                                        if ('id' in q && q.id === qtyId && typeof q.boxCount === 'number' && itemType === 'box') {
                                            return {
                                                ...q,
                                                boxCount: q.boxCount + 1,
                                            };
                                        } else if ('id' in q && q.id === qtyId && typeof q.cansCount === 'number' && itemType === 'can') {
                                            return {
                                                ...q,
                                                cansCount: q.cansCount + 1,
                                            };
                                        }
                                        return { q };
                                    }),
                                };
                            }
                            return brand;
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return item;
};

const incrementMultiCounter = (products: IncomingRequestsTypes, productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
    return products.map((product) => {
        if ('id' in product && product.id === productId) {
            return incrementCounterHelper(product, multiId, brandId, qtyId, itemType);
        }
        return product;
    });
};

const incrementMultiItemCounter = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
    if ('id' in product && product.id === productId) {
        return incrementCounterHelper(product, multiId, brandId, qtyId, itemType);
    }
    return product;
};

const decrementCounterHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
    if ('multiBrand' in item) {
        return {
            ...item,
            multiBrand: item.multiBrand.map((multi) => {
                if ('id' in multi && multi.id === multiId && 'brands' in multi) {
                    return {
                        ...multi,
                        brands: multi.brands.map((brand) => {
                            if ('id' in brand && brand.id === brandId && 'qty' in brand) {
                                return {
                                    ...brand,
                                    qty: brand.qty.map((q) => {
                                        if ('id' in q && q.id === qtyId && typeof q.boxCount === 'number' && q.boxCount > 0 && itemType === 'box') {
                                            return {
                                                ...q,
                                                boxCount: q.boxCount - 1,
                                            };
                                        } else if ('id' in q && q.id === qtyId && typeof q.cansCount === 'number' && q.cansCount > 0 && itemType === 'can') {
                                            return {
                                                ...q,
                                                cansCount: q.cansCount - 1,
                                            };
                                        }
                                        return q;
                                    }),
                                };
                            }
                            return brand;
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return item;
};

const decrementMultiCounter = (products: IncomingRequestsTypes, productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
    return products.map((product) => {
        if ('id' in product && product.id === productId) {
            return decrementCounterHelper(product, multiId, brandId, qtyId, itemType);
        }
        return product;
    });
};

const decrementMultiItemCounter = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, brandId: string | null, qtyId: string | null, itemType: 'box' | 'can') => {
    if ('id' in product && product.id === productId) {
        return decrementCounterHelper(product, multiId, brandId, qtyId, itemType);
    }
    return product;
};

const adjustCounterHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), multiId: string, brandId: string | null, qtyId: string | null, newCount: number, itemType: 'box' | 'can') => {
    if ('multiBrand' in item) {
        return {
            ...item,
            multiBrand: item.multiBrand.map((multi) => {
                if ('id' in multi && multi.id === multiId && 'brands' in multi) {
                    return {
                        ...multi,
                        brands: multi.brands.map((brand) => {
                            if ('id' in brand && brand.id === brandId && 'qty' in brand) {
                                return {
                                    ...brand,
                                    qty: brand.qty.map((q) => {
                                        if ('id' in q && q.id === qtyId && typeof q.boxCount === 'number' && itemType === 'box') {
                                            return {
                                                ...q,
                                                boxCount: newCount,
                                            };
                                        } else if ('id' in q && q.id === qtyId && typeof q.cansCount === 'number' && itemType === 'can') {
                                            return {
                                                ...q,
                                                cansCount: newCount,
                                            };
                                        }
                                        return q;
                                    }),
                                };
                            }
                            return brand;
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return item;
};

const adjustMultiCounter = (products: IncomingRequestsTypes, productId: string, multiId: string, brandId: string | null, qtyId: string | null, newCount: number, itemType: 'box' | 'can') => {
    return products.map((product) => {
        if ('id' in product && product.id === productId) {
            return adjustCounterHelper(product, multiId, brandId, qtyId, newCount, itemType,);
        }
        return product;
    });
};

const adjustMultiItemCounter = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, brandId: string | null, qtyId: string | null, newCount: number, itemType: 'box' | 'can') => {
    if ('id' in product && product.id === productId) {
        return adjustCounterHelper(product, multiId, brandId, qtyId, newCount, itemType,);
    }
    return product;
};

const incrementBaseUnitInputHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), multiIndex: number, baseUnitIndex: number) => {
    if ('multiBrand' in item && Array.isArray(item.multiBrand)) {
        return {
            ...item,
            multiBrand: item.multiBrand.map((multi, mIndex) => {
                if (multi && mIndex === multiIndex && 'baseUnit' in multi && Array.isArray(multi.baseUnit)) {
                    return {
                        ...multi,
                        baseUnit: multi.baseUnit.map((bU, bUIndex) => {
                            if (bU && bUIndex === baseUnitIndex && typeof bU.count === 'number') {
                                return {
                                    ...bU,
                                    count: bU.count + 1,
                                };
                            }
                            return bU;
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return item;
};

const incrementBaseUnitInput = (products: IncomingRequestsTypes, productId: string, multiIndex: number, baseUnitIndex: number) => {
    return products.map((product) => {
        if (product && product.id === productId) {
            return incrementBaseUnitInputHelper(product, multiIndex, baseUnitIndex);
        }
        return product;
    });
};

const incrementItemBaseUnitInput = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiIndex: number, baseUnitIndex: number) => {
    if ('id' in product && product.id === productId) {
        return incrementBaseUnitInputHelper(product, multiIndex, baseUnitIndex);
    }
    return product;
};

const decrementBaseUnitInputHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), multiIndex: number, baseUnitIndex: number) => {
    if ('multiBrand' in item && Array.isArray(item.multiBrand)) {
        return {
            ...item,
            multiBrand: item.multiBrand.map((multi, mIndex) => {
                if (multi && mIndex === multiIndex && 'baseUnit' in multi && Array.isArray(multi.baseUnit)) {
                    return {
                        ...multi,
                        baseUnit: multi.baseUnit.map((bU, bUIndex) => {
                            if (bU && bUIndex === baseUnitIndex && typeof bU.count === 'number' && bU.count > 0) {
                                return {
                                    ...bU,
                                    count: bU.count - 1,
                                };
                            }
                            return bU;
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return item;
};

const decrementBaseUnitInput = (products: IncomingRequestsTypes, productId: string, multiIndex: number, baseUnitIndex: number) => {
    return products.map((product) => {
        if (product && product.id === productId) {
            return decrementBaseUnitInputHelper(product, multiIndex, baseUnitIndex);
        }
        return product;
    });
};

const decrementItemBaseUnitInput = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiIndex: number, baseUnitIndex: number) => {
    if ('id' in product && product.id === productId) {
        return decrementBaseUnitInputHelper(product, multiIndex, baseUnitIndex);
    }
    return product;
};

const adjustBaseUnitInputHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), multiIndex: number, baseUnitIndex: number, newCount: number) => {
    if ('multiBrand' in item && Array.isArray(item.multiBrand)) {
        return {
            ...item,
            multiBrand: item.multiBrand.map((multi, mIndex) => {
                if (multi && mIndex === multiIndex && 'baseUnit' in multi && Array.isArray(multi.baseUnit)) {
                    return {
                        ...multi,
                        baseUnit: multi.baseUnit.map((bU, bUIndex) => {
                            if (bU && bUIndex === baseUnitIndex && typeof bU.count === 'number') {
                                return {
                                    ...bU,
                                    count: newCount,
                                };
                            }
                            return bU;
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return item;
};

const adjustBaseUnitInput = (products: IncomingRequestsTypes, productId: string, multiIndex: number, baseUnitIndex: number, newCount: number) => {
    return products.map((product) => {
        if (product && product.id === productId) {
            return adjustBaseUnitInputHelper(product, multiIndex, baseUnitIndex, newCount);
        }
        return product;
    });
};

const adjustItemBaseUnitInput = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiIndex: number, baseUnitIndex: number, newCount: number) => {
    if ('id' in product && product.id === productId) {
        return adjustBaseUnitInputHelper(product, multiIndex, baseUnitIndex, newCount);
    }
    return product;
};

const changeBaseUnitWeight = (products: IncomingRequestsTypes, productId: string, multiIndex: number, baseUnitIndex: number, newCount: number, weightType: string) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('multiBrand' in product) {
                return {
                    ...product,
                    multiBrand: product.multiBrand.map((multi, mIndex) => {
                        if (mIndex === multiIndex && 'baseUnit' in multi) {
                            return {
                                ...multi,
                                baseUnit: multi.baseUnit.map((bUnit, i) => {
                                    if (i === baseUnitIndex) {
                                        const convertedCount =
                                            weightType === 'Gr' ? newCount * 1000 : newCount / 1000;
                                        return {
                                            ...bUnit,
                                            weightType,
                                            count: convertedCount,
                                        };
                                    }
                                    return { ...bUnit };
                                }),
                            };
                        }
                        return multi;
                    }),
                };
            }
            return product;
        }
        return product;
    });
};

const changeItemBaseUnitWeight = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiIndex: number, baseUnitIndex: number, newCount: number, weightType: string,) => {
    if (product.id === productId && 'multiBrand' in product) {
        return {
            ...product,
            multiBrand: product.multiBrand.map((multi, mIndex) => {
                if (mIndex === multiIndex && 'baseUnit' in multi) {
                    return {
                        ...multi,
                        baseUnit: multi.baseUnit.map((bUnit, i) => {
                            if (i === baseUnitIndex) {
                                const convertedCount =
                                    weightType === 'Gr' ? newCount * 1000 : newCount / 1000;
                                return {
                                    ...bUnit,
                                    weightType,
                                    count: convertedCount,
                                };
                            }
                            return { ...bUnit };
                        }),
                    };
                }
                return multi;
            }),
        };
    }
    return product;
};

const incrementQtyHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), productId: string, multiId: string, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    if ('id' in item && item.id === productId && 'products' in item) {
        return {
            ...item,
            products: item.products.map((prod) => {
                if ('id' in prod && prod.id === multiId && qtyType in prod) {
                    return {
                        ...prod,
                        [qtyType]: (prod[qtyType] || 0) + 1,
                    };
                }
                return prod;
            }),
        };
    }
    return item;
};

const incrementQty = (products: IncomingRequestsTypes, productId: string, multiId: string, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    return products.map((product) => {
        return incrementQtyHelper(product, productId, multiId, qtyType);
    });
};

const incrementItemQty = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    return incrementQtyHelper(product, productId, multiId, qtyType);
};

const decrementQtyHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), productId: string, multiId: string, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    if ('id' in item && item.id === productId && 'products' in item) {
        return {
            ...item,
            products: item.products.map((prod) => {
                if ('id' in prod && prod.id === multiId && qtyType in prod && prod[qtyType] > 0) {
                    return {
                        ...prod,
                        [qtyType]: (prod[qtyType] || 0) - 1,
                    };
                }
                return prod;
            }),
        };
    }
    return item;
};

const decrementQty = (products: IncomingRequestsTypes, productId: string, multiId: string, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    return products.map((product) => {
        return decrementQtyHelper(product, productId, multiId, qtyType);
    });
};

const decrementItemQty = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    return decrementQtyHelper(product, productId, multiId, qtyType);
};

const adjustQtyHelper = (item: ((IncomingRequest | IncomingMultiBrandRequest) | IncomingRequestsTypes), productId: string, multiId: string, newCount: number, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    if ('id' in item && item.id === productId && 'products' in item) {
        return {
            ...item,
            products: item.products.map((prod) => {
                if ('id' in prod && prod.id === multiId && qtyType in prod) {
                    return {
                        ...prod,
                        [qtyType]: newCount,
                    };
                }
                return prod;
            }),
        };
    }
    return item;
};

const adjustQty = (products: IncomingRequestsTypes, productId: string, multiId: string, newCount: number, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH',) => {
    return products.map((product) => {
        return adjustQtyHelper(product, productId, multiId, newCount, qtyType);
    });
};

const adjustItemQty = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, newCount: number, qtyType: 'qtyApproved' | 'qtyRequested' | 'qtyOH' | 'ourQtyOH') => {
    return adjustQtyHelper(product, productId, multiId, newCount, qtyType);
};

const setStatus = (products: IncomingRequestsTypes, productId: string, multiId: string, setStatus: string) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('products' in product) {
                return {
                    ...product,
                    products: product.products.map((prod, i) => {
                        if ('id' in prod && prod.id === multiId && 'status' in prod) {
                            return {
                                ...prod,
                                status: setStatus
                            };
                        }
                        return prod;
                    }),
                };
            }
        }
        return product;
    });
};

const setItemStatus = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, setStatus: string) => {
    if (product.id === productId && 'products' in product) {
        return {
            ...product,
            products: product.products.map((prod) => {
                if ('id' in prod && prod.id === multiId && 'status' in prod) {
                    return {
                        ...prod,
                        status: setStatus
                    };
                }
                return prod;
            }),
        };
    }
    return product;
};

const setMultiBrandStatus = (products: IncomingRequestsTypes, productId: string, multiId: string, setStatus: string) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('multiBrand' in product) {
                return {
                    ...product,
                    multiBrand: product.multiBrand.map((prod) => {
                        if ('id' in prod && prod.id === multiId && 'status' in prod) {
                            return {
                                ...prod,
                                status: setStatus
                            };
                        }
                        return prod;
                    }),
                };
            }
        }
        return product;
    });
};

const setMultiBrandItemStatus = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string, multiId: string, setStatus: string) => {
    if (product.id === productId && 'multiBrand' in product) {
        return {
            ...product,
            multiBrand: product.multiBrand.map((prod) => {
                if ('id' in prod && prod.id === multiId && 'status' in prod) {
                    return {
                        ...prod,
                        status: setStatus
                    };
                }
                return prod;
            }),
        };
    }
    return product;
};

const setAllProductsStatuses = (state: IncomingRequestsTypes, productId: string, setStatus: string): IncomingRequestsTypes => {
    return state.map((product) => {
        if (product.id === productId) {
            return setAllItemProductsStatuses(product, setStatus);
        }
        return product;
    });
};

const setAllItemProductsStatuses = (product: IncomingRequest | IncomingMultiBrandRequest, setStatus: string): IncomingRequest | IncomingMultiBrandRequest => {
    if ('products' in product) {
        return {
            ...product,
            products: product.products.map((singleProduct) => {
                return {
                    ...singleProduct,
                    status: setStatus,
                }
            }),
        };
    }
    return product;
};

const setAllMultiBrandStatuses = (state: IncomingRequestsTypes, productId: string, setStatus: string): IncomingRequestsTypes => {
    return state.map((product) => {
        if (product.id === productId) {
            return setAllMultiBrandItemsStatuses(product, setStatus);
        }
        return product;
    });
};

const setAllMultiBrandItemsStatuses = (product: IncomingRequest | IncomingMultiBrandRequest, setStatus: string): IncomingRequest | IncomingMultiBrandRequest => {
    if ('multiBrand' in product) {
        return {
            ...product,
            multiBrand: product.multiBrand.map((multiBrandItem) => {
                return {
                    ...multiBrandItem,
                    status: setStatus,
                };
            }),
        };
    }
    return product;
};

const setRequestStatus = (products: IncomingRequestsTypes, productId: string, setStatus: string) => {
    return products.map((product) => {
        if (product.id === productId) {
            return {
                ...product,
                status: setStatus
            };
        }
        return product;
    });
};

// const resetBaseUnitCount = (product: IncomingRequest | IncomingMultiBrandRequest, productId: string) => {
//     if (product.id === productId && 'multiBrand' in product) {
//         const updatedMultiBrand = product.multiBrand.map((multi) => {
//             if ('baseUnit' in multi) {
//                 const updatedBaseUnit = multi.baseUnit.map((bUnit) => ({
//                     ...bUnit,
//                     count: 0,
//                 }));
//                 return {
//                     ...multi,
//                     baseUnit: updatedBaseUnit,
//                 };
//             }
//             return multi;
//         });
//         return {
//             ...product,
//             multiBrand: updatedMultiBrand,
//         };
//     }
//     return product;
// };

const IncomingRequestReducer = (state = INITIAL_STATE, action: IncomingRequeststActionTypes) => {
    let updatedProducts;
    let updatedItem;
    let BUtoZero;
    switch (action.type) {
        case INCREMENT_MULTI_INCOMING_REQUEST:
            updatedProducts = incrementMultiCounter(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.brandId, action.payload.qtyId, action.payload.itemType);
            updatedItem = incrementMultiItemCounter(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.brandId, action.payload.qtyId, action.payload.itemType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case DECREMENT_MULTI_INCOMING_REQUEST:
            updatedProducts = decrementMultiCounter(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.brandId, action.payload.qtyId, action.payload.itemType);
            updatedItem = decrementMultiItemCounter(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.brandId, action.payload.qtyId, action.payload.itemType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case ADJUST_MULTI_INCOMING_REQUEST:
            updatedProducts = adjustMultiCounter(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.brandId, action.payload.qtyId, action.payload.newQuantity, action.payload.itemType);
            updatedItem = adjustMultiItemCounter(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.brandId, action.payload.qtyId, action.payload.newQuantity, action.payload.itemType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case INCREMENT_INCOMING_BASE_UNIT:
            updatedProducts = incrementBaseUnitInput(state.incomingReq, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex);
            updatedItem = incrementItemBaseUnitInput(state.currentIncomingItem, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case DECREMENT_INCOMING_BASE_UNIT:
            updatedProducts = decrementBaseUnitInput(state.incomingReq, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex);
            updatedItem = decrementItemBaseUnitInput(state.currentIncomingItem, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case ADJUST_INCOMING_BASE_UNIT:
            updatedProducts = adjustBaseUnitInput(state.incomingReq, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex, action.payload.newCount);
            updatedItem = adjustItemBaseUnitInput(state.currentIncomingItem, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex, action.payload.newCount);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case INCREMENT_OUR_QTY:
            updatedProducts = incrementQty(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.qtyType);
            updatedItem = incrementItemQty(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.qtyType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case DECREMENT_OUR_QTY:
            updatedProducts = decrementQty(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.qtyType);
            updatedItem = decrementItemQty(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.qtyType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case ADJUST_OUR_QTY:
            updatedProducts = adjustQty(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.newQuantity, action.payload.qtyType);
            updatedItem = adjustItemQty(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.newQuantity, action.payload.qtyType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case CHANGE_INCOMING_WEIGHT_TYPE:
            updatedProducts = changeBaseUnitWeight(state.incomingReq, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex, action.payload.newQuantity, action.payload.weightType);
            updatedItem = changeItemBaseUnitWeight(state.currentIncomingItem, action.payload.productId, action.payload.productIndex, action.payload.baseUnitIndex, action.payload.newQuantity, action.payload.weightType);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case SET_REQUEST:
            updatedProducts = setStatus(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.status);
            updatedItem = setItemStatus(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.status);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case SET_MULTIBRAND_REQUEST:
            updatedProducts = setMultiBrandStatus(state.incomingReq, action.payload.productId, action.payload.multiId, action.payload.status);
            updatedItem = setMultiBrandItemStatus(state.currentIncomingItem, action.payload.productId, action.payload.multiId, action.payload.status);
            return {
                ...state,
                incomingReq: updatedProducts,
                currentIncomingItem: updatedItem,
            };

        case LOAD_CURRENT_INCOMING_ITEM:
            return {
                ...state,
                currentIncomingItem: action.payload.item
            };
        case SET_SEND_ORDER:
            updatedProducts = state.incomingReq.map((request) => {
                if (request.id === action.payload.productId) {
                    return {
                        ...request,
                        sendOrder: true,
                    };
                }
                return request;
            }
            );

            return {
                ...state,
                incomingReq: updatedProducts,
            };
        case SET_ALL_STATUSES:
            return {
                ...state,
                incomingReq: setAllProductsStatuses(state.incomingReq, action.payload.productId, action.payload.status),
                currentIncomingItem: setAllItemProductsStatuses(state.currentIncomingItem, action.payload.status)
            };
        case SET_ALL_MULTIBRAND_STATUSES:
            return {
                ...state,
                incomingReq: setAllMultiBrandStatuses(state.incomingReq, action.payload.productId, action.payload.status),
                currentIncomingItem: setAllMultiBrandItemsStatuses(state.currentIncomingItem, action.payload.status)
            };
        case SET_REQUEST_STATUS:
            return {
                ...state,
                incomingReq: setRequestStatus(state.incomingReq, action.payload.productId, action.payload.status),
            };

        default:
            return state;
    }
};

export default IncomingRequestReducer;