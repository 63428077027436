import { NewPurchaseType } from "../../types";
import { Dispatch, Action } from 'redux';
export const INCREMENT_ORDER_QUANTITY = 'INCREMENT_ORDER_QUANTITY'
export const DECREMENT_ORDER_QUANTITY = 'DECREMENT_ORDER_QUANTITY'
export const ADJUST_ORDER_QUANTITY = 'ADJUST_ORDER_QUANTITY';
export const REMOVE_ORDER_FROM_PURCHASE = 'REMOVE_ORDER_FROM_PURCHASE'
export const UPDATE_UPRICE_ON_HAND = 'UPDATE_UPRICE_ON_HAND';
export const UPDATE_UPRICE_REQ = 'UPDATE_UPRICE_REQ'
export const SET_TOTAL_QTY_ON_HAND_PRICE = 'SET_TOTAL_QTY_ON_HAND_PRICE';
export const SET_TOTAL_QTY_REQUESTED_PRICE = 'SET_TOTAL_QTY_REQUESTED_PRICE';
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';
export const SET_DISCOUNT_AED = 'SET_DISCOUNT_AED';
export const SET_DISCOUNT_PERCENTAGE = 'SET_DISCOUNT_PERCENTAGE';
export const SET_FINAL_PRICE = 'SET_FINAL_PRICE'

export interface RootState {
    PurchasedProducts: NewPurchaseType[];
    PurchasedProductsDetails: NewPurchaseType[];
    cansPerBox: number,
}
interface IncrementOrderQuantityAction extends Action<typeof INCREMENT_ORDER_QUANTITY> { payload: { productId: string; qtyIndex: number; } }
interface DecrementOrderQuantityAction extends Action<typeof DECREMENT_ORDER_QUANTITY> { payload: { productId: string; qtyIndex: number; } }
interface AdjustOrderQuantityAction extends Action<typeof ADJUST_ORDER_QUANTITY> { payload: { productId: string; qtyIndex: number; newQuantity: number; }; }
interface RemoveOrderFromPurchaseAction extends Action<typeof REMOVE_ORDER_FROM_PURCHASE> { payload: { productId: string; } }
interface UpdateUpriceOnHandAction extends Action<typeof UPDATE_UPRICE_ON_HAND> { payload: { productIndex: number; newValue: string; }; }
interface UpdateUpriceReqAction extends Action<typeof UPDATE_UPRICE_REQ> { payload: { productReqIndex: number; newReqValue: string; }; }
interface SetTotalQtyOnHandPriceAction { type: typeof SET_TOTAL_QTY_ON_HAND_PRICE; payload: number | string; }
interface SetTotalQtyRequestedPriceAction { type: typeof SET_TOTAL_QTY_REQUESTED_PRICE; payload: number | string; }
interface SetTotalPriceAction { type: typeof SET_TOTAL_PRICE; payload: number | string; }
interface SetDiscountAEDAction { type: typeof SET_DISCOUNT_AED; payload: { discountAEDproductId: number; discountAED: string; } }
interface SetDiscountPercAction { type: typeof SET_DISCOUNT_PERCENTAGE; payload: { discountAPercproductId: number, discountPerc: string } }
interface SetFinalPriceAction { type: typeof SET_FINAL_PRICE; payload: number | string; }

export type ProductActionTypes =
    | IncrementOrderQuantityAction | DecrementOrderQuantityAction | AdjustOrderQuantityAction | RemoveOrderFromPurchaseAction
    | UpdateUpriceOnHandAction | UpdateUpriceReqAction
    | SetTotalQtyOnHandPriceAction | SetTotalQtyRequestedPriceAction | SetTotalPriceAction
    | SetDiscountAEDAction | SetDiscountPercAction
    | SetFinalPriceAction

const incrementQuantity = (productId: string, qtyIndex: number): IncrementOrderQuantityAction => ({ type: INCREMENT_ORDER_QUANTITY, payload: { productId, qtyIndex } });
const DecrementQuantity = (productId: string, qtyIndex: number): DecrementOrderQuantityAction => ({ type: DECREMENT_ORDER_QUANTITY, payload: { productId, qtyIndex } });
const adjustQuantity = (productId: string, qtyIndex: number, newQuantity: number): AdjustOrderQuantityAction => ({ type: ADJUST_ORDER_QUANTITY, payload: { productId, qtyIndex, newQuantity, }, });
const RemoveProd = (productId: string): RemoveOrderFromPurchaseAction => ({ type: REMOVE_ORDER_FROM_PURCHASE, payload: { productId } });
const updateUpriceOnHand = (productIndex: number, newValue: string): UpdateUpriceOnHandAction => ({ type: UPDATE_UPRICE_ON_HAND, payload: { productIndex, newValue }, });
const updateUpriceReq = (productReqIndex: number, newReqValue: string,): UpdateUpriceReqAction => ({ type: UPDATE_UPRICE_REQ, payload: { productReqIndex, newReqValue }, });
const setTotalQtyOnHandPrice = (price: number | string): SetTotalQtyOnHandPriceAction => ({ type: SET_TOTAL_QTY_ON_HAND_PRICE, payload: price, });
const setTotalQtyRequestedPrice = (price: number | string): SetTotalQtyRequestedPriceAction => ({ type: SET_TOTAL_QTY_REQUESTED_PRICE, payload: price, });
const setTotalPrice = (price: number | string): SetTotalPriceAction => ({ type: SET_TOTAL_PRICE, payload: price, });
const setDiscountAED = (discountAEDproductId: number, discountAED: string): SetDiscountAEDAction => ({ type: SET_DISCOUNT_AED, payload: { discountAEDproductId, discountAED } });
const setDiscountPercentage = (discountAPercproductId: number, discountPerc: string): SetDiscountPercAction => ({ type: SET_DISCOUNT_PERCENTAGE, payload: { discountAPercproductId, discountPerc } });
const setFinalPrice = (finalPrice: number | string): SetFinalPriceAction => ({ type: SET_FINAL_PRICE, payload: finalPrice, });

export const PurchaseOrdertActions = {
    incrementQty: (productId: string, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementQuantity(productId, qtyIndex)); },
    decrementQty: (productId: string, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementQuantity(productId, qtyIndex)); },
    adjustQty: (productId: string, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustQuantity(productId, qtyIndex, newQuantity)); },
    removeProduct: (productId: string) => (dispatch: Dispatch<any>) => { dispatch(RemoveProd(productId)); },
    updateUpriceHand: (productIndex: number, newValue: string) => (dispatch: Dispatch<any>) => { dispatch(updateUpriceOnHand(productIndex, newValue)); },
    updateUpriceReq: (productReqIndex: number, newReqValue: string,) => (dispatch: Dispatch<any>) => { dispatch(updateUpriceReq(productReqIndex, newReqValue)); },
    setTotalQtyOnHandPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalQtyOnHandPrice(price)); },
    setTotalQtyRequestedPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalQtyRequestedPrice(price)); },
    setTotalPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalPrice(price)); },
    setDiscountAED: (discountAEDproductId: number, discountAED: string) => (dispatch: Dispatch<any>) => { dispatch(setDiscountAED(discountAEDproductId, discountAED)); },
    setDiscountPercentage: (discountAPercproductId: number, discountPerc: string) => (dispatch: Dispatch<any>) => { dispatch(setDiscountPercentage(discountAPercproductId, discountPerc)); },
    setFinalPrice: (finalPrice: number | string) => (dispatch: Dispatch<any>) => { dispatch(setFinalPrice(finalPrice)); }
};