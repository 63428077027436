import cannedfood from '../../../../assets/images/cannedfood.svg'
import { IngredientTypes, NewPurchaseType, } from '../../../../types';
const Ingredients: NewPurchaseType[] = [
    {
        id: "A3323",
        refferenceNumber: 7884,
        supplierName: " Lucas Schultz",
        type: 'Poultry & Eggs',
        productname: 'Garlic',
        price: 300,
        weight: 700,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "potato",
        itemimage: cannedfood,
        itemweight: '30',
        expirydate: 'none',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'a121', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'a2456', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '66-ps-corn-3',
                brandname: 'Plein Soleil',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.10",
                can: "0.1",
                canAvg: "0.20",
                deliveryDuration: "2",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],

                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '1-ps-corn-3',
                brandname: 'Maxims',
                supplierName: "Antony Antonny",
                gram: "0.50",
                gramAvg: "0.70",
                can: "0.5",
                canAvg: "0.70",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '126-ps-corn-3',
                brandname: 'Heinz',
                supplierName: "Lucas Lucas",
                gram: "0.20",
                gramAvg: "0.20",
                can: "0.2",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "B699",
        refferenceNumber: 1234,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'Chickpeas',
        price: 300,
        weight: 600,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b14545', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b12122', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '7-ps-humus-7',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '4-ps-humus-3',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "B7799",
        refferenceNumber: 1234,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'peper',
        price: 300,
        weight: 40,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b14545', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b12122', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '44-ps-humus-6',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '66-ps-humus-6',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "B1299",
        refferenceNumber: 1234,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'Salt',
        price: 300,
        weight: 2,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b14545', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b12122', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '99-ps-humus-9',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '47-ps-humus-38',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "g55699",
        refferenceNumber: 1234,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'Tehine',
        price: 300,
        weight: 70,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b14545', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b12122', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '765-ps-humus-75',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '455-ps-humus-35',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "v0979",
        refferenceNumber: 1234,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'Aubergine',
        price: 300,
        weight: 3,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b14545', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b12122', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '70-ps-humus-700',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '411-ps-humus-322',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 4,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
]

export default Ingredients;