import {
    ProductActionTypes,
    INCREMENT_ORDER_QUANTITY,
    DECREMENT_ORDER_QUANTITY,
    ADJUST_ORDER_QUANTITY,
    REMOVE_ORDER_FROM_PURCHASE,
    UPDATE_UPRICE_ON_HAND, UPDATE_UPRICE_REQ,
    SET_TOTAL_QTY_ON_HAND_PRICE,
    SET_TOTAL_QTY_REQUESTED_PRICE,
    SET_TOTAL_PRICE,
    SET_DISCOUNT_AED, SET_DISCOUNT_PERCENTAGE,
    SET_FINAL_PRICE,

} from "../actions/purchaseOrderActions";
import { RootState } from "../actions/purchaseOrderActions";
import { NewPurchaseType } from "../../types";
import { PurchasedProductsData } from "../../pages/purchase/purchase/purchase-product-data";
const INITIAL_STATE: RootState = {
    PurchasedProducts: PurchasedProductsData as NewPurchaseType[],
    PurchasedProductsDetails: [] as NewPurchaseType[],
    cansPerBox: 24,

};

const updatePurchasedProductQty = (
    products: NewPurchaseType[],
    productId: string,
    qtyIndex: number,

) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ("quantity" in product) {
                product.quantity = product.quantity?.map((qty, i) => {
                    if (i === qtyIndex) {
                        let count = qty.count;
                        count += 1;

                        return { ...qty, count };


                    }
                    return { ...qty };
                });
            }
        }
        return product;
    });
};

const decrementPurchasedProductQty = (
    products: NewPurchaseType[],
    productId: string,
    qtyIndex: number
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ("quantity" in product) {
                product.quantity = product.quantity?.map((qty, qIx) => {
                    if (
                        qIx === qtyIndex &&
                        typeof qty.count === "number" &&
                        qty.count > 0
                    ) {
                        return {
                            ...qty,
                            count: qty.count - 1,

                        };
                    }
                    return { ...qty };
                });
            }
        }
        return product;
    });
};

const AddToPurchaseOrderDetails = (
    details: any,
    updatedProducts: NewPurchaseType[],
    productId: string
) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(
            existingProduct,
            updatedProducts.find((product) => product.id === productId)
        );
    } else {
        const productToAdd = updatedProducts.find(
            (product) => product.id === productId
        );
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateProductDetailsQty = (
    products: NewPurchaseType[],
    productId: string,
    qtyIndex: number,
    newQuantity: number,
    details: any[]
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ("quantity" in product) {
                product.quantity = product.quantity?.map((qty, j) => {
                    if (j === qtyIndex) {
                        return { ...qty, count: newQuantity };
                    }
                    return { ...qty };
                });


            }
        }
        return product;
    });
};

const PurchaseOrderReducer = (state = INITIAL_STATE, action: ProductActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    switch (action.type) {

        case UPDATE_UPRICE_ON_HAND:
            const { productIndex, newValue } = action.payload;
            let convertUpriceProducts = state.PurchasedProducts.map((product, index) => {
                if (index === productIndex) {
                    return {
                        ...product,
                        upriceonhand: parseFloat(newValue),
                        upricereq: parseFloat(newValue) / state.cansPerBox

                    };
                }
                return product;
            });
            console.log(`After update:`, convertUpriceProducts);
            return {
                ...state,
                PurchasedProducts: convertUpriceProducts,
                PurchasedProductsDetails: convertUpriceProducts
            }
        case UPDATE_UPRICE_REQ:
            const { productReqIndex, newReqValue } = action.payload;
            let convertUpriceReqProducts = state.PurchasedProducts.map((product, index) => {
                if (index === productReqIndex) {
                    return {
                        ...product,
                        upricereq: parseFloat(newReqValue),
                        upriceonhand: parseFloat(newReqValue) * state.cansPerBox,
                    };
                }


                return product;
            });
            return {
                ...state,
                PurchasedProducts: convertUpriceReqProducts,
                PurchasedProductsDetails: convertUpriceReqProducts
            }

        case INCREMENT_ORDER_QUANTITY:
            updatedProducts = updatePurchasedProductQty(
                state.PurchasedProducts,
                action.payload.productId,
                action.payload.qtyIndex
            );
            updatedDetails = AddToPurchaseOrderDetails(
                state.PurchasedProductsDetails,
                updatedProducts,
                action.payload.productId
            );


            return {
                ...state,
                PurchasedProducts: updatedProducts,
                PurchasedProductsDetails: updatedDetails,
            };

        case DECREMENT_ORDER_QUANTITY:
            updatedProducts = decrementPurchasedProductQty(
                state.PurchasedProducts,
                action.payload.productId,
                action.payload.qtyIndex
            );

            updatedDetails = AddToPurchaseOrderDetails(
                state.PurchasedProductsDetails,
                updatedProducts,
                action.payload.productId
            );

            return {
                ...state,
                PurchasedProducts: updatedProducts,
                PurchasedProductsDetails: updatedDetails,

            };

        case ADJUST_ORDER_QUANTITY:
            const { productId, qtyIndex, newQuantity } = action.payload;
            updatedProducts = updateProductDetailsQty(
                state.PurchasedProducts,
                productId,
                qtyIndex,
                newQuantity,
                state.PurchasedProductsDetails
            );

            updatedDetails = AddToPurchaseOrderDetails(
                state.PurchasedProductsDetails,
                updatedProducts,
                productId,
            );

            return {
                ...state,
                PurchasedProducts: updatedProducts,
                PurchasedProductsDetails: updatedDetails,

            };

        case REMOVE_ORDER_FROM_PURCHASE:
            updatedDetails = state.PurchasedProductsDetails.filter((item) => item.id !== action.payload.productId);
            const itemToRemove = state.PurchasedProductsDetails.find((item) => item.id === action.payload.productId);
            if (itemToRemove) {
                if ('quantity' in itemToRemove) {
                    itemToRemove.quantity.forEach((q) => {
                        q.count = 0;
                    });
                }
            }
            return {
                ...state,
                PurchasedProductsDetails: updatedDetails,
            };

        case SET_TOTAL_QTY_ON_HAND_PRICE: return { ...state, totalQtyOnHandPrice: action.payload, };
        case SET_TOTAL_QTY_REQUESTED_PRICE: return { ...state, totalQtyRequestedPrice: action.payload, };
        case SET_TOTAL_PRICE: return { ...state, totalPrice: action.payload, };

        case SET_DISCOUNT_AED:
            const { discountAEDproductId, discountAED } = action.payload;
            let DiscountAED = state.PurchasedProducts.map((product, index) => {
                if (index === discountAEDproductId) {
                    return {
                        ...product,
                        discountAED: parseFloat(discountAED),
                        discountPercentage: (((parseFloat(discountAED)) / product.totalPrice) * 100).toFixed(2)

                    };
                }
                return product;
            });
            console.log(`discountAED:`, discountAED);
            return {
                ...state,
                PurchasedProducts: DiscountAED,
                PurchasedProductsDetails: DiscountAED
            };
        case SET_DISCOUNT_PERCENTAGE:
            const { discountAPercproductId, discountPerc } = action.payload;
            let DiscountPercentage = state.PurchasedProducts.map((product, index) => {
                if (index === discountAPercproductId) {
                    return {
                        ...product,
                        discountPercentage: parseFloat(discountPerc),
                        discountAED: ((parseFloat(discountPerc) / 100) * product.totalPrice).toFixed(2)

                    };
                }
                return product;
            });
            console.log(`After update:`, DiscountPercentage);
            return {
                ...state,
                PurchasedProducts: DiscountPercentage,
                PurchasedProductsDetails: DiscountPercentage
            }
        case SET_FINAL_PRICE: return { ...state, finalPrice: action.payload, };

        default:
            return state;
    }
};
export default PurchaseOrderReducer;
