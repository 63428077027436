import {
    appState, MenuDrawerActionTypes, OPEN_SIDEBAR, CLOSE_SIDEBAR, SET_OPEN
} from '../actions/menuDrawerActions'

const INITIAL_STATE: appState = {
    open: false
}

const menuDrawerReducer = (state: appState = INITIAL_STATE, action: MenuDrawerActionTypes) => {
    switch (action.type) {
        case OPEN_SIDEBAR:
            return { ...state, open: true };
        case CLOSE_SIDEBAR:
            return { ...state, open: false };
        default:
            return state;
    }
}

export default menuDrawerReducer;