import React, { useState } from 'react'
import { Dialog, Box, styled, FormControl, InputBase, NativeSelect, Button } from '@mui/material'
import { connect } from 'react-redux'
import { actions } from '../../redux/actions/Translation.action';
import TranslateIcon from '@mui/icons-material/Translate';
import useStyles from '../../theme/styles';

const ChooseLanguage = ({ isOpen, onClose, dictionary, language, getField, supportedLanguages, setLanguage,   loading }: {
    isOpen: boolean,
    onClose: () => void,
    dictionary: any,
    language: string, 
    getField: any, 
    supportedLanguages: string[], 
    setLanguage: (data: any) => void, 
    loading: boolean,
   
    
  }) => {

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            fontSize: 16,
            fontWeight: 'bold',
            padding: '6px 6px 6px 6px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            color: theme.palette.primary.main,
        },
    }))
    const [selectedLanguage, setSelectedLanguage] = useState(language)
    const handleContinue = () => {
        setLanguage(selectedLanguage);
        onClose();
    }

    const handleCancel = () => {
        onClose();
    }
    
    const classes = useStyles()
    
    return (
        <Dialog open={isOpen} onClose={onClose} >
            <Box padding={2} sx={{ backgroundColor: 'background.default', justifyContent:'center' }}>
            <div style={{display:'flex', justifyContent:'center',marginTop:'5px'}}><TranslateIcon className={classes.langlogo}/></div>
                <h4>{/*{getField(dictionary, 'choose-language', language)}*/}Choose your language</h4>
                <FormControl fullWidth sx={{marginBottom:'20px'}}>
                    <NativeSelect variant="standard" sx={{ backgroundColor:'background.paper',borderRadius:'8px',width:'100%',height: 36,}}
                    input={<BootstrapInput />} value={selectedLanguage} onChange={(event) => setSelectedLanguage(event.target.value)}>

                    {supportedLanguages.map((supportedLanguage, i) =>
                    <option key={i} value={supportedLanguage}>{supportedLanguage.charAt(0).toUpperCase() + supportedLanguage.slice(1)}</option>
                        )}
                    </NativeSelect>
                </FormControl>
                <Box sx={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', marginTop: 1, gap:'10px'}}>
                <Button variant='contained' sx={{ margin: '0px 2px 0px 2px',  }} onClick={handleContinue}>Continue</Button>
                    <div className={classes.langclose} onClick={handleCancel}>Close</div>

                </Box>
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => ({
    language: state.translation.language,
    supportedLanguages: state.translation.supportedLanguages,
    translation: state.translation.translation,
});

const mapDispatchToProps = (dispatch: any) => ({
    setLanguage: (data: any) => dispatch(actions.setLanguage(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ChooseLanguage)