import { Card, CardContent, Typography, Button, Box, useTheme } from '@mui/material';
import viewtask from "../../../assets/images/viewtask.svg"
import { ReactComponent as Profiles } from '../../../assets/images/profiles.svg'
import { ReactComponent as Profile } from '../../../assets/images/profile.svg'
import { ReactComponent as MoreIosIcon } from '../../../assets/images/Icon ionic-ios-more.svg'
import { ReactComponent as Calls } from '../../../assets/images/call.svg'
import { getDictionary, getField } from '../../../utils/translation/translation';
import { TranslationType } from '../../../types/translationTypes';
import { connect } from 'react-redux';
import useStyles from '../../../theme/styles';
import { CustomTheme } from '../../../theme/index';
import viewTaskStyle from './style';

function ViewTask({ translation, language, }: { translation: TranslationType[], language: string, }) {

    const Dictionary = getDictionary(translation, "view-task", language);
    const theme: CustomTheme = useTheme();
    const classes = useStyles();
    const tasks = viewTaskStyle();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
            <div className="modal-container" >
                <div className='modal-header'>
                    <div className={tasks.viewTaskImage}> <img src={viewtask} /></div>
                    <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}> 1 {getField(Dictionary, "task-is-left", language)} ! </div>
                </div>
                <div className='view-task-cards'>
                    <Card sx={{ borderRadius: 2, width: 300, maxHeight: 80, marginBottom: '10px', marginTop: "20px", background: theme.palette.card.main, borderLeft: "8px solid ", borderLeftColor: theme.palette.yelloww }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 0, marginLeft: 2 }}>
                            <div style={{ display: 'flex', marginTop: "10px" }}>
                                <Typography style={{ fontWeight: "bold", fontFamily: "Segoe UI" }}>
                                    {getField(Dictionary, "products-requests", language)}
                                </Typography>
                                <div style={{ marginLeft: "120px" }} >     <MoreIosIcon /> </div>
                            </div>
                            <div>
                                <div className={tasks.viewTaskCardBodyLeft}>
                                    <p style={{ color: theme.palette.darkGrey, marginTop: "5px" }}> {getField(Dictionary, "date", language)} : 9:12 PM</p>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Profiles />
                                        <Typography style={{ marginLeft: '3px', fontSize: "10px" }}>Helpers X Name</Typography>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <p style={{ fontWeight: "bold", margin: "0" }}> {getField(Dictionary, "branch", language)} </p>
                    <p style={{ fontSize: "12px" }}> Branch 1 , Branch</p>
                </div>
                <div>
                    <p style={{ fontWeight: "bold", whiteSpace: "nowrap", margin: "0" }}>{getField(Dictionary, "participant-information", language)}</p>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", lineHeight: "1" }}>

                        <span style={{ fontSize: "12px", display: "flex", alignItems: "center", gap: "10px" }}> <Profile /> Helper X Name</span>
                        <Calls />
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "30px", }}>
                    <Button variant="contained" style={{ padding: "7px 30px", textTransform: "none", background: theme.palette.primary.main, color: theme.palette.primary.contrastText, borderRadius: "15px", fontWeight: "bold", marginBottom: "15px" }}>
                        {getField(Dictionary, "send-reminder", language)}
                    </Button>
                </div>
            </div >
        </div>
    )
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, });
export default connect(mapStateToProps, null)(ViewTask);