import { productionArr, IngredientBrandsType, NewPurchaseType, BrandsType, customproductionArr } from "../../types";
import { Dispatch, Action } from 'redux';


export const INCREMENT_ORDER_QUANTITY = 'INCREMENT_ORDER_QUANTITY'
export const DECREMENT_ORDER_QUANTITY = 'DECREMENT_ORDER_QUANTITY'
export const ADJUST_ORDER_QUANTITY = 'ADJUST_ORDER_QUANTITY';
export const INCREMENT_BATCH_QTY = 'INCREMENT_BATCH_QTY';
export const DECREMENT_BATCH_QTY = "DECREMENT_BATCH_QTY"
export const ADJUST_BATCH_QTY = "ADJUST_BATCH_QTY"
export const SET_CARDS_ID = "SET_CARDS_ID";
export const INCREMENT_BOX_ORDER_QUANTITY = 'INCREMENT_BOX_ORDER_QUANTITY'
export const DECREMENT_BOX_ORDER_QUANTITY = 'DECREMENT_BOX_ORDER_QUANTITY'
export const ADJUST_BOX_ORDER_QUANTITY = 'ADJUST_BOX_ORDER_QUANTITY'
export const INCREMENT_CAN_ORDER_QUANTITY = 'INCREMENT_CAN_ORDER_QUANTITY'
export const DECREMENT_CAN_ORDER_QUANTITY = 'DECREMENT_CAN_ORDER_QUANTITY'
export const ADJUST_CAN_ORDER_QUANTITY = 'ADJUST_CAN_ORDER_QUANTITY'
export const INCREMENT_ORDER_BASE_UNIT_QUANTITY = 'INCREMENT_ORDER_BASE_UNIT_QUANTITY'
export const DECREMENT_ORDER_BASE_UNIT_QUANTITY = 'DECREMENT_ORDER_BASE_UNIT_QUANTITY'
export const UPDATE_ORDER_BASE_UNIT_QUANTITY = 'UPDATE_ORDER_BASE_UNIT_QUANTITY'
export const REMOVE_ORDER_FROM_PURCHASE = 'REMOVE_ORDER_FROM_PURCHASE'
export const UPDATE_UPRICE_ON_HAND = 'UPDATE_UPRICE_ON_HAND'
export const UPDATE_UPRICE_REQ = 'UPDATE_UPRICE_REQ'
export const SET_TOTAL_QTY_ON_HAND_PRICE = 'SET_TOTAL_QTY_ON_HAND_PRICE'
export const SET_TOTAL_QTY_REQUESTED_PRICE = 'SET_TOTAL_QTY_REQUESTED_PRICE'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const SET_DISCOUNT_AED = 'SET_DISCOUNT_AED'
export const SET_DISCOUNT_PERCENTAGE = 'SET_DISCOUNT_PERCENTAGE';
export const SET_BRAND_DISCOUNT_AED = 'SET_BRAND_DISCOUNT_AED';
export const SET_BRAND_DISCOUNT_PERCENTAGE = 'SET_BRAND_DISCOUNT_PERCENTAGE';
export const SET_FINAL_PRICE = 'SET_FINAL_PRICE'
export const CHANGE_ORDER_WEIGHT_TYPE = 'CHANGE_ORDER_WEIGHT_TYPE';
export const ADD_ORDER_TO_DETAILS = 'ADD_ORDER_TO_DETAILS';
export const REMOVE_BRAND_FROM_ORDER = 'REMOVE_BRAND_FROM_ORDER'
export const UPDATE_BASE_UNIT_PRICE = 'UPDATE_BASE_UNIT_PRICE';
export const SET_TOTAL_BASE_UNIT_PRICE = 'SET_TOTAL_BASE_UNIT_PRICE';
export const TOGGLE_BRAND_FAVORITE = 'TOGGLE_BRAND_FAVORITE';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD';
export const ADD_REMARKS_TO_REQUEST = 'ADD_REMARKS_TO_REQUEST';
export const ADD_INTERNAL_REMARKS_TO_PURCHASE = 'ADD_INTERNAL_REMARKS_TO_PURCHASE';
export const ADD_PRODUCT_BRAND = 'ADD_PRODUCT_BRAND';
export const UPDATE_PRODUCT_REASON = 'UPDATE_PRODUCT_REASON';
export const SET_SELECTED_REASON = 'SET_SELECTED_REASON';
//custom Production
export const INCREMENT_CUSTOM_QUANTITY = 'INCREMENT_CUSTOM__QUANTITY'
export const DECREMENT_CUSTOM__QUANTITY = 'DECREMENT_CUSTOM__QUANTITY'
export const ADJUST_CUSTOM__QUANTITY = 'ADJUST_CUSTOM__QUANTITY';
export const INCREMENT_CUSTOM_BATCH_QTY = 'INCREMENT_CUSTOM_BATCH_QTY';
export const DECREMENT_CUSTOM_BATCH_QTY = "DECREMENT_CUSTOM_BATCH_QTY"
export const ADJUST_CUSTOM_BATCH_QTY = "ADJUST_CUSTOM_BATCH_QTY";
export const INCREMENT_INGREDIENT_QUANTITY = 'INCREMENT_INGREDIENT_QUANTITY'
export const DECREMENT_INGREDIENT_QUANTITY = 'DECREMENT_INGREDIENT_QUANTITY'
export const ADJUST_INGREDIENT_QUANTITY = 'ADJUST_INGREDIENT_QUANTITY';

export interface RootState {
    productionProduct: productionArr,
    customProduct: customproductionArr,
    selectedCards: number[]
    ingredientProducts: NewPurchaseType[],
    newPODetails: NewPurchaseType[],
    cansPerBox: number; activeCardId: null, selectedReason: '',
}
//production Items 
interface IncrementOrderQuantityAction extends Action<typeof INCREMENT_ORDER_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface DecrementOrderQuantityAction extends Action<typeof DECREMENT_ORDER_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface AdjustOrderQuantityAction extends Action<typeof ADJUST_ORDER_QUANTITY> { payload: { productId: string; newQuantity: number; brandId: string | null, index: number }; }
interface SetCardId extends Action<typeof SET_CARDS_ID> { payload: { cardIds: string }; }
interface IncrementBatchQuantityAction extends Action<typeof INCREMENT_BATCH_QTY> { payload: { productId: string; index: number } }
interface DecrementBatchQuantityAction extends Action<typeof DECREMENT_BATCH_QTY> { payload: { productId: string; index: number } }
interface AdjustBatchQuantityAction extends Action<typeof ADJUST_BATCH_QTY> { payload: { productId: string; newQuantity: number; index: number }; }
interface IncrementBoxOrderQuantityAction extends Action<typeof INCREMENT_BOX_ORDER_QUANTITY> { payload: { productId: string; brandIndex: number | null; qtyIndex: number; } }
interface DecrementBoxOrderQuantityAction extends Action<typeof DECREMENT_BOX_ORDER_QUANTITY> { payload: { productId: string; brandIndex: number | null; qtyIndex: number; } }
interface IncrementCanOrderQuantityAction extends Action<typeof INCREMENT_CAN_ORDER_QUANTITY> { payload: { productId: string; brandIndex: number | null; qtyIndex: number; } }
interface DecrementCanOrderQuantityAction extends Action<typeof DECREMENT_CAN_ORDER_QUANTITY> { payload: { productId: string; brandIndex: number | null; qtyIndex: number; } }
interface AdjustBoxOrderQuantityAction extends Action<typeof ADJUST_BOX_ORDER_QUANTITY> { payload: { productId: string; brandIndex: number | null; qtyIndex: number; newQuantity: number; }; }
interface AdjustCanOrderQuantityAction extends Action<typeof ADJUST_CAN_ORDER_QUANTITY> { payload: { productId: string; brandIndex: number | null; qtyIndex: number; newQuantity: number; }; }
interface IncrementOrderBaseUnitQuantityAction extends Action<typeof INCREMENT_ORDER_BASE_UNIT_QUANTITY> { payload: { productId: string; baseUnitIndex: number; } }
interface DecrementOrderBaseUnitQuantityAction extends Action<typeof DECREMENT_ORDER_BASE_UNIT_QUANTITY> { payload: { productId: string; baseUnitIndex: number; } }
interface UpdateOrderBaseUnitQuantityAction extends Action<typeof UPDATE_ORDER_BASE_UNIT_QUANTITY> { payload: { productId: string; baseUnitId: string; newCount: number; } }
interface ChangeOrderWeightTypeAction extends Action<typeof CHANGE_ORDER_WEIGHT_TYPE> { payload: { productId: string; baseUnitId: string; weightType: string }; }
interface AddOrderToDetailsAction extends Action<typeof ADD_ORDER_TO_DETAILS> { payload: { productId: string }; }
interface RemoveBrandFromOrderAction extends Action<typeof REMOVE_BRAND_FROM_ORDER> { payload: { brandID: string; } }
interface UpdateBaseUnitPrice { type: typeof UPDATE_BASE_UNIT_PRICE; payload: { productInd: number, newUnitPrice: number } }interface RemoveOrderFromPurchaseAction extends Action<typeof REMOVE_ORDER_FROM_PURCHASE> { payload: { productId: string; } }
interface UpdateUpriceOnHandAction extends Action<typeof UPDATE_UPRICE_ON_HAND> { payload: { productIndex: number; newValue: string; }; }
interface UpdateUpriceReqAction extends Action<typeof UPDATE_UPRICE_REQ> { payload: { productReqIndex: number; newReqValue: string; }; }
interface SetTotalQtyOnHandPriceAction { type: typeof SET_TOTAL_QTY_ON_HAND_PRICE; payload: number | string; }
interface SetTotalQtyRequestedPriceAction { type: typeof SET_TOTAL_QTY_REQUESTED_PRICE; payload: number | string; }
interface SetTotalPriceAction { type: typeof SET_TOTAL_PRICE; payload: number | string; }
interface SetDiscountAEDAction { type: typeof SET_DISCOUNT_AED; payload: { discountAEDproductId: number; discountAED: string; } }
interface SetDiscountPercAction { type: typeof SET_DISCOUNT_PERCENTAGE; payload: { discountAPercproductId: number, discountPerc: string } }
interface SetBrandDiscountAEDAction { type: typeof SET_BRAND_DISCOUNT_AED; payload: { discountAEDbrandId: number; bdiscountAED: string; } }
interface SetBrandDiscountPercAction { type: typeof SET_BRAND_DISCOUNT_PERCENTAGE; payload: { discountPercbrandId: number, bdiscountPerc: string } }
interface SetFinalPriceAction { type: typeof SET_FINAL_PRICE; payload: number | string; }
interface SetTotalBaseUnitPriceAction { type: typeof SET_TOTAL_BASE_UNIT_PRICE; payload: number | string; }
interface toggleBrandFavorite { type: typeof TOGGLE_BRAND_FAVORITE; payload: { brandId: string } }
interface SetActiveCardId { type: typeof SET_ACTIVE_CARD; payload: { brandId: string } }
interface AddRemarksToRequestAction extends Action<typeof ADD_REMARKS_TO_REQUEST> { payload: { productId: string; remarks: string; } }
interface AddInternalRemarkAction extends Action<typeof ADD_INTERNAL_REMARKS_TO_PURCHASE> { payload: { productId: string; remarks: string; } }
interface AddProductBrandAction extends Action<typeof ADD_PRODUCT_BRAND> { payload: { productId: string; newBrand: IngredientBrandsType } }
interface updateProductReason { type: typeof UPDATE_PRODUCT_REASON; payload: { productId: string, reason: string }, }
interface setSelectedReason { type: typeof SET_SELECTED_REASON; payload: { reason: string }, }
//custom Production
interface IncrementCustomQuantityAction extends Action<typeof INCREMENT_CUSTOM_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface DecrementCustomQuantityAction extends Action<typeof DECREMENT_CUSTOM__QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface AdjustCustomQuantityAction extends Action<typeof ADJUST_CUSTOM__QUANTITY> { payload: { productId: string; newQuantity: number; brandId: string | null, index: number }; }
interface IncrementCustomBatchQuantityAction extends Action<typeof INCREMENT_CUSTOM_BATCH_QTY> { payload: { productId: string; index: number } }
interface DecrementCustomBatchQuantityAction extends Action<typeof DECREMENT_CUSTOM_BATCH_QTY> { payload: { productId: string; index: number } }
interface AdjustCustomBatchQuantityAction extends Action<typeof ADJUST_CUSTOM_BATCH_QTY> { payload: { productId: string; newQuantity: number; index: number }; }
interface IncrementIngredientQuantityAction extends Action<typeof INCREMENT_INGREDIENT_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface DecrementIngredientQuantityAction extends Action<typeof DECREMENT_INGREDIENT_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface AdjustIngredientQuantityAction extends Action<typeof ADJUST_INGREDIENT_QUANTITY> { payload: { productId: string; newQuantity: number; brandId: string | null, index: number }; }
export type ProductActionTypes =
    | IncrementOrderQuantityAction | DecrementOrderQuantityAction | AdjustOrderQuantityAction | IncrementBatchQuantityAction | DecrementBatchQuantityAction
    | AdjustBatchQuantityAction | SetCardId | IncrementBoxOrderQuantityAction | DecrementBoxOrderQuantityAction | AdjustBoxOrderQuantityAction
    | IncrementCanOrderQuantityAction | DecrementCanOrderQuantityAction | AdjustCanOrderQuantityAction |
    IncrementOrderBaseUnitQuantityAction | DecrementOrderBaseUnitQuantityAction | UpdateOrderBaseUnitQuantityAction |
    ChangeOrderWeightTypeAction | AddOrderToDetailsAction | RemoveBrandFromOrderAction |
    RemoveOrderFromPurchaseAction | UpdateUpriceOnHandAction | UpdateUpriceReqAction
    | SetTotalQtyOnHandPriceAction | SetTotalQtyRequestedPriceAction | SetTotalPriceAction
    | SetDiscountAEDAction | SetDiscountPercAction | SetBrandDiscountAEDAction | SetBrandDiscountPercAction
    | SetFinalPriceAction | UpdateBaseUnitPrice | SetTotalBaseUnitPriceAction | toggleBrandFavorite | SetActiveCardId | AddRemarksToRequestAction
    | AddInternalRemarkAction | AddProductBrandAction | updateProductReason | setSelectedReason
    | IncrementCustomQuantityAction | DecrementCustomQuantityAction | AdjustCustomQuantityAction | IncrementCustomBatchQuantityAction | AdjustCustomBatchQuantityAction | DecrementCustomBatchQuantityAction
    | IncrementIngredientQuantityAction | DecrementIngredientQuantityAction | AdjustIngredientQuantityAction;


const incrementQuantity = (productId: string, brandId: string | null, index: number): IncrementOrderQuantityAction => ({ type: INCREMENT_ORDER_QUANTITY, payload: { productId, brandId, index } });
const DecrementQuantity = (productId: string, brandId: string | null, index: number): DecrementOrderQuantityAction => ({ type: DECREMENT_ORDER_QUANTITY, payload: { productId, brandId, index } });
const adjustQuantity = (productId: string, newQuantity: number, brandId: string | null, index: number): AdjustOrderQuantityAction => ({ type: ADJUST_ORDER_QUANTITY, payload: { productId, newQuantity, brandId, index }, });

const incrementBatchQuantity = (productId: string, index: number): IncrementBatchQuantityAction => ({ type: INCREMENT_BATCH_QTY, payload: { productId, index } });
const DecrementBatchQuantity = (productId: string, index: number): DecrementBatchQuantityAction => ({ type: DECREMENT_BATCH_QTY, payload: { productId, index } });
const adjustBatchQuantity = (productId: string, newQuantity: number, index: number): AdjustBatchQuantityAction => ({ type: ADJUST_BATCH_QTY, payload: { productId, newQuantity, index }, });

const incrementCustomQuantity = (productId: string, brandId: string | null, index: number): IncrementCustomQuantityAction => ({ type: INCREMENT_CUSTOM_QUANTITY, payload: { productId, brandId, index } });
const DecrementCustomQuantity = (productId: string, brandId: string | null, index: number): DecrementCustomQuantityAction => ({ type: DECREMENT_CUSTOM__QUANTITY, payload: { productId, brandId, index } });
const adjustCustomQuantity = (productId: string, newQuantity: number, brandId: string | null, index: number): AdjustCustomQuantityAction => ({ type: ADJUST_CUSTOM__QUANTITY, payload: { productId, newQuantity, brandId, index }, });

const incrementCustomBatchQuantity = (productId: string, index: number): IncrementCustomBatchQuantityAction => ({ type: INCREMENT_CUSTOM_BATCH_QTY, payload: { productId, index } });
const DecrementCustomBatchQuantity = (productId: string, index: number): DecrementCustomBatchQuantityAction => ({ type: DECREMENT_CUSTOM_BATCH_QTY, payload: { productId, index } });
const adjustCustomBatchQuantity = (productId: string, newQuantity: number, index: number): AdjustCustomBatchQuantityAction => ({ type: ADJUST_CUSTOM_BATCH_QTY, payload: { productId, newQuantity, index }, });

const IncrementIngredientQuantity = (productId: string, brandId: string | null, index: number): IncrementIngredientQuantityAction => ({ type: INCREMENT_INGREDIENT_QUANTITY, payload: { productId, brandId, index } });
const DecrementIngredientQuantity = (productId: string, brandId: string | null, index: number): DecrementIngredientQuantityAction => ({ type: DECREMENT_INGREDIENT_QUANTITY, payload: { productId, brandId, index } });
const AdjustIngredientQuantity = (productId: string, newQuantity: number, brandId: string | null, index: number): AdjustIngredientQuantityAction => ({ type: ADJUST_INGREDIENT_QUANTITY, payload: { productId, newQuantity, brandId, index }, });

const SetCardId = (cardIds: string): SetCardId => ({ type: SET_CARDS_ID, payload: { cardIds } });
const incrementBoxOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): IncrementBoxOrderQuantityAction => ({ type: INCREMENT_BOX_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });
const decrementBoxOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): DecrementBoxOrderQuantityAction => ({ type: DECREMENT_BOX_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });
const adjustBoxOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number): AdjustBoxOrderQuantityAction => ({ type: ADJUST_BOX_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex, newQuantity } });
const incrementCanOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): IncrementCanOrderQuantityAction => ({ type: INCREMENT_CAN_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });
const decrementCanOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number): DecrementCanOrderQuantityAction => ({ type: DECREMENT_CAN_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex } });
const adjustCanOrdQ = (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number): AdjustCanOrderQuantityAction => ({ type: ADJUST_CAN_ORDER_QUANTITY, payload: { productId, brandIndex, qtyIndex, newQuantity } });
const incrementBaseUnitOrdQ = (productId: string, baseUnitIndex: number,): IncrementOrderBaseUnitQuantityAction => ({ type: INCREMENT_ORDER_BASE_UNIT_QUANTITY, payload: { productId, baseUnitIndex, } });
const decrementBaseUnitOrdQ = (productId: string, baseUnitIndex: number,): DecrementOrderBaseUnitQuantityAction => ({ type: DECREMENT_ORDER_BASE_UNIT_QUANTITY, payload: { productId, baseUnitIndex, } });
const updatetBaseUnitOrdQ = (productId: string, baseUnitId: string, newCount: number): UpdateOrderBaseUnitQuantityAction => ({ type: UPDATE_ORDER_BASE_UNIT_QUANTITY, payload: { productId, baseUnitId, newCount } });
const changeOrdWeightType = (productId: string, baseUnitId: string, weightType: string): ChangeOrderWeightTypeAction => ({ type: CHANGE_ORDER_WEIGHT_TYPE, payload: { productId, baseUnitId, weightType }, });
const removeBrandFromOrd = (brandID: string): RemoveBrandFromOrderAction => ({ type: REMOVE_BRAND_FROM_ORDER, payload: { brandID } });
const RemoveProd = (productId: string): RemoveOrderFromPurchaseAction => ({ type: REMOVE_ORDER_FROM_PURCHASE, payload: { productId } });
const updateUpriceOnHand = (productIndex: number, newValue: string): UpdateUpriceOnHandAction => ({ type: UPDATE_UPRICE_ON_HAND, payload: { productIndex, newValue }, });
const updateUpriceReq = (productReqIndex: number, newReqValue: string,): UpdateUpriceReqAction => ({ type: UPDATE_UPRICE_REQ, payload: { productReqIndex, newReqValue }, });
const setTotalQtyOnHandPrice = (price: number | string): SetTotalQtyOnHandPriceAction => ({ type: SET_TOTAL_QTY_ON_HAND_PRICE, payload: price, });
const setTotalQtyRequestedPrice = (price: number | string): SetTotalQtyRequestedPriceAction => ({ type: SET_TOTAL_QTY_REQUESTED_PRICE, payload: price, });
const setTotalPrice = (price: number | string): SetTotalPriceAction => ({ type: SET_TOTAL_PRICE, payload: price, });
const setDiscountAED = (discountAEDproductId: number, discountAED: string): SetDiscountAEDAction => ({ type: SET_DISCOUNT_AED, payload: { discountAEDproductId, discountAED } });
const setDiscountPercentage = (discountAPercproductId: number, discountPerc: string): SetDiscountPercAction => ({ type: SET_DISCOUNT_PERCENTAGE, payload: { discountAPercproductId, discountPerc } });
const setBrandDiscountAED = (discountAEDbrandId: number, bdiscountAED: string): SetBrandDiscountAEDAction => ({ type: SET_BRAND_DISCOUNT_AED, payload: { discountAEDbrandId, bdiscountAED } });
const setBrandDiscountPercentage = (discountPercbrandId: number, bdiscountPerc: string): SetBrandDiscountPercAction => ({ type: SET_BRAND_DISCOUNT_PERCENTAGE, payload: { discountPercbrandId, bdiscountPerc } });
const setFinalPrice = (finalPrice: number | string): SetFinalPriceAction => ({ type: SET_FINAL_PRICE, payload: finalPrice, });
const addOrdToDet = (productId: string): AddOrderToDetailsAction => ({ type: ADD_ORDER_TO_DETAILS, payload: { productId }, });
const updateBaseUnitPrice = (productInd: number, newUnitPrice: number): UpdateBaseUnitPrice => ({ type: UPDATE_BASE_UNIT_PRICE, payload: { productInd, newUnitPrice } });
const SetTotalBaseUnitPriceAction = (price: number | string): SetTotalBaseUnitPriceAction => ({ type: SET_TOTAL_BASE_UNIT_PRICE, payload: price, });
const toggleBrandFavorite = (brandId: string): toggleBrandFavorite => ({ type: TOGGLE_BRAND_FAVORITE, payload: { brandId } });
const SetActiveCardId = (brandId: string): SetActiveCardId => ({ type: SET_ACTIVE_CARD, payload: { brandId } });
const addRemarksReq = (productId: string, remarks: string): AddRemarksToRequestAction => ({ type: ADD_REMARKS_TO_REQUEST, payload: { productId, remarks } });
const addInternalRemark = (productId: string, remarks: string): AddInternalRemarkAction => ({ type: ADD_INTERNAL_REMARKS_TO_PURCHASE, payload: { productId, remarks } });
const addProdBrand = (productId: string, newBrand: BrandsType): AddProductBrandAction => ({ type: ADD_PRODUCT_BRAND, payload: { productId, newBrand } })
const updateProductReason = (productId: string, reason: string): updateProductReason => ({ type: UPDATE_PRODUCT_REASON, payload: { productId, reason } });
const SetSelectedReason = (reason: string): setSelectedReason => ({ type: SET_SELECTED_REASON, payload: { reason } });
export const ProductionActions = {
    //PRODUCTION iTEMS
    incrementQty: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(incrementQuantity(productId, brandId, index)); },
    decrementQty: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementQuantity(productId, brandId, index)); },
    adjustQty: (productId: string, newQuantity: number, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(adjustQuantity(productId, newQuantity, brandId, index)); },
    incrementBatchQuantity: (productId: string, index: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBatchQuantity(productId, index)); },
    DecrementBatchQuantity: (productId: string, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementBatchQuantity(productId, index)); },
    adjustBatchQuantity: (productId: string, newQuantity: number, index: number) => (dispatch: Dispatch<any>) => { dispatch(adjustBatchQuantity(productId, newQuantity, index)); },
    //CUSTOM PRODUCTION
    incrementCustomQuantity: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(incrementCustomQuantity(productId, brandId, index)); },
    DecrementCustomQuantity: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementCustomQuantity(productId, brandId, index)); },
    adjustCustomQuantity: (productId: string, newQuantity: number, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(adjustCustomQuantity(productId, newQuantity, brandId, index)); },
    incrementCustomBatchQuantity: (productId: string, index: number) => (dispatch: Dispatch<any>) => { dispatch(incrementCustomBatchQuantity(productId, index)); },
    DecrementCustomBatchQuantity: (productId: string, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementCustomBatchQuantity(productId, index)); },
    adjustCustomBatchQuantity: (productId: string, newQuantity: number, index: number) => (dispatch: Dispatch<any>) => { dispatch(adjustCustomBatchQuantity(productId, newQuantity, index)); },

    IncrementIngredientQuantity: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(IncrementIngredientQuantity(productId, brandId, index)); },
    DecrementIngredientQuantity: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementIngredientQuantity(productId, brandId, index)); },
    adjustIngredientQuantity: (productId: string, newQuantity: number, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(AdjustIngredientQuantity(productId, newQuantity, brandId, index)); },
    SetCardId: (cardIds: string) => (dispatch: Dispatch<any>) => { dispatch(SetCardId(cardIds)); },
    //Box
    incrementBoxOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBoxOrdQ(productId, brandIndex, qtyIndex)); },
    decrementBoxOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBoxOrdQ(productId, brandIndex, qtyIndex)); },
    adjustBoxOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustBoxOrdQ(productId, brandIndex, qtyIndex, newQuantity)); },
    //Can
    incrementCanOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementCanOrdQ(productId, brandIndex, qtyIndex)); },
    decrementCanOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementCanOrdQ(productId, brandIndex, qtyIndex)); },
    adjustCanOrderQty: (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustCanOrdQ(productId, brandIndex, qtyIndex, newQuantity)); },
    //Base Unit
    incrementBaseUnitOrderQty: (productId: string, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBaseUnitOrdQ(productId, baseUnitIndex,)); },
    decrementBaseUnitOrderQty: (productId: string, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBaseUnitOrdQ(productId, baseUnitIndex,)); },
    updateBaseUnitOrderQty: (productId: string, baseUnitId: string, newCount: number) => (dispatch: Dispatch<any>) => { dispatch(updatetBaseUnitOrdQ(productId, baseUnitId, newCount)); },
    convertOrderWeight: (productId: string, baseUnitId: string, weightType: string) => (dispatch: Dispatch<any>) => { dispatch(changeOrdWeightType(productId, baseUnitId, weightType)); },
    //Add Order To details
    addOrderDetails: (productId: string) => (dispatch: Dispatch<any>) => { dispatch(addOrdToDet(productId)); },
    removeBrandOrder: (brandID: string) => (dispatch: Dispatch<any>) => { dispatch(removeBrandFromOrd(brandID)); },
    //remove product 
    removeProduct: (productId: string) => (dispatch: Dispatch<any>) => { dispatch(RemoveProd(productId)); },
    // transfer unit prices 
    updateUpriceHand: (productIndex: number, newValue: string) => (dispatch: Dispatch<any>) => { dispatch(updateUpriceOnHand(productIndex, newValue)); },
    updateUpriceReq: (productReqIndex: number, newReqValue: string,) => (dispatch: Dispatch<any>) => { dispatch(updateUpriceReq(productReqIndex, newReqValue)); },
    updateBaseUnitPrice: (productInd: number, newUnitPrice: number) => (dispatch: Dispatch<any>) => { dispatch(updateBaseUnitPrice(productInd, newUnitPrice)); },
    // calculate total prices 
    setTotalQtyOnHandPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalQtyOnHandPrice(price)); },
    setTotalQtyRequestedPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalQtyRequestedPrice(price)); },
    setTotalPrice: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(setTotalPrice(price)); },
    SetTotalBaseUnitPriceAction: (price: number | string) => (dispatch: Dispatch<any>) => { dispatch(SetTotalBaseUnitPriceAction(price)); },
    // set discount AED / PERCENTAGE 
    setDiscountAED: (discountAEDproductId: number, discountAED: string) => (dispatch: Dispatch<any>) => { dispatch(setDiscountAED(discountAEDproductId, discountAED)); },
    setDiscountPercentage: (discountAPercproductId: number, discountPerc: string) => (dispatch: Dispatch<any>) => { dispatch(setDiscountPercentage(discountAPercproductId, discountPerc)); },
    //brand discounts 
    setBrandDiscountAED: (discountAEDbrandId: number, bdiscountAED: string) => (dispatch: Dispatch<any>) => { dispatch(setBrandDiscountAED(discountAEDbrandId, bdiscountAED)); },
    setBrandDiscountPercentage: (discountPercbrandId: number, bdiscountPerc: string) => (dispatch: Dispatch<any>) => { dispatch(setBrandDiscountPercentage(discountPercbrandId, bdiscountPerc)); },
    setFinalPrice: (finalPrice: number | string) => (dispatch: Dispatch<any>) => { dispatch(setFinalPrice(finalPrice)); },
    //select brand 
    toggleBrandFavorite: (brandId: string) => (dispatch: Dispatch<any>) => {
        console.log(brandId)
        dispatch(toggleBrandFavorite(brandId));
    },
    SetActiveCardId: (brandId: string) => (dispatch: Dispatch<any>) => { dispatch(SetActiveCardId(brandId)); },
    //add remark 
    addRemarkstoRequest: (productId: string, remarks: string) => (dispatch: Dispatch<any>) => { dispatch(addRemarksReq(productId, remarks)); },
    addInternalRemark: (productId: string, remarks: string) => (dispatch: Dispatch<any>) => { dispatch(addInternalRemark(productId, remarks)); },
    addProductBrand: (productId: string, newBrand: BrandsType) => (dispatch: Dispatch<any>) => { dispatch(addProdBrand(productId, newBrand)); },
    updateProductReason: (productId: string, reason: string) => (dispatch: Dispatch<any>) => { dispatch(updateProductReason(productId, reason)); },
    SetSelectedReason: (reason: string) => (dispatch: Dispatch<any>) => { dispatch(SetSelectedReason(reason)); },
}