import { prodArr } from "../../types";
import { Dispatch, Action } from 'redux';
export const INCREMENT_ORDER_QUANTITY = 'INCREMENT_ORDER_QUANTITY'
export const DECREMENT_ORDER_QUANTITY = 'DECREMENT_ORDER_QUANTITY'
export const ADJUST_ORDER_QUANTITY = 'ADJUST_ORDER_QUANTITY';
export const INCREMENT_EXPIRY_QUANTITY = 'INCREMENT_EXPIRY_QUANTITY'
export const DECREMENT_EXPIRY_QUANTITY = 'DECREMENT_EXPIRY_QUANTITY'
export const ADJUST_EXPIRY_QUANTITY = 'ADJUST_EXPIRY_QUANTITY'
export const INCREMENT_BRAND_EXPIRY_QUANTITY = 'INCREMENT_BRAND_EXPIRY_QUANTITY'
export const DECREMENT_BRAND_EXPIRY_QUANTITY = 'DECREMENT_BRAND_EXPIRY_QUANTITY'
export const ADJUST_BRAND_EXPIRY_QUANTITY = 'ADJUST_BRAND_EXPIRY_QUANTITY'
export const INCREMENT_UNPACKED_QTY = 'INCREMENT_UNPACKED_QTY'
export const DECREMENT_UNPACKED_QTY = 'DECREMENT_UNPACKED_QTY '
export const ADJUST_UNPACKED_QTY = 'ADJUST_UNPACKED_QTY'
export interface RootState {
    StockProducts: prodArr;

}
interface IncrementOrderQuantityAction extends Action<typeof INCREMENT_ORDER_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface DecrementOrderQuantityAction extends Action<typeof DECREMENT_ORDER_QUANTITY> { payload: { productId: string; brandId: string | null, index: number } }
interface AdjustOrderQuantityAction extends Action<typeof ADJUST_ORDER_QUANTITY> { payload: { productId: string; newQuantity: number; brandId: string | null, index: number }; }
//update expiry dates quantities 
interface IncrementExpiryQuantityAction extends Action<typeof INCREMENT_EXPIRY_QUANTITY> { payload: { productId: string; expiryIndex: number; type: string, brandId: string } }
interface DecrementExpiryQuantityAction extends Action<typeof DECREMENT_EXPIRY_QUANTITY> { payload: { productId: string; expiryIndex: number; type: string, brandId: string } }
interface AdjustExpiryQuantityAction extends Action<typeof ADJUST_EXPIRY_QUANTITY> { payload: { newQty: number; prodId: string; expiryIndex: number; type: string, brandId: string }; }
//UPDATE expiry date qty for multiBrands 
interface IncrementBrandExpiryQuantityAction extends Action<typeof INCREMENT_BRAND_EXPIRY_QUANTITY> { payload: { productId: string; expiryIndex: number; type: string, brandId: string } }
interface DecrementBrandExpiryQuantityAction extends Action<typeof DECREMENT_BRAND_EXPIRY_QUANTITY> { payload: { productId: string; expiryIndex: number; type: string, brandId: string } }
interface AdjustBrandExpiryQuantityAction extends Action<typeof ADJUST_BRAND_EXPIRY_QUANTITY> { payload: { newQty: number; prodId: string; expiryIndex: number; type: string, brandId: string }; }
//UPDATE UNPACKED QTY 
interface IncrementUnpackedQuantityAction extends Action<typeof INCREMENT_UNPACKED_QTY> { payload: { productId: string; brandId: string | null, index: number } }
interface DecrementUnpackedQuantityAction extends Action<typeof DECREMENT_UNPACKED_QTY> { payload: { productId: string; brandId: string | null, index: number } }
interface AdjustUnpackedQuantityAction extends Action<typeof ADJUST_UNPACKED_QTY> { payload: { productId: string; newQuantity: number }; }
export type ProductActionTypes =
    | IncrementOrderQuantityAction | DecrementOrderQuantityAction | AdjustOrderQuantityAction
    | IncrementExpiryQuantityAction | DecrementExpiryQuantityAction | AdjustExpiryQuantityAction
    | IncrementBrandExpiryQuantityAction | DecrementBrandExpiryQuantityAction | AdjustBrandExpiryQuantityAction
    | IncrementUnpackedQuantityAction | DecrementUnpackedQuantityAction | AdjustUnpackedQuantityAction

const incrementQuantity = (productId: string, brandId: string | null, index: number): IncrementOrderQuantityAction => ({ type: INCREMENT_ORDER_QUANTITY, payload: { productId, brandId, index } });
const DecrementQuantity = (productId: string, brandId: string | null, index: number): DecrementOrderQuantityAction => ({ type: DECREMENT_ORDER_QUANTITY, payload: { productId, brandId, index } });
const adjustQuantity = (productId: string, newQuantity: number, brandId: string | null, index: number): AdjustOrderQuantityAction => ({ type: ADJUST_ORDER_QUANTITY, payload: { productId, newQuantity, brandId, index }, });

const incrementExpQuantity = (productId: string, expiryIndex: number, type: string, brandId: string): IncrementExpiryQuantityAction => ({ type: INCREMENT_EXPIRY_QUANTITY, payload: { productId, expiryIndex, type, brandId } });
const DecrementExpQuantity = (productId: string, expiryIndex: number, type: string, brandId: string): DecrementExpiryQuantityAction => ({ type: DECREMENT_EXPIRY_QUANTITY, payload: { productId, expiryIndex, type, brandId } });
const adjustExpQuantity = (prodId: string, expiryIndex: number, newQty: number, type: string, brandId: string): AdjustExpiryQuantityAction => ({ type: ADJUST_EXPIRY_QUANTITY, payload: { prodId, newQty, expiryIndex, type, brandId }, });
//UPDATE expiry date qty for multiBrands 
const incrementBrandExpQuantity = (productId: string, expiryIndex: number, type: string, brandId: string): IncrementBrandExpiryQuantityAction => ({ type: INCREMENT_BRAND_EXPIRY_QUANTITY, payload: { productId, expiryIndex, type, brandId } });
const DecrementBrandExpQuantity = (productId: string, expiryIndex: number, type: string, brandId: string): DecrementBrandExpiryQuantityAction => ({ type: DECREMENT_BRAND_EXPIRY_QUANTITY, payload: { productId, expiryIndex, type, brandId } });
const adjustBrandExpQuantity = (prodId: string, expiryIndex: number, newQty: number, type: string, brandId: string): AdjustBrandExpiryQuantityAction => ({ type: ADJUST_BRAND_EXPIRY_QUANTITY, payload: { prodId, newQty, expiryIndex, type, brandId }, });
//UPDATE UNPACKED 
const incrementUnpackedQuantity = (productId: string, brandId: string | null, index: number): IncrementUnpackedQuantityAction => ({ type: INCREMENT_UNPACKED_QTY, payload: { productId, brandId, index } });
const DecrementUnpackedQuantity = (productId: string, brandId: string | null, index: number): DecrementUnpackedQuantityAction => ({ type: DECREMENT_UNPACKED_QTY, payload: { productId, brandId, index } });
const adjustUnpackedQuantity = (productId: string, newQuantity: number): AdjustUnpackedQuantityAction => ({ type: ADJUST_UNPACKED_QTY, payload: { productId, newQuantity }, });

export const StockTakeActions = {
    incrementQty: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(incrementQuantity(productId, brandId, index)); },
    decrementQty: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementQuantity(productId, brandId, index)); },
    adjustQty: (productId: string, newQuantity: number, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(adjustQuantity(productId, newQuantity, brandId, index)); },

    incrementExpQty: (productId: string, expiryIndex: number, type: string, brandId: string) => (dispatch: Dispatch<any>) => { dispatch(incrementExpQuantity(productId, expiryIndex, type, brandId)); },
    decrementExpQty: (productId: string, expiryIndex: number, type: string, brandId: string) => (dispatch: Dispatch<any>) => { dispatch(DecrementExpQuantity(productId, expiryIndex, type, brandId)); },
    adjustExpQty: (newQty: number, prodId: string, expiryIndex: number, type: string, brandId: string) => (dispatch: Dispatch<any>) => { dispatch(adjustExpQuantity(prodId, newQty, expiryIndex, type, brandId)); },

    incrementBrandExpQty: (productId: string, expiryIndex: number, type: string, brandId: string) => (dispatch: Dispatch<any>) => { dispatch(incrementBrandExpQuantity(productId, expiryIndex, type, brandId)); },
    decrementBrandExpQty: (productId: string, expiryIndex: number, type: string, brandId: string) => (dispatch: Dispatch<any>) => { dispatch(DecrementBrandExpQuantity(productId, expiryIndex, type, brandId)); },
    adjustBrandExpQty: (newQty: number, prodId: string, expiryIndex: number, type: string, brandId: string) => (dispatch: Dispatch<any>) => { dispatch(adjustBrandExpQuantity(prodId, newQty, expiryIndex, type, brandId)); },

    incrementUnpackedQty: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(incrementUnpackedQuantity(productId, brandId, index)); },
    decrementUnpackedQty: (productId: string, brandId: string | null, index: number) => (dispatch: Dispatch<any>) => { dispatch(DecrementUnpackedQuantity(productId, brandId, index)); },
    adjustUnpackedQty: (productId: string, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustUnpackedQuantity(productId, newQuantity)); },
}