import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
bigBtn2:{height:'45px',fontSize:'16px !important',fontWeight:'500 !important',borderRadius:'20px !important',backgroundColor:`${theme.palette.card.main} !important`,color:`${theme.palette.text.primary} !important`,},

PendingModal:{ backgroundColor: theme.palette.third.main, padding: '20px', outline: 'none', borderRadius: "40px", overflowY: 'auto', alignItems: 'center', justifyContent: 'center'},

DetailsCard:{ borderLeft:`4px solid ${theme.palette.warning.main} `, border:`1px solid ${theme.palette.warning.main}`,  borderRadius: '5px  !important', minWidth:'330px !important', maxWidth: '350px !important',padding:'5px',display: 'flex', flexDirection: 'column', gap: '8px',},

qtyNumber: {fontSize: '15px',fontWeight: 500,},

Name: {fontWeight: 'bold',fontSize: '14px',},

iconBtn:{width:'65px',height:'32px',backgroundColor:theme.palette.third.main, borderRadius: '13px',cursor:"pointer",textAlign:'center','&:hover': { opacity:'80%' },},

btnTitle:{fontWeight:'bold',fontSize:"10px",textAlign:'center',cursor:"pointer"},

HorizCentering:{display: 'flex', alignItems: 'center', justifyContent: 'center'},

closeButton:{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12),0px 0px 0px 1px rgba(0, 0, 0, 0.05)'},

RejectProductsButton:{ position: 'fixed', bottom: '0',zIndex: '1',transform: 'translate(67px, -10px)',},

MultiBrandModal:{ backgroundColor: theme.palette.third.main, padding: '10px', outline: 'none', borderRadius: "40px",height:'100%',overflowY:'auto'},
})
);
export default useStyles;