
import antonnyprof from "../../../../assets/images/antonnyprof.svg"
import tomato from "../../../../assets/images/tomato.svg"
export const PoReceivedData = [
    {
        id: "16002",
        user: 'User 1',
        date: 'July 25,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen beirut',
        branch: "Branch 1",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing',
        supplier: [{ name: "Angelo Schultz", img: antonnyprof }],
        products: [
            {
                id: 'a1',
                name: 'Salad',
                requested: 25,
                received: [{ id: "5", recCount: 15 }],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '18 Oct 2023',
                        count: 15,
                    },
                    {
                        date: '25 May 2023',
                        count: 10,
                    },


                ]
            },
            {
                id: 'a2',
                name: 'Hommos',
                requested: 5,
                received: [{ id: "4", recCount: 15 }],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '25 May 2023',
                        count: 0,
                    },


                ]
            },

            {
                id: 'a4',
                name: 'Tomato',
                requested: 40,
                received: [{ id: "3", recCount: 15 }],
                oh: 0,
                img: tomato,
                date: [
                    {
                        date: '25 May 2023',
                        count: 0,
                    },
                    {
                        date: '8 Sep 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'a5',
                name: 'Hommos',
                requested: 5,
                received: [{ id: "2", recCount: 15 }],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 Sep 2023',
                        count: 2,
                    },
                    {
                        date: '27 Sep 2023',
                        count: 1,
                    }

                ]
            },
        ]
    },

    {
        id: "16003",
        user: 'User 3',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 1",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing',
        supplier: [{ name: "Antony Schultz", img: antonnyprof }],
        products: [
            {
                id: 'c1',
                name: 'Hommos',
                requested: 25,
                received: [{ id: "1", recCount: 15 }],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 Sep 2023',
                        count: 0,
                    },
                    {
                        date: '8 Sep 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'c2',
                name: 'Hommos',
                requested: 20,
                received: [{ id: "8", recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 Sep 2023',
                        count: 0,
                    },
                    {
                        date: '8 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },
    {
        id: "16004",
        user: 'User 4',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen joun',
        status: 'Sent',
        branch: "Branch 4",
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing',
        supplier: [{ name: "George Schultz", img: antonnyprof }],
        products: [
            {
                id: 'd1',
                name: 'Hommos',
                requested: 25,
                received: [{id: "11",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '22 May 2023',
                        count: 0,
                    },
                    {
                        date: '23 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'd2',
                name: 'chicken Breast',
                requested: 20,
                received: [{id: "12",recCount: 15}],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '11 May 2023',
                        count: 0,
                    },
                    {
                        date: '8 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },
    {
        id: "1789",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "213",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "153",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },
    {
        id: "1695",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "130",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "174",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },

    {
        id: "16785",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "18",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "75",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },

    {
        id: "26008",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "213",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "45",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },

    {
        id: "170077",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "173",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "157",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },

    {
        id: "17805",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "113",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "125",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },

    {
        id: "160305",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "13",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "135",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },

    {
        id: "160405",
        user: 'User 5',
        date: 'July 26,2022 9:00 PM',
        from: ' Central kitchen',
        to: 'kitchen bey',
        branch: "Branch 2",
        status: 'Sent',
        orderDate: '18 Oct 2023',
        orderPlacedDate: '22 Oct 2023',
        deliveryDate: 'Processing ',
        supplier: [{ name: "Lucas Schultz", img: antonnyprof }],
        products: [
            {
                id: 'e1',
                name: 'Hommos',
                requested: 20,
                received: [{id: "13",recCount: 15}],
                oh: 1,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '17 May 2023',
                        count: 0,
                    }

                ]
            },
            {
                id: 'e2',
                name: 'Salmon',
                requested: 20,
                received: [{ id: "145",recCount: 15 }],
                oh: 10,
                img: tomato,
                date: [
                    {
                        date: '8 May 2023',
                        count: 0,
                    },
                    {
                        date: '30 May 2023',
                        count: 0,
                    }

                ]
            }

        ]
    },


]

