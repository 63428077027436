import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({

background:{backgroundColor: theme.palette.primary.main, height: '70vh', width: '100%', borderBottomLeftRadius: '216px', borderBottomRightRadius: '216px',  },   

desktopBackground:{
// backgroundColor: theme.palette.primary.main,bottom:0, height: '-70vh', width: '100%', borderTopLeftRadius: '216px', borderTopRightRadius: '216px', 
 },

loginHeader: {  display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '10px',zIndex: '1' },

loginHeaderTitle: { fontSize: '25px', fontWeight: 700, marginTop: '10px', },

loginFormContainer: {  display: 'flex',  flexDirection: 'column', },

usernameLabel: {  color: theme.palette.text.fourth,  fontWeight: 500,  fontSize: '17px', },

textfield: { '& .MuiOutlinedInput-root': { borderRadius: '30px !important',height: '35px',backgroundColor: theme.palette.background.paper, },'& .MuiOutlinedInput-root input::placeholder': { fontStyle: 'oblique', fontWeight: 'lighter', }, },
forgotPassword:{display: 'flex', justifyContent: 'right', zIndex: '1', transform: 'translate(-5%, 0%)', fontSize: '13px', fontWeight: '500', cursor: 'pointer',},


loginButton:{width: '190px', height: '40px', fontSize: '14px' },

groupbuttons:{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px', marginTop: '20px' },
})
);

export default useStyles;