import humus from '../../../../assets/images/humus.jpg'


export interface CustomProdProduct {
    id: string;
    image: string;
    type: string;
    name: string;
    lastdate: string;
    quantity: string;
    qtyOnhand: string;
    qtyKg: string;
    reason: string;
    expiryDate: string;
    ingredient: {
        id: string;
        name: string;
        weight: string;
        qtyOnhand: string;
        specification: string,
    }[];

}
export type productionArr = (CustomProdProduct)[]

const CustomProdLookupData: productionArr = [
    {
        id: '1',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fatteh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "389",
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [

            { id: "b", name: "Garlic", weight: "0", specification: "Auto Produce", qtyOnhand: "20" },
            { id: "1323", name: "Chickpeas", weight: "0", specification: "Boiled Chickpeas", qtyOnhand: "20" },
            { id: "98573", name: "Aubergine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "98hj77", name: "Garlic", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "98435dd", name: "Salt", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "00pp0p", name: "Chickpeas", weight: "0", specification: "Boiled Chickpeas", qtyOnhand: "20" },
            { id: "33rrtt", name: "Aubergine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "22wwee", name: "Garlic", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "11qqaa", name: "Salt", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "98573", name: "Aubergine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "98hj77", name: "Garlic", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "98435dd", name: "Salt", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "00pp0p", name: "Chickpeas", weight: "0", specification: "Boiled Chickpeas", qtyOnhand: "20" },
            { id: "33rrtt", name: "Aubergine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "22wwee", name: "Garlic", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "11qqaa", name: "Salt", weight: "0", specification: "", qtyOnhand: "20" },
        ]

    },
    {
        id: '2',
        image: humus,
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "25",
        reason: "Request",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "c", name: "Chickpeas", weight: "0", specification: "Boiled Chickpeas", qtyOnhand: "20" },
            { id: "sd33", name: "Aubergine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "c55", name: "Garlic", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "46c", name: "Salt", weight: "0", specification: "", qtyOnhand: "20" },
        ]


    },
    {
        id: '5',
        image: humus,
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        qtyKg: '5',
        quantity: "0",
        qtyOnhand: "5",
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "1212d", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "c5yu7", name: "Garlic", weight: "0", specification: "Auto Produce", qtyOnhand: "20" },
            { id: "4c77", name: "Salt", weight: "0", specification: "", qtyOnhand: "20" },
        ]


    },
    {
        id: '3',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "5f44", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "5e565", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },
    {
        id: '673',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "45r", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "09ij", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },

    {
        id: '563',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "98h", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "03er", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },

    {
        id: '302',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "8uy", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "55rt", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },

    {
        id: '793',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "rt5", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "89i", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },

    {
        id: '377',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "013vv", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "0ert", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },

    {
        id: '345',
        image: humus,
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        qtyKg: '3',
        quantity: "0",
        qtyOnhand: '5',
        reason: "Event",
        expiryDate: "09 Sep 2023",
        ingredient: [
            { id: "136", name: "Tehine", weight: "0", specification: "", qtyOnhand: "20" },
            { id: "776", name: "Chickpeas", weight: "0", specification: "", qtyOnhand: "20" },

        ]

    },



]



export default CustomProdLookupData;