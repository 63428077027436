import { EmployeeFpActionTypes, REMOVE_FROM_EMPLOYEE_DETAILS, INCREMENT_EMPLOYEE, DECREMENT_EMPLOYEE, ADJUST_EMPLOYEE_QUANTITY, EDIT_EMPLOYEE_COMMENT } from '../actions/employeeFinishedProductsActions '
import { AppState, Product } from '../actions/employeeFinishedProductsActions ';
import EmployeeFPData from '../../pages/employee/employee-finished-products/employee-fp-data';

const INITIAL_STATE: AppState = {
    employeeProducts: EmployeeFPData as Product[],
    employeeDetails: [] as Product[],
}

const updateProductLookup = (products: Product[], productId: string, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        let count = expiry.count;
                        count += 1;
                        return { ...expiry, count };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    })
}

const decrementProductLookup = (products: Product[], productId: string, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, eIndex) => {
                    if (eIndex === expiryIndex && typeof expiry.count === 'number' && expiry.count > 0) {
                        return {
                            ...expiry,
                            count: expiry.count - 1,
                        };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const updateDetailsWithProduct = (details: any, updatedProducts: Product[], productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateQuantityAndDetails = (
    products: Product[], productId: string, expiryIndex: number,
    newQuantity: number, details: any[],
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const employeeRawMaterilasReducer = (state = INITIAL_STATE, action: EmployeeFpActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    switch (action.type) {

        case INCREMENT_EMPLOYEE:
            updatedProducts = updateProductLookup(state.employeeProducts, action.payload.productId, action.payload.expieryIndex)
            updatedDetails = updateDetailsWithProduct(state.employeeDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                employeeProducts: updatedProducts,
                employeeDetails: updatedDetails
            };

        case DECREMENT_EMPLOYEE:
            updatedProducts = decrementProductLookup(state.employeeProducts, action.payload.productId, action.payload.expieryIndex)

            updatedDetails = updateDetailsWithProduct(
                state.employeeDetails,
                updatedProducts,
                action.payload.productId
            );
            return {
                ...state,
                employeeProducts: updatedProducts,
                employeeDetails: updatedDetails
            };

        case REMOVE_FROM_EMPLOYEE_DETAILS:
            updatedDetails = state.employeeDetails.filter((item) => item.id !== action.payload.itemID);
            const itemToRemove = state.employeeDetails.find((item) => item.id === action.payload.itemID);
            if (itemToRemove) {
                if ('expiry' in itemToRemove) {
                    itemToRemove.expiry.forEach((expiry) => {
                        expiry.count = 0;
                    });
                }
            }
            return {
                ...state,
                employeeDetails: updatedDetails,
            };

        case ADJUST_EMPLOYEE_QUANTITY:
            const { productId, expieryIndex, newQuantity } = action.payload;
            updatedProducts = updateQuantityAndDetails(
                state.employeeProducts,
                productId,
                expieryIndex,
                newQuantity,
                state.employeeDetails
            );
            updatedDetails = updateDetailsWithProduct(
                state.employeeDetails,
                updatedProducts,
                productId
            );
            return {
                ...state,
                employeeProducts: updatedProducts,
                employeeDetails: updatedDetails,
            };

        case EDIT_EMPLOYEE_COMMENT:
            updatedProducts = state.employeeProducts.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        comment: action.payload.comment,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(
                state.employeeDetails,
                updatedProducts,
                action.payload.productId
            );

            return {
                ...state,
                employeeProducts: updatedProducts,
                employeeDetails: updatedDetails,
            };


        default:
            return state;
    }
};
export default employeeRawMaterilasReducer;