import antonnyprof from "../../../assets/images/antonnyprof.svg"
import annanas from '../../../assets/images/annanas.svg'
import fish from '../../../assets/images/fish.svg'
import cannedfood from '../../../assets/images/cannedfood.svg'
import tinnedfood from '../../../assets/images/tinnedfood.svg'
import tomato from "../../../assets/images/tomato.svg"



export interface Products {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    quantity: number;
    availableqty: string;
    image: string;
    weight: number;
    Unpacked: number;
    expiry: {

        date: string;
        count: number;
    }[]

}

export interface BrandProduct {
    id: string;
    type: string;
    name: string;
    image: string;
    lastdate: string;
    Unpacked: number;
    prodbrands: {
        id: string;
        name: string;
        description: string;
        quantity: number;
        cost: number;
        availableqty: string;
        qty: { type: string, count: number }[]
        expiry: {
            qtyType: string;
            date: string;
            count: number
        }[];

    }[]
}





type prodArr = (BrandProduct | Products)[]

export const StockTakeData: prodArr = [
    {
        id: '3',
        type: 'Dry Food',
        name: 'Corn',
        Unpacked: 0,
        lastdate: '09 Sep 2023',
        image: tinnedfood,
        prodbrands: [
            {
                id: '185-ps-corn-1',
                name: 'Plein Soleil',
                description: "500g",
                cost: 3,
                quantity: 0,
                availableqty: "60",
                qty: [{ type: "box", count: 0 }, { type: "can", count: 0 }],
                expiry: [
                    { date: '18/01/2023', count: 0, qtyType: 'box' },
                    { date: '22/08/2023', count: 0, qtyType: 'can' }
                ]
            },
            {
                id: '297-awa-corn-2',
                name: 'Al Wadi el AKhdar',
                description: "480g",
                cost: 2,
                quantity: 0,
                availableqty: "60",
                qty: [{ type: "box", count: 0 }, { type: "can", count: 0 }],
                expiry: [
                    { date: '18/01/2023', count: 0, qtyType: 'box' },
                    { date: '22/08/2023', count: 0, qtyType: 'box' },
                    { date: '07/11/2023', count: 0, qtyType: 'box' },
                    { date: '11/12/2023', count: 0, qtyType: 'can' }
                ]
            },
            {
                id: '257-max-corn-4',
                name: 'Maxims',
                description: "700g",
                cost: 2.5,
                quantity: 0,
                availableqty: "60",
                qty: [{ type: "box", count: 0 }, { type: "can", count: 0 }],
                expiry: [
                    { date: '19/10/2025', count: 0, qtyType: 'box' },
                    { date: '19/10/2026', count: 0, qtyType: 'can' },
                    { date: '20/02/2030', count: 0, qtyType: 'can' },
                ]
            },
            {
                id: '259-max-corn-4',
                name: 'Cedars',
                description: "700g",
                cost: 2.5,
                quantity: 0,
                availableqty: "60",
                qty: [{ type: "box", count: 0 }, { type: "can", count: 0 }],
                expiry: [
                    { date: '19/10/2025', count: 0, qtyType: 'box' },
                    { date: '19/10/2026', count: 0, qtyType: 'can' },
                    { date: '20/02/2030', count: 0, qtyType: 'can' },
                ]
            },
        ]
    },
    {
        id: '1',
        type: 'Pantry',
        name: 'Tomato',
        weight: 200,
        quantity: 0,
        lastdate: '09 Sep 2023',
        availableqty: "60",
        Unpacked: 0,
        image: cannedfood,

        expiry: [
            { date: '18/01/2023', count: 0 },
            { date: '22/08/2023', count: 0 }
        ]

    },
    {
        id: '2',
        type: 'Pantry',
        name: 'Lettuce',
        weight: 200,
        quantity: 0,
        lastdate: '09 Sep 2023',
        availableqty: "60",
        image: tinnedfood,
        Unpacked: 0,
        expiry: [
            { date: '18/01/2023', count: 0 },
            { date: '22/08/2023', count: 0 }
        ]

    },

    {
        id: '7',
        type: 'Pantry',
        name: 'Tomato',
        weight: 200,
        quantity: 0,
        lastdate: '09 Sep 2023',
        availableqty: "60",
        Unpacked: 0,
        image: cannedfood,
        expiry: [
            { date: '18/01/2023', count: 0 },
            { date: '22/08/2023', count: 0 }
        ]

    },
    {
        id: '8',
        type: 'Pantry',
        name: 'Lettuce',
        weight: 200,
        quantity: 0,
        lastdate: '09 Sep 2023',
        availableqty: "60",
        image: tinnedfood,
        Unpacked: 0,
        expiry: [
            { date: '18/01/2023', count: 0 },
            { date: '22/08/2023', count: 0 }
        ]

    },
    {
        id: '9',
        type: 'Pantry',
        name: 'Tomato',
        weight: 200,
        quantity: 0,
        lastdate: '09 Sep 2023',
        availableqty: "60",
        image: cannedfood,
        Unpacked: 0,
        expiry: [
            { date: '18/01/2023', count: 0 },
            { date: '22/08/2023', count: 0 }
        ]

    },
    {
        id: '10',
        type: 'Pantry',
        name: 'Lettuce',
        weight: 200,
        quantity: 0,
        lastdate: '09 Sep 2023',
        availableqty: "60",
        image: tinnedfood,
        Unpacked: 0,
        expiry: [
            { date: '18/01/2023', count: 0 },
            { date: '22/08/2023', count: 0 }
        ]

    },




]