import { Dispatch, Action } from 'redux';

export const INCREMENT_EMPLOYEE = 'INCREMENT_EMPLOYEE'
export const DECREMENT_EMPLOYEE = 'DECREMENT_EMPLOYEE'
export const ADJUST_EMPLOYEE_QUANTITY = 'ADJUST_EMPLOYEE_QUANTITY';
export const EDIT_EMPLOYEE_COMMENT = 'EDIT_EMPLOYEE_COMMENT';
export const REMOVE_FROM_EMPLOYEE_DETAILS = 'REMOVE_FROM_EMPLOYEE_DETAILS'

export interface Product {
    map(arg0: (item: any) => any): any
    id: string;
    type: string;
    name: string;
    lastdate: string;
    cost: number;
    expiry: { date: string; count: number; }[]
    reason: string;
    comment?: string;
}

export interface MultiBrandProduct {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    reason: string;
    comment?: string;
    Unpacked: number;
    brands: {
        id: string; name: string; description: string; cost: number;
        expiry: { date: string; count: number }[];
    }[]
}

export type prodArr = (MultiBrandProduct | Product)[]

export interface AppState {
    employeeProducts: Product[];
    employeeDetails: Product[];
}

interface IncrementEmployeeAction extends Action<typeof INCREMENT_EMPLOYEE> {
    payload: {
        productId: string;
        expieryIndex: number;
    }
}

interface DecrementEmployeeAction extends Action<typeof DECREMENT_EMPLOYEE> {
    payload: {
        productId: string;
        expieryIndex: number;
    }
}

interface AdjustEmployeeQuantityAction extends Action<typeof ADJUST_EMPLOYEE_QUANTITY> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
        newQuantity: number;
    };
}

interface RemoveFromEmployeeAction extends Action<typeof REMOVE_FROM_EMPLOYEE_DETAILS> {
    payload: {
        itemID: string;
    }

}
interface EditEmployeeAction extends Action<typeof EDIT_EMPLOYEE_COMMENT> {
    payload: {
        productId: string;
        comment: string;
    };
}

export type EmployeeFpActionTypes = | IncrementEmployeeAction
    | DecrementEmployeeAction
    | AdjustEmployeeQuantityAction
    | RemoveFromEmployeeAction
    | EditEmployeeAction

const incrementEmployeeQty = (productId: string, expieryIndex: number): IncrementEmployeeAction => ({ type: INCREMENT_EMPLOYEE, payload: { productId, expieryIndex, } });

const decrementEmployeeQty = (productId: string, expieryIndex: number): DecrementEmployeeAction => ({ type: DECREMENT_EMPLOYEE, payload: { productId, expieryIndex, } });

const adjustEmployeeQty = (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number): AdjustEmployeeQuantityAction => ({ type: ADJUST_EMPLOYEE_QUANTITY, payload: { productId, brandIndex, expieryIndex, newQuantity } });

const removeFromEmpDet = (itemID: string): RemoveFromEmployeeAction => ({ type: REMOVE_FROM_EMPLOYEE_DETAILS, payload: { itemID } });

const editEmpComment = (productId: string, comment: string): EditEmployeeAction => ({ type: EDIT_EMPLOYEE_COMMENT, payload: { productId, comment } });

export const EmployeeFpActions = {
    incrementEmployee: (productId: string, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementEmployeeQty(productId, expieryIndex)); },
    decrementEmployee: (productId: string, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementEmployeeQty(productId, expieryIndex)); },
    adjustEmployeeQuantity: (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustEmployeeQty(productId, brandIndex, expieryIndex, newQuantity)); },
    removeFromEmployeeDetails: (itemID: string) => (dispatch: Dispatch<any>) => { dispatch(removeFromEmpDet(itemID)); },
    editEmployeeComment: (productId: string, comment: string) => (dispatch: Dispatch<any>) => { dispatch(editEmpComment(productId, comment)); },
};