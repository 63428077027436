import {
    GET_TRANSLATION_REQUEST,
    GET_TRANSLATION_SUCCESS,
    GET_TRANSLATION_ERROR,
    SET_LANGUAGE
} from "../actions/Translation.action";

const initialState = {
    loading: false,
    supportedLanguages: [],
    translation: {},
    language:"english"
  };
function translation(state = initialState, action:any) {
    
    switch (action.type) {
        case GET_TRANSLATION_REQUEST:
            return Object.assign({}, state, {

                loading: true,
            });
        case GET_TRANSLATION_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                supportedLanguages: action.payload.supportedLanguages,
                translation: action.payload.translation,
            });
        case GET_TRANSLATION_ERROR:
            return Object.assign({}, state, {
                loading: false,
            });
        case SET_LANGUAGE:
            return Object.assign({}, state, {
                language: action.payload
            })
              default:
                return state
            }
          }
export default translation;