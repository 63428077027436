import Humus from '../../assets/images/humus.jpg'
import Corn from '../../assets/images/OIP.jpeg';
import { prodArr } from '../../redux/actions/internalRequestActions'

const InternalRequestData: prodArr = [
    {
        id: '1',
        name: 'Humus',
        img:Humus,
        type:"Mezza Berde",
        avQty: 60,
        level: 'none',
        lastdate: '09 Sep 2023',
  expiry: [
    {id:'a',qtyType:'Qty.On', count:0},
    {id:'b',qtyType:'Qty.Req', count:0}
  ],
    },
    {
        id: '2',
      name: 'Tabbouleh',
      img:Humus,
      type:"Mezza Berde",
      avQty: 60,
      level: 'none',
      lastdate: '09 Sep 2023',
      expiry:[
        {id:'c',qtyType:'Qty.On', count:0},
        {id:'d',qtyType:'Qty.Req', count:0}
      ],
    },
    {
        id: '3',
     name: 'Wine',
        img:Humus,
        type:"Drinks",
        avQty: 2,
        level: 'Reorder',
        lastdate: '09 Sep 2023',
        expiry:[
            {id:'e',qtyType:'Qty.On', count:0},
            {id:'f',qtyType:'Qty.Req', count:0}
          ],
      },
      {
        id: '4',
        name: 'Corn',
        img:Corn,
        type:"Dry Food",
        avQty: 70,
        level: 'none',
        lastdate: '09 Sep 2023',
        baseUnit: [ {id:'b-u-a1',qtyType:'Qty.On',count: 0 ,weightType:'Kg'},{id:'b-u-a2',qtyType:'Qty.Req',count: 0,date:'24/01/2024',weightType:'Kg'}, ],
        brands:[
          {
            id: '124-ps-corn-3',
            name: 'Plein Soleil',
            boxSize:10,
            canWeight:400,
            avBoxes:2,
            avCans:7,
            expiry: [
                { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
                { qtyType:'Qty.Req', boxCount: 0, cansCount: 0, date:'24/01/2024', },
            ]
        },
        {
            id: '235-awa-corn-5',
            name: 'Wadi Akhdar',
            boxSize:12,
            canWeight:400,
            avBoxes:3,
            avCans:6,
            expiry: [
                { qtyType:'Qty.On', boxCount: 0, cansCount: 0,},
                { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'21/03/2024',},
            ]
        },
        {
            id: '269-max-corn-7',
            name: 'Maxims',
            boxSize:12,
            canWeight:800,
            avBoxes:2,
            avCans:6,
            expiry: [
              { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
              { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'15/05/2024', },
         
            ]
        },]
    },
      {
        id: '5',
        name: 'Corn',
        img:Corn,
        type:"Dry Food",
        avQty: 5,
        level: 'Reorder',
        lastdate: '09 Sep 2023',
        baseUnit: [ {id:'b-u-b1',qtyType:'Qty.On',count: 0,weightType:'Kg' },{id:'b-u-b2',qtyType:'Qty.Req',count: 0,date:'24/01/2024',weightType:'Kg'}, ],
        brands:[
          {
            id: '127-ps-corn-1',
            name: 'Plein Soleil',
            boxSize:10,
            canWeight:400,
            avBoxes:2,
            avCans:7,
            expiry: [
                { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
                { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'24/01/2024', },
            ]
        },
        {
            id: '228-awa-corn-3',
            name: 'Wadi Akhdar',
            boxSize:12,
        canWeight:400,
        avBoxes:3,
        avCans:6,
            expiry: [
                { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
                { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'21/03/2024', },
            ]
        },
        {
            id: '258-max-corn-5',
            name: 'Maxims',
            boxSize:12,
            canWeight:800,
            avBoxes:2,
            avCans:6,
            expiry: [
              { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
              { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'15/05/2024',},
         
            ]
        },]
    },
       

    {
        id: '6',
       name: 'Beer',
        img:Humus,
        type:"Drinks",
        avQty: 2,
        level: 'Reorder',
        lastdate: '09 Sep 2023',
        expiry:[
            {id:'g',qtyType:'Qty.On', count:0},
            {id:'h',qtyType:'Qty.Req', count:0}
          ],
    },
    {
      id: '7',
      name: 'Mushroom',
      img:Humus,
      type:"Dry Food",
      avQty: 2,
      level: 'Reorder',
      lastdate: '09 Sep 2023',
      baseUnit: [ {id:'b-u-c1',qtyType:'Qty.On',count: 0,weightType:'Kg' },{id:'b-u-c2',qtyType:'Qty.Req',count: 0,date:'24/01/2024',weightType:'Kg'}, ],
      brands:[
        {
          id: '190-ps-corn-7',
          name: 'Plein Soleil',
          boxSize:10,
          canWeight:400,
          avBoxes:2,
          avCans:7,
          expiry: [
              { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
              { qtyType:'Qty.Req',boxCount: 0, cansCount: 0, date:'24/01/2024', },
          ]
      },
      {
          id: '280-awa-corn-8',
          name: 'Wadi Akhdar',
          boxSize:12,
          canWeight:400,
          avBoxes:3,
            avCans:6,
          expiry: [
              { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
              { qtyType:'Qty.Req', boxCount: 0, cansCount: 0, date:'21/03/2024', },
          ]
      },
      {
          id: '270-max-corn-9',
          name: 'Maxims',
          boxSize:12,
          canWeight:800,
          avBoxes:2,
          avCans:6,
          expiry: [
            { qtyType:'Qty.On',  boxCount: 0, cansCount: 0, },
            { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,   date:'15/05/2024',},
       
          ]
      },]
  },
    {
        id: '8',
     name: 'Kafta',
     img:Humus,
        type:"Mezza Sokhne",
        avQty: 2,
        level: 'Reorder',
        lastdate: '09 Sep 2023',
        expiry:[
            {id:'i',qtyType:'Qty.On', count:0},
            {id:'j',qtyType:'Qty.Req', count:0}
          ],
    },
    {
        id: '9',
        name: 'Taouk',
        img:Humus,
        type:"Mezza Sokhne",
        avQty: 60,
        level: 'none',
        lastdate: '09 Sep 2023',
        expiry:[
            {id:'k',qtyType:'Qty.On', count:0},
            {id:'l',qtyType:'Qty.Req', count:0}
          ],
    },
    {
        id: '10',
        name: 'Product Name',
        img:Humus,
        type:"Dry Food",
        avQty: 60,
        level: 'none',
        lastdate: '09 Sep 2023',
        expiry:[
            {id:'m',qtyType:'Qty.On', count:0},
            {id:'n',qtyType:'Qty.Req', count:0}
          ],
    },

    {
      id: '11',
      name: 'Corn',
      img:Corn,
      type:"Dry Food",
      avQty: 8,
      level: 'Reorder',
      lastdate: '09 Sep 2023',
      baseUnit: [ {id:'b-u-d1',qtyType:'Qty.On',count: 0 ,weightType:'Kg'},{id:'b-u-d2',qtyType:'Qty.Req',count: 0,date:'24/01/2024',weightType:'Kg'}, ],
      brands:[
        {
          id: '125-ps-corn-1',
          name: 'Plein Soleil',
          boxSize:10,
          canWeight:400,
          avBoxes:2,
          avCans:7,
          expiry: [
              { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
              { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,    date:'24/01/2024', },
          ]
      },
      {
          id: '227-awa-corn-2',
          name: 'Wadi Akhdar',
          boxSize:12,
          canWeight:400,
          avBoxes:3,
          avCans:6,
          expiry: [
              { qtyType:'Qty.On',  boxCount: 0, cansCount: 0, },
              { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,   date:'21/03/2024',},
          ]
      },
      {
          id: '254-max-corn-4',
          name: 'Maxims',
          boxSize:12,
        canWeight:800,
        avBoxes:2,
        avCans:6,
          expiry: [
            { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
            { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,   date:'15/05/2024', },
       
          ]
      },]
  },

  {
    id: '12',
    name: 'Corn',
    img:Corn,
    type:"Dry Food",
    avQty: 20,
    level: 'none',
    lastdate: '09 Sep 2023',
    baseUnit: [ {id:'b-u-e1',qtyType:'Qty.On',count: 0,weightType:'Kg' },{id:'b-u-e2',qtyType:'Qty.Req',count: 0,date:'24/01/2024',weightType:'Kg'}, ],
    brands:[
      {
        id: '185-ps-corn-88',
        name: 'Plein Soleil',
    boxSize:10,
    canWeight:400,
      avBoxes:2,
      avCans:7,
        expiry: [
            { qtyType:'Qty.On',  boxCount: 0, cansCount: 0, },
            { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'24/01/2024',},
        ]
    },
    {
        id: '287-awa-corn-3',
        name: 'Wadi Akhdar',
        boxSize:12,
        canWeight:400,
        avBoxes:3,
        avCans:6,
        expiry: [
            { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
            { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,   date:'21/03/2024',},
        ]
    },
    {
        id: '284-max-corn-4',
        name: 'Maxims',
        boxSize:12,
        canWeight:800,
        avBoxes:2,
            avCans:6,
        expiry: [
          { qtyType:'Qty.On',  boxCount: 0, cansCount: 0,},
          { qtyType:'Qty.Req', boxCount: 0, cansCount: 0,  date:'15/05/2024', },
     
        ]
    },]
},

];

export default InternalRequestData;