import { createStyles, makeStyles } from '@mui/styles';
import { CustomTheme } from '../../../theme/index';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({

langbutton: { fontSize: '16px !important', },

menuBold: {fontWeight: 'bold','&:hover': {opacity: '70%'}, display:'flex', alignItems:'center', fontSize:"16px" , lineHeight:"1" , color:"#0F2B5D"},

listButton:{ textAlign: 'center', fontWeight: 'bold','&:hover': {opacity: '70%'}},

submenu: { maxHeight: 0,overflow: 'hidden',transition: 'max-height 0.5s ease',},

submenuVisible: {maxHeight: 1000,},

noBullet:{listStyleType: 'none',padding:'0px 40px 0px 40px'},

submenuOpened:{  maxHeight: '500px',  opacity: '1', transition: 'max-height 0.3s ease, opacity 0.3s ease'},

submenuClosed:{ maxHeight: '0',  opacity: '0', overflow: 'hidden', transition: 'max-height 0.3s ease, opacity 0.3s ease'},

desktopButton:{textAlign: 'left', borderRadius: '20px !important',  transition: 'all 0.3s ease !important', '&:hover':{ border:'2px solid !important',transition: 'border 0.3s ease !important'}},

subMenuButton:{textAlign: 'left', borderRadius: '20px !important',   transition: 'all 0.3s ease !important', '&:hover':{ border:'2px solid !important',transition: 'border 0.3s ease !important'},},

})
);

export default useStyles;