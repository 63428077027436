import {
    InternalRequestActionTypes, INCREMENT_BOX_QUANTITY, DECREMENT_BOX_QUANTITY, ADJUST_BOX_QUANTITY,
    INCREMENT_CAN_QUANTITY, DECREMENT_CAN_QUANTITY, ADJUST_CAN_QUANTITY,
    ADD_REMARKS_TO_REQUEST, REMOVE_PRODUCT_FROM_REQUEST, REMOVE_BRAND_FROM_REQUEST,
    INCREMENT_BASE_UNIT_QUANTITY, DECREMENT_BASE_UNIT_QUANTITY, UPDATE_BASE_UNIT_QUANTITY, UPDATE_PRODUCT_QUANTITY, CHANGE_WEIGHT_TYPE
} from '../actions/internalRequestActions'
import { AppState, prodArr } from '../actions/internalRequestActions';
import InternalRequestData from '../../pages/order/internal-request-data'

const INITIAL_STATE: AppState = {
    irProducts: InternalRequestData as prodArr,
    irDetails: [] as prodArr,
}

const incrementBoxCounter = (products: prodArr, productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof expiry.boxCount === 'number'
                                    ) {
                                        return {
                                            ...expiry,
                                            boxCount: expiry.boxCount + 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === qtyIndex) {
                        let count = expiry.count;
                        count += 1;
                        return { ...expiry, count };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    })
}

const decrementBoxCounter = (products: prodArr, productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof expiry.boxCount === 'number' &&
                                        expiry.boxCount > 0
                                    ) {
                                        return {
                                            ...expiry,
                                            boxCount: expiry.boxCount - 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, eIndex) => {
                    if (eIndex === qtyIndex && typeof expiry.count === 'number' && expiry.count > 0) {
                        return {
                            ...expiry,
                            count: expiry.count - 1,
                        };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const incrementCanCounter = (products: prodArr, productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof expiry.cansCount === 'number'
                                    ) {
                                        return {
                                            ...expiry,
                                            cansCount: expiry.cansCount + 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    })
}

const decrementCanCounter = (products: prodArr, productId: string, brandIndex: number | null, qtyIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === qtyIndex &&
                                        typeof expiry.cansCount === 'number' &&
                                        expiry.cansCount > 0
                                    ) {
                                        return {
                                            ...expiry,
                                            cansCount: expiry.cansCount - 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            }
        }
        return product;
    });
};

const incrementBaseUnitInput = (products: prodArr, productId: string, baseUnitIndex: number,) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('baseUnit' in product) {
                product.baseUnit = product.baseUnit?.map((bUnit, i) => {
                    if (i === baseUnitIndex) {
                        let count = bUnit.count;
                        count += 1;
                        return { ...bUnit, count };
                    }
                    return { ...bUnit };
                });
            }
        }
        return product;
    })
}

const decrementBaseUnitInput = (products: prodArr, productId: string, baseUnitIndex: number,) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('baseUnit' in product) {
                product.baseUnit = product.baseUnit?.map((bUnit, buIndex) => {
                    if (buIndex === baseUnitIndex && typeof bUnit.count === 'number' && bUnit.count > 0) {
                        return {
                            ...bUnit,
                            count: bUnit.count - 1,
                        };
                    }
                    return { ...bUnit };
                });
            }
        }
        return product;
    });
};

const updateDetailsWithProduct = (details: any, updatedProducts: prodArr, productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateBoxQuantity = (
    products: prodArr, productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number,
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (eIndex === qtyIndex && typeof expiry.boxCount === 'number') {
                                        return {
                                            ...expiry,
                                            boxCount: newQuantity,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === qtyIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const updateCanQuantity = (
    products: prodArr, productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number,
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (eIndex === qtyIndex && typeof expiry.cansCount === 'number') {
                                        return {
                                            ...expiry,
                                            cansCount: newQuantity,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === qtyIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const setBaseUnitToZero = (details: prodArr, productId: string,) => {
    const bUToZero = details.find((item) => item.id === productId);
    if (bUToZero) {
        if ('baseUnit' in bUToZero) { bUToZero.baseUnit.forEach((b) => { b.count = 0; }); }
    }
}
const setBoxAndCansUnitToZero = (details: prodArr, productId: string,) => {
    const boxAndCansToRemove = details.find((item) => item.id === productId);
    if (boxAndCansToRemove) {
        if ('brands' in boxAndCansToRemove) {
            boxAndCansToRemove.brands.forEach((brand) => {
                brand.expiry.forEach((q) => {
                    q.boxCount = 0;
                    q.cansCount = 0;
                });
            });
        }
    }
}

const internalRequestReducer = (state = INITIAL_STATE, action: InternalRequestActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    let bUToZero;
    let boxAndCansToRemove;
    switch (action.type) {

        case INCREMENT_BOX_QUANTITY:
            updatedProducts = incrementBoxCounter(state.irProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.irDetails, action.payload.productId)
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case DECREMENT_BOX_QUANTITY:
            updatedProducts = decrementBoxCounter(state.irProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case ADJUST_BOX_QUANTITY:
            const { productId, brandIndex, qtyIndex, newQuantity } = action.payload;
            updatedProducts = updateBoxQuantity(state.irProducts, productId, brandIndex, qtyIndex, newQuantity,);
            bUToZero = setBaseUnitToZero(state.irDetails, action.payload.productId)
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case INCREMENT_CAN_QUANTITY:
            updatedProducts = incrementCanCounter(state.irProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            bUToZero = setBaseUnitToZero(state.irDetails, action.payload.productId)
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case DECREMENT_CAN_QUANTITY:
            updatedProducts = decrementCanCounter(state.irProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex);
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case ADJUST_CAN_QUANTITY:
            updatedProducts = updateCanQuantity(state.irProducts, action.payload.productId, action.payload.brandIndex, action.payload.qtyIndex, action.payload.newQuantity,);
            bUToZero = setBaseUnitToZero(state.irDetails, action.payload.productId)
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case REMOVE_PRODUCT_FROM_REQUEST:
            updatedDetails = state.irDetails.filter((item) => item.id !== action.payload.itemID);
            const itemToRemove = state.irDetails.find((item) => item.id === action.payload.itemID);
            if (itemToRemove) {
                if ('expiry' in itemToRemove) {
                    itemToRemove.expiry.forEach((q) => {
                        q.count = 0;
                    });
                }
            }
            return {
                ...state,
                irDetails: updatedDetails,
            };
        case ADD_REMARKS_TO_REQUEST:
            updatedProducts = state.irProducts.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        remarks: action.payload.remarks,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irDetails: updatedDetails,
                irProducts: updatedProducts,
            };

        case REMOVE_BRAND_FROM_REQUEST:
            updatedDetails = state.irDetails.filter((item) => item.id !== action.payload.brandID);
            const productToRemove = state.irDetails.find((item) => item.id === action.payload.brandID);
            if (productToRemove) {
                if ('brands' in productToRemove) {
                    productToRemove.brands.forEach((brand) => {
                        brand.expiry.forEach((q) => {
                            q.boxCount = 0;
                            q.cansCount = 0;
                        });
                    });
                    productToRemove.baseUnit.forEach((bU) => { bU.count = 0; });
                }
                return {
                    ...state,
                    irDetails: updatedDetails,
                };
            }
            return state;

        case INCREMENT_BASE_UNIT_QUANTITY:
            updatedProducts = incrementBaseUnitInput(state.irProducts, action.payload.productId, action.payload.baseUnitIndex,);
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.irDetails, action.payload.productId)
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case DECREMENT_BASE_UNIT_QUANTITY:
            updatedProducts = decrementBaseUnitInput(state.irProducts, action.payload.productId, action.payload.baseUnitIndex,);
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case UPDATE_BASE_UNIT_QUANTITY:
            updatedProducts = state.irProducts.map((product) => {
                if ('baseUnit' in product) {
                    product.baseUnit = product.baseUnit.map((bUnit) => {
                        if (bUnit.id === action.payload.baseUnitId) {
                            return { ...bUnit, count: action.payload.newCount };
                        }
                        return { ...bUnit };
                    });
                }
                return { ...product };
            });
            boxAndCansToRemove = setBoxAndCansUnitToZero(state.irDetails, action.payload.productId)
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case UPDATE_PRODUCT_QUANTITY:
            updatedProducts = state.irProducts.map((product) => {
                if ('expiry' in product) {
                    product.expiry = product.expiry.map((exp) => {
                        if (exp.id === action.payload.qtyId) {
                            return { ...exp, count: action.payload.newCount };
                        }
                        return { ...exp };
                    });
                }
                return { ...product };
            });
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                irDetails: updatedDetails,
            };

        case CHANGE_WEIGHT_TYPE:
            updatedProducts = state.irProducts.map((product) => {
                if ('baseUnit' in product) {
                    product.baseUnit = product.baseUnit.map((bUnit) => {
                        if (bUnit.id === action.payload.baseUnitId) {
                            const newCount =
                                action.payload.weightType === 'Gr'
                                    ? bUnit.count * 1000
                                    : bUnit.count / 1000;
                            return { ...bUnit, weightType: action.payload.weightType, count: newCount };
                        }
                        return { ...bUnit };
                    });
                }
                return { ...product };
            });
            updatedDetails = updateDetailsWithProduct(state.irDetails, updatedProducts, action.payload.productId);
            return {
                ...state,
                irProducts: updatedProducts,
                // irDetails: updatedDetails,
            };

        default:
            return state;
    }
};
export default internalRequestReducer;