export interface Product {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    cost: number;
    expiry: {
        date: string;
        count: number;
    }[]
    reason?: string;
    comment?: string;
}

const EmployeeFPData: Product[] = [
    {
        id: '1',
        type: 'Mezza Berde',
        name: 'Fatteh',
        lastdate: '09 Sep 2023',
        cost: 22,
        expiry: [
            { date: '22/02/2023', count: 0 },
        ],
    },

    {
        id: '2',
        type: 'Mezza Berde',
        name: 'Tabbouleh',
        lastdate: '09 Sep 2023',
        cost: 20,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },
    {
        id: '3',
        type: 'Mezza Berde',
        name: 'Fattouch',
        lastdate: '09 Sep 2023',
        cost: 25,
        expiry: [
            { date: '23/04/2023', count: 0 },
        ],
    },

    {
        id: '4',
        type: 'Mezza Berde',
        name: 'Hommos',
        lastdate: '09 Sep 2023',
        cost: 5,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '5',
        type: 'Salad',
        name: 'Salade chevre',
        lastdate: '09 Sep 2023',
        cost: 7,
        expiry: [
            { date: '23/04/2023', count: 0 },
        ],
    },

    {
        id: '6',
        type: 'Salad',
        name: 'Salade Quinoa',
        lastdate: '09 Sep 2023',
        cost: 50,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '7',
        type: 'Salad',
        name: 'Salade pattes',
        lastdate: '09 Sep 2023',
        cost: 3,
        expiry: [
            { date: '23/04/2023', count: 0 },
        ],
    },

    {
        id: '8',
        type: 'Mezza Sokhne',
        name: 'Kafta',
        lastdate: '09 Sep 2023',
        cost: 27,
        expiry: [
            { date: '23/04/2023', count: 0 },
        ],
    },

    {
        id: '9',
        type: 'Sauces',
        name: 'Drob Sauce',
        lastdate: '09 Sep 2023',
        cost: 1,
        expiry: [
            { date: '23/04/2023', count: 0 },
        ],
    },

    {
        id: '10',
        type: 'Sauces',
        name: 'Thousand Island',
        lastdate: '09 Sep 2023',
        cost: 1,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '11',
        type: 'Drinks',
        name: 'Coke',
        lastdate: '09 Sep 2023',
        cost: 1,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
            { date: '19/05/2023', count: 0 },

        ],
    },

    {
        id: '12',
        type: 'Sauces',
        name: 'Honey Mustard',
        lastdate: '09 Sep 2023',
        cost: 5,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '13',
        type: 'Dry Food',
        name: 'Product Name',
        lastdate: '09 Sep 2023',
        cost: 3,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
            { date: '19/05/2023', count: 0 },
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
            { date: '19/05/2023', count: 0 },
        ],
    },


    {
        id: '14',
        type: 'Dry Food',
        name: 'Product Name',
        lastdate: '09 Sep 2023',
        cost: 2,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '15',
        type: 'Drinks',
        name: 'Beer',
        lastdate: '09 Sep 2023',
        cost: 2,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '16',
        type: 'Drinks',
        name: 'Wine',
        lastdate: '09 Sep 2023',
        cost: 7,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

    {
        id: '17',
        type: 'Drinks',
        name: 'Cocktails',
        lastdate: '09 Sep 2023',
        cost: 20,
        expiry: [
            { date: '23/04/2023', count: 0 },
            { date: '17/07/2023', count: 0 },
        ],
    },

];

export default EmployeeFPData;