import { Box, AppBar, Toolbar, Typography, useTheme, Grid, Modal, Button, MenuItem, Divider, TextareaAutosize } from '@mui/material';
import CircularProgressFixed from '../../statistics/circular-progress'
import ChooseLanguage from '../../choose-language/ChooseLanguage';
import Menu, { MenuProps } from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';
import ViewTask from '../../../pages/tasks/view-task-modal/view-tasks-modal';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as WarningIcon } from '../../../assets/images/warning-icon.svg'
import MenuIcon from '@mui/icons-material/Menu';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { ThemeActions } from '../../../redux/actions/themeActions'
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTheme } from '../../../theme/index';
import { getDictionary, getField } from '../../../utils/translation/translation';
import { TranslationType } from "../../../types/translationTypes";
import { connect } from "react-redux";
import { useState, useEffect } from 'react';
import Calendar from '../../calendar/Calendar';
import moment, { Moment } from "moment";
import WastageSave from '../../../pages/wastage/wastage-modal/wastage-save';
import WastageSend from '../../../pages/wastage/wastage-modal/wastage-send';
import TransferComplete from '../../../pages/transfer/transfer-modals/transfer-complete';
import TransferApproval from '../../../pages/transfer/transfer-modals/transfer-approval';
import { IncomingRequeststActions } from '../../../redux/actions/incomingRequestAction'
import { Dispatch } from 'redux';
import useStyles from './styles';
import ThemeSwitch from '../../switches/theme-switch'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import NormalButton from '../../buttons/button';
interface TopSectionTitle {
    id: string;
    title: string;
    paths: string[];
    showNotifications?: boolean;
    showPrintAndDownload?: boolean;
    showDownloadAndShare?: boolean;
    showCalendarAndMenu?: boolean;
    showHistoryIcon?: boolean;
    showTwoButtons?: boolean;
    showDateCalendar?: boolean;
    showSingleButton?: boolean;
    showDailyTasks?: boolean;
    showFixedCalendar?: boolean;
    showRemarks?: boolean;


}

interface HeaderSectionProps {
    translation: TranslationType[], language: any,
    currentItem: any, currentIncomingItem: any, currentProduct: any, SetSendOrder: (productId: string,) => void;
    handleFinalizeApproval: any, setAllStatuses: (productId: string, status: string) => void;
    setAllMultiBrandStatuses: (productId: string, status: string) => void;
    switchTheme: (theme: string) => void; themeChoice: string; handleDrawerOpen: any, handleDrawerClose: any, open: any, 
    lightTheme: any, handleSave: any, mainColor: any, Theme: any, darkTheme: any

}
function HeaderSection({ Theme, darkTheme, lightTheme, mainColor, open, handleDrawerClose, handleDrawerOpen, handleSave, translation, language, currentItem, currentProduct, currentIncomingItem, SetSendOrder, handleFinalizeApproval, setAllMultiBrandStatuses, setAllStatuses }: HeaderSectionProps) {
    const Dictionary = getDictionary(translation, "header", language);
    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [openTasks, setOpenTasks] = useState(false);
    const handleOpenTasks = () => { setOpenTasks(true); };
    const handleCloseTasks = () => { setOpenTasks(false); };
    const handleModalClose = () => { handleClose(); };
    const back = () => { navigate(-1); }
    const currentPath = location.pathname;


    const navigations: TopSectionTitle[] = [
        { id: 'all-supplier', title: getField(Dictionary, "all-supplier", language), paths: ["/supplier/all-supplier"], showPrintAndDownload: true, },
        { id: 'supplier-details', title: getField(Dictionary, "supplier-details", language), paths: ["/supplier/supplier-details",], showDownloadAndShare: true, },
        { id: 'new-supplier', title: getField(Dictionary, "new-supplier", language), paths: ['/supplier/new-supplier'], showDownloadAndShare: true, },
        { id: 'payment', title: getField(Dictionary, "payment", language), paths: ['/supplier/payment'] },
        { id: 'statement', title: getField(Dictionary, "statement", language), paths: ['/supplier/statement', '/supplier/all-payment'] },
        { id: 'tasks-status', title: getField(Dictionary, "tasks", language), paths: ['/tasks/status'], showTwoButtons: true, showDailyTasks: true, showNotifications: true },
        { id: 'tasks-list', title: getField(Dictionary, "tasks", language), paths: ['/tasks/tasks-list'], showNotifications: true },
        { id: 'my-tasks', title: getField(Dictionary, "tasks", language), paths: ['/mytasks'], showFixedCalendar: true, showCalendarAndMenu: true },
        { id: 'new-task', title: getField(Dictionary, "new-task", language), paths: ["/new-task"], showNotifications: true, showSingleButton: true },
        { id: 'transfer-tasks', title: getField(Dictionary, "transfer-tasks", language), paths: ["/transfer-task"], showHistoryIcon: true, showSingleButton: true },
        { id: 'transfer-preview', title: getField(Dictionary, "transfer", language), paths: ['/transfer/transfer-preview'], showPrintAndDownload: true, showSingleButton: true },
        { id: 'transfer-details', title: getField(Dictionary, "transfer", language), paths: ['/transfer/transfer-main'], showDateCalendar: true, },
        { id: 'transfer-lookup', title: getField(Dictionary, "transfer", language), paths: ["/transfer/lookup-product", "/transfer/transfer-details"], showDateCalendar: true, showNotifications: true, showTwoButtons: true },
        { id: 'internal-request', title: getField(Dictionary, "internal-request", language), paths: ['/requests/main'], showDateCalendar: true, },
        { id: 'request', title: ` ${getField(Dictionary, "requests", language)} #16001`, paths: ['/order/order-details'], showDateCalendar: true, showNotifications: true, showTwoButtons: true, },
        { id: 'product-lookup', title: getField(Dictionary, "internal-request", language), paths: ['/order/order-lookup'], showDateCalendar: true, showNotifications: true, showTwoButtons: true, },
        { id: 'my-tasks', title: getField(Dictionary, "stock", language), paths: ["/scan/lookup-with-expiry", '/scan/lookup', '/scan/product-brand', '/scan/lookup-without-expiry'] },
        { id: 'tasks-status', title: getField(Dictionary, "stock-take", language), paths: ['/stock/warehouse-progress'], showNotifications: true },
        { id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ["/wastage/preview"], showPrintAndDownload: true, showNotifications: true, showSingleButton: true },
        { id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ["/wastage/finished-products", '/wastage/stock-items', '/wastage/stock-items/details', "/wastage/finished-products/details",], showDateCalendar: true, showNotifications: true, showTwoButtons: true },
        { id: 'wastage', title: getField(Dictionary, "wastage", language), paths: ["/wastage/main"], showDateCalendar: true },
        { id: 'employees-meals', title: getField(Dictionary, "employee", language), paths: ["/employee/main"], showDateCalendar: true },
        { id: 'employees-meals', title: getField(Dictionary, "employee", language), paths: ['/employee/finished-products', '/employee/stock-items', '/employee/stock-items/details', '/employee/finished-products/details'], showDateCalendar: true, showNotifications: true, showTwoButtons: true },
        { id: 'transfer', title: getField(Dictionary, "outgoing", language), paths: ["/outgoing"], showSingleButton: true, showPrintAndDownload: true, showNotifications: true },
        { id: 'employee', title: getField(Dictionary, "employee", language), paths: ["/employee/preview"], showPrintAndDownload: true, showNotifications: true, showSingleButton: true },
        { id: 'purchase', title: getField(Dictionary, "new-po", language), paths: ["/purchase/main"], showDateCalendar: true, },
        { id: 'purchase', title: getField(Dictionary, "new-po", language), paths: ["/purchase/lookup", '/purchase/order-details'], showDateCalendar: true, showNotifications: true, showTwoButtons: true },
        { id: 'purchase', title: getField(Dictionary, "purchasing", language), paths: ["/purchase/purchasing-main"], showDateCalendar: true, },
        { id: 'purchase', title: "Purchase", paths: ["/purchase/purchasing-tabs"], showNotifications: true },
        { id: 'purchase', title: getField(Dictionary, "add-linked-prod", language), paths: ["/purchase/linked-product"], showNotifications: true },
        { id: 'purchase', title: getField(Dictionary, "price-comparaison", language), paths: ["/purchase/price-comparison"], showNotifications: true },
        { id: 'outgoing', title: getField(Dictionary, "outgoing", language), paths: ["/outgoing"], showNotifications: true },
        { id: 'incoming', title: getField(Dictionary, "incoming", language), paths: ["/incoming"], showSingleButton: true, showPrintAndDownload: true, showNotifications: true },
        { id: 'request', title: currentItem ? `${getField(Dictionary, "request", language)} ${currentIncomingItem.id}` : '', paths: currentIncomingItem ? [`/requests/${currentIncomingItem.id}`] : [], showTwoButtons: true, showSingleButton: true, showPrintAndDownload: true },
        { id: 'new-purchase', title: getField(Dictionary, "received-purchase", language), paths: ["/new-purchase/main"], showDateCalendar: true, showNotifications: false },
        { id: 'new-purchase', title: 'new Purchase', paths: ["/new-purchase/lookup"], showNotifications: true },
        { id: 'new-purchase', title: `${getField(Dictionary, "ref-numb", language)}#12345`, paths: ["/new-purchase/details"], showPrintAndDownload: true, },
        { id: 'new-purchase', title: "Scan Document", paths: ["/new-purchase/scan-invoice-main"], },
        { id: 'purchase', title: "Receiving", paths: ["/purchase/recieving"], showNotifications: true },
        { id: 'purchase', title: "Received Purchase", paths: ["/purchase/purchasing-verification"], showNotifications: true },
        { id: 'purchase', title: currentProduct ? `PO# ${currentProduct.id}` : '', paths: currentProduct ? [`/received/${currentProduct.id}`] : [], showPrintAndDownload: true },
        { id: 'stock', title: "Stock Take", paths: ["/stock/main"], },
        { id: 'stock', title: "Stock Take", paths: ["/stock/lookup"], showNotifications: true },
        { id: 'stock', title: "Stock Take", paths: ["/stock/preview"], showNotifications: true, showTwoButtons: true },
        { id: 'stock', title: "Stock Variance", paths: ["/stock/variance"], showNotifications: true },
        { id: 'production', title: "Production", paths: ["/production/main"], showNotifications: true, showTwoButtons: true },
        { id: 'production', title: "Production", paths: ["/production/newMain"], showNotifications: true },
        { id: 'production', title: "Production", paths: ["/production/lookup"], showNotifications: true, showTwoButtons: true },
        { id: 'production', title: "Production", paths: ["/production/summary"], showNotifications: true },
        { id: 'production', title: "Custom Production", paths: ["/production/custom-lookup"], showNotifications: true, showTwoButtons: true },
        { id: 'production', title: "Custom Production", paths: ["/production/custom/summary"], showNotifications: true },
        { id: 'sales', title: "Sales", paths: ["/sales/main"], showNotifications: true },
        { id: 'sales', title: "Quotation", paths: ["/sales/quotation-main"], showNotifications: true },
        { id: 'theme', title: "Design Setup", paths: ["/design/theme"], showNotifications: true, showTwoButtons: true },
    ];
    const getTitle = () => {
        const matchingNavigation = navigations.find(nav => nav.paths?.includes(location.pathname));
        if (matchingNavigation) { return matchingNavigation.title; } return 'No Title';
    };
    function checkNavigationProperty(property: keyof TopSectionTitle): boolean {
        const currentPath = location.pathname;
        const matchingNavigation = navigations.find(nav => nav.paths?.includes(currentPath));
        return matchingNavigation ? !!matchingNavigation[property] : false;
    }
    const shouldShowNotifications = checkNavigationProperty('showNotifications');
    const shouldShowPrintAndDownload = checkNavigationProperty('showPrintAndDownload');
    const shouldShowDownloadAndShare = checkNavigationProperty('showDownloadAndShare');
    const shouldShowCalendarAndMenu = checkNavigationProperty('showCalendarAndMenu');
    const shouldHistoryIcon = checkNavigationProperty('showHistoryIcon');
    const shouldShowTwoButtons = checkNavigationProperty('showTwoButtons');
    const shouldShowDateCalendar = checkNavigationProperty('showDateCalendar');
    const shouldShowSingleButton = checkNavigationProperty('showSingleButton');
    const shouldShowDailyTasks = checkNavigationProperty('showDailyTasks');
    const shouldShowFixedCalendar = checkNavigationProperty('showFixedCalendar');
    const shouldShowRemarks = checkNavigationProperty('showRemarks');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [chooseLangModalState, setChooseLangModalState] = useState(false)
    const closeChooseLanguage = () => { setChooseLangModalState(false) }
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [openSave, setOpenSave] = useState(false);
    const [openSend, setOpenSend] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
    const handleOpenSave = () => { setOpenSave(true); };
    const handleCloseSave = () => { setOpenSave(false); };
    const handleSaveClose = () => { handleCloseSave(); };
    const handleOpenSend = () => { setOpenSend(true); };
    const handleCloseSend = () => { setOpenSend(false); };
    const handleSendClose = () => { handleCloseSend(); };
    const handleDateSelect = (date: Moment | null): void => { setSelectedDate(date); };

    const twoButtons = (
        location.pathname === '/production/custom-lookup' ? (
            <Button onClick={() => { navigate('/production/custom-lookup') }}
                variant='contained' sx={{
                    width: 'fit-content', padding: '4px 10px', height: 'fit-content',
                    backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor
                }}>
                save
            </Button>
        ) :
            location.pathname === '/design/theme' ? (
                <Button onClick={handleSave}
                    variant='contained' sx={{
                        width: 'fit-content', padding: '4px 10px', height: 'fit-content',
                        backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor
                    }}>
                    save
                </Button>
            ) :

                location.pathname === '/production/lookup' ? (
                    <Button onClick={() => { navigate('/production/lookup') }}
                        variant='contained' sx={{
                            width: 'fit-content', padding: '4px 10px', height: 'fit-content',
                            backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor
                        }}>
                        save
                    </Button>
                ) :
                    location.pathname === '/production/main' ? (
                        <Button onClick={() => { navigate('/production/newMain') }}
                            variant='contained' sx={{
                                width: 'fit-content', padding: '4px 10px', height: 'fit-content',
                                backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor
                            }}>
                            new production
                        </Button>
                    ) :
                        (location.pathname === "/wastage/finished-products" || location.pathname === "/wastage/finished-products" ||
                            location.pathname === '/wastage/stock-items' ||
                            location.pathname === '/wastage/stock-items/details' ||
                            location.pathname === "/wastage/finished-products/details") ? (
                            <>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Button onClick={handleOpenSave} variant='outlined' sx={{ color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, borderColor: theme.palette.text.color, width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}> {getField(Dictionary, "save", language)}</Button>
                                    <Button onClick={handleOpenSend} variant='contained' sx={{ color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "send-approval", language)}</Button>
                                </div>
                                <Modal open={openSave} onClose={handleCloseSave} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className={classes.ModalStyle}>
                                        <WastageSave />
                                        <div className={classes.InsideModalButtons}><Button variant="contained" onClick={handleSaveClose} className={classes.ModalButton}>{getField(Dictionary, "done", language)}</Button></div>
                                    </div>
                                </Modal>
                                <Modal open={openSend} onClose={handleCloseSend} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className={classes.ModalStyle}>
                                        <WastageSend />
                                        <div className={classes.InsideModalButtons}><Button variant="contained" onClick={handleSendClose} className={classes.ModalButton}>{getField(Dictionary, "notify-me", language)}</Button></div>
                                    </div>
                                </Modal>
                            </>
                        ) : (
                            (location.pathname === '/stock/preview' ? (
                                <Button onClick={() => { navigate('/stock/warehouse-progress') }}
                                    variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor }}>
                                    new Stock
                                </Button>

                            ) : (
                                (location.pathname === "/transfer/lookup-product" || location.pathname === "/transfer/transfer-details") ? (
                                    <>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <Button onClick={handleOpenSave} variant='outlined' sx={{ color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, borderColor: theme.palette.text.color, width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}> {getField(Dictionary, "save", language)}             </Button>
                                            <Button onClick={handleOpenSend} variant='contained' sx={{ color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "send-approval", language)}</Button>
                                        </div>
                                        <Modal open={openSave} onClose={handleCloseSave} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className={classes.ModalStyle}>
                                                <TransferComplete />
                                                <div className={classes.InsideModalButtons}><Button variant="contained" onClick={handleSaveClose} className={classes.ModalButton}>{getField(Dictionary, "done", language)}</Button></div>
                                            </div>
                                        </Modal>
                                        <Modal open={openSend} onClose={handleCloseSend} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className={classes.ModalStyle}>
                                                <TransferApproval />
                                                <div className={classes.InsideModalButtons}><Button variant="contained" onClick={handleSendClose} className={classes.ModalButton}>{getField(Dictionary, "notify-me", language)}</Button></div>
                                            </div>
                                        </Modal>
                                    </>
                                ) : (
                                    (location.pathname === "/tasks/status") ? (
                                        <>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Button onClick={() => navigate('/tasks/tasks-list')} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, '&:hover': { color: theme.palette.white } }}>View Tasks</Button>
                                                <Button startIcon={<AddIcon />} onClick={() => navigate('/new-task')} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, '&:hover': { color: theme.palette.white } }}>Add Task</Button>
                                            </div>

                                        </>) : (
                                        (location.pathname === "/purchase/lookup") ? (
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Button /*onClick={}*/ variant='outlined' sx={{ borderColor: theme.palette.text.color, width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor }}>    {getField(Dictionary, "save", language)}         </Button>
                                                <Button /*onClick={}*/ variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor }}>   {getField(Dictionary, "send", language)}</Button>
                                            </div>
                                        ) : (
                                            (location.pathname === '/order/order-lookup' || location.pathname === '/order/order-details') ? (

                                                <div style={{ display: 'flex', /*flexDirection: isMobile ? 'row' : 'column',*/ gap: '5px' }}>
                                                    <Button /*onClick={}*/ variant='outlined' sx={{ color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, borderColor: theme.palette.text.color, width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>  {getField(Dictionary, "save", language)}</Button>
                                                    <Button /*onClick={}*/ variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}> {getField(Dictionary, "send", language)}</Button>
                                                </div>
                                            ) : (
                                                (location.pathname === `/requests/${currentIncomingItem.id}`) ? (
                                                    <div style={{ display: 'flex', /*flexDirection: isMobile ? 'row' : 'column',*/ gap: '5px' }}>
                                                        <Button onClick={() => approveAll()} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content' }}>Approve All</Button>
                                                        <Button onClick={() => rejectAll()} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: theme.palette.primary.lighter, color: theme.palette.primary.main, '&:hover': { color: theme.palette.white }, border: `2px solid ${theme.palette.primary.main} !important`, }}>Reject All</Button>
                                                    </div>
                                                ) : null
                                            ))))))))


    const singleButton = (
        location.pathname === '/wastage/preview' ? (
            <Button onClick={() => { navigate('/wastage/main') }} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "new-wastage", language)}</Button>
        ) : (
            location.pathname === '/employee/preview' ? (
                <Button onClick={() => { navigate('/employee/main') }} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "new-employee", language)}</Button>
            ) : (
                location.pathname === '/transfer/transfer-preview' ? (
                    <Button onClick={() => { navigate('/transfer/transfer-main') }} variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "new-transfer", language)}</Button>
                ) : (
                    location.pathname === '/transfer-task' ? (
                        <Button variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "transfer-tasks", language)}</Button>
                    ) : (
                        location.pathname === '/new-task' ? (
                            <Button variant='contained' sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>{getField(Dictionary, "create-new-task", language)}</Button>
                        ) : (
                            location.pathname === '/outgoing' || location.pathname === '/incoming' ? (
                                < Button variant='contained' onClick={() => { navigate('/requests/main') }}
                                    sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}> {getField(Dictionary, "new-request", language)}</Button >
                            ) : (
                                location.pathname === `/requests/${currentIncomingItem.id}` ? (
                                    < Button variant='contained' disabled={currentIncomingItem.sendOrder} onClick={() => handleFinalizeApproval()} sx={{ width: 'fit-content', padding: '4px 10px', height: 'fit-content', backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor }}>Finalize Approval</Button >
                                ) : null
                            ))
                    )
                )
            )
        )
    );

    const dailyTasks = (<Grid container justifyContent='space-between' alignItems='center' gap='15px'>
        <Grid item >
            <b style={{ fontSize: '18px' }}>{getField(Dictionary, "daily-task-almost-done", language)}</b>
            <div>{new Date().toLocaleDateString(language, { weekday: 'long', day: 'numeric', month: 'long', })}</div>
        </Grid>
        <Grid item >
            <div><CircularProgressFixed /> </div>
        </Grid>
    </Grid>
    );

    const rejectAll = () => { setAllStatuses(currentIncomingItem.id, 'Rejected'); setAllMultiBrandStatuses(currentIncomingItem.id, 'Rejected') }
    const approveAll = () => { setAllStatuses(currentIncomingItem.id, 'Approved'); setAllMultiBrandStatuses(currentIncomingItem.id, 'Approved') }


    return (<>

        <Grid container justifyContent='space-between' alignItems='center'  >
            <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >

                {currentPath !== '/dashboard' && (
                    <IconButton
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        // onClick={() => setOpen((prevOpen: boolean) => !prevOpen)}
                        edge="start"
                        sx={{ mr: 2, backgroundColor: open ? "#E5E5E5" : theme.palette.third.main, borderRadius: '9px', padding: "5px" }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                {shouldShowDailyTasks && (<>{dailyTasks}</>)}
                {shouldShowFixedCalendar && (<Calendar initialDate={selectedDate || moment()} selectedDate={selectedDate} onDateSelect={handleDateSelect} textColor={'inherit'} textPosition="left" FixedDateComponent={true} />)}
                {shouldShowDateCalendar && (
                    <Calendar initialDate={selectedDate || moment()} selectedDate={selectedDate} onDateSelect={handleDateSelect} textColor='inherit' textPosition="left" oneComponent={true} miniCalendar={true} />
                )}
                <Typography component="div" sx={{
                    flexGrow: 1, textTransform: 'none', fontSize: '27px', fontWeight: 'bold',
                    ...(shouldShowDateCalendar && { position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)', }), ...(shouldShowDailyTasks && { display: 'none' }), ...(shouldShowFixedCalendar && { display: 'none' }),
                    ...((shouldShowDateCalendar && isMobile && shouldShowRemarks) && { position: 'relative !important', left: '0 !important', transform: 'translate(20%, 0%) !important', fontSize: '25px' }),
                }} >
                    {getTitle()}
                </Typography>
                {shouldShowFixedCalendar && (<div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 40%)', }}><Calendar initialDate={selectedDate || moment()} selectedDate={selectedDate} onDateSelect={handleDateSelect} textColor={'inherit'} textPosition="left" doubleComponents={true} onlyCalendar={true} /></div>)}
                {shouldShowDailyTasks && (
                    <>
                        <Button className={`menu-warning ${classes.tasksleftbutton}`} onClick={(handleOpenTasks)} sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)', }}>
                            <div style={{ color: theme.palette.text.primary, fontWeight: "bold", whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '5px' }}><WarningIcon /> 2 {getField(Dictionary, "tasks-left", language)}</div>
                        </Button>
                        <Modal open={openTasks} onClose={handleCloseTasks} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ backgroundColor: theme.palette.white, padding: '20px', outline: 'none', borderRadius: "40px" }}>
                                <ViewTask />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <u onClick={handleModalClose} style={{ cursor: 'pointer' }}>{getField(Dictionary, "close", language)}</u>
                                </div>
                            </div>
                        </Modal>
                    </>
                )}
            </Grid>
            <Grid item sx={{ display: 'flex', gap: '10px', alignItems: 'center', }}>
                {shouldShowSingleButton && (<>{singleButton}</>)}
                {shouldShowRemarks && (<TextareaAutosize className={classes.textarea} placeholder={getField(Dictionary, 'enter-inst', language)} minRows={1} style={{ width: isMobile ? '120px' : '200px' }} />)}
                <Button variant='outlined' sx={{ color: Theme === "lightTheme" ? lightTheme.buttonTextColor : darkTheme.buttonTextColor, backgroundColor: Theme === "lightTheme" ? lightTheme.mainColor : darkTheme.mainColor, width: 'fit-content', padding: '4px 10px', height: 'fit-content' }}
                    onClick={() => {
                        back();
                        handleDrawerClose();
                    }}> {getField(Dictionary, "cancel", language)}</Button>

                {(!isMobile && !shouldShowRemarks) && (<>{shouldShowTwoButtons && (twoButtons)}</>)}
                <Grid item flexDirection='column' display='flex' gap='5px' >
                    <Grid item display='flex' justifyContent='right' gap='10px'>
                        {shouldShowPrintAndDownload && (<>
                            <IconButton size="small" edge="end" sx={{color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}><PrintOutlinedIcon /></IconButton>
                            <IconButton size="small" edge="end" sx={{color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}><FileDownloadOutlinedIcon /></IconButton> </>)}
                        {shouldHistoryIcon && (<>
                            <IconButton size="small" edge="end"  sx={{color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}> <HistoryIcon /></IconButton> </>)}
                        {shouldShowCalendarAndMenu && (<IconButton size="small" edge="end"  sx={{color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}><CalendarTodayRoundedIcon /></IconButton>)}
                        {shouldShowDownloadAndShare && (<>
                            <IconButton size="small" edge="end" sx={{color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}><ShareIcon /></IconButton>
                            <IconButton size="small" edge="end"  sx={{color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}><FileDownloadOutlinedIcon /></IconButton> </>)}
                        {shouldShowNotifications && (
                            <IconButton size="small" edge="end"  sx={{ height: 'fit-content' , color:Theme === 'lightTheme' ? lightTheme.headerIconsColor : darkTheme.headerIconsColor}}> <NotificationsOutlinedIcon /> </IconButton>)}
                        <Button id="user-menu" variant="contained" onClick={handleClick} disableElevation
                            sx={{ backgroundColor: theme.palette.third.main, color: theme.palette.third.contrastText, width: 'fit-content', padding: '4px 10px', '&:hover': { color: theme.palette.text.fourth } }}
                            aria-controls={open ? 'user-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
                            startIcon={<PersonRoundedIcon />} endIcon={<KeyboardArrowDownIcon />}>
                            User
                        </Button>
                    </Grid>
                    {(isMobile || shouldShowRemarks) && (<>	{shouldShowTwoButtons && (twoButtons)}	</>)}
                </Grid>

            </Grid>
        </Grid>







    </>)
}

const mapStateToProps = (state: any) => ({
    translation: state.translation.translation, language: state.translation.language, currentItem: state.outgoing.currentItem, currentIncomingItem: state.incomingRequest.currentIncomingItem, currentProduct: state.receivedProducts.currentIncomingItem,
    themeChoice: state.themeSwitch.themeChoice,


});
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setAllStatuses: (productId: string, status: string) => dispatch(IncomingRequeststActions.setAllStatuses(productId, status)),
        setAllMultiBrandStatuses: (productId: string, status: string) => dispatch(IncomingRequeststActions.setAllMultiBrandStatuses(productId, status)),
        SetSendOrder: (productId: string,) => dispatch(IncomingRequeststActions.SetSendOrder(productId)),
        switchTheme: (theme: string) => dispatch(ThemeActions.switchTheme(theme)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection);
