import { toast } from 'react-toastify';
import { getTranslation } from '../../config/config';
import { TranslationType } from '../../types/translationTypes';
import { Dispatch } from 'redux';

export const GET_TRANSLATION_REQUEST = 'GET_TRANSLATION_REQUEST';
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS';
export const GET_TRANSLATION_ERROR = 'GET_TRANSLATION_ERROR';
export const SET_LANGUAGE = 'SET_LANGUAGE'

export const actions = {
  getTranslation: () => async (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_TRANSLATION_REQUEST });
    try {
      const res = await getTranslation();
      if (res?.status === 200) {
        console.log(res);
        dispatch({
          type: GET_TRANSLATION_SUCCESS,
          payload: {
            supportedLanguages: res?.data?.supportedLanguages,
            translation: res?.data?.translations[0]?.languages,
          },
        });
      } else {
        const errorMessage = res?.data?.message || 'Get Translation error';
        toast.error(errorMessage);
        dispatch({ type: GET_TRANSLATION_ERROR });
      }
    } catch (error) {
      toast.error('Get Translation error');
      dispatch({ type: GET_TRANSLATION_ERROR });
    }
  },
  setLanguage: (data: TranslationType) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: data
    })
  }
};
