import { LookupActionTypes, INCREMENT_COUNTER, DECREMENT_COUNTER, ADJUST_QUANTITY, CHANGE_REASON, REMOVE_FROM_DETAILS, REMOVE_BRAND_FROM_DETAILS, INCREMENT_UNPACKED, DECREMENT_UNPACKED, ADJUST_UNPACKED_QTY } from '../actions/lookupAction'
import { prodArr, AppState } from '../../types/lookupTypes';
import WastageProductsData from '../../pages/wastage/wastage-raw-materials/wastage-products-data';
import { MultiBrandProduct } from '../actions/wastageFinishedProductsActions';

const INITIAL_STATE: AppState = {
    products: WastageProductsData as prodArr,
    details: [] as prodArr,
}

const updateProductLookup = (products: prodArr, productId: string, brandIndex: number | null, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === expiryIndex &&
                                        typeof expiry.count === 'number'
                                    ) {
                                        return {
                                            ...expiry,
                                            count: expiry.count + 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        let count = expiry.count;
                        count += 1;
                        return { ...expiry, count };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    })
}

const decrementProductLookup = (products: prodArr, productId: string, brandIndex: number | null, expiryIndex: number) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (
                                        eIndex === expiryIndex &&
                                        typeof expiry.count === 'number' &&
                                        expiry.count > 0
                                    ) {
                                        return {
                                            ...expiry,
                                            count: expiry.count - 1,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, eIndex) => {
                    if (eIndex === expiryIndex && typeof expiry.count === 'number' && expiry.count > 0) {
                        return {
                            ...expiry,
                            count: expiry.count - 1,
                        };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const incrementUnpacked = (products: prodArr, productId: string): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'Unpacked' in product) {
            return {
                ...product,
                Unpacked: product.Unpacked + 1,
            };
        }
        return product;
    });
};

const decrementUnpacked = (products: prodArr, productId: string): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'Unpacked' in product && product.Unpacked > 0) {
            return {
                ...product,
                Unpacked: product.Unpacked - 1,
            };
        }
        return product;
    });
};

const adjustUnpackedQty = (products: prodArr, productId: string, newQuantity: number): prodArr => {
    return products.map((product) => {
        if (product.id === productId && 'Unpacked' in product) {
            return {
                ...product,
                Unpacked: newQuantity >= 0 ? newQuantity : 0,
            };
        }
        return product;
    });
};



const updateDetailsWithProduct = (details: any, updatedProducts: prodArr, productId: string) => {
    const updatedDetails = [...details];
    const existingProduct = updatedDetails.find((item) => item.id === productId);
    if (existingProduct) {
        Object.assign(existingProduct, updatedProducts.find((product) => product.id === productId));
    } else {
        const productToAdd = updatedProducts.find((product) => product.id === productId);
        if (productToAdd) {
            updatedDetails.push(productToAdd);
        }
    }
    return updatedDetails;
};

const updateQuantityAndDetails = (
    products: prodArr, productId: string,
    brandIndex: number | null, expiryIndex: number,
    newQuantity: number, details: any[],
) => {
    return products.map((product) => {
        if (product.id === productId) {
            if ('brands' in product) {
                return {
                    ...product,
                    brands: product.brands.map((brand, bIndex) => {
                        if (bIndex === brandIndex && 'expiry' in brand) {
                            return {
                                ...brand,
                                expiry: brand.expiry.map((expiry, eIndex) => {
                                    if (eIndex === expiryIndex && typeof expiry.count === 'number') {
                                        return {
                                            ...expiry,
                                            count: newQuantity,
                                        };
                                    }
                                    return expiry;
                                }),
                            };
                        }
                        return brand;
                    }),
                };
            } else if ('expiry' in product) {
                product.expiry = product.expiry?.map((expiry, i) => {
                    if (i === expiryIndex) {
                        return { ...expiry, count: newQuantity };
                    }
                    return { ...expiry };
                });
            }
        }
        return product;
    });
};

const setExpCountsToZero = (details: prodArr, productId: string,) => {
    const ExpCountsToRemove = details.find((item) => item.id === productId);
    if (ExpCountsToRemove) {
        if ('brands' in ExpCountsToRemove) {
            ExpCountsToRemove.brands.forEach((brand) => {
                brand.expiry.forEach((exp) => { exp.count = 0; });
            });
        }
    }
}
const lookupReducer = (state = INITIAL_STATE, action: LookupActionTypes) => {
    let updatedProducts;
    let updatedDetails;
    let expCountToRemove;
    switch (action.type) {

        case INCREMENT_COUNTER:
            updatedProducts = updateProductLookup(state.products, action.payload.productId, action.payload.brandIndex, action.payload.expieryIndex)
            updatedDetails = updateDetailsWithProduct(state.details, updatedProducts, action.payload.productId);
            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails
            };

        case DECREMENT_COUNTER:
            updatedProducts = decrementProductLookup(state.products, action.payload.productId, action.payload.brandIndex, action.payload.expieryIndex)
            updatedDetails = updateDetailsWithProduct(
                state.details,
                updatedProducts,
                action.payload.productId
            );
            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails
            };

        case REMOVE_FROM_DETAILS:
            updatedDetails = state.details.filter((item) => item.id !== action.payload.itemID);
            const itemToRemove = state.details.find((item) => item.id === action.payload.itemID);
            if (itemToRemove) {
                if ('brands' in itemToRemove) {
                    itemToRemove.brands.forEach((brand) => {
                        brand.expiry.forEach((expiry) => {
                            expiry.count = 0;
                        });
                    });
                } else if ('expiry' in itemToRemove) {
                    itemToRemove.expiry.forEach((expiry) => {
                        expiry.count = 0;
                    });
                }
            }
            return {
                ...state,
                details: updatedDetails,
            };

        case REMOVE_BRAND_FROM_DETAILS:
            updatedDetails = state.details.map((item) => {
                if ('brands' in item) {
                    const brandsToRemove = item.brands.filter((brand) => brand.id === action.payload.brandID);
                    brandsToRemove.forEach((brand) => {
                        brand.expiry.forEach((expiry) => {
                            expiry.count = 0;
                        });
                    });

                }
                return item;
            });

            return {
                ...state,
                details: updatedDetails,
            };


        case CHANGE_REASON:
            updatedProducts = state.products.map((product) => {
                if (product.id === action.payload.productId) {
                    return {
                        ...product,
                        reason: action.payload.newReason,
                    };
                }
                return product;
            });
            updatedDetails = updateDetailsWithProduct(
                state.details,
                updatedProducts,
                action.payload.productId
            );

            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails,
            };

        case ADJUST_QUANTITY:
            const { productId, brandIndex, expieryIndex, newQuantity } = action.payload;
            updatedProducts = updateQuantityAndDetails(
                state.products,
                productId,
                brandIndex,
                expieryIndex,
                newQuantity,
                state.details
            );
            updatedDetails = updateDetailsWithProduct(
                state.details,
                updatedProducts,
                productId
            );
            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails,
            };

        case INCREMENT_UNPACKED:
            updatedProducts = incrementUnpacked(state.products, action.payload.productId);
            updatedDetails = updateDetailsWithProduct(state.details, updatedProducts, action.payload.productId);
            expCountToRemove = setExpCountsToZero(state.details, action.payload.productId)
            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails
            };

        case DECREMENT_UNPACKED:
            updatedProducts = decrementUnpacked(state.products, action.payload.productId);
            updatedDetails = updateDetailsWithProduct(state.details, updatedProducts, action.payload.productId);
            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails
            };

        case ADJUST_UNPACKED_QTY:
            updatedProducts = adjustUnpackedQty(state.products, action.payload.productId, action.payload.newQuantity);
            updatedDetails = updateDetailsWithProduct(state.details, updatedProducts, action.payload.productId);
            expCountToRemove = setExpCountsToZero(state.details, action.payload.productId)
            return {
                ...state,
                products: updatedProducts,
                details: updatedDetails
            };

        default:
            return state;
    }
};
export default lookupReducer;