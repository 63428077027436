import { Dispatch, Action } from 'redux';

export const INCREMENT_WASTAGE = 'INCREMENT_WASTAGE'
export const DECREMENT_WASTAGE = 'DECREMENT_WASTAGE'
export const ADJUST_WASTAGE_QUANTITY = 'ADJUST_WASTAGE_QUANTITY';
export const CHANGE_WASTAGE_REASON = 'CHANGE_WASTAGE_FP_REASON';
export const REMOVE_FROM_WASTAGE_DETAILS = 'REMOVE_FROM_WASTAGE_DETAILS'

export interface Product {
    map(arg0: (item: any) => any): any
    id: string;
    type: string;
    name: string;
    lastdate: string;
    cost: number;
    expiry: { date: string; count: number; }[]
    reason: string;
    comment?: string;
}

export interface MultiBrandProduct {
    id: string;
    type: string;
    name: string;
    lastdate: string;
    reason: string;
    comment?: string;
    Unpacked: number;
    brands: {
        id: string; name: string; description: string; cost: number;
        expiry: { date: string; count: number }[];
    }[]
}

export type prodArr = (MultiBrandProduct | Product)[]

export interface AppState {
    wastProds: Product[];
    wastDetails: Product[];
}

interface IncrementWastageAction extends Action<typeof INCREMENT_WASTAGE> {
    payload: {
        productId: string;
        expieryIndex: number;
    }
}

interface DecrementWastageAction extends Action<typeof DECREMENT_WASTAGE> {
    payload: {
        productId: string;
        expieryIndex: number;
    }
}

interface AdjustWastageQuantityAction extends Action<typeof ADJUST_WASTAGE_QUANTITY> {
    payload: {
        productId: string;
        brandIndex: number | null;
        expieryIndex: number;
        newQuantity: number;
    };
}

interface ChangeWastageReasonAction extends Action<typeof CHANGE_WASTAGE_REASON> {
    payload: {
        productId: string;
        newReason: string;
    };
}

interface RemoveFromWastageDetailsAction extends Action<typeof REMOVE_FROM_WASTAGE_DETAILS> {
    payload: {
        itemID: string;
    }
}

export type WastageActionTypes = | IncrementWastageAction | DecrementWastageAction
    | AdjustWastageQuantityAction | ChangeWastageReasonAction
    | RemoveFromWastageDetailsAction

const incrementWastageQty = (productId: string, expieryIndex: number): IncrementWastageAction => ({ type: INCREMENT_WASTAGE, payload: { productId, expieryIndex } });

const decrementWastageQty = (productId: string, expieryIndex: number): DecrementWastageAction => ({ type: DECREMENT_WASTAGE, payload: { productId, expieryIndex } });

const adjustWastageQty = (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number): AdjustWastageQuantityAction => ({ type: ADJUST_WASTAGE_QUANTITY, payload: { productId, brandIndex, expieryIndex, newQuantity } });

const changeWastageReason = (productId: string, newReason: string): ChangeWastageReasonAction => ({ type: CHANGE_WASTAGE_REASON, payload: { productId, newReason } });

const removeWastageFromDet = (itemID: string): RemoveFromWastageDetailsAction => ({ type: REMOVE_FROM_WASTAGE_DETAILS, payload: { itemID } });

export const WastageActions = {
    incrementWastage: (productId: string, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementWastageQty(productId, expieryIndex)); },
    decrementWastage: (productId: string, expieryIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementWastageQty(productId, expieryIndex)); },
    adjustWastageQuantity: (productId: string, brandIndex: number | null, expieryIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustWastageQty(productId, brandIndex, expieryIndex, newQuantity)); },
    changeWastageFPReason: (productId: string, comment: string) => (dispatch: Dispatch<any>) => { dispatch(changeWastageReason(productId, comment)); },
    removeFromWastageDetails: (itemID: string) => (dispatch: Dispatch<any>) => { dispatch(removeWastageFromDet(itemID)); },
};