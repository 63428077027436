import { IncomingRequestsTypes } from "../../redux/actions/incomingRequestAction"

export const IncomingRequestData: IncomingRequestsTypes = [
  {
    id: '16001',
    user: 'User 1',
    date: 'July 25,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen beirut',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'a1',
        name: 'Tabbouleh',
        qtyRequested: 25,
        qtyOH: 10,
        ourQtyOH: 15,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'a2',
        name: 'Fatteh',
        qtyRequested: 5,
        qtyOH: 0,
        ourQtyOH: 0,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 5,
      },
      {
        id: 'a4',
        name: 'Pasta',
        qtyRequested: 40,
        qtyOH: 30,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 40,
      },
      {
        id: 'a5',
        name: 'Kafta',
        qtyRequested: 5,
        qtyOH: 15,
        ourQtyOH: 5,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 5,
      },
      {
        id: 'a8',
        name: 'Fattoush',
        qtyRequested: 20,
        qtyOH: 15,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      },
      {
        id: 'a9',
        name: 'Taouk',
        qtyRequested: 30,
        qtyOH: 15,
        ourQtyOH: 40,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 30,
      },
      {
        id: 'a10',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 30,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'a11',
        name: 'Salade Chevre',
        qtyRequested: 35,
        qtyOH: 35,
        ourQtyOH: 35,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 35,
      },
    ],

    multiBrand: [
      {
        id: 'a3',
        name: 'Corn',
        qtyRequested: 25, qtyOH: 1, ourQtyOH: 3, status: 'none',
        baseUnit: [
          { qtyType: 'Qty.On', id: 'plsbu1', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Qty.Req', id: 'plsbu1', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Approved', id: 'plsbu1', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Our.Qty.On', id: 'plsbu1', count: 0, weightType: 'Kg', date: '22/2//2024' },

        ],
        brands: [
          {
            id: 'plnsol1', name: 'Plein Soleil', boxSize: 10, canWeight: 400, avBoxes: 2, avCans: 7, expiryDate: '24/01/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 4, cansCount: 6, id: 'plnsol1onh' },
              { qtyType: 'Qty.Req', boxCount: 3, cansCount: 5, id: 'plnsol1req' },
              { qtyType: 'Approved', boxCount: 3, cansCount: 5, date: '24/01/2024', id: 'plnsol1app' },
              { qtyType: 'Our.Qty.On', boxCount: 2, cansCount: 4, id: 'plnsol1ouronh' },
            ],
          },
          {
            id: 'plnsol2', name: 'Maxims', boxSize: 12, canWeight: 400, avBoxes: 3, avCans: 6, expiryDate: '23/03/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 3, cansCount: 5, id: 'plnsol2onh' },
              { qtyType: 'Qty.Req', boxCount: 2, cansCount: 4, id: 'plnsol2req' },
              { qtyType: 'Approved', boxCount: 2, cansCount: 4, date: '24/01/2024', id: 'plnsol2app' },
              { qtyType: 'Our.Qty.On', boxCount: 4, cansCount: 4, id: 'plnsol2ouronh' },
            ],
          },
          {
            id: 'plnsol3', name: 'Wadi Akhdar', boxSize: 12, canWeight: 800, avBoxes: 3, avCans: 6, expiryDate: '22/02/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 4, cansCount: 3, id: 'plnsol3onh' },
              { qtyType: 'Qty.Req', boxCount: 2, cansCount: 5, id: 'plnsol3req' },
              { qtyType: 'Approved', boxCount: 2, cansCount: 5, date: '24/01/2024', id: 'plnsol3app' },
              { qtyType: 'Our.Qty.On', boxCount: 3, cansCount: 4, id: 'plnsol3ouronh' },
            ],
          },
        ]

      },
      {
        id: 'a6',
        name: 'Mushroom',
        qtyRequested: 25, qtyOH: 1, ourQtyOH: 3, status: 'none',
        baseUnit: [
          { qtyType: 'Qty.On', id: 'mushbu1', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Qty.Req', id: 'mushbu2', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Approved', id: 'mushbu3', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Our.Qty.On', id: 'mushbu3', count: 0, weightType: 'Kg', date: '22/2//2024' },
        ],
        brands: [
          {
            id: 'mush1', name: 'Plein Soleil', boxSize: 10, canWeight: 400, avBoxes: 2, avCans: 7, expiryDate: '24/01/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 2, cansCount: 4, id: 'mush1onh' },
              { qtyType: 'Qty.Req', boxCount: 3, cansCount: 5, id: 'mush1req' },
              { qtyType: 'Approved', boxCount: 3, cansCount: 5, date: '24/01/2024', id: 'mush1app' },
              { qtyType: 'Our.Qty.On', boxCount: 1, cansCount: 2, id: 'mush1ouronh' },

            ],
          },
          {
            id: 'mush2', name: 'Maxims', boxSize: 12, canWeight: 400, avBoxes: 3, avCans: 6, expiryDate: '21/03/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 1, cansCount: 5, id: 'mush2onh' },
              { qtyType: 'Qty.Req', boxCount: 2, cansCount: 4, id: 'mush2req' },
              { qtyType: 'Approved', boxCount: 2, cansCount: 4, date: '24/01/2024', id: 'mush2app' },
              { qtyType: 'Our.Qty.On', boxCount: 0, cansCount: 6, id: 'mush2ouronh' },
            ],
          },
          {
            id: 'mush3', name: 'Wadi Akhdar', boxSize: 12, canWeight: 800, avBoxes: 3, avCans: 6, expiryDate: '22/02/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 2, cansCount: 3, id: 'mush3onh' },
              { qtyType: 'Qty.Req', boxCount: 2, cansCount: 5, id: 'mush3req' },
              { qtyType: 'Approved', boxCount: 2, cansCount: 5, date: '24/01/2024', id: 'mush3app' },
              { qtyType: 'Our.Qty.On', boxCount: 1, cansCount: 3, id: 'mush3ouronh' },
            ],
          },
        ]
      },
      {
        id: 'a7',
        name: 'Corn',
        qtyRequested: 25, qtyOH: 1, ourQtyOH: 3, status: 'none',
        baseUnit: [
          { qtyType: 'Qty.On', id: 'plsbu8', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Qty.Req', id: 'plsbu9', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Approved', id: 'plsbu10', count: 0, weightType: 'Kg', date: '22/2//2024' },
          { qtyType: 'Our.Qty.On', id: 'plsbu8', count: 0, weightType: 'Kg', date: '22/2//2024' },

        ],
        brands: [
          {
            id: 'plnsol5', name: 'Plein Soleil', boxSize: 10, canWeight: 400, avBoxes: 2, avCans: 7, expiryDate: '24/01/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 3, cansCount: 2, id: 'plnsol5onh' },
              { qtyType: 'Qty.Req', boxCount: 3, cansCount: 5, id: 'plnsol5req' },
              { qtyType: 'Approved', boxCount: 3, cansCount: 5, date: '24/01/2024', id: 'plnsol5app' },
              { qtyType: 'Our.Qty.On', boxCount: 0, cansCount: 0, id: 'plnsol5ouronh' },

            ],
          },
          {
            id: 'plnsol6', name: 'Maxims', boxSize: 12, canWeight: 400, avBoxes: 3, avCans: 6, expiryDate: '21/03/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 2, cansCount: 2, id: 'plnsol6onh' },
              { qtyType: 'Qty.Req', boxCount: 2, cansCount: 4, id: 'plnsol6req' },
              { qtyType: 'Approved', boxCount: 2, cansCount: 4, date: '24/01/2024', id: 'plnsol6app' },
              { qtyType: 'Our.Qty.On', boxCount: 0, cansCount: 0, id: 'plnsol6ouronh' },

            ],
          },
          {
            id: 'plnsol7', name: 'Wadi Akhdar', boxSize: 12, canWeight: 800, avBoxes: 3, avCans: 6, expiryDate: '22/02/2024',
            qty: [
              { qtyType: 'Qty.On', boxCount: 1, cansCount: 5, id: 'plnsol7onh' },
              { qtyType: 'Qty.Req', boxCount: 2, cansCount: 5, id: 'plnsol7req' },
              { qtyType: 'Approved', boxCount: 2, cansCount: 5, date: '24/01/2024', id: 'plnsol7app' },
              { qtyType: 'Our.Qty.On', boxCount: 0, cansCount: 0, id: 'plnsol7ouronh' },

            ],
          },
        ]
      },
    ],

  },
  {
    id: '16002',
    user: 'User 2',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen damascus',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '',
    products: [
      {
        id: 'b1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 20,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'b2',
        name: 'Tabbouleh',
        qtyRequested: 20,
        qtyOH: 15,
        ourQtyOH: 10,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      },
      {
        id: 'b3',
        name: 'Fattoush',
        qtyRequested: 20,
        qtyOH: 15,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      },
      {
        id: 'b4',
        name: 'Taouk',
        qtyRequested: 30,
        qtyOH: 15,
        ourQtyOH: 40,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 30,
      },
    ]
  },
  {
    id: '16003',
    user: 'User 3',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen bey',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: 'Processing',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'c1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 15,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'c2',
        name: 'Tabbouleh',
        qtyRequested: 20,
        qtyOH: 20,
        ourQtyOH: 20,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      },
      {
        id: 'c3',
        name: 'Taouk',
        qtyRequested: 30,
        qtyOH: 15,
        ourQtyOH: 40,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 30,
      },
    ]
  },
  {
    id: '16004',
    user: 'User 4',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen joun',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '30 Oct 2023',
    products: [
      {
        id: 'd1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 15,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'd2',
        name: 'Tabbouleh',
        qtyRequested: 20,
        qtyOH: 10,
        ourQtyOH: 10,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      },
      {
        id: 'd3',
        name: 'Fattoush',
        qtyRequested: 20,
        qtyOH: 15,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      },
    ]
  },
  {
    id: '16005',
    user: 'User 5',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen ghazir',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '',
    products: [
      {
        id: 'e1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 15,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'e2',
        name: 'Tabbouleh',
        qtyRequested: 20,
        qtyOH: 25,
        ourQtyOH: 20,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      }
    ]
  },
  {
    id: '16007',
    user: 'User cr7',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen jeddah',
    sendOrder: false,
    status: 'Pending',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '30 Oct 2023',
    products: [
      {
        id: 'f1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 30,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'f2',
        name: 'Tabbouleh',
        qtyRequested: 25,
        qtyOH: 0,
        ourQtyOH: 0,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      }

    ]
  },
  {
    id: ' 16009',
    user: 'User 9',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen dubai',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '',
    products: [
      {
        id: 'g1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 30,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'g2',
        name: 'Tabbouleh',
        qtyRequested: 20,
        qtyOH: 30,
        ourQtyOH: 20,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      }
    ]
  },
  {
    id: '16006',
    user: 'User 6',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen homos',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: 'Processing',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'h1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 25,
        ourQtyOH: 20,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'h2',
        name: 'Tabbouleh',
        qtyRequested: 25,
        qtyOH: 30,
        ourQtyOH: 25,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      }

    ]
  },
  {
    id: '16008',
    user: 'User 8',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen paris',
    status: 'Pending',
    sendOrder: false,
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '30 Oct 2023',
    products: [
      {
        id: 'i1',
        name: 'Hommos',
        qtyRequested: 25,
        qtyOH: 10,
        ourQtyOH: 5,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 25,
      },
      {
        id: 'i2',
        name: 'Tabbouleh',
        qtyRequested: 20,
        qtyOH: 15,
        ourQtyOH: 20,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 20,
      }

    ]
  },
  {
    id: '20022',
    user: 'EKS',
    date: 'Feb 22,2022 2:22 AM',
    from: 'Breaking Kitchen',
    to: 'Albacurque',
    status: 'Pending',
    sendOrder: false,
    orderDate: '20 Feb 2022',
    acceptanceDate: '22 Feb 2022',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'j1',
        name: 'Tabbouleh',
        qtyRequested: 22,
        qtyOH: 2,
        ourQtyOH: 2,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 22,
      },
      {
        id: 'j2',
        name: 'Taouk',
        qtyRequested: 22,
        qtyOH: 15,
        ourQtyOH: 0,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 22,
      },
      {
        id: 'j3',
        name: '57',
        qtyRequested: 22,
        qtyOH: 25,
        ourQtyOH: 22,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 22,
      },
      {
        id: 'j4',
        name: 'Burger',
        qtyRequested: 22,
        qtyOH: 5,
        ourQtyOH: 2,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 22,
      },
      {
        id: 'j5',
        name: 'Chicken Sub',
        qtyRequested: 22,
        qtyOH: 25,
        ourQtyOH: 22,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 22,
      },
      {
        id: 'j6',
        name: 'Escalope',
        qtyRequested: 22,
        qtyOH: 22,
        ourQtyOH: 22,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 22,
      },
      {
        id: 'j7',
        name: 'Balila',
        qtyRequested: 50,
        qtyOH: 25,
        ourQtyOH: 20,
        date: '22/2/2022',
        status: 'none',
        qtyApproved: 50,
      }
    ]
  },
]

