import { Dispatch, Action } from 'redux';

export const INCREMENT_BOX_QUANTITY = 'INCREMENT_BOX_QUANTITY'
export const DECREMENT_BOX_QUANTITY = 'DECREMENT_BOX_QUANTITY'
export const ADJUST_BOX_QUANTITY = 'ADJUST_BOX_QUANTITY'
export const INCREMENT_CAN_QUANTITY = 'INCREMENT_CAN_QUANTITY'
export const DECREMENT_CAN_QUANTITY = 'DECREMENT_CAN_QUANTITY'
export const ADJUST_CAN_QUANTITY = 'ADJUST_CAN_QUANTITY'
export const REMOVE_PRODUCT_FROM_REQUEST = 'REMOVE_PRODUCT_FROM_REQUEST'
export const ADD_REMARKS_TO_REQUEST = 'ADD_REMARKS_TO_REQUEST'
export const REMOVE_BRAND_FROM_REQUEST = 'REMOVE_BRAND_FROM_REQUEST'
export const INCREMENT_BASE_UNIT_QUANTITY = 'INCREMENT_BASE_UNIT_QUANTITY'
export const DECREMENT_BASE_UNIT_QUANTITY = 'DECREMENT_BASE_UNIT_QUANTITY'
export const UPDATE_BASE_UNIT_QUANTITY = 'UPDATE_BASE_UNIT_QUANTITY'
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY'
export const CHANGE_WEIGHT_TYPE = 'CHANGE_WEIGHT_TYPE';

export interface InternalRequestType {
    id: string;
    name: string;
    img: string;
    type: string;
    avQty: number;
    level: string;
    lastdate: string;
    remarks?: string;
    expiry: { id: string, qtyType: string; count: number; date?: string; }[];
    reason?: string;
    comment?: string;
    cost?: number
}

export interface InternalRequestMultiBrandType {
    id: string;
    name: string;
    img: string;
    type: string;
    avQty: number;
    level: string;
    lastdate: string;
    remarks?: string;
    baseUnit: { id: string; qtyType: string; count: number; weightType: string; date?: string; }[];
    brands: {
        id: string;
        name: string;
        boxSize: number,
        canWeight: number,
        description?: string;
        avBoxes: number,
        avCans: number,
        expiry: { qtyType: string; count?: number; boxCount: number; cansCount: number; date?: string; }[];
    }[]
    reason?: string;
    comment?: string;
}

export type prodArr = (InternalRequestMultiBrandType | InternalRequestType)[]

export interface AppState { irProducts: prodArr; irDetails: prodArr; }

interface IncrementBoxQuantityAction extends Action<typeof INCREMENT_BOX_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}

interface DecrementBoxQuantityAction extends Action<typeof DECREMENT_BOX_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}

interface IncrementCanQuantityAction extends Action<typeof INCREMENT_CAN_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}

interface DecrementCanQuantityAction extends Action<typeof DECREMENT_CAN_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; }
}

interface AdjustBoxQuantityAction extends Action<typeof ADJUST_BOX_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; newQuantity: number; };
}
interface AdjustCanQuantityAction extends Action<typeof ADJUST_CAN_QUANTITY> {
    payload: { productId: string; brandIndex: number | null; qtyIndex: number; newQuantity: number; };
}

interface RemoveProductFromRequestAction extends Action<typeof REMOVE_PRODUCT_FROM_REQUEST> {
    payload: { itemID: string; }
}

interface AddRemarksToRequestAction extends Action<typeof ADD_REMARKS_TO_REQUEST> {
    payload: { productId: string; remarks: string; }
}

interface RemoveBrandFromRequestAction extends Action<typeof REMOVE_BRAND_FROM_REQUEST> {
    payload: { brandID: string; }
}

interface IncrementBaseUnitQuantityAction extends Action<typeof INCREMENT_BASE_UNIT_QUANTITY> {
    payload: { productId: string; baseUnitIndex: number; }
}

interface DecrementBaseUnitQuantityAction extends Action<typeof DECREMENT_BASE_UNIT_QUANTITY> {
    payload: { productId: string; baseUnitIndex: number; }
}

interface UpdateBaseUnitQuantityAction extends Action<typeof UPDATE_BASE_UNIT_QUANTITY> {
    payload: { productId: string; baseUnitId: string; newCount: number; }
}

interface UpdateProductQuantityAction extends Action<typeof UPDATE_PRODUCT_QUANTITY> {
    payload: { productId: string; qtyId: string; newCount: number; }
}

interface ChangeWeightTypeAction extends Action<typeof CHANGE_WEIGHT_TYPE> {
    payload: { productId: string; baseUnitId: string; weightType: string };
}

export type InternalRequestActionTypes = | IncrementBoxQuantityAction | DecrementBoxQuantityAction | AdjustBoxQuantityAction
    | IncrementCanQuantityAction | DecrementCanQuantityAction | AdjustCanQuantityAction
    | RemoveProductFromRequestAction | AddRemarksToRequestAction | RemoveBrandFromRequestAction
    | IncrementBaseUnitQuantityAction | DecrementBaseUnitQuantityAction | UpdateBaseUnitQuantityAction | UpdateProductQuantityAction | ChangeWeightTypeAction;

const incrementBoxQ = (productId: string, brandIndex: number | null, qtyIndex: number): IncrementBoxQuantityAction => ({ type: INCREMENT_BOX_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const decrementBoxQ = (productId: string, brandIndex: number | null, qtyIndex: number): DecrementBoxQuantityAction => ({ type: DECREMENT_BOX_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const adjustBoxQ = (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number): AdjustBoxQuantityAction => ({ type: ADJUST_BOX_QUANTITY, payload: { productId, brandIndex, qtyIndex, newQuantity } });

const incrementCanQ = (productId: string, brandIndex: number | null, qtyIndex: number): IncrementCanQuantityAction => ({ type: INCREMENT_CAN_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const decrementCanQ = (productId: string, brandIndex: number | null, qtyIndex: number): DecrementCanQuantityAction => ({ type: DECREMENT_CAN_QUANTITY, payload: { productId, brandIndex, qtyIndex } });

const adjustCanQ = (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number): AdjustCanQuantityAction => ({ type: ADJUST_CAN_QUANTITY, payload: { productId, brandIndex, qtyIndex, newQuantity } });

const removeProdFromDet = (itemID: string): RemoveProductFromRequestAction => ({ type: REMOVE_PRODUCT_FROM_REQUEST, payload: { itemID } });

const addRemarksReq = (productId: string, remarks: string): AddRemarksToRequestAction => ({ type: ADD_REMARKS_TO_REQUEST, payload: { productId, remarks } });

const removeBrandFromDet = (brandID: string): RemoveBrandFromRequestAction => ({ type: REMOVE_BRAND_FROM_REQUEST, payload: { brandID } });

const incrementBaseUnitQ = (productId: string, baseUnitIndex: number,): IncrementBaseUnitQuantityAction => ({ type: INCREMENT_BASE_UNIT_QUANTITY, payload: { productId, baseUnitIndex, } });

const decrementBaseUnitQ = (productId: string, baseUnitIndex: number,): DecrementBaseUnitQuantityAction => ({ type: DECREMENT_BASE_UNIT_QUANTITY, payload: { productId, baseUnitIndex, } });

const updatetBaseUnitQ = (productId: string, baseUnitId: string, newCount: number): UpdateBaseUnitQuantityAction => ({ type: UPDATE_BASE_UNIT_QUANTITY, payload: { productId, baseUnitId, newCount } });

const updatetProductQ = (productId: string, qtyId: string, newCount: number): UpdateProductQuantityAction => ({ type: UPDATE_PRODUCT_QUANTITY, payload: { productId, qtyId, newCount } });

const changeWeightType = (productId: string, baseUnitId: string, weightType: string): ChangeWeightTypeAction => ({ type: CHANGE_WEIGHT_TYPE, payload: { productId, baseUnitId, weightType }, });

export const InternalRequestActions = {
    incrementBoxQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBoxQ(productId, brandIndex, qtyIndex)); },
    decrementBoxQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBoxQ(productId, brandIndex, qtyIndex)); },
    adjustBoxQty: (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustBoxQ(productId, brandIndex, qtyIndex, newQuantity)); },

    incrementCanQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementCanQ(productId, brandIndex, qtyIndex)); },
    decrementCanQty: (productId: string, brandIndex: number | null, qtyIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementCanQ(productId, brandIndex, qtyIndex)); },
    adjustCanQty: (productId: string, brandIndex: number | null, qtyIndex: number, newQuantity: number) => (dispatch: Dispatch<any>) => { dispatch(adjustCanQ(productId, brandIndex, qtyIndex, newQuantity)); },

    removeProductQty: (itemID: string) => (dispatch: Dispatch<any>) => { dispatch(removeProdFromDet(itemID)); },
    addRemarkstoRequest: (productId: string, remarks: string) => (dispatch: Dispatch<any>) => { dispatch(addRemarksReq(productId, remarks)); },
    removeBrandQty: (brandID: string) => (dispatch: Dispatch<any>) => { dispatch(removeBrandFromDet(brandID)); },

    incrementBaseUnitQty: (productId: string, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(incrementBaseUnitQ(productId, baseUnitIndex,)); },
    decrementBaseUnitQty: (productId: string, baseUnitIndex: number) => (dispatch: Dispatch<any>) => { dispatch(decrementBaseUnitQ(productId, baseUnitIndex,)); },
    updateBaseUnitQty: (productId: string, baseUnitId: string, newCount: number) => (dispatch: Dispatch<any>) => { dispatch(updatetBaseUnitQ(productId, baseUnitId, newCount)); },
    updateProductQty: (productId: string, qtyId: string, newCount: number) => (dispatch: Dispatch<any>) => { dispatch(updatetProductQ(productId, qtyId, newCount)); },
    convertWeight: (productId: string, baseUnitId: string, weightType: string) => (dispatch: Dispatch<any>) => { dispatch(changeWeightType(productId, baseUnitId, weightType)); },

};