import antonnyprof from "../../../assets/images/antonnyprof.svg"
import annanas from '../../../assets/images/annanas.svg'
import fish from '../../../assets/images/fish.svg'
import cannedfood from '../../../assets/images/cannedfood.svg'
import tinnedfood from '../../../assets/images/tinnedfood.svg'
import tomato from "../../../assets/images/tomato.svg"
import { NewPurchaseType } from "../../../types"

export const PoData = [
    { id: '1106', orderDate: 'July 25,2022', time: "9:00", user: "user1", to: "Kitchen Beirut", status: "Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023' },
    { id: '1107', orderDate: 'July 25,2022', time: "9:00", user: "user2", to: "Kitchen Beirut", status: "Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1108', orderDate: 'July 25,2022', time: "9:00", user: "user3", to: "Kitchen Beirut", status: "Partially Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1109', orderDate: 'sep 25,2022', time: "9:00", user: "user4", to: "Kitchen Beirut", status: "Credit Note", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1102', orderDate: 'July 25,2022', time: "9:00", user: "user5", to: "Kitchen Beirut", status: "Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1104', orderDate: 'July 25,2022', time: "9:00", user: "user6", to: "Kitchen Beirut", status: "Credit Note", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1105', orderDate: 'July 25,2022', time: "9:00", user: "user7", to: "Kitchen Beirut", status: "Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '110', orderDate: 'July 25,2022', time: "9:00", user: "user8", to: "Kitchen Beirut", status: "Credit Note", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1101', orderDate: 'July 25,2022', time: "9:00", user: "user9", to: "Kitchen Beirut", status: "Partially Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '1103', orderDate: 'July 25,2022', time: "9:00", user: "user3", to: "Kitchen Beirut", status: "Partially Received", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
    { id: '11010', orderDate: 'July 25,2022', time: "9:00", user: "user5", to: "Kitchen Beirut", status: "Credit Note", OrderPrepared: [{ date: '18 Oct 2023', itemNumer: "12" }], orderSentDate: '22 Oct 2023', },
]

export const PurchasedProductsData: NewPurchaseType[] = [

    {
        id: "A123",
        refferenceNumber: 96334,
        supplierName: " Lucas Schultz",
        type: 'Poultry & Eggs',
        productname: 'corn',
        price: 300,
        weight: 700,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: tinnedfood,
        itemname: "potato",
        itemimage: tinnedfood,
        itemweight: '30',
        expirydate: 'none',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'a1', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'a2', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '124-ps-corn-3',
                brandname: 'Plein Soleil',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.10",
                can: "0.1",
                canAvg: "0.20",
                deliveryDuration: "2",
                canWeight: 300,
                cansPerBox: 20,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '125-ps-corn-3',
                brandname: 'Maxims',
                supplierName: "Antony Antonny",
                gram: "0.50",
                gramAvg: "0.70",
                can: "0.5",
                canAvg: "0.70",
                deliveryDuration: "7",
                canWeight: 200,
                cansPerBox: 24,
                lastPurchase: "27/11/2023",
                favorite: true,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '126-ps-corn-3',
                brandname: 'Heinz',
                supplierName: "Lucas Lucas",
                gram: "0.20",
                gramAvg: "0.20",
                can: "0.2",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 400,
                cansPerBox: 16,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "B145",
        refferenceNumber: 123477,
        supplierName: " Lucas Schultz",
        type: 'Canned Food',
        productname: 'Humus',
        price: 300,
        weight: 600,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: cannedfood,
        itemname: "Annanas",
        itemimage: cannedfood,
        itemweight: '60',
        expirydate: '27/11/2023',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'b1', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'b2', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '174-ps-humus-7',
                brandname: 'Cedars',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.50",
                can: "0.3",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 300,
                cansPerBox: 18,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '554-ps-humus-3',
                brandname: 'Cortas',
                supplierName: "George luchi",
                gram: "0.5",
                gramAvg: "0.70",
                can: "0.7",
                canAvg: "0.80",
                deliveryDuration: "7",
                canWeight: 300,
                cansPerBox: 17,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "jj778",
        refferenceNumber: 96334,
        supplierName: " Lucas Schultz",
        type: 'Poultry & Eggs',
        productname: 'corn',
        price: 300,
        weight: 700,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: tinnedfood,
        itemname: "potato",
        itemimage: tinnedfood,
        itemweight: '30',
        expirydate: 'none',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'a188', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'a200', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: '124',
                brandname: 'Plein Soleil',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.10",
                can: "0.1",
                canAvg: "0.20",
                deliveryDuration: "2",
                canWeight: 300,
                cansPerBox: 20,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '988',
                brandname: 'Maxims',
                supplierName: "Antony Antonny",
                gram: "0.50",
                gramAvg: "0.70",
                can: "0.5",
                canAvg: "0.70",
                deliveryDuration: "7",
                canWeight: 200,
                cansPerBox: 24,
                lastPurchase: "27/11/2023",
                favorite: true,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '556',
                brandname: 'Heinz',
                supplierName: "Lucas Lucas",
                gram: "0.20",
                gramAvg: "0.20",
                can: "0.2",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 400,
                cansPerBox: 16,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "j7kl",
        refferenceNumber: 96334,
        supplierName: " Lucas Schultz",
        type: 'Poultry & Eggs',
        productname: 'corn',
        price: 300,
        weight: 700,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: tinnedfood,
        itemname: "potato",
        itemimage: tinnedfood,
        itemweight: '30',
        expirydate: 'none',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'a199', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'a2oo', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: 'oopp99',
                brandname: 'Plein Soleil',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.10",
                can: "0.1",
                canAvg: "0.20",
                deliveryDuration: "2",
                canWeight: 300,
                cansPerBox: 20,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '55tt',
                brandname: 'Maxims',
                supplierName: "Antony Antonny",
                gram: "0.50",
                gramAvg: "0.70",
                can: "0.5",
                canAvg: "0.70",
                deliveryDuration: "7",
                canWeight: 200,
                cansPerBox: 24,
                lastPurchase: "27/11/2023",
                favorite: true,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: 'll93',
                brandname: 'Heinz',
                supplierName: "Lucas Lucas",
                gram: "0.20",
                gramAvg: "0.20",
                can: "0.2",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 400,
                cansPerBox: 16,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },
    {
        id: "ll123",
        refferenceNumber: 96334,
        supplierName: " Lucas Schultz",
        type: 'Poultry & Eggs',
        productname: 'corn',
        price: 300,
        weight: 700,
        brandname: '',
        purchase: '1.57',
        availableqty: '60kg',
        levelstatus: 'none',
        lastdate: '09 Sep 2023',
        imageUrl: tinnedfood,
        itemname: "potato",
        itemimage: tinnedfood,
        itemweight: '30',
        expirydate: 'none',
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { id: 'a1p', qtyType: 'Quantity on Hand', count: 0, weightType: 'Kg' },
            { id: 'ao', qtyType: 'Quantity Requested', count: 0, weightType: 'Kg' }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
        productBrands: [
            {
                id: 'p9',
                brandname: 'Plein Soleil',
                supplierName: "George luchi",
                gram: "0.1",
                gramAvg: "0.10",
                can: "0.1",
                canAvg: "0.20",
                deliveryDuration: "2",
                canWeight: 300,
                cansPerBox: 20,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: '9055tt',
                brandname: 'Maxims',
                supplierName: "Antony Antonny",
                gram: "0.50",
                gramAvg: "0.70",
                can: "0.5",
                canAvg: "0.70",
                deliveryDuration: "7",
                canWeight: 200,
                cansPerBox: 24,
                lastPurchase: "27/11/2023",
                favorite: true,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
            {
                id: 'll9773',
                brandname: 'Heinz',
                supplierName: "Lucas Lucas",
                gram: "0.20",
                gramAvg: "0.20",
                can: "0.2",
                canAvg: "0.60",
                deliveryDuration: "3",
                canWeight: 400,
                cansPerBox: 16,
                lastPurchase: "27/11/2023",
                favorite: false,
                upriceonhand: 240,
                upricereq: 10,
                quantity: [
                    { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
                    { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0, }
                ],
                costonhand: 0,
                costReq: 0,
                discountAED: 0,
                discountPercentage: 0,
                totalPrice: 0,
                finalPrice: 0,
            },
        ]
    },

    
]