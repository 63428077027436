import { OutgoingRequest } from "../../redux/actions/outgoingRequestActions"

export const RequestData: OutgoingRequest[] = [
  {
    id: 16001,
    user: 'User 1',
    date: 'July 25,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen beirut',
    status: 'Approved',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'a1',
        name: 'Tabbouleh',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 15,
          },
          {
            date: '22/2/2022',
            count: 10,
          }

        ]
      },
      {
        id: 'a2',
        name: 'Fatteh',
        requested: 5,
        approved: 2,
        oh: 1,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'a3',
        name: 'Fatoush',
        requested: 8,
        approved: 7,
        oh: 6,
        date: [
          {
            date: '11/11/2022',
            count: 3,
          },
          {
            date: '11/11/2022',
            count: 2,
          }

        ]
      },
      {
        id: 'a4',
        name: 'Pasta',
        requested: 40,
        approved: 8,
        oh: 0,
        date: [
          {
            date: '07/07/2023',
            count: 0,
          },
          {
            date: '08/08/2023',
            count: 0,
          }

        ]
      },
      {
        id: 'a5',
        name: 'Kafta',
        requested: 5,
        approved: 4,
        oh: 1,
        date: [
          {
            date: '11/11/2022',
            count: 2,
          },
          {
            date: '11/11/2022',
            count: 1,
          }

        ]
      },
    ]
  },
  {
    id: 16002,
    user: 'User 2',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen damascus',
    status: 'Rejected',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '',
    products: [
      {
        id: 'b1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'b2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16003,
    user: 'User 3',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen bey',
    status: 'Pending',
    orderDate: '18 Oct 2023',
    acceptanceDate: 'Processing',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'c1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'c2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16004,
    user: 'User 4',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen joun',
    status: 'Delivered',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '30 Oct 2023',
    products: [
      {
        id: 'd1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'd2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16005,
    user: 'User 5',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen ghazir',
    status: 'Rejected',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '',
    products: [
      {
        id: 'e1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'e2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16007,
    user: 'User cr7',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen jeddah',
    status: 'Delivered',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '30 Oct 2023',
    products: [
      {
        id: 'f1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'f2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16009,
    user: 'User 9',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen dubai',
    status: 'Rejected',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '',
    products: [
      {
        id: 'g1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'g2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16006,
    user: 'User 6',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen homos',
    status: 'Pending',
    orderDate: '18 Oct 2023',
    acceptanceDate: 'Processing',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'h1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'h2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 16008,
    user: 'User 8',
    date: 'July 26,2022 9:00 PM',
    from: ' Central kitchen',
    to: 'kitchen paris',
    status: 'Delivered ',
    orderDate: '18 Oct 2023',
    acceptanceDate: '22 Oct 2023',
    deliveryDate: '30 Oct 2023',
    products: [
      {
        id: 'i1',
        name: 'Hommos',
        requested: 25,
        approved: 25,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 0,
          },
          {
            date: '22/2/2022',
            count: 0,
          }

        ]
      },
      {
        id: 'i2',
        name: 'Tabbouleh',
        requested: 20,
        approved: 15,
        oh: 10,
        date: [
          {
            date: '11/11/2022',
            count: 0,
          },
          {
            date: '11/11/2022',
            count: 0,
          }

        ]
      }

    ]
  },
  {
    id: 20022,
    user: 'EKS',
    date: 'Feb 22,2022 2:22 AM',
    from: 'Breaking Kitchen',
    to: 'Albacurque',
    status: 'Approved',
    orderDate: '20 Feb 2022',
    acceptanceDate: '22 Feb 2022',
    deliveryDate: 'Processing',
    products: [
      {
        id: 'j1',
        name: 'Tabbouleh',
        requested: 22,
        approved: 20,
        oh: 1,
        date: [
          {
            date: '22/2/2022',
            count: 15,
          },
          {
            date: '22/2/2022',
            count: 5,
          }

        ]
      },
      {
        id: 'j2',
        name: 'Taouk',
        requested: 22,
        approved: 22,
        oh: 22,
        date: [
          {
            date: '22/22/2022',
            count: 11,
          },
          {
            date: '22/22/2022',
            count: 11,
          }

        ]
      },
      {
        id: 'j3',
        name: '57',
        requested: 22,
        approved: 2,
        oh: 1,
        date: [
          {
            date: '22/22/2022',
            count: 1,
          },
          {
            date: '22/22/2022',
            count: 1,
          }

        ]
      },
      {
        id: 'j4',
        name: 'Burger',
        requested: 22,
        approved: 22,
        oh: 2,
        date: [
          {
            date: '22/22/2022',
            count: 20,
          },
          {
            date: '22/22/2022',
            count: 2,
          }

        ]
      },
      {
        id: 'j5',
        name: 'Chicken Sub',
        requested: 22,
        approved: 22,
        oh: 22,
        date: [
          {
            date: '22/22/2022',
            count: 15,
          },
          {
            date: '22/22/2022',
            count: 7,
          }

        ]
      },
      {
        id: 'j6',
        name: 'Escalope',
        requested: 22,
        approved: 22,
        oh: 5,
        date: [
          {
            date: '22/22/2022',
            count: 8,
          },
          {
            date: '22/22/2022',
            count: 14,
          }

        ]
      },
      {
        id: 'j7',
        name: 'Balila',
        requested: 50,
        approved: 30,
        oh: 30,
        date: [
          {
            date: '22/22/2022',
            count: 22,
          },
          {
            date: '22/22/2022',
            count: 7,
          }

        ]
      }
    ]
  },
]

