import { Grid, useTheme, Button, } from '@mui/material';
//Redux
import { getDictionary, getField } from '../../../utils/translation/translation';
import { connect } from "react-redux";
import { Dispatch } from 'redux';
//Types
import { TranslationType } from "../../../types/translationTypes";
//Design
import useStyles from './styles';
import { CustomTheme } from "../../../theme";

interface AlertModalProps { translation: TranslationType[]; language: string; desktopView?: boolean; mobileView?: boolean; alert: string, closeModal: any }

const AlertModal: React.FC<AlertModalProps> = ({ translation, language, desktopView, mobileView, alert, closeModal }) => {

    const Dictionary = getDictionary(translation, "request-details", language);
    const classes = useStyles();
    const theme: CustomTheme = useTheme();

    return (
        <div className={classes.MultiBrandModal} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>  <div className={classes.HorizCentering}>
            <Grid container className={classes.PendingModal} flexDirection='column' gap='5px'>
                <Grid item sx={{ fontSize: "25px", textAlign: 'center' }}><b>{alert}</b></Grid>
                <Button className={classes.bigBtn} variant="contained" onClick={closeModal}>Close</Button>
            </Grid>
        </div></div>
    );
}
const mapStateToProps = (state: any) => ({ translation: state.translation.translation, language: state.translation.language, });
const mapDispatchToProps = (dispatch: Dispatch<any>) => { return {} }
export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);